(function () {
    "use strict";

    angular.module("taskServiceApp").config(stateConfig);
    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("trip-model", {
                parent: "entity",
                url: "/trip-model?page&sort&search&searchType&appSource&token&account",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.tripModel.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/trip-model/trip-models.html",
                        controller: "TripModelController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    searchType: {
                        value: "0",
                        squash: true,
                    },
                    search: null,
                    appSource: null,
                    token: null,
                    account: null,
                    login: false,
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("tripModel");
                            $translatePartialLoader.addPart("global");
                            $translatePartialLoader.addPart("eTripType");
                            $translatePartialLoader.addPart("tripStatusType");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("trip-model-detail", {
                parent: "trip-model",
                url: "/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "线路详情",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/trip-model/trip-model-detail.html",
                        controller: "TripModelDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "TripModel",
                        function ($stateParams, TripModel) {
                            return TripModel.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("tripModel");
                            $translatePartialLoader.addPart("eTripType");
                            $translatePartialLoader.addPart("tripStatusType");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("trip-model-detail.edit", {
                parent: "trip-model-detail",
                url: "/detail/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip-model/trip-model-dialog.html",
                                controller: "TripModelDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "TripModel",
                                        function (TripModel) {
                                            return TripModel.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    // $state.go('^', {}, { reload: true });
                                    window.history.go(-1);
                                },
                                function () {
                                    // $state.go('^');
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("trip-model.new", {
                parent: "trip-model",
                url: "s/new",
                data: {
                    authorities: ["ROLE_USER"],
                },
                params: {
                    type: "0",
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip-model/trip-model-dialog.html",
                                controller: "TripModelDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: function () {
                                        return {
                                            type: null,
                                            title: null,
                                            attribute: null,
                                            area: null,
                                            createDate: null,
                                            createBy: null,
                                            updateDate: null,
                                            updateBy: null,
                                            id: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    window.location.href =
                                        window.location.origin +
                                        "/#/trip-model";
                                    window.location.reload();
                                    return false;
                                },
                                function () {
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("trip-model.edit", {
                parent: "trip-model",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip-model/trip-model-dialog.html",
                                controller: "TripModelDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "TripModel",
                                        function (TripModel) {
                                            return TripModel.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("trip-model", null, {
                                        reload: "trip-model",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("trip-model-detail-copy", {
                parent: "trip-model-detail",
                url: "/{id}/copy",
                data: {
                    authorities: ["ROLE_USER"],
                },
                params: {
                    id: "",
                    upgrade: false,
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip-model/trip-model-copy-dialog.html",
                                controller: "TripModelCopyController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "TripModel",
                                        function (TripModel) {
                                            return TripModel.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    // $state.go('trip-model', null, { reload: 'trip-model' });
                                    // window.history.go(-1);
                                },
                                function () {
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("trip-model.delete", {
                parent: "trip-model-detail",
                url: "/delete",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip-model/trip-model-delete-dialog.html",
                                controller: "TripModelDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "TripModel",
                                        function (TripModel) {
                                            return TripModel.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("trip-model", null, {
                                        reload: "trip-model",
                                    });
                                },
                                function () {
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            });
    }
})();
