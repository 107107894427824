(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("VersionDescDetailController", VersionDescDetailController);

    VersionDescDetailController.$inject = [
        "$scope",
        "$rootScope",
        "$stateParams",
        "previousState",
        "entity",
        "VersionDesc",
        "Principal",
        "$sce",
    ];

    function VersionDescDetailController(
        $scope,
        $rootScope,
        $stateParams,
        previousState,
        entity,
        VersionDesc,
        Principal,
        $sce
    ) {
        var vm = this;

        vm.versionDesc = entity;
        vm.previousState = previousState.name;

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.versionDesc.description = $sce.trustAsHtml(
            vm.versionDesc.description
        );

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:versionDescUpdate",
            function (event, result) {
                vm.versionDesc = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
