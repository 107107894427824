(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("ProgramCenterController", controller)
        .filter("descFilter", function () {
            return function (text) {
                var subStr = text.substring(0, 18);
                return subStr + (text.length > 18 ? "..." : "");
            };
        })
        .filter("labelFilter", function () {
            return function (text) {
                if (text == null) {
                    return "";
                }
                var arr = JSON.parse(text);
                return arr;
            };
        });
    controller.$inject = [
        "ProgramCenterSearch",
        "$http",
        "AlertService",
        "$cookies",
        "$scope",
        "$stateParams",
    ];
    function controller(
        ProgramCenterSearch,
        $http,
        AlertService,
        $cookies,
        $scope,
        $stateParams
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.page = $stateParams.page;
        vm.sort = $stateParams.sort;
        vm.itemsPerPage = 20;
        vm.programType = $stateParams.programType;
        vm.searchQuery = $stateParams.search;
        vm.doSaveClickNum = function (id) {
            var strAction = "方案-从方案中心点击-" + id;
            md(strAction);
            $http({
                method: "POST",
                url: "/api/program/saveNum",
                params: {
                    id: id,
                    type: 1,
                },
            }).then(
                function (res) {},
                function (err) {
                    console.log(err);
                }
            );
        };

        vm.ad = null;
        function getAdByType() {
            // 方案中心顶部广告
            $http({
                method: "GET",
                url: "/api/ad/getByType/" + 1,
            }).then(
                function (res) {
                    console.log(res);
                    if (res.data != null) {
                        vm.ad = res.data[0];
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
            // 只有从登录界面跳转到该界面才展示登录弹窗广告
            if ($stateParams.login) {
                $http({
                    method: "GET",
                    url: "/api/ad/getByType/" + 2,
                }).then(
                    function (res) {
                        console.log(res);
                        if (res.data != null && res.data.length > 0) {
                            vm.adModel = res.data[0];
                            $("#loginAd").modal("show");
                        } else {
                            // 页面加载版本更新日志
                            getVersion();
                        }
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            } else {
                getVersion();
            }
        }

        vm.adModleClose = function () {
            $("#loginAd").modal("hide");
            // 页面加载版本更新日志
            getVersion();
        };

        vm.toAd = toAd;
        function toAd(link, id, type) {
            var strAction = "";
            if (type == 1) {
                strAction = "广告-从方案中心点击-" + id;
            } else if (type == 2) {
                strAction = "广告-登录后首屏-" + id;
            }
            md(strAction);

            var strAction1 = "";
            if (link.indexOf("program-center-detail") != -1) {
                var arr = link.split("/");
                if (type == 1) {
                    strAction1 = "方案-从顶部banner点击-" + arr[arr.length - 1];
                } else if (type == 2) {
                    strAction1 = "方案-从首屏广告点击-" + arr[arr.length - 1];
                }
                md(strAction1);
            }
            if (link == null || link.trim().length == 0) {
                return;
            }
            window.open(link, "_blank");
        }

        function md(strAction) {
            $http({
                method: "POST",
                url: "/api/mdLog/save",
                params: {
                    type: 1,
                    action: strAction,
                },
            }).then(
                function (res) {},
                function (err) {
                    console.log(err);
                }
            );
        }

        //防止登录时候用户来回点击，没法进入指向登录的页面
        var loginUrl = $cookies.get("loginUrl");
        if (loginUrl != null && loginUrl.length > 0) {
            $cookies.remove("loginUrl");
            if (loginUrl.indexOf("copy=true") != -1) {
                loginUrl = loginUrl.replace("copy=true", "");
                //防止线路多次复制使用cookies
                var expireDate = new Date();
                expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000); // cookies10分钟有效期
                $cookies.put("copy", true, { expires: new Date(expireDate) });
            }
            window.location.href = loginUrl;
        }

        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                for (var i = 0; i < vm.account.authorities.length; i++) {
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN") {
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER") {
                        vm.isSuper = true;
                    }
                }
                if (vm.account !== null) {
                    if ($(window).width() < 767) {
                        if (
                            !vm.account.mobileTipShowFlag &&
                            !sessionStorage.getItem("asfxf")
                        ) {
                            sessionStorage.setItem("asfxf", "1211");
                            $("#myModalPh").modal("show");
                        } else {
                            // 发布页面灰色背景关闭
                            $(".modal-backdrop").remove();
                            getAdByType();
                        }
                    } else if (
                        !vm.account.navberTipShowFlag &&
                        document.getElementById("newuserguide1")
                    ) {
                        // navberTipShowFlag
                        console.log(
                            "触发了新用户引导=================================="
                        );
                        document.getElementById("newuserguide").style.display =
                            "block";
                        // 插入视频dom节点
                        // <video src="https://image.itasktour.com/WX/ittr/welcome.mp4" controls autoplay></video>

                        // 去掉新用户引导视频
                        // var videoDom=document.createElement('video');
                        // videoDom.src="https://image.itasktour.com/WX/ittr/welcome.mp4";
                        // videoDom.controls=true;
                        // videoDom.autoplay=true;
                        // document.querySelector('#newuserguide .video-container').appendChild(videoDom);
                        // document.querySelector('.video-container>.close-video').addEventListener('click',function(){
                        // console.dir(this);
                        // document.getElementById('newuserguide').removeChild(this.parentNode);
                        // $http({
                        //     url: 'api/tip/change-status/2',
                        //     method: 'POST'
                        // }).then(function (res) {
                        //     console.log("修改结果：" + JSON.stringify(res));
                        // });
                        // });
                        document.querySelector(
                            "#newuserguide .video-container"
                        ).style.display = "none";
                        document
                            .getElementById("newuserguide1")
                            .addEventListener("click", newuserguideFn);
                        document.getElementById("newuserguide1").style.zIndex =
                            "999";
                        document.getElementById("newuserguide1").style.color =
                            "#f44158";
                        document.getElementById(
                            "newuserguide1"
                        ).style.backgroundColor = "rgba(255,255,255,0.2)";
                        document.querySelector(
                            "#newuserguide1 .new-user-guide"
                        ).style.display = "block";
                        document
                            .getElementById("newuserguide2")
                            .addEventListener("click", newuserguideFn);
                        document
                            .getElementById("newuserguide3")
                            .addEventListener("click", newuserguideFn);
                        // 去掉dom节点hover效果 onmouseover
                        document
                            .getElementById("newuserguide2")
                            .addEventListener("mouseover", disableHover);
                        document
                            .getElementById("newuserguide3")
                            .addEventListener("mouseover", disableHover);
                    } else {
                        getAdByType();
                    }

                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                    //防止导航栏和下角标消失
                    $(".container").removeClass("login-container-content");
                    $("#nav, #foot").css("display", "block");
                }
            });
        }

        // 禁用hover效果
        function disableHover() {
            event.stopPropagation();
            event.preventDefault();
            this.style.color = "#ffffff";
            return false;
        }
        function newuserguideFn() {
            event.stopPropagation();
            event.preventDefault();
            var id = this.dataset.id;
            this.style.zIndex = "unset";
            this.style.color = "unset";
            this.style.backgroundColor = "transparent";
            this.querySelector(".new-user-guide").style.display = "none";
            if (id == 3) {
                document.getElementById("newuserguide").style.display = "none";
                document
                    .getElementById("newuserguide1")
                    .removeEventListener("click", newuserguideFn);
                document
                    .getElementById("newuserguide2")
                    .removeEventListener("mouseover", disableHover);
                document
                    .getElementById("newuserguide2")
                    .removeEventListener("click", newuserguideFn);
                document
                    .getElementById("newuserguide3")
                    .removeEventListener("mouseover", disableHover);
                document
                    .getElementById("newuserguide3")
                    .removeEventListener("click", newuserguideFn);
                getAdByType();
            } else {
                var nextDom = document.getElementById(
                    "newuserguide" + (Number(id) + 1)
                );
                nextDom.style.zIndex = "999";
                nextDom.style.backgroundColor = "rgba(255,255,255,0.2)";
                nextDom.querySelector(".new-user-guide").style.display =
                    "block";
                if (id == 1) {
                    $http({
                        url: "api/tip/change-status/2",
                        method: "POST",
                    }).then(function (res) {
                        console.log("修改结果：" + JSON.stringify(res));
                    });
                }
            }
            this.parentNode.blur();
        }

        vm.transition = function (params) {
            if (params === 1) {
                vm.page = 1;
            }
            ProgramCenterSearch.query(
                {
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    type: vm.programType,
                    query: vm.searchQuery,
                    sort: vm.sort,
                    release: "0",
                },
                onSuccess,
                onError
            );
            function onSuccess(data, headers) {
                vm.totalItems = headers("X-Total-Count");
                vm.programs = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        vm.transition();
        vm.keyDown = function (event) {
            if (event.keyCode === 13) {
                vm.transition(1);
            }
        };
        vm.selectType = function (type) {
            if (vm.programType === type) {
                return false;
            } else {
                vm.programType = type;
            }
            vm.transition(1);
        };

        //获取 版本更新日志信息
        function getVersion() {
            $http({
                method: "GET",
                url: "/api/newest-version-descs",
            }).then(
                function (res) {
                    console.log("res=", res);
                    //判断获取到的最新的日志是否给用户展示过，是否展示的判断依据是本地是否缓存日志，缓存的日志是否与要展示的日志一样；
                    var updateLog = JSON.parse(
                        window.localStorage.getItem("updateLog")
                    );
                    if (!updateLog || updateLog.name !== res.data.name) {
                        vm.versionDesc = res.data;
                        window.localStorage.setItem(
                            "updateLog",
                            JSON.stringify(vm.versionDesc)
                        );
                        $("#myUpdateLogModal").modal("show");
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }
        //更新提示页面
        vm.toLogin = function () {
            $("#myUpdateLogModal").modal("hide");
        };
        // //电脑提示首次登录关闭
        vm.pcLgoinBtn = pcLgoinBtn;
        function pcLgoinBtn() {
            $http({
                url: "api/tip/change-status/0",
                method: "POST",
            }).then(function (res) {
                console.log("修改结果：" + JSON.stringify(res));
            });
            getAdByType();
        }

        vm.breakPhoneWarn = breakPhoneWarn;
        function breakPhoneWarn() {
            if (vm.phoneLogin) {
                vm.account.mobileTipShowFlag = true;
                $http({
                    url: "api/tip/change-status/" + 1,
                    method: "POST",
                }).then(function (res) {
                    console.log("修改结果：" + JSON.stringify(res));
                });
            }
            getAdByType();
        }

        //手机提示首次登陆以后不再弹出
        vm.phoneLogin = false;
        vm.breakPhoneWarn = breakPhoneWarn;
        function breakPhoneWarn() {
            if (vm.phoneLogin) {
                $http({
                    url: "api/tip/change-status/" + 1,
                    method: "POST",
                }).then(function (res) {
                    console.log("修改结果：" + JSON.stringify(res));
                });
            }
        }

        $scope.$on("$destroy", function () {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
            $("body").removeClass("modal-open");
        });
    }
})();
