(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ConfirmJoinController', ConfirmJoinController);

    ConfirmJoinController.$inject = ['$scope', '$stateParams', '$state', '$uibModal','$http', '$uibModalInstance', '$cookies'];

    function ConfirmJoinController ($scope, $stateParams, $state, $uibModal, $http, $uibModalInstance, $cookies) {
        var vm = this;
        vm.eAccountName = decodeURI($stateParams.eName);
        vm.fromName = decodeURI($stateParams.uName);
        vm.login = false;
        getAccount();
        function getAccount() {//主要判断是否登录
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.login = true;
                vm.account = res.data;
            });
        }
        vm.join = join;
        function join() {
            // eid企业id
            // uid用户id
            $uibModalInstance.dismiss('cancel');
            // var expireDate = new Date();
            // expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000); // cookies10分钟有效期
            // $cookies.put('eid', $stateParams.eid, {expires: new Date(expireDate)});
            // $cookies.put('uid', $stateParams.uid, {expires: new Date(expireDate)});
            // eid当前用户被邀请加入的企业的id，uid当前被邀请加入企业的用户的id
            if(vm.login){
                //如果登录直接跳转当前页面，并且带参数
                $state.go("e-account-my",{eid:$stateParams.eid,uid:$stateParams.uid},{reload:false});
            }else{
                //如果为登录，直接前往登录，
                var location = window.location.host;
                window.location.href = window.location.protocol+"//"+location + "/#/?eid=" + $stateParams.eid + "&uid=" + $stateParams.uid;
            }
        }
    }
})();
