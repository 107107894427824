(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('VersionDescDialogController', controller);

    controller.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'VersionDesc','$sce'];
    function controller ($timeout, $scope, $stateParams, $uibModalInstance, entity, VersionDesc,$sce) {
        var vm = this;
        vm.versionDesc = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        if(!vm.versionDesc.appSource){
            vm.versionDesc.appSource='1';
        }
        vm.isDisable= $stateParams.isDisable;
        // vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
        if (vm.versionDesc.id != null) {
            vm.versionDesc.description = vm.versionDesc.description.replace(/<br\/>/g, "\n");
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.versionDesc.id !== null) {
                VersionDesc.update(vm.versionDesc, onSaveSuccess, onSaveError);
            } else {
                VersionDesc.save(vm.versionDesc, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:versionDescUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.create_date = false;
        vm.datePickerOpenStatus.update_date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
