(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("NavbarController", NavbarController);
    NavbarController.$inject = [
        "$scope",
        "Principal",
        "$http",
        "$state",
        "Auth",
        "ProfileService",
        "EAccount",
    ];
    function NavbarController(
        $scope,
        Principal,
        $http,
        $state,
        Auth,
        ProfileService,
        EAccount
    ) {
        var vm = this;
        vm.isNavbarCollapsed = true;
        // 是否是魔法特工品牌管理员
        vm.mftgManager = false;
        vm.isAuthenticated = Principal.isAuthenticated;
        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.change1 = change1;
        vm.show1 = show1;
        vm.change2 = change2;
        vm.show2 = show2;
        vm.change3 = change3;
        vm.show3 = show3;
        vm.change4 = change4;
        vm.show4 = show4;
        vm.change5 = change5;
        vm.show5 = show5;
        vm.change6 = change6;
        vm.show6 = show6;
        vm.change7 = change7;
        vm.show7 = show7;
        vm.change8 = change8;
        vm.show8 = show8;
        vm.change9 = change9;
        vm.show9 = show9;
        vm.show10 = show10;
        vm.change11 = change11;
        vm.show11 = show11;
        vm.show13 = show13;
        vm.change13 = change13;
        vm.show14 = show14;
        vm.change14 = change14;
        vm.show15 = show15;
        vm.change15 = change15;
        vm.signUp = false;
        vm.changeFAZX = changeFAZX;
        vm.showFAZX = showFAZX;
        $scope.$on("authenticationSuccess", function () {
            getAccount();
        });
        vm.eAccountStatus = "";
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    if (
                        vm.account.avatar == null ||
                        vm.account.avatar.length < 0
                    ) {
                        vm.account.avatar =
                            "/USER/23/location_task/p1599805459189_152c81a0-aa38-4b17-af08-392bf85c1e75.png";
                    }
                    var author = vm.account.authorities;
                    vm.nick = vm.account.firstName.replace(/_mftg/g, "");
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                        vm.author = "超级管理员";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                        vm.author = "运营管理";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                        vm.author = "兼职设计师";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                        vm.author = "独立设计师";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                        vm.author = "全职设计师";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                        vm.author = "普通用户";
                    }
                    // 判断用户在魔法特工品牌下的权限
                    if (
                        vm.account.authorities.indexOf("ROLE_MFTG_MANAGER") > -1
                    ) {
                        vm.mftgManager = true;
                        vm.auth = "MftgManager";
                        vm.author = "品牌管理员";
                    }
                    //判断该用户所属企业有没有公开报名活动权限
                    if (vm.account.eAccountId != null) {
                        EAccount.get(
                            { id: vm.account.eAccountId },
                            function (res) {
                                if (res != null && res.signUpAuth) {
                                    vm.signUp = true;
                                }
                            },
                            function (err) {
                                console.log(err);
                            }
                        );
                        $http({
                            url: "api/e-accounts/my-e-account",
                            method: "GET",
                        }).then(function (res) {
                            vm.eAccountStatus = res.data.status;
                        });
                    }
                    //判断哪些账号可以设置优惠券
                    // console.log(vm.account.login);
                    var admin_user = [
                        18016073337,
                        18917615957,
                        13918747480,
                        13916144547,
                        "admin",
                    ];
                    for (var i = 0; i < admin_user.length; i++) {
                        // console.log(admin_user[i]);
                        if (admin_user[i] == vm.account.login) {
                            vm.canSetCoupon = true;
                        }
                    }
                    //下载结算和充值报表
                    var finance_user = [13801778172];
                    for (var i = 0; i < finance_user.length; i++) {
                        // console.log(admin_user[i]);
                        if (finance_user[i] == vm.account.login) {
                            vm.canDownload = true;
                        }
                    }
                    var avatar = vm.account.avatar;
                    if (
                        avatar != null &&
                        avatar.indexOf(vm.IMAGE_SERVER) === -1 &&
                        avatar.indexOf("http") === -1
                    ) {
                        vm.account.avatar = vm.IMAGE_SERVER + avatar;
                    }
                }
            });
        }

        vm.showList = false;
        vm.list = list;

        function list() {
            vm.showList = true;
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            var date = new Date();
            date.setTime(date.getTime() - 1);
            document.cookie =
                "isV3LoginUser=isV3LoginUser;Path=/;domain=itasktour.com;expires=" +
                date.toGMTString();
            //退出到后台登录页
            if (vm.account.appSource === "2") {
                window.location.href = window.location.origin + "/#/mftglogin";
            } else {
                window.location.href = window.location.origin + "/#/";
            }
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
        vm.coach = function () {
            vm.isNavbarCollapsed = true;
            if (vm.checkPhone()) {
                var judgeUrl = "";
                if (
                    window.location.origin.indexOf("localhost") > -1 ||
                    window.location.hostname.split(".").length === 4
                ) {
                    judgeUrl =
                        window.location.protocol +
                        "//" +
                        window.location.hostname +
                        ":8080/cd";
                } else {
                    judgeUrl =
                        "https://www.itasktour.com/" +
                        (window.location.origin.indexOf("dev") === -1
                            ? "coach"
                            : "cd");
                }
                window.location.href =
                    judgeUrl +
                    "?at=" +
                    vm.account.password +
                    "_coach&appSource=" +
                    vm.account.appSource;
            } else {
                $state.go(
                    "coach-web-hint",
                    { appSource: vm.account.appSource },
                    { reload: true }
                );
            }
        };
        function change1() {
            document.getElementById("pic1").src =
                "content/images/home-selected.png";
        }
        function show1() {
            document.getElementById("pic1").src =
                "content/images/home-normal.png";
        }
        function change2() {
            document.getElementById("pic2").src =
                "content/images/guide-selected.png";
        }
        function show2() {
            document.getElementById("pic2").src =
                "content/images/guide-normal.png";
        }
        function change3() {
            document.getElementById("pic3").src =
                "content/images/search-selected.png";
        }
        function show3() {
            document.getElementById("pic3").src =
                "content/images/search-normal.png";
        }
        function change4() {
            document.getElementById("pic4").src =
                "content/images/manage-selected.png";
        }
        function show4() {
            document.getElementById("pic4").src =
                "content/images/manage-normal.png";
        }
        function change5() {
            document.getElementById("pic5").src =
                "content/images/manage-selected.png";
        }
        function show5() {
            document.getElementById("pic5").src =
                "content/images/manage-normal.png";
        }
        function change6() {
            document.getElementById("pic6").src =
                "content/images/manage-selected.png";
        }
        function show6() {
            document.getElementById("pic6").src =
                "content/images/manage-normal.png";
        }
        function change7() {
            document.getElementById("pic7").src =
                "content/images/search-selected.png";
        }
        function show7() {
            document.getElementById("pic7").src =
                "content/images/search-normal.png";
        }
        function changeFAZX() {
            document.getElementById("picFAZX").src =
                "content/images/search-selected.png";
        }
        function showFAZX() {
            document.getElementById("picFAZX").src =
                "content/images/search-normal.png";
        }

        function change8() {
            document.getElementById("pic8").src =
                "content/images/company-selected.png";
        }
        function show8() {
            document.getElementById("pic8").src = "content/images/company.png";
        }
        function change9() {
            document.getElementById("pic9").src =
                "content/images/help-hover.png";
        }
        function show9() {
            document.getElementById("pic9").src = "content/images/help.png";
        }
        vm.checkPhone = function () {
            var sUserAgent = navigator.userAgent.toLowerCase();
            var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
            var bIsAndroid = sUserAgent.match(/android/i) == "android";
            return bIsIphoneOs || bIsAndroid;
        };
        function show10() {
            document.getElementById("pic10").src =
                "content/images/manage-normal.png";
        }
        function change11() {
            document.getElementById("pic10").src =
                "content/images/manage-selected.png";
        }
        function show11() {
            document.getElementById("pic10").src =
                "content/images/manage-normal.png";
        }
        vm.openWeb = openWeb;
        function openWeb() {
            var judgeUrl = "";
            if (
                window.location.origin.indexOf("localhost") > -1 ||
                window.location.hostname.split(".").length === 4 ||
                window.location.origin.indexOf("dev") !== -1
            ) {
                if (vm.account.appSource === "1") {
                    judgeUrl =
                        "http://dev.itasktour.com/index.php?go=ittrweb.index.index";
                } else if (vm.account.appSource === "2") {
                    judgeUrl = "https://www.mofategong.com";
                }
            } else {
                if (vm.account.appSource === "1") {
                    judgeUrl = "https://www.itasktour.com";
                } else if (vm.account.appSource === "2") {
                    judgeUrl = "https://www.mofategong.com";
                }
            }
            window.location.href = judgeUrl;
        }
        // 实景游戏
        function show13() {
            document.getElementById("pic13").src =
                "content/images/truegame-normal.png";
        }
        function change13() {
            document.getElementById("pic13").src =
                "content/images/truegame-selected.png";
        }
        // 培训游戏
        function show14() {
            document.getElementById("pic14").src =
                "content/images/traingame-normal.png";
        }
        function change14() {
            document.getElementById("pic14").src =
                "content/images/traingame-selected.png";
        }
        // 设计师
        function show15() {
            document.getElementById("pic15").src =
                "content/images/showdesign-normal.png";
        }
        function change15() {
            document.getElementById("pic15").src =
                "content/images/showdesign-selected.png";
        }
        vm.ident = function () {
            // console.log("1111");
            //刚注册时候登录，没有任务信息重新获取
            if (vm.account == null) {
                getAccount();
                return;
            }

            if (vm.account.eAccountId != null) {
                if (vm.account.eAccountConfirmEmail !== true) {
                    window.setTimeout(function () {
                        $state.go("e-account-my.edit", {}, { reload: true });
                    }, 300);
                } else {
                    window.setTimeout(function () {
                        $state.go("e-account-my", {}, { reload: true });
                    }, 300);
                }
            } else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init", {}, { reload: true });
                }, 300);
            }
        };

        vm.changeService = changeService;
        function changeService() {
            document.getElementById("service").src =
                "content/images/service-selected.png";
        }
        vm.showService = showService;
        function showService() {
            document.getElementById("service").src =
                "content/images/service-normal.png";
        }

        vm.changeBill = changeBill;
        function changeBill() {
            document.getElementById("bill").src =
                "content/images/bill-selected.png";
        }
        vm.showBill = showBill;
        function showBill() {
            document.getElementById("bill").src =
                "content/images/bill-normal.png";
        }
        vm.changeLeaders = changeLeaders;
        function changeLeaders() {
            document.getElementById("leaders").src =
                "content/images/leaders_selected.png";
        }
        vm.showLeaders = showLeaders;
        function showLeaders() {
            document.getElementById("leaders").src =
                "content/images/leaders_normal.png";
        }
    }
})();
