(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TripModelDeleteController", controller);

    controller.$inject = [
        "$uibModalInstance",
        "entity",
        "TripModel",
        "$uibModalStack",
    ];

    function controller($uibModalInstance, entity, TripModel, $uibModalStack) {
        var vm = this;
        if (entity.id == null || !entity.canShow) {
            vm.tripModel = {};
            setTimeout(function () {
                alert("无访问权限");
                clear();
            }, 100);
            return;
        }
        vm.tripModel = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalStack.dismissAll();
        }

        function confirmDelete(id) {
            TripModel.delete({ id: id }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
