(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('CheckEAccountDetailController', CheckEAccountDetailController);

    CheckEAccountDetailController.$inject = ['$scope', '$state','$rootScope', '$stateParams', 'previousState', 'entity', 'MyEAccountCheck'];

    function CheckEAccountDetailController($scope,$state, $rootScope, $stateParams, previousState, entity, MyEAccountCheck) {
        var vm = this;

        vm.eAccount = entity;
        vm.previousState = previousState.name;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.checkPass = checkPass;
        function checkPass() {
            if(vm.isSaving){
                return
            }
            vm.isSaving = true;
            vm.eAccount.pass = true;
            MyEAccountCheck.checkPass(vm.eAccount,onSaveSuccess,onSaveError);
        }
        function onSaveSuccess () {
            // vm.isSaving = false;
            // console.log(vm.eAccount);
            $state.go("check-pass-e-account");
        }
        function onSaveError () {
            vm.isSaving = false;
            $state.go("e-account");
        }

        vm.notPass =notPass;
        function notPass() {
            vm.eAccount.pass = false;
            // console.log(vm.eAccount.notPassReason);
            if (typeof vm.eAccount.notPassReasons==='undefined' || vm.eAccount.notPassReasons==null){
                alert("审核未通过原因不能为空！！");
            }else {
                if(vm.isSaving){
                    return
                }
                vm.isSaving = true;
                vm.eAccount.notPassReason = vm.eAccount.notPassReasons;
                MyEAccountCheck.checkPass(vm.eAccount,onSaveSuccessNot,onSaveErrorNot);
            }
        }
        function onSaveSuccessNot () {
            // vm.isSaving = false;
            $state.go("e-account");
        }
        function onSaveErrorNot () {
            vm.isSaving = false;
            $state.go("e-account");
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:eAccountUpdate', function(event, result) {
            vm.eAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
