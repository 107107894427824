(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("SettingsController", SettingsController);

    SettingsController.$inject = [
        "Principal",
        "Auth",
        "JhiLanguageService",
        "$translate",
        "$state",
        "$http",
    ];

    function SettingsController(
        Principal,
        Auth,
        JhiLanguageService,
        $translate,
        $state,
        $http
    ) {
        var vm = this;
        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.auth = null;

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
            };
        };

        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
        });

        getAccount();
        function getAccount() {
            //Principal.identity().then(function(account) {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                console.log(res);
                vm.account = res.data;
                if (vm.account !== null) {
                    for (var i = 0; i < vm.account.authorities.length; i++) {
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN") {
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE") {
                            vm.isRelease = true;
                        }
                    }

                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        function save() {
            Auth.updateAccount(vm.settingsAccount)
                .then(function () {
                    vm.error = null;
                    vm.success = "OK";
                    Principal.identity(true).then(function (account) {
                        vm.settingsAccount = copyAccount(account);
                    });
                    JhiLanguageService.getCurrent().then(function (current) {
                        if (vm.settingsAccount.langKey !== current) {
                            $translate.use(vm.settingsAccount.langKey);
                        }
                    });
                })
                .catch(function () {
                    vm.success = null;
                    vm.error = "ERROR";
                });
        }

        vm.ident = ident;
        function ident() {
            // console.log("1111");
            window.setTimeout(function () {
                $state.go("e-account-my.init", {}, { reload: true });
            }, 300);
        }
    }
})();
