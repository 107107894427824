(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("ActivateFreezeController", controller);
    controller.$inject = [
        "$http",
        "$cookies",
        "$scope",
        "$state",
        "entity",
        "EAccount",
        "Upload",
        "$stateParams",
        "LoginUrlService",
        "Principal",
    ];
    function controller(
        $http,
        $cookies,
        $scope,
        $state,
        entity,
        EAccount,
        Upload,
        $stateParams,
        LoginUrlService,
        Principal
    ) {
        var vm = this;
        vm.loginParam = $stateParams.loginParam;
        // 页面资源加载完毕（同步资源非文件类资源加载完毕DOMContentLoaded），angularjs自动开启，angularjs会自动执行并把自身挂载到window上边保证自己不被回收内存，然后angularjs会搜寻全局所有具有ng-app指令dom元素，并以angularjs的规则重新初始化这些dom元素以及dom元素内部的元素；
        // 每一个控制器js文件都是一个立即执行函数，这个控制器代码执行完毕后会建立指定的dom节点和angularjs的关系；
        vm.eaccount = entity;
        // 弹框状态pagingParams  1表示待激活位提交申请，2、表示填写表单，3、表示已提交激活申请；
        vm.popState = 1;
        // 检查是否是企业邀请走过来的
        if (vm.loginParam.eid != null) {
            vm.eAccountId = vm.loginParam.eid;
            //因为已经有企业所以清除企业邀请数据
            delete vm.loginParam["eid"];
            delete vm.loginParam["uid"];
            if (vm.eAccount.id == vm.eAccountId) {
                alert("您已加入该企业，请勿重复操作。");
            } else {
                alert("加入失败！您已有所属企业。");
            }
        }
        //冻结以后需要激活需要刷新页面$stateParams数据会丢失,如果还有页面需要跳转数据保留,所以还有数据存放cookie
        if (Object.keys(vm.loginParam).length > 0) {
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 24 * 60 * 60 * 1000); // cookies24分钟有效期
            $cookies.put("loginParam", JSON.stringify(vm.loginParam), {
                expires: new Date(expireDate),
            });
        } else {
            //检查当前没有数据去cookie中看是否有参数，为了页面跳转使用，如果跳转成功在删除数据
            var data = $cookies.get("loginParam");
            if (data != null) {
                vm.loginParam = JSON.parse(data);
            }
        }

        if (vm.eaccount.rabbitActivationState == 1) {
            Principal.identity().then(function (account) {
                if (account == null) {
                    return;
                }
                $cookies.remove("loginParam");
                LoginUrlService.login(vm.loginParam, account);
            });
            // $state.go('program-center');
            return;
        } else {
            vm.popState = vm.eaccount.rabbitActivationState == 0 ? 1 : 3;
        }
        // vm.popState=2;
        vm.activateAccount = activateAccount;
        vm.radioCheckbox = radioCheckbox;
        // 表单所有单选框增加点击介绍文本触发表单行文；
        vm.selectRadio = selectRadio;

        vm.questionList = [
            {
                answerList: [
                    {
                        answer: "A. 定向工具",
                        checked: false,
                    },
                    {
                        answer: "B. 数字化团建工具",
                        checked: false,
                    },
                    {
                        answer: "C. 活动工具",
                        checked: false,
                    },
                    {
                        answer: "D. 方案库",
                        checked: false,
                    },
                    {
                        answer: "E. 不太清楚",
                        checked: false,
                    },
                ],
                title: "1. 您印象中爱闯闯平台是？（可多选）",
                type: "checkbox",
                reasonActive: false,
                reasonDesc: "",
                answer: "F. 其他：",
            },
            {
                answerList: [
                    {
                        answer: "A. 操作复杂不好用",
                        checked: false,
                    },
                    {
                        answer: "B. 线路设计太困难",
                        checked: false,
                    },
                    {
                        answer: "C. 没时间做线路",
                        checked: false,
                    },
                    {
                        answer: "D. 使用过程中遇到困难未解决",
                        checked: false,
                    },
                    {
                        answer: "E. 功能欠缺不满足需求",
                        checked: false,
                    },
                    {
                        answer: "F. 业务方向不需要使用",
                        checked: false,
                    },
                    {
                        answer: "G. 使用过程中，服务不及时",
                        checked: false,
                    },
                ],
                title: "2. 您长时间未使用爱闯闯的原因是？（可多选）",
                type: "checkbox",
                reasonActive: false,
                reasonDesc: "",
                answer: "H. 其他原因：",
            },
            {
                answerList: [
                    {
                        answer: "A. 公众号",
                        checked: false,
                    },
                    {
                        answer: "B. 朋友圈",
                        checked: false,
                    },
                    {
                        answer: "C. 百度搜索",
                        checked: false,
                    },
                    {
                        answer: "D. 在别人活动现场看见",
                        checked: false,
                    },
                    {
                        answer: "E. 爱闯闯产品体验会",
                        checked: false,
                    },
                ],
                type: "radio",
                title: "3. 您最近一次接触爱闯闯的途径是？",
                reasonActive: false,
                reasonDesc: "",
                answer: "F. 其他途径：",
            },
            {
                answerList: [
                    {
                        answer: "A. 尝试使用爱闯闯，为以后做准备",
                        checked: false,
                    },
                    {
                        answer: "B. 有业务急需使用爱闯闯",
                        checked: false,
                    },
                    {
                        answer: "C. 对推出的新产品感兴趣",
                        checked: false,
                    },
                    {
                        answer: "D. 对新的线路方案感兴趣",
                        checked: false,
                    },
                    {
                        answer: "E. 受同行的成功案例影响",
                        checked: false,
                    },
                ],
                title: "4. 再次使用的原因是？（可多选）",
                type: "checkbox",
                reasonActive: false,
                reasonDesc: "",
                answer: "F. 其他原因：",
            },
        ];

        vm.formSubmit = formSubmit;
        vm.inputBlur = inputBlur;

        vm.eAccount = entity;
        vm.clear = clear;
        vm.clearTip = clearTip;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.IMAGE_SERVER = IMAGE_SERVER;

        function selectRadio(param) {
            // 一组单选框，用户点击同一个单选框的文本，不触发单选框组的状态改变
            if (event.target.previousElementSibling.checked) {
                return false;
            }
            event.target.previousElementSibling.checked =
                !event.target.previousElementSibling.checked;
            vm.questionList[2].reasonActive = false;
            vm.questionList[2].answerList.forEach(function (item) {
                item.checked = false;
            });
            if (param == "6") {
                vm.questionList[2].reasonActive =
                    event.target.previousElementSibling.checked;
            } else {
                vm.questionList[2].answerList[param - 1].checked =
                    event.target.previousElementSibling.checked;
            }
        }

        function activateAccount() {
            vm.popState = 2;
        }

        // 用户点击其他按钮的时候清除用户已选中的按钮的状态
        function radioCheckbox(param) {
            // 用户点击文字也触发单选框的行为；
            // 单选框的特殊性质导致单选框很难和angularjs的双向绑定相结合，这个地方通过监听用户行为去更改和单选框对应的数据对象；
            vm.questionList[2].reasonActive = false;
            vm.questionList[2].answerList.forEach(function (item) {
                item.checked = false;
            });
            if (param == "6") {
                vm.questionList[2].reasonActive = event.target.checked;
            } else {
                vm.questionList[2].answerList[param - 1].checked =
                    event.target.checked;
            }
        }

        function inputBlur() {
            if (event.target.value.length > 0) {
                event.target.style.borderColor = "#C8C8C8";
                event.target.previousElementSibling.style.display = "none";
            } else {
                event.target.style.borderColor = "red";
                event.target.previousElementSibling.style.display = "block";
            }
        }

        function formSubmit() {
            // 判断用户是否漏做了题
            var questionFinish1 = false;
            // debugger;
            vm.questionList[0].answerList.forEach(function (item) {
                if (item.checked) {
                    questionFinish1 = true;
                }
            });
            if (vm.questionList[0].reasonActive) {
                questionFinish1 = true;
            }
            var questionFinish2 = false;
            vm.questionList[1].answerList.forEach(function (item) {
                if (item.checked) {
                    questionFinish2 = true;
                }
            });
            if (vm.questionList[1].reasonActive) {
                questionFinish2 = true;
            }
            var questionFinish3 = false;
            vm.questionList[2].answerList.forEach(function (item) {
                if (item.checked) {
                    questionFinish3 = true;
                }
            });
            if (vm.questionList[2].reasonActive) {
                questionFinish3 = true;
            }
            var questionFinish4 = false;
            vm.questionList[3].answerList.forEach(function (item) {
                if (item.checked) {
                    questionFinish4 = true;
                }
            });
            if (vm.questionList[3].reasonActive) {
                questionFinish4 = true;
            }
            if (
                !(
                    questionFinish1 &&
                    questionFinish2 &&
                    questionFinish3 &&
                    questionFinish4
                )
            ) {
                alert("请填写完所有题目再提交");
                return false;
            }

            var isreturn = false;
            // 判断用户是否打开了其他的选项 qustion2warn  previousElementSibling
            if (
                vm.questionList[0].reasonActive &&
                vm.questionList[0].reasonDesc.length < 1
            ) {
                document.getElementById("qustion1warn").style.display = "block";
                document.getElementById(
                    "qustion1warn"
                ).nextElementSibling.style.borderColor = "red";
                isreturn = true;
            }
            if (
                vm.questionList[1].reasonActive &&
                vm.questionList[1].reasonDesc.length < 1
            ) {
                document.getElementById("qustion2warn").style.display = "block";
                document.getElementById(
                    "qustion2warn"
                ).nextElementSibling.style.borderColor = "red";
                isreturn = true;
            }
            if (
                vm.questionList[2].reasonActive &&
                vm.questionList[2].reasonDesc.length < 1
            ) {
                document.getElementById("qustion3warn").style.display = "block";
                document.getElementById(
                    "qustion3warn"
                ).nextElementSibling.style.borderColor = "red";
                isreturn = true;
            }
            if (
                vm.questionList[3].reasonActive &&
                vm.questionList[3].reasonDesc.length < 1
            ) {
                document.getElementById("qustion4warn").style.display = "block";
                document.getElementById(
                    "qustion4warn"
                ).nextElementSibling.style.borderColor = "red";
                isreturn = true;
            }
            if (isreturn) {
                alert("请填写输入框内容");
                return false;
            }
            var htmlstring = createHtml();
            // 静态模拟表单测试；把下边的代码取消注释，提交表单的时候会把结果渲染的屏幕上，可以和之前填写的表单对比看看是否有区别；
            // console.log("提交的字符串",htmlstring);
            // setTimeout(function() {
            //     alert('清空页面数据');
            //     document.body.innerHTML=htmlstring;
            // }, 2000);
            // return false;

            // UrlEncode编码/UrlDecode解码 - 站长工具

            // 需要传递到后端的答题数据；JSON.stringify(vm.questionList)
            $http({
                url: "/api/e-account/activate",
                method: "POST",
                params: {
                    eAccountId: vm.eaccount.id,
                    versions: 1,
                    questionnaire: htmlstring,
                },
            }).success(function (res) {
                console.log("提交激活申请---", res);
                if (res.errorCode == 200) {
                    // location.reload();
                    vm.popState = 3;
                } else {
                    alert(res.message);
                }
            });
        }

        // 将用户选择的答案生成一个静态的不可改变的字符串存储到后端，在别的地方展示只需要拿出这个字符串插入到页面展示就可以了；
        function createHtml() {
            var strHtml = "";
            for (var i = 0; i < 4; i++) {
                var answerobj = vm.questionList[i];
                var internalHtml = "";
                for (var j = 0, jl = answerobj.answerList.length; j < jl; j++) {
                    var checkboxRadio = "";
                    var activeHtml = "";
                    if (answerobj.answerList[j].checked) {
                        activeHtml = '<div class="active-cr"></div>';
                    }
                    if (answerobj.type === "radio") {
                        checkboxRadio =
                            '<div class="radio ' +
                            (answerobj.answerList[j].checked && "radioactive") +
                            '">' +
                            activeHtml +
                            "</div>";
                    } else {
                        checkboxRadio =
                            '<div class="checkbox ' +
                            (answerobj.answerList[j].checked &&
                                "checkboxactive") +
                            '">' +
                            activeHtml +
                            "</div>";
                    }
                    internalHtml +=
                        '<div class="radio-checkbox">' +
                        checkboxRadio +
                        "<p>" +
                        answerobj.answerList[j].answer +
                        "</p></div>";
                }
                var activeHtml2 = "";
                var checkboxRadio2 = "";
                if (answerobj.reasonActive) {
                    activeHtml2 = '<div class="active-cr"></div>';
                }
                if (answerobj.type === "radio") {
                    checkboxRadio2 =
                        '<div class="radio ' +
                        (answerobj.reasonActive && "radioactive") +
                        '">' +
                        activeHtml2 +
                        "</div>";
                } else {
                    checkboxRadio2 =
                        '<div class="checkbox ' +
                        (answerobj.reasonActive && "checkboxactive") +
                        '">' +
                        activeHtml2 +
                        "</div>";
                }
                internalHtml +=
                    '<div class="radio-checkbox">' +
                    checkboxRadio2 +
                    "<p>" +
                    answerobj.answer +
                    "</p></div>";
                if (answerobj.reasonActive) {
                    internalHtml +=
                        '<input  class="desc-text"  type="text" style="display:block;" value="' +
                        answerobj.reasonDesc +
                        '"  disabled></input>';
                }
                strHtml +=
                    '<div class="select-question"><h4><span style="color:red;margin-right:5px;">*</span>' +
                    answerobj.title +
                    "</h4>" +
                    internalHtml +
                    "</div>";
            }
            return strHtml;
        }

        function addLayout() {
            document.getElementById("nav").style.display = "block";
            document.getElementById("foot").style.display = "block";
            document.getElementById("con").className = "well";
            $(function () {
                $(".container").removeClass("login-container-content");
                if ($(window).width() < 768)
                    $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }

        function clear() {
            window.history.go(-1);
            addLayout();
        }

        function save() {
            if (vm.eAccount.companyRegion.indexOf(",") < 0) {
                alert("请选择完成的地区");
                return false;
            }
            // if (vm.eAccount.licence == null) {
            //     alert("请上传最新的营业执照");
            // } else
            if (vm.eAccount.contactIdCardImg == null) {
                alert("请上传法人/运营者/经办人身份证照片");
            } else {
                vm.isSaving = true;
                $http({
                    url: "/api/check-invate-code",
                    method: "POST",
                    params: {
                        code: vm.eAccount.invitationCode,
                    },
                }).success(function (res) {
                    if (res.errorCode == -1) {
                        alert("邀请码有误");
                        vm.isSaving = false;
                        vm.code = null;
                        return;
                    } else if (res.errorCode == -2) {
                        alert("邀请码已失效");
                        vm.isSaving = false;
                        vm.code = null;
                        return;
                    } else {
                        EAccount.save(vm.eAccount, onSaveSuccess, onSaveError);
                    }
                });
            }

            // if (vm.eAccount.contact!=null){
            //     var phone = vm.eAccount.contact;
            //     if(!(/^1[34578]\d{9}$/.test(phone))){
            //         alert("手机号码有误，请重填");
            //         vm.eAccount.contact = null;
            //         vm.isSaving = false;
            //         return;
            //     }
            // }
            // if (vm.eAccount.email != null){
            //     var email = vm.eAccount.email;
            //     var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/; //邮箱验证正则表达式
            //     if (!reg.test(email)){
            //         alert("邮箱格式不正确");
            //         vm.isSaving = false;
            //         return;
            //     }
            // } else {
            //     alert("邮箱不能为空");
            //     vm.isSaving = false;
            //     return;
            // }
        }

        function onSaveSuccess(result) {
            $scope.$emit("taskServiceApp:eAccountUpdate", result);
            // $uibModalInstance.close(result);
            vm.isSaving = false;
            addLayout();
            // $state.go("trip-model",{},{reload:true});
            window.setTimeout(function () {
                $state.go("e-account-my.edit", {}, { reload: true });
            }, 300);
        }

        function onSaveError(err) {
            vm.isSaving = false;
        }

        vm.getActivationCode = function () {
            $("#myModal").modal("show");
        };

        function clearTip() {
            $("#myModal").modal("hide");
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect(uploadFile, type) {
            if (uploadFile != null) {
                uploadHelp(uploadFile);
            }
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: "/api/postfile",
                    fields: {},
                    data: { type: "EACCOUNT" },
                    file: uploadFile,
                    method: "POST",
                })
                    .progress(function (evt) {
                        var progressPercentage = parseInt(
                            (100.0 * evt.loaded) / evt.total
                        );
                    })
                    .success(function (data, status, headers, config) {
                        if (type == 1) {
                            vm.eAccount.licence = data.file;
                        } else if (type == 2) {
                            vm.eAccount.contactIdCardImg = data.file;
                        } else {
                            vm.eAccount.web = data.file;
                        }

                        load();
                    })
                    .error(function (data, status, headers, config) {
                        console.log("error status: " + status);
                    });
            }
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        load();
        function load() {
            $("#nav, #foot").css("display", "none");
            $("#con").removeClass().addClass("con");
            $(".container").addClass("login-container-content");
            $(".eAccountIdent").css("min-height", $(window).height());
            window.onresize = function (event) {
                $(".eAccountIdent").css("min-height", $(window).height());
            };
            if ($(window).height() >= 732)
                $(".footer .foot").removeClass("mobile");
        }

        $scope.$on("$destroy", function () {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
            $("body").removeClass("modal-open");
        });
    }
})();
