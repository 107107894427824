(function () {
    "use strict";
    angular.module("taskServiceApp").config(stateConfig);
    stateConfig.$inject = ["$stateProvider"];
    function stateConfig($stateProvider) {
        $stateProvider
            .state("login", {
                parent: "app",
                url: "/",
                data: {
                    authorities: [],
                },
                params: {
                    loginType: {
                        value: "1",
                        squash: true,
                    },
                    appSource: "1",
                },
                views: {
                    "navbar@": {},
                    "content@": {
                        templateUrl: "app/account/login/login.html",
                        controller: "LoginSelfController",
                        controllerAs: "vm",
                    },
                },
                resolve: {},
            })
            .state("mftglogin", {
                parent: "app",
                url: "/mftglogin",
                data: {
                    authorities: [],
                },
                params: {
                    loginType: {
                        value: "1",
                        squash: true,
                    },
                    appSource: "2",
                },
                views: {
                    "navbar@": {},
                    "content@": {
                        templateUrl: "app/account/login/login.html",
                        controller: "LoginSelfController",
                        controllerAs: "vm",
                    },
                },
                resolve: {},
            });
    }
})();
