(function () {
    angular
        .module("taskServiceApp")
        .controller("TripMonitorController", controller);
    controller.$inject = [
        "$scope",
        "$state",
        "TripMonitor",
        "Principal",
        "TripSearch",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
    ];
    function controller(
        $scope,
        $state,
        TripMonitor,
        Principal,
        TripSearch,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants
    ) {
        var vm = this;
        vm.account = null;
        vm.isAdmin = false;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        loadAll();

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                for (var i = 0; i < vm.account.authorities.length; i++) {
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN") {
                        vm.isAdmin = true;
                    }
                }
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_DESIGNER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "PGC";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        /*var isScroll = true;
$(window).on("scroll",function(){
    if(isScroll){
        setTimeout(function(){
            var top = $(this).scrollTop();
            if(top > 150){
                $("#trFix").css({"position":"fixed","background-color":"#f9f9f9","margin-top":"-210px","height":"50px"});
                $("#title").css({"width":"80px"});
                // $("#start").css({"width":"120px"});
                // $("#startt").css({"width":"150px"});
                // $("#end").css({"width":"150px"});
                // $("#cover").css({"width":"80px"});
                // $("#tripType").css({"width":"100px"});
            }else{
                $("#trFix").css({"position":"","background-color":"transparent"});
            }
            isScroll = true;
        },0);
    }
    isScroll = false;
});*/

        function loadAll() {
            if (pagingParams.search) {
                TripSearch.query(
                    {
                        query: pagingParams.search,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                        release: "0",
                    },
                    onSuccess,
                    onError
                );
            } else {
                TripMonitor.query(
                    {
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                        release: "0",
                    },
                    onSuccess,
                    onError
                );
            }
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.trips = data;
                vm.page = pagingParams.page;
                // console.log("data")
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = "_score";
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = "id";
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        vm.tripCoachQrCode = function (id, title, attribute, appSource) {
            $state.go(
                "tripCoachQrCode",
                {
                    id: id,
                    title: title,
                    attribute: attribute,
                    showWeb: true,
                    appSource: appSource,
                },
                { reload: false }
            );
        };
    }
})();
