(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("WxGameTypeController", controller);
    controller.$inject = ["$http"];
    function controller($http) {
        var vm = this;
        vm.bombProgramId = null;
        vm.spyProgramId = null;
        getBombProgramId();
        function getBombProgramId() {
            var url = "/api/getBombProgram";
            $http({
                method: "POST",
                url: url,
            })
                .success(function (res) {
                    if (res == null) {
                        return;
                    }
                    if (res["极限拆弹"]) {
                        vm.bombProgramId = res["极限拆弹"].id;
                    }
                    if (res["极限谍战"]) {
                        vm.spyProgramId = res["极限谍战"].id;
                    }
                })
                .error(function () {
                    vm.isSaving = false;
                });
        }
    }
})();
