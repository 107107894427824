(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("TripDialogController", controller);
    controller.$inject = [
        "$filter",
        "$scope",
        "$sce",
        "$uibModalInstance",
        "entity",
        "Trip",
        "$state",
        "$http",
        "$stateParams",
        "$uibModalStack",
        "Auth",
    ];
    function controller(
        $filter,
        $scope,
        $sce,
        $uibModalInstance,
        entity,
        Trip,
        $state,
        $http,
        $stateParams,
        $uibModalStack,
        Auth
    ) {
        // 超管去除了原有的大型活动和公开计时赛
        // platform活动类型： 0-专业版 1-基础版  2-OTA，已废除 3-开放寻宝，已废除  4-急速追踪
        // attribute活动属性： OFFICIAL-公开计分赛 TIMERACE-公开计时赛 COMMON-日常活动 OUTWARD-计分活动 OUTTIMERACE-计时活动 PUBLIC-公开计分活动  REGISTRATION-大型活动 OTA-第三方平台销售，面向C端用户 TRACE-急速追踪 TS-极限寻宝 STEALTH-极限潜航  BOMB-极限拆弹  SPY-极限谍战
        // traceType急速追踪玩法类型：   0-多抓一，1-多抓多；
        // startType活动开启方式：   1-定时开启  2-教练端开启  3-口令开启
        // coverImg   活动的图片和视频，视频和图片都用该字段
        // cachefile: 是否缓存文件，1:缓存，0:实时
        // syncUpload: 是否实时上传图片视频资源，1: 强制上传，0: 不上传
        // maxTrackers: 组内最大追踪者数
        // maxFugitives: 组内最大逃亡者数

        // 这个字段由后端返回，该用户是否有查看该线路的权限是后端判定的；
        if (!entity.canShow) {
            setTimeout(function () {
                $message.warning({
                    text: "无访问权限",
                });
                clear();
            }, 100);
            return;
        }
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        // 用户创建或者编辑活动；
        vm.createOrEditTrip = false;
        if ($stateParams.createOrEditTrip) {
            vm.createOrEditTrip = true;
        }
        vm.trip = entity;
        vm.trip.maxFugitives = String(vm.trip.maxFugitives);
        console.log(
            "vm.trip===--------------====",
            JSON.parse(JSON.stringify(vm.trip))
        );
        console.log(
            "$stateParams=====---------==========",
            JSON.parse(JSON.stringify($stateParams))
        );
        // 用户创建不同类类型的活动的一些默认值
        if ($stateParams.title != null && $stateParams.title != "") {
            vm.trip.myTripModelId = $stateParams.id;
            vm.trip.myTripModelTitle = decodeURI($stateParams.title);
            vm.trip.platform = $stateParams.platform;
            if (vm.trip.platform && vm.trip.platform == 1) {
                // 基础版设置最大活动人数且不可更改
                vm.trip.maxPeople = 100;
                // 开启方式为口令开启
                vm.trip.startType = 3;
                // 只允许队长操作
                vm.trip.leaderOnlyFlag = true;
                // 基础版只有计分一种活动类型，
                vm.trip.attribute = "OUTWARD";
                // 基础版活动类型没有队伍人数限制
                vm.trip.limitTeampeople = false;
            } else if (vm.trip.platform == 4) {
                // 急速追踪只有口令开启
                vm.trip.startType = 3;
            }
            if (vm.trip.platform == 0) {
                // 专业版默认关闭队伍人数限制
                vm.trip.limitTeampeople = false;
            }
        }

        // 以前的老的那些被用户设置为“未满员不可开启”的活动，需要根据enableStart这个字段的值来判断是否把“limitTeampeople”这个字段设置为true（设置这个字段的原因是该字段控制“enableStart”字段所在的模块的显示与隐藏）
        if (vm.trip.enableStart === false) {
            vm.trip.limitTeampeople = true;
        }

        // 如果用户编辑一队一码活动的时候需要把vm.trip.maxPeople设置为null，因为这个值使用代码加上去的，而用户编辑活动的时候不能让用户看到莫名其妙的东西，所以要设置为null，等到用户保存的时候再计算赋值
        if (vm.trip.teamset == 3) {
            vm.trip.maxPeople = null;
        }
        if (vm.trip.platform != 4) {
            if (vm.trip.limitTeampeople === null) {
                // limitTeampeople===null说明这个活动是3.9.3版本以前的活动，
                if (vm.trip.teamset == 1) {
                    // 老的团建计分和团建计时
                    // 后端给用户赋的值，不展示给用户
                    vm.trip.teamMaxpeople = null;
                    vm.trip.limitTeampeople = false;
                }
                if (vm.trip.teamset == 2 || vm.trip.teamset == 3) {
                    // 老的公开计分活动
                    vm.trip.limitTeampeople = true;
                }
            } else {
                // 这个地方是把后台给赋值的teamMaxpeople的值取消掉,不让用户看到不是自己设置的值
                if (!vm.trip.limitTeampeople) {
                    vm.trip.teamMaxpeople = null;
                }
            }
        }

        // 判断该活动下是否有正式战队
        if (vm.trip.id) {
            $http({
                method: "GET",
                url: "/api/trip/getTeamsByTripId/" + vm.trip.id + "/normal",
            }).then(function (res) {
                // 用户创建的活动，一但该活动创建了正式战队，就不准更改组队方式
                if (res.data.length > 0) {
                    vm.disableChangeTeamset = true;
                } else {
                    vm.disableChangeTeamset = false;
                }
            });
        }

        //当前时间是否在活动开始日期0点之后（活动当天不允许修改的字段以此为依据判断）
        vm.afterTripStartTime = false;
        if (vm.trip != null) {
            var date = $filter("date")(vm.trip.startTime, "yyyy-MM-dd");
            var startDay = new Date((date + " " + "00:00").replace(/-/g, "/"));
            var nowTime = new Date().getTime();
            if (nowTime > startDay.getTime()) {
                vm.afterTripStartTime = true;
            }
        }

        vm.datePickerOpenStatus = {};
        vm.openCalendar = function (date) {
            if (vm.trip.status != "EDIT" && vm.afterTripStartTime) {
                // 日期选择器绑定的函数返回false的时候日期选择器不触发
                return false;
            }
            vm.datePickerOpenStatus[date] = true;
            var id = setTimeout(function () {
                $(".uib-daypicker thead tr:nth-child(1) th:nth-child(2)").attr(
                    "colspan",
                    "5"
                );
                clearTimeout(id);
            }, 30);
        };
        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        vm.date = null;
        if (vm.trip.startTime != null) {
            vm.date = vm.trip.startTime;
        }
        // 限制爱闯闯所有非白名单用户功能的使用，爱闯闯非白名单用户获取组队方式，然后设置当前组队方式之外的组队方式不对爱闯闯非白名单用户展示，
        vm.teamsetLimit = vm.trip.teamset; //组队方式
        vm.authAreaList = []; //魔法特工授权地址
        // 如果是品牌管理员查看别人的活动，禁止更改授权地点一栏，禁止原因：活动的授权地点列表是挂在企业下边的，品牌管理员作为管理员可以操作所有品牌企业的活动，假设品牌管理员修改别人的企业的活动的授权地点，他不在别人的企业，拿不到别人企业的授权地点列表，后续操作无法进行，所以干脆禁止操作。
        vm.disabledManagerChangeArea = false; //是否是魔法特工品牌管理员
        vm.whiteListUser = null;
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                    // 品牌管理员不能修改自己活动之外的活动的授权地址
                    if (
                        !$stateParams.title &&
                        vm.account.authorities.indexOf("ROLE_MFTG_MANAGER") > -1
                    ) {
                        if (vm.trip.myArtistId != vm.account.id) {
                            vm.disabledManagerChangeArea = true;
                        }
                    }
                    if (vm.account.eAccountId != null) {
                        getEaccountContractTime(vm.account.eAccountId);
                    }
                    // 用户从线路编辑器跳转到后台，比对线路编辑器的token和后台的token是否一致，如果不一致推出后台账户的登录状态，让他重新登录
                    if ($stateParams.appSource) {
                        if (vm.account.login !== $stateParams.account) {
                            // 线路编辑器和当前域名下的后台登录的账户不一致，退出登录，
                            logout($stateParams.appSource);
                        }
                    }
                    if (vm.account.appSource == 2) {
                        // 获取企业信息
                        $http({
                            url: "/api/e-accounts/my-e-account",
                            method: "GET",
                        }).then(function (res) {
                            if (res.data) {
                                vm.eAccount = res.data;
                                if (vm.eAccount.selectRegionType == "1") {
                                    // 该用户所在的企业开启的授权地址， authRegions
                                    if (vm.eAccount.authRegions) {
                                        vm.authAreaList = JSON.parse(
                                            vm.eAccount.authRegions
                                        );
                                    }
                                }
                            }
                        });
                    } else {
                        vm.whiteListUser = vm.account.inWhitelist;
                    }
                    // 魔法特工专业版线路创建活动默认设置为自动建队组队方式
                    if (
                        vm.account.appSource == "2" &&
                        $stateParams.title != null &&
                        $stateParams.title != "" &&
                        vm.trip.platform == 0
                    ) {
                        vm.trip.teamset = 2;
                        vm.trip.maxPeople = null;
                        // 一堆一码活动必须填写队伍人数，
                        vm.trip.limitTeampeople = true;
                        vm.trip.enableStart = true;
                    }
                    // 如果是魔法特工专业版活动，且组队方式为自动建队和一队一码，最大队伍人数设定为3且不可更改
                    if (
                        vm.account.appSource == "2" &&
                        vm.trip.platform == 0 &&
                        (vm.trip.teamset == 2 || vm.trip.teamset == 3)
                    ) {
                        vm.trip.teamMaxpeople = 3;
                    }
                    if (
                        vm.account.appSource == "2" &&
                        vm.trip.platform == 0 &&
                        $stateParams.eTime
                    ) {
                        vm.trip.etime = Number($stateParams.eTime);
                    }
                }
            });
        }
        function getEaccountContractTime(id) {
            if (id != null) {
                $http({
                    url: "api/e-accounts/" + id,
                    method: "GET",
                }).then(
                    function (res) {
                        // console.log(res);
                        if (res != null) {
                            if (res.data.contractTime != null) {
                                vm.contractTime = new Date(
                                    res.data.contractTime
                                );
                            } else {
                                var startTime = new Date();
                                vm.contractTime = startTime;
                            }
                            vm.expireTime = new Date(res.data.expireTime);
                        }
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }
        }
        $scope.htmlTooltip1 = $sce.trustAsHtml(
            '<p style="text-align: left">定时开启<br><br>在所选活动日期当天，到达指定时刻后，活动自动开启。活动当天支持教练端「一键开启」（仅教练组组长拥有权限）。<br><br> 发布后不可更改为其他方式</p>'
        );
        $scope.htmlTooltip2 = $sce.trustAsHtml(
            '<p style="text-align: left">仅教练端手动开启<br><br>在所选活动日期当天，仅能通过教练端「一键开启」功能开启活动（仅教练组组长拥有权限）<br><br> 注:「一键开始」功能仅开启正式活动，测试战队不受此限制，请勿用于测试。<br><br> 发布后不可更改为其他方式</p>'
        );
        $scope.htmlTooltip3 = $sce.trustAsHtml(
            '<p style="text-align: left">口令开启(离线)<br><br>在所选活动日期当天，玩家在进入游戏时输入预设的口令即可开始游戏，口令验证无须联网（不支持教练端「一键开启」） <br><br>发布后不可更改为其他方式</p>'
        );
        $scope.htmlTooltip4 = $sce.trustAsHtml(
            '<p style="text-align: left">免费版最多支持100人以内，如需更多人数，请将线路<a style="color:#FF224F;">转换为高级版</a>或<a style="color:#FF224F;">了解更多</a></p>'
        );
        $scope.htmlTooltip5 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">在所选活动日期当天，活动可进入。<br>计时规则：在任意一个逃亡者进入游戏，即为活动正式开始，活动持续时间到达设置的活动时长，会自动结束。</p>'
        );
        $scope.htmlTooltip6 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">玩家在进入游戏时输入预设的“行动口令”即可进入游戏。</p>'
        );
        $scope.htmlTooltip7 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">到达指定时间后才可签到。</p>'
        );
        $scope.htmlTooltip8 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">逃亡者各自为战，追踪者随机加入某个逃亡者的追踪小组内，组内集体作战。<br>组内的逃亡者被抓捕，追踪者可加入其他的追踪组，继续追踪。</p>'
        );
        $scope.htmlTooltip20 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">每N名逃亡者自动成为一组，追踪者平均分配至各逃亡小组内。<br>  同组的追踪者集体作战，可抓捕组内的所有逃亡者。</p>'
        );
        $scope.htmlTooltip46 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">每N名逃亡者自动成为一组，变异者平均分配至各逃亡小组内。<br> 同组的变异者集体作战，可抓捕组内的所有逃亡者。</p>'
        );
        $scope.htmlTooltip10 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">追踪者与逃亡者的距离小于一定范围，追踪者将看不到逃亡者的方向和距离。<br>不同的场地类型，该范围区间不同。</p>'
        );
        $scope.htmlTooltip43 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">变异者与逃亡者的距离小于一定范围，变异者将看不到逃亡者的方向和距离。<br>不同的场地类型，该范围区间不同。</p>'
        );
        $scope.htmlTooltip11 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">可视距离大，视野较开阔，遮挡物少。如：公园、街道。 <br>范围初始值：30米，组内每加入一个追踪者+10米，上限100米。</p>'
        );
        $scope.htmlTooltip42 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">可视距离大，视野较开阔，遮挡物少。如：公园、街道。 <br>范围初始值：30米，组内每加入一个变异者+10米，上限100米。</p>'
        );
        $scope.htmlTooltip12 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">可视距离短，视野较狭窄，遮挡物较多。如：古镇、山地。 <br>范围初始值：10米，组内每加入一个追踪者+5米，上限50米。</p>'
        );
        $scope.htmlTooltip44 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">可视距离短，视野较狭窄，遮挡物较多。如：古镇、山地。 <br>范围初始值：10米，组内每加入一个变异者+5米，上限50米。</p>'
        );
        $scope.htmlTooltip13 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">是否进行ibeacon信号违规判定。</p>'
        );
        $scope.htmlTooltip14 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">关闭违规判定</p>'
        );
        $scope.htmlTooltip15 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">开启违规判定</p>'
        );
        $scope.htmlTooltip21 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">逃亡者加入后将按单组逃亡者人数上限和加入顺序自动分组。<br> 例如设置逃亡者人数上限设置为3，则第1-3名玩家为第1组，则第4-6名玩家自动分为第2组，以此类推。</p>'
        );
        $scope.htmlTooltip22 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">追踪者加入后将自动平均分配至每个逃亡者小组。（优先加入当前追踪者人数最少的小组）。<br> 小组内的追踪者人数达到上限后，该组将无法继续加入。</p>'
        );
        $scope.htmlTooltip48 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">变异者加入后将自动平均分配至每个逃亡者小组。<br> 小组内的变异者人数达到该上限后，将无法继续加入。</p>'
        );
        $scope.htmlTooltip23 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">编号组队——扫码后需要线下组队，输入相同编号的玩家进入同一队伍（组号最多支持4位数）。首个进入小队的为队长。（正式码组队后将无法更换为其他组队方式）</p>'
        );
        $scope.htmlTooltip24 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">自动建队——扫码后玩家自动新建一个战队并成为该队队长。（正式码组队后将无法更换为其他组队方式）</p>'
        );
        $scope.htmlTooltip25 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">一队一码——批量导出战队二维码，此模式下无邀请踢出功能，加入后不能退队。批量导出战队二维码，此模式下无邀请踢出功能，加入后不能退队。</p>'
        );
        $scope.htmlTooltip26 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">允许加入的最大人数，超过人数将无法加入。</p>'
        );
        $scope.htmlTooltip27 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">可导出的队伍总数，活动总人数=队伍数上限*队伍人数上限。</p>'
        );
        $scope.htmlTooltip28 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">进入点位后允许退出，领取其他点位</p>'
        );
        $scope.htmlTooltip29 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">活动中仅队长能够给领取点位、退出点位、操作答题,其余队员仅自动同步最新的答题记录。</p>'
        );
        $scope.htmlTooltip30 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">默认模式——同队的玩家自动进入相同点位，进入后必须完成，不可中途退出点位。</p><p style="text-align:left;">基础版仅支持队长操作，专业版无此限制</p>'
        );
        $scope.htmlTooltip31 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">开放寻宝模式——可自由进出点位，仅限队长操作，队员被动同步。（原开放寻宝模式） </p><p style="text-align:left;">该模式仅支持专业版</p>'
        );
        $scope.htmlTooltip32 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">自由模式——全员可自由进出不同的点位。 </p><p style="text-align:left;">该模式仅支持专业版</p>'
        );
        $scope.htmlTooltip33 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">队内一人领取或退出点位后，队友也将自动进入、退出该点位。</p>'
        );
        $scope.htmlTooltip34 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">领取点位后必须完成，不允许中途退出</p>'
        );
        $scope.htmlTooltip35 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">可创建的队伍总数。</p>'
        );
        $scope.htmlTooltip36 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">实时网络加载 </p><p style="text-align:left;">线路内的图片、视频、音频、ar模型等资源在活动过程中实时网络加载。</p>'
        );
        $scope.htmlTooltip37 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">活动前预下载 </p><p style="text-align:left;">线路内的图片、视频、音频、ar模型等资源在用户进入活动前提前集中下载，减少现场对网络的依赖。</p>'
        );
        $scope.htmlTooltip38 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">强制上传 </p><p style="text-align:left;">点击上传后，须等待上传完毕才能过关，后台监控可实时查看。</p>'
        );
        $scope.htmlTooltip39 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">不上传 </p><p style="text-align:left;">点击上传后仅保存到本地，直接过关。</p>'
        );
        $scope.htmlTooltip40 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;">逃亡者被感染后将会转变为变异者， </p><p style="text-align:left;">所有逃亡者被感染或逃脱后本组结束。</p>'
        );
        $scope.htmlTooltip41 = $sce.trustAsHtml(
            '<p style="text-align: left;width:155px;"> 逃亡者被捕后可由同组队友救援复活。</p><p style="text-align:left;">所有逃亡者被捕或逃脱后本组结束。</p><p style="text-align:left;">胜负规则可由组织者线下判定。</p>'
        );
        var imageSizeLimit = 10 * 1024 * 1024;
        var videoSizeLimit = 40 * 1024 * 1024;
        var cropperimage = null;
        var filereader = null;
        vm.uploadType = "";
        vm.dataUrl = null; //暂存用户选择的数据对象；   用户上传成功后需要手动清除
        vm.uploadPercent = 0; //上传进度
        vm.ossUploadToken = null; //后台返回的上传oss需要的参数；
        vm.ossUploadFile = null; //上传到oss的文件对象；超时重试的时候用得到
        vm.delayShow = false; //等图片读取完成后显示裁剪容器
        // 加载该页面的时候，需要判断是新建活动还是编辑活动，如果是编辑活动需要手动给vm.uploadType赋值
        if (vm.trip.coverImg) {
            if (
                "pngjpegjpgbmpPNGJPEGJPGBMP".indexOf(
                    vm.trip.coverImg.split(".").pop()
                ) > -1
            ) {
                vm.uploadType = "image";
            } else {
                vm.uploadType = "video";
            }
        }
        vm.uploadFileDom = null;
        vm.clickUpload = function () {
            if (!vm.uploadFileDom) {
                vm.uploadFileDom = document.getElementById("uploadfile");
                vm.uploadFileDom.addEventListener("change", getFileObject);
            }
            vm.uploadFileDom.click();
        };
        vm.cropperStatus = false;
        // 用户上传文件
        function getFileObject() {
            if (this.files.length > 0) {
                if (this.files[0].type.indexOf("image") > -1) {
                    console.log("上传的图片类型", this.files[0]);
                    // 图片类型先调用图片裁剪功能
                    vm.cropperStatus = true;
                    vm.delayShow = false;
                    if (cropperimage) {
                        cropperimage.clear();
                    }
                    // 如果用户裁剪的图片很大的时候，
                    $scope.$apply();
                    filereader = new FileReader();
                    filereader.onload = function () {
                        // 切换图片和视频的时候，老的dom节点容器被销毁，裁剪器绑定的是已经被销毁的dom节点，所以这个地方要加判断重新初始化裁剪器
                        if (!cropperimage) {
                            var needid2 = setTimeout(function () {
                                cropperimage = new Cropper(
                                    document.getElementById("image"),
                                    {
                                        aspectRatio: 2 / 1,
                                        autoCropArea: 1,
                                        rotatable: false,
                                        scalable: false,
                                        zoomable: false,
                                        zoomOnTouch: false,
                                        viewMode: 1,
                                        background: false,
                                        guides: false,
                                        ready: function () {
                                            // 每次更换裁剪图像城后之后触发；
                                            vm.delayShow = true;
                                            $scope.$apply();
                                        },
                                    }
                                );
                                cropperimage.replace(filereader.result);
                                clearTimeout(needid2);
                                $scope.$apply();
                            }, 200);
                        } else {
                            cropperimage.replace(this.result);
                            $scope.$apply();
                        }
                    };
                    // 转化为数据URL
                    filereader.readAsDataURL(event.target.files[0]);
                } else if (this.files[0].type.indexOf("video") > -1) {
                    if (!(this.files[0].type.indexOf("mp4") > -1)) {
                        $message.warning({
                            text: "只支持mp4视频格式的视频",
                        });
                        vm.uploadFileDom.value = "";
                        return false;
                    }
                    if (this.files[0].size > videoSizeLimit) {
                        $message.warning({
                            text: "视频大小不能超过40M",
                        });
                        vm.uploadFileDom.value = "";
                        return false;
                    }
                    vm.uploadType = "video";
                    vm.dataUrl = getObjectURL(this.files[0]);
                    uploadFile(this.files[0]);
                }
            }
        }
        vm.croppedImage = function () {
            // 这个图片裁剪插件设置最大裁剪宽度和高度无效；
            // {maxWidth:1200,maxHeight:600}
            // {width:1200,height:600}
            // 插件裁剪后得到base的图片格式
            // 用户裁剪完毕后立即把图片展示给用户，然后在展示的图片上去显示上传到服务器的加载过程；
            vm.uploadType = "image";
            var imageDataUrl = cropperimage
                .getCroppedCanvas()
                .toDataURL("image/jpeg");
            // 转化为文件对象
            var imageFile = getBlobBydataURL(imageDataUrl);
            // console.log('传到服务器的图片对象',imageFile);
            // 转化后的图片文件对象name字段为null，给图片命名一个名字
            imageFile.name = new Date().getTime() + ".jpeg";
            if (imageFile.size > imageSizeLimit) {
                $message.warning({
                    text: "图片大小不能超过10M",
                });
                vm.uploadFileDom.value = "";
                vm.cropperStatus = false;
                return false;
            }
            // vm.dataUrl要在vm.cropperStatus之前做出更改
            vm.dataUrl = imageDataUrl;
            vm.cropperStatus = false;
            uploadFile(imageFile);
        };
        vm.cancelCropper = function () {
            vm.cropperStatus = false;
            vm.uploadFileDom.value = "";
        };
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI) {
            var binary = atob(dataURI.split(",")[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
        }
        // 将用户上传的文件对象转化为DataURL
        function getObjectURL(file) {
            var url = null;
            // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
            if (window.createObjectURL != undefined) {
                // basic
                url = window.createObjectURL(file);
            } else if (window.URL != undefined) {
                // mozilla(firefox)
                url = window.URL.createObjectURL(file);
            } else if (window.webkitURL != undefined) {
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file);
            }
            return url;
        }
        vm.uploadStatus = false;
        vm.retryUpload = false;
        function uploadFile(file) {
            // console.log(file);
            vm.uploadStatus = true;
            // 上传oss
            $http({
                method: "POST",
                url: "/api/postFileNameAndToken",
                timeout: 30000,
                data: {
                    type: "TRIP",
                    fileName: file.name,
                },
            })
                .then(function (res) {
                    console.log("后台返回的数据", res);
                    vm.ossUploadToken = res.data.data;
                    vm.ossUploadFile = file;
                    uploadOss();
                })
                .catch(function (err) {
                    vm.uploadStatus = false;
                    vm.cropperStatus = false;
                    vm.uploadFileDom.value = "";
                    vm.dataUrl = null;
                    console.log("上传失败err=", err);
                    $message.warning({
                        text: "上传失败，请重试！",
                    });
                });
        }
        // 上传oss
        function uploadOss() {
            vm.retryUpload = false;
            console.log("传递给oss的osstoken", vm.ossUploadToken);
            var client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: "oss-cn-shanghai",
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: vm.ossUploadToken.AccessKeyID,
                accessKeySecret: vm.ossUploadToken.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: vm.ossUploadToken.SecurityToken,
                // 填写Bucket名称。
                bucket: "itasktour-muti",
            });
            vm.uploadPercent = 0;
            // 第一个参数为上传的路径，第二个参数为上传的文件
            client
                .multipartUpload(vm.ossUploadToken.fileName, vm.ossUploadFile, {
                    // 进度条的配置项
                    progress: function (percentage) {
                        // 获取进度条的值
                        vm.uploadPercent = percentage * 100;
                        // console.log('percentage='+percentage+'----------vm.uploadPercent='+vm.uploadPercent);
                        $scope.$apply();
                    },
                    timeout: 60000,
                    // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大 可能导致很长时间进度条都看不到效果
                    // partSize: 102400 // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
                })
                .then(function (res) {
                    console.log("oss返回数据res=", res);
                    // 为了让用户看到上传进度条条的百分百的状态，这个地方延迟一秒改变状态
                    var delayId = setTimeout(function () {
                        vm.uploadPercent = 0;
                        vm.uploadStatus = false;
                        vm.uploadFileDom.value = "";
                        vm.trip.coverImg = "/" + res.name;
                        clearTimeout(delayId);
                        $scope.$apply();
                    }, 700);
                })
                .catch(function (err) {
                    vm.uploadPercent = 0;
                    // 判断错误类型，如果是致命性错误弹出警告框提示用户，如果是超时错误为用户显示重试按钮
                    if (JSON.stringify(err).indexOf("Timeout") > -1) {
                        vm.retryUpload = true;
                        $scope.$apply();
                    } else {
                        vm.uploadStatus = false;
                        // 上传失败的情况下，清除本都存储的用户选择的文件，使用服务器上的资源vm.trip.coverImg
                        vm.dataUrl = null;
                        vm.uploadFileDom.value = "";
                        $scope.$apply();
                        $message.warning({
                            text: "文件上传异常，请重新上传或更换素材！！",
                        });
                    }
                });
        }
        vm.deleteImageOrVideo = function () {
            vm.trip.coverImg = null;
            vm.dataUrl = null;
            vm.uploadStatus = false;
            vm.retryUpload = false;
            // 用户删除视频或者图片，需要把上传文件对象的文件清空，如果不清空，将不能上传上次上传失败的文件(浏览器检测两次选择的文件相同将不触发绑定的事件)
            // 用户编辑活动页面的时候，这个值可能为空
            if (vm.uploadFileDom) {
                vm.uploadFileDom.value = "";
            }
        };
        // 重新上传视频或者图片(仅在文件上传超时的时候触发)
        vm.retryUploadFile = function () {
            uploadOss();
        };
        // 设置活动类型
        vm.changeAttribute = function () {
            vm.date = null;
            vm.trip.startTime = null;
            vm.trip.endTime = null;
        };
        // 切换组队方式
        vm.changeTeamset = function () {
            // console.log('切换组队方式changeTeamset-vm.trip.teamset',vm.trip.teamset);
            // 切换组队方式置空和组队方式相关的字段
            if (vm.account.appSource == "2") {
                if (vm.trip.teamset == 1) {
                    vm.trip.maxTeamcount = null;
                    vm.trip.limitTeampeople = false;
                    vm.trip.teamMaxpeople = null;
                } else if (vm.trip.teamset == 3 || vm.trip.teamset == 2) {
                    vm.trip.maxPeople = null;
                    // 一堆一码活动必须填写队伍人数，
                    vm.trip.limitTeampeople = true;
                    vm.trip.enableStart = true;
                    vm.trip.teamMaxpeople = 3;
                }
            } else {
                if (vm.trip.teamset == 1 || vm.trip.teamset == 2) {
                    vm.trip.maxTeamcount = null;
                    // 如果用户切换到一队一码后并没有设置队伍最大人数，然后再切回自动建队和输入编号组队的时候，取消设置最大人数的多选框
                    if (vm.trip.teamMaxpeople) {
                        vm.trip.limitTeampeople = true;
                    } else {
                        vm.trip.limitTeampeople = false;
                        vm.trip.teamMaxpeople = null;
                    }
                } else if (vm.trip.teamset == 3) {
                    vm.trip.maxPeople = null;
                    // 一堆一码活动必须填写队伍人数，
                    vm.trip.limitTeampeople = true;
                    vm.trip.enableStart = true;
                }
            }
        };
        vm.changeCachefile = function () {};
        vm.changeSyncUpload = function () {};
        // 设置队伍人数限制
        vm.changeTeamLimit = function () {
            if (!vm.trip.limitTeampeople) {
                vm.trip.teamMaxpeople = null;
            } else {
                // 用户打开最大队伍人数限制默认开启“未满员可开启”
                vm.trip.enableStart = true;
            }
        };
        // 开启方式
        vm.clearStartCode = function () {
            // vm.trip.startCode = null;
        };
        // 页面的作用域对象； 显示视频地址
        $scope.videoUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };
        // 切换队内同步模式
        vm.changeTeamSync = function () {
            if (vm.trip.teamSyncModel === "1") {
                vm.trip.ltExit = true;
                vm.trip.ltSync = true;
                vm.trip.leaderOnlyFlag = false;
            } else if (vm.trip.teamSyncModel === "2") {
                vm.trip.ltExit = false;
                vm.trip.ltSync = true;
                vm.trip.leaderOnlyFlag = true;
            } else if (vm.trip.teamSyncModel === "3") {
                vm.trip.ltExit = false;
                vm.trip.ltSync = false;
                vm.trip.leaderOnlyFlag = false;
            }
        };

        function logout(appSource) {
            $uibModalInstance.dismiss("appSourceLogin");
            Auth.logout();
            var date = new Date();
            date.setTime(date.getTime() - 1);
            document.cookie =
                "isV3LoginUser=isV3LoginUser;Path=/;domain=itasktour.com;expires=" +
                date.toGMTString();
            //退出到后台登录页   经过测试，这个老框架让跳转过去的登录页面布局异常，所以要调用“window.location.reload()”刷新一下登录页面
            if (appSource == 2) {
                window.location.href = window.location.origin + "/#/mftglogin";
                window.location.reload();
            } else {
                window.location.href = window.location.origin + "/#/";
                window.location.reload();
            }
        }
        vm.selectArea = function () {
            // 判断授权列表是否为空，如果为空，提示用户找管理员添加授权地址
            if (vm.authAreaList.length === 0) {
                $message.warning({
                    text: "授权地址列表为空，请联系管理员为企业添加授权地址！",
                });
                return false;
            }
            var listDom =
                document.getElementsByClassName("select-area-list")[0];
            if (listDom.style.display === "block") {
                listDom.style.display = "none";
            } else {
                listDom.style.display = "block";
            }
            if (
                vm.trip.startLocation &&
                listDom.style.display === "block" &&
                !listDom.isSelect
            ) {
                vm.authAreaList.every(function (item, index) {
                    if (
                        item.name + "-" + item.authRegionsItem ===
                        vm.trip.startLocation
                    ) {
                        setAreaActive(
                            listDom.children,
                            listDom.children[index]
                        );
                        return false;
                    } else {
                        return true;
                    }
                });
            }
        };
        vm.selectAreaItem = function (event) {
            var listDom =
                document.getElementsByClassName("select-area-list")[0];
            vm.trip.startLocation = event.target.dataset.area;
            setAreaActive(listDom.children, event.target);
            listDom.style.display = "none";
        };
        function setAreaActive(domlist, targetDom) {
            Array.prototype.slice.call(domlist).forEach(function (item, index) {
                item.classList.remove("area-item-active");
            });
            targetDom.classList.add("area-item-active");
            targetDom.parentNode.isSelect = true;
        }
        //购买弹框
        vm.openBuydiv = openBuydiv;
        vm.closeBuydiv = closeBuydiv;
        vm.openBuy = 0;
        function openBuydiv() {
            vm.openBuy = 1;
        }
        function closeBuydiv() {
            vm.openBuy = 0;
        }

        // 经过测试，这个方法是angular界面初始化完成之后调用的回调函数
        this.$onInit = function () {
            if (vm.trip.myTripModelId) {
                vm.trip.myTripModel = {
                    id: vm.trip.myTripModelId,
                    title: vm.trip.myTripModelTitle,
                };
            }
        };
        function checkIsImage(url) {
            if (url == null || url == "") {
                return false;
            }
            if (/\.(jpg|jpeg|png|bmp)$/.test(url)) {
                return true;
            }
            return false;
        }
        function checkIsViedo(url) {
            if (url == null || url == "") {
                return false;
            }
            if (/\.(mp4|avi|MOV|mov)$/.test(url)) {
                return true;
            }
            return false;
        }
        vm.changeZombie = function () {
            // if (vm.trip.maxPeople) {
            //     if (Number(vm.trip.maxFugitives) > Number(vm.trip.maxPeople)) {
            //         vm.trip.maxFugitives = vm.trip.maxPeople;
            //     }
            // }
            // if (Number(vm.trip.maxFugitives) < 1) {
            //     vm.trip.maxFugitives = 1;
            // }
        };
        vm.changeTraceType = function (e) {
            if (vm.trip.traceType == 0) {
                vm.trip.maxFugitives = "1";
                vm.trip.maxTrackers = 8;
                vm.trip.tracePlayModelType = "none";
            } else {
                vm.trip.maxFugitives = "3";
                vm.trip.maxTrackers = 15;
                vm.trip.tracePlayModelType = "rescue";
            }
        };
        vm.changeTraceType2 = function (e) {
            if (vm.trip.tracePlayModelType == "rescue") {
                vm.trip.maxFugitives = "3";
                vm.trip.maxTrackers = 15;
            } else if (vm.trip.tracePlayModelType == "zombie") {
                vm.trip.maxFugitives =
                    vm.trip.maxPeople && Number(vm.trip.maxPeople) < 15
                        ? vm.trip.maxPeople
                        : 15;
                vm.trip.maxTrackers = vm.trip.maxPeople
                    ? vm.trip.maxPeople
                    : 15;
            }
        };
        vm.updateTrackers = function () {
            if (Number(vm.trip.maxPeople) < 1) {
                vm.trip.maxPeople = 1;
            }
        };
        vm.changePeopleNumber = function () {
            console.log("vm.trip.maxFugitives=", vm.trip.maxFugitives);
        };
        vm.save = function () {
            console.log("--------save-----------");
            if (
                vm.trip.attribute != null &&
                vm.trip.attribute != "" &&
                vm.trip.attribute != "TRACE" &&
                vm.trip.attribute != "OUTWARD" &&
                vm.trip.attribute != "OUTTIMERACE"
            ) {
                vm.trip.maxPeople =
                    vm.trip.teamMaxpeople * vm.trip.maxTeamcount;
            }
            vm.isSaving = true;

            if (vm.trip.title == null || vm.trip.title.trim() == "") {
                $message.warning({
                    text: "请设置活动名称",
                });
                vm.isSaving = false;
                return;
            }
            if (vm.trip.attribute === "COMMON") {
                vm.trip.teamMaxpeople = 1;
                vm.trip.maxTeamcount = 20000;
                var startDate = new Date();
                vm.date = startDate;
                vm.trip.startTime = startDate;
                vm.trip.endTime = null;
            }

            if (vm.trip.myArtistId) {
                if (vm.trip.myArtistId.id)
                    vm.trip.myArtistId = vm.trip.myArtistId.id;
            }

            // if (vm.trip.myTripModel){
            //     if (vm.trip.myTripModelId){
            //         vm.trip.myTripModelId = vm.trip.myTripModel.id;
            //     }else {
            //         vm.trip.myTripModelId = vm.trip.myTripModel;
            //     }
            // }
            // console.log(vm.trip.myTripModel);
            if (vm.trip.myTripModel != null) {
                //活动绑定线路不为空继续执行
                if (vm.trip.myTripModelId) {
                    if (vm.trip.myTripModel.id) {
                        vm.trip.myTripModelId = vm.trip.myTripModel.id;
                    } else {
                        vm.trip.myTripModelId = vm.trip.myTripModel;
                    }
                } else {
                    vm.trip.myTripModelId = vm.trip.myTripModel;
                }
            } else {
                $message.warning({
                    text: "活动必须引用线路，请选择活动所引用的线路",
                });
                vm.isSaving = false;
                return;
            }
            if (vm.trip.platform != 4) {
                if (vm.trip.coverImg !== null) {
                    if (/\.(jpg|jpeg|png|bmp)$/.test(vm.trip.coverImg)) {
                        var image = document.getElementById("file");
                        if (image != null) {
                            var height = image.height;
                            var width = image.width;
                            var bi = width / height;
                            if (bi < 1.8 || bi > 2.2) {
                                // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                                $message.warning({
                                    text: "上传图片比例应为2:1",
                                });
                                if (vm.trip.coverImg != null) {
                                    vm.trip.coverImg = null;
                                }
                                vm.isSaving = false;
                                return;
                            }
                        }
                    }
                } else {
                    $message.warning({
                        text: "上传图片不能为空",
                    });
                    vm.isSaving = false;
                    return;
                }
            }

            var price = $("#field_price").val();
            if (price < 0) {
                $message.warning({
                    text: "价格不符合要求。",
                });
                vm.isSaving = false;
                return;
            }
            var maxTeamCount = $("#field_maxTeamcount").val();
            if (maxTeamCount < 0) {
                $message.warning({
                    text: "战队数上限不符合要求。",
                });
                vm.trip.maxTeamcount = null;
                vm.isSaving = false;
                return;
            }

            //创建线路时给某些字段赋默认值
            // vm.trip.status = 'EDIT';
            vm.trip.type = "Normal";
            //创建线路时给UGC权限者赋值
            // if (vm.auth==='UGC'){
            //     vm.trip.attribute = 'OUTWARD';
            // }
            if (vm.trip.attribute == null && vm.trip.platform != 4) {
                $message.warning({
                    text: "活动类型不能为空，请设置活动类型",
                });
                vm.isSaving = false;
                return;
            }
            //判断结束时间是否在开始时间之后 及 开始时间，结束时间是否在当前时间之后，
            // 急速追踪活动已发布的情况下不走这个方法；&&!(vm.trip.platform == 4&&vm.trip.status==='PUBLISH')
            if (
                vm.trip.attribute != "COMMON" &&
                !(
                    vm.trip.platform == 4 &&
                    vm.trip.status === "PUBLISH" &&
                    vm.afterTripStartTime
                )
            ) {
                var date = $filter("date")(vm.date, "yyyy-MM-dd");
                if (vm.trip.startType != 1) {
                    vm.trip.startTime = "00:00";
                }
                var time = $filter("date")(vm.trip.startTime, "HH:mm");
                // 后端说急速追踪的开始时间需要追加1秒钟，
                if (vm.trip.platform == 4) {
                    vm.trip.startTime = "00:00:01";
                    time = $filter("date")(vm.trip.startTime, "HH:mm:ss");
                }
                vm.trip.startTime = new Date(
                    (date + " " + time).replace(/-/g, "/")
                );
                if (vm.trip.startTime && vm.trip.endTime) {
                    var start = new Date(vm.trip.startTime).getTime();
                    var end = new Date(vm.trip.endTime).getTime();
                    if (end <= start) {
                        alert(
                            "结束时间必须在开始时间之后，请重新录入结束时间！"
                        );
                        if (vm.trip.platform != 4) {
                            //极速追踪活动结束时间不重置
                            vm.trip.endTime = null;
                        }
                        vm.isSaving = false;
                        return;
                    }
                    var now = new Date().getTime();
                    var nowDateLimit = $filter("date")(
                        new Date().getTime(),
                        "yyyy-MM-dd HH:mm"
                    );
                    if (end < now) {
                        if (vm.trip.platform != 4) {
                            //极速追踪活动结束时间不重置
                            vm.trip.endTime = null;
                            alert(
                                "活动结束时间不能在当前时间（" +
                                    nowDateLimit +
                                    "）之前！"
                            );
                        } else {
                            alert("活动已结束不能更改");
                        }
                        vm.isSaving = false;
                        return;
                    }
                } else {
                    //正则验证时间格式是否正确
                    var startTime = checkTime(vm.trip.startTime);
                    var endTime = checkTime(vm.trip.endTime);
                    if (startTime === false) {
                        alert("请输入正确的开始时间格式!");
                        vm.trip.startTime = null;
                        vm.isSaving = false;
                        return;
                    }
                    if (vm.trip.platform != 4) {
                        if (endTime === false) {
                            alert("请输入正确的结束时间格式!");
                            vm.trip.endTime = null;
                            vm.isSaving = false;
                            return;
                        }
                    }
                }
                // console.log('开始时间：'+start+'结束时间：'+end);
            }
            // 爱闯闯黑名单用户限制修改结束事件
            if (
                vm.account.appSource == "1" &&
                vm.trip.platform != 4 &&
                !vm.whiteListUser
            ) {
                // 把时间提取成北京时间字符串的方法
                var dateLimit = $filter("date")(vm.date, "yyyy-MM-dd");
                var dateLimit2 = $filter("date")(
                    new Date(vm.date).getTime() + 86400000,
                    "yyyy-MM-dd"
                );
                var startTimeLimit = $filter("date")(
                    vm.trip.startTime,
                    "HH:mm"
                );
                if (vm.trip.startType == 1) {
                    if (
                        new Date(vm.trip.endTime).getTime() -
                            new Date(
                                dateLimit + " " + startTimeLimit
                            ).getTime() >
                        86459000
                    ) {
                        alert(
                            "普通用户设置活动时长不能超过24小时，当前开始时间为" +
                                dateLimit +
                                " " +
                                startTimeLimit +
                                ",结束时间应小于或等于" +
                                dateLimit2 +
                                " " +
                                startTimeLimit
                        );
                        vm.isSaving = false;
                        return false;
                    }
                } else if (vm.trip.startType == 2 || vm.trip.startType == 3) {
                    if (
                        new Date(vm.trip.endTime).getTime() -
                            new Date(dateLimit + " 00:00").getTime() >
                        86459000
                    ) {
                        alert(
                            "普通用户设置活动时长不能超过24小时，当前开始时间为" +
                                dateLimit +
                                " 00:00，结束时间应小于或等于" +
                                dateLimit2 +
                                " 00:00"
                        );
                        vm.isSaving = false;
                        return false;
                    }
                }
            }
            //后台修改活动不对战队做修改，返回null即可
            vm.trip.hasTeams = null;
            vm.trip.hasNormalTeams = null;
            vm.trip.hasTestTeams = null;
            vm.trip.hasWebTeams = null;

            // 品牌定制账户的企业如果是固定授权地点的情况下，必须选择地址，
            if (
                vm.trip.platform != 4 &&
                vm.account.appSource == "2" &&
                vm.eAccount &&
                vm.eAccount.selectRegionType == "1" &&
                (vm.trip.startLocation === null || vm.trip.startLocation === "")
            ) {
                var textMsg = null;
                if (vm.authAreaList.length > 0) {
                    textMsg = "请选择授权地点";
                } else {
                    textMsg = "企业授权地点为空，请企业联系管理员添加授权地址";
                }
                $message.warning({
                    text: textMsg,
                });
                vm.isSaving = false;
                return false;
            }
            if (vm.trip.platform == 4 && vm.trip.terrainDiff == null) {
                alert("请选择地图难度!");
                vm.isSaving = false;
                return;
            }
            if (vm.trip.platform == 4 && vm.trip.traceType == null) {
                alert("请选择组队模式!");
                vm.isSaving = false;
                return;
            }
            if (
                vm.trip.platform == 4 &&
                !(angular.isNumber(vm.trip.etime) && vm.trip.etime > 0)
            ) {
                alert("游戏时长为空，请重新设置");
                vm.trip.etime = null;
                vm.isSaving = false;
                $scope.$apply();
                return;
            }
            if (
                vm.trip.platform == 0 &&
                vm.account.appSource == 2 &&
                !(angular.isNumber(vm.trip.etime) && vm.trip.etime > 0)
            ) {
                alert("线路预计通关时长为空，请重新设置");
                vm.trip.etime = null;
                vm.isSaving = false;
                $scope.$apply();
                return;
            }
            if (
                vm.trip.platform == 4 &&
                !(angular.isNumber(vm.trip.saftyTime) && vm.trip.saftyTime > 0)
            ) {
                alert("安全区开启时间为空，请重新设置");
                vm.trip.saftyTime = null;
                vm.isSaving = false;
                $scope.$apply();
                return;
            }
            if (vm.trip.platform == 4 && vm.trip.etime < vm.trip.saftyTime) {
                alert("安全区开启时间不能大于活动时长,请重新输入");
                vm.trip.saftyTime = null;
                vm.isSaving = false;
                return;
            }
            if (
                vm.trip.platform == 4 &&
                vm.trip.tracePlayModelType == "zombie"
            ) {
                vm.trip.maxTrackers = vm.trip.maxPeople;
                if (Number(vm.trip.maxFugitives) >= Number(vm.trip.maxPeople)) {
                    alert("感染者模式的组内最大逃亡者数应小于最大人数");
                    vm.isSaving = false;
                    return false;
                }
            }
            if (vm.trip.platform == 4) {
                if (
                    vm.trip.traceType == 0 ||
                    vm.trip.tracePlayModelType == "rescue"
                ) {
                    vm.trip.teamMaxpeople =
                        Number(vm.trip.maxTrackers) +
                        Number(vm.trip.maxFugitives);
                } else if (vm.trip.tracePlayModelType == "zombie") {
                    vm.trip.teamMaxpeople = vm.trip.maxPeople;
                }
            }
            if (vm.trip.limitTeampeople && !vm.trip.teamMaxpeople) {
                alert("请设置最大队伍人数");
                vm.trip.teamMaxpeople = null;
                vm.isSaving = false;
                $("#field_teamMaxpeople").focus();
                return false;
            }
            if (vm.trip.platform != 4) {
                // 后端的一队一码组队方式需要maxPeople这个字段，
                if (vm.trip.teamset == 3) {
                    vm.trip.maxPeople =
                        vm.trip.teamMaxpeople * vm.trip.maxTeamcount;
                } else {
                    // 后端保存用户设置的输入编号组队和自动建队方式的活动的时候，后台会主动给teamMaxpeople这个字段赋值并保存，但是这个字段对于用户来说是可以选择设置的，用户没有设置，不应该让用户看到莫名其妙的东西
                    if (vm.trip.teamMaxpeople == null) {
                        vm.trip.teamMaxpeople = vm.trip.maxPeople;
                    }
                }
                if (
                    vm.account.appSource == "2" &&
                    vm.trip.teamset == 2 &&
                    vm.trip.teamMaxpeople != null &&
                    vm.trip.maxTeamcount != null
                ) {
                    vm.trip.maxPeople =
                        vm.trip.teamMaxpeople * vm.trip.maxTeamcount;
                }
            }
            if (
                vm.trip.teamSyncModel != 1 &&
                vm.trip.teamSyncModel != 2 &&
                vm.trip.teamSyncModel != 3
            ) {
                $message.warning({
                    text: "请选择队内同步模式！",
                });
                vm.isSaving = false;
                return false;
            }
            vm.trip.mailMaxPeople = vm.trip.maxPeople;
            vm.trip.maxFugitives = Number(vm.trip.maxFugitives);
            if (vm.trip.id !== null) {
                Trip.update(vm.trip, onSaveSuccess, onSaveError);
            } else {
                Trip.save(vm.trip, onSaveSuccess, onSaveError);
            }
        };
        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
            $message.success({
                text: vm.trip.id ? "活动修改成功" : "活动创建成功",
            });
        }
        function onSaveError(result) {
            if (result.data != null && result.data.errorCode != null) {
                if (result.data.errorCode == -1) {
                    alert("发布活动所需点数不足，请先前往充值。");
                    // 这个地方缺失一个提示框提示用户是否现在就跳转到企业账户充值页面（超管不能跳转，因为超管没有充值按钮）
                } else if (result.data.errorCode == -2) {
                    // 1、用户修改活动人数相关字段导致人数相关字段变大导致扣点，并且用户所在企业账户点券余额不足的情况下提示用户余额不足；
                    // 2、用户修改活动人数相关字段导致人数相关字段变小，修改的值小于当前活动已经参与的玩家的人数的时候提示用户不能把值修改为当前玩家总数以下的值；
                    alert(result.data.message);
                    vm.isSaving = false;
                    vm.maxPeople = null;
                    vm.maxTeamcount = null;
                    vm.teamMaxpeople = null;
                    return false;
                }
            }
            vm.isSaving = false;
        }
        //正则验证时间格式
        function checkTime(str) {
            //console.log(str);
            str = String(str);
            str = str.replace(/ GMT.+$/, ""); // Or str = str.substring(0, 24)
            var d = new Date(str);
            var a = [
                d.getFullYear(),
                d.getMonth() + 1,
                d.getDate(),
                d.getHours(),
                d.getMinutes(),
                d.getSeconds(),
            ];
            for (var i = 0, len = a.length; i < len; i++) {
                if (a[i] < 10) {
                    a[i] = "0" + a[i];
                }
            }
            str =
                a[0] +
                "-" +
                a[1] +
                "-" +
                a[2] +
                " " +
                a[3] +
                ":" +
                a[4] +
                ":" +
                a[5];
            var reDateTime =
                /^(?:19|20)[0-9][0-9]-(?:(?:[0][1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
            var isDateTime = reDateTime.test(str);
            return isDateTime;
        }
        vm.clear = function () {
            $uibModalInstance.dismiss("cancel");
            $uibModalStack.dismissAll();
        };
    }
})();
