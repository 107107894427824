(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TsTrip', TsTrip);

    TsTrip.$inject = ['$resource', 'DateUtils'];

    function TsTrip ($resource, DateUtils) {
        var resourceUrl =  'api/gamets/trip/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TsTripExt', TsTripExt);

    TsTripExt.$inject = ['DateUtils', '$http'];

    function TsTripExt (DateUtils, $http) {
        return {
            getRanksByTsTrip: function (tripId) {
                return $http.get('/api/gamets/trip/rank/'+ tripId).then(function (response) {
                    return (response.data);
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TsTripPay', TsTripPay);

    TsTripPay.$inject = ['$http'];

    function TsTripPay ($http) {

        return{
            createOrder:function(param){
                var data = $http({
                    method: 'POST',
                    url: '/api/gamets/trip/order/create',
                    data: {eAccountId:param.eId,salePrice:param.sPrice,type:param.eType,tripId:param.tripId}
                });
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('getTsOrder', getTsOrder);

    getTsOrder.$inject = ['$resource', 'DateUtils'];

    function getTsOrder ($resource, DateUtils) {
        var resourceUrl =  '/api/gamets/order/:orderId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    console.log("service:" + JSON.stringify(data));
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('PublishTsTrip', PublishTsTrip);

    PublishTsTrip.$inject = ['$http'];

    function PublishTsTrip ($http) {

        return{
            publish:function(couponIds,startCode,title,version,mapData, mailStartTime, mailEndTime, emailMaxpeople){
                var url = '/api/trip-game/publish?title='+title+'&startCode='+startCode+'&version='+version+'&type=TS'
                    +  "&mailStartTime=" + mailStartTime + "&mailEndTime=" + mailEndTime + "&mailMaxPeople=" + emailMaxpeople;
                if (couponIds.length > 0) {
                    url = url + "&couponIds=" + couponIds;
                }
                return $http({
                    method: 'POST',
                    url: url,
                    data: {mapData: mapData}
                });
            }

        };
    }
})();
