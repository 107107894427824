(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TeamDialogController", TeamDialogController);

    TeamDialogController.$inject = [
        "$timeout",
        "$scope",
        "$uibModalInstance",
        "entity",
        "Team",
        "UserSearch",
        "UserExt",
        "Upload",
        "Ahdin",
    ];

    function TeamDialogController(
        $timeout,
        $scope,
        $uibModalInstance,
        entity,
        Team,
        UserSearch,
        UserExt,
        Upload,
        Ahdin
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.team = entity;
        console.log(vm.team.oldUser);
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        // vm.users = User.query();
        // vm.trips = Trip.query();
        vm.itemsPerPage = 250;
        vm.filterUser = this.filterUser;
        this.filterUser = function (search) {
            if (search) {
                vm.users = UserSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
            }
        };

        this.$onInit = function () {
            if (vm.team.leaderId) {
                UserExt.getUserByID(vm.team.leaderId).then(function (response) {
                    var user = response.data;
                    vm.team.leaderId = {
                        id: user.id,
                        login: user.login,
                        firstName: user.firstName,
                    };
                    console.log(vm.team.leaderId);
                });
            }

            // var privates = document.getElementById("private");
            // console.log("privates:"+vm.team.private);
            // if (vm.team.private == false){
            //     // privates.style.display = "none";
            //     $("#private").setAttribute('display','none');
            // }
        };

        $scope.isPrivate = vm.team.private;

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function save() {
            vm.isSaving = true;
            if (vm.team.leaderId) {
                if (vm.team.leaderId.id) vm.team.leaderId = vm.team.leaderId.id;
            }

            if (vm.team.id !== null) {
                Team.update(vm.team, onSaveSuccess, onSaveError);
            } else {
                Team.save(vm.team, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("taskServiceApp:teamUpdate", result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
