(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("ProgramCenterDetailController", controller)
        .filter("pdfFilter", function () {
            return function (text) {
                return text + ".pdf";
            };
        })
        .filter("labelFilter", function () {
            return function (text) {
                if (text == null) {
                    return "";
                }
                var arr = JSON.parse(text);
                return arr;
            };
        });
    controller.$inject = ["$state", "$http", "$stateParams", "$scope", "$sce"];
    function controller($state, $http, $stateParams, $scope, $sce) {
        var vm = this;
        //schemeId(超管->综合管理->方案管理，添加的方案id)
        vm.schemeId = $stateParams.id;
        // 用户是否登录 经过查找发现，本地缓存的框架的token是唯一判断用户是否登录的依据，
        vm.isLogin = Boolean(localStorage.getItem("jhi-authenticationToken"));
        // 用户未登录跳转到未登录的方案详情页面
        if (!vm.isLogin) {
            $state.go(
                "program-center-detail-share",
                { id: vm.schemeId },
                { reload: true }
            );
        }
        vm.IMAGE_SERVER = IMAGE_SERVER;
        //vm.programDetail.tripModelId  该方案的示范线路id，超管在方案管理界面添加一个方案，可以为这个方案指定示范线路id，
        vm.programDetail = null;
        $("body").css("background-color", "#ecefef");
        // 发布页面灰色背景关闭
        $(".modal-backdrop").remove();
        document.documentElement.scrollTop = 0;
        // 正式服的微信分享逻辑
        if (window.location.origin.indexOf("v3") != -1) {
            $http({
                method: "POST",
                url: "/api/program/share",
                params: {
                    id: vm.schemeId,
                    url: window.location.href,
                },
            }).then(
                function (res) {
                    console.log(res.data);
                    wechatStart(res.data);
                },
                function (err) {
                    console.log(err);
                }
            );
        }
        function wechatStart(obj) {
            var linkURL = window.location.href;
            wx.config({
                debug: false, // true时，手机微信环境访问会alert一些errmsg信息，如果显示errmsg:ok表示正常，否认表示出了问题
                appId: obj.appId, // 必填，公众号的唯一标识
                timestamp: obj.timestamp, // 后台返回的数据中取
                nonceStr: obj.nonceStr, // 后台返回的数据中取
                signature: obj.signature, // 后台返回的数据中取
                jsApiList: [
                    "updateTimelineShareData",
                    "updateAppMessageShareData",
                ], // 必填，需要使用的JS接口列表
            });
            // 需在用户可能点击分享按钮前就先调用，说白了就是放在文章详情信息读取完后就可以执行下面的wx.ready()方法
            wx.ready(function () {
                // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                wx.updateAppMessageShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function () {
                        // 设置成功
                    },
                });
                // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
                wx.updateTimelineShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function () {
                        // 设置成功
                    },
                });
            });
        }
        // 获取指定方案id的方案数据
        $http({
            method: "GET",
            url: "/api/program/" + vm.schemeId,
        }).then(function (res) {
            vm.programDetail = res.data;
            document.title = vm.programDetail.title;
            vm.programDetail.programText = $sce.trustAsHtml(
                vm.programDetail.programText
            );
            vm.programDetail.manualText = $sce.trustAsHtml(
                vm.programDetail.manualText
            );
        });
        // 方案中心模板页面中培训游戏立即发布活动按钮事件
        vm.pushActivity = function () {
            var urlType = "";
            switch (vm.programDetail.programType) {
                case "极限寻宝":
                    urlType = "/#/ts/trips?back=";
                    break;
                case "极限潜航":
                    urlType = "/#/wx-game-trips?category=2&return_scheme_id=";
                    break;
                case "极限拆弹":
                    urlType = "/#/wx-game-trips?category=3&return_scheme_id=";
                    break;
                case "极限谍战":
                    urlType = "/#/wx-game-trips?category=4&return_scheme_id=";
                    break;
            }
            window.location.href =
                window.location.origin + urlType + vm.schemeId;
        };
        // 打开联系我们的弹窗的功能
        vm.openModelid = 2;
        // 在手机端的联系我们是通过弹窗展示，默认值设置为0，不让弹窗展示
        if ($(window).width() < 767) {
            vm.openModelid = 0;
        }
        vm.openModel = function (id) {
            vm.openModelid = id;
            if ($(window).width() < 767) {
                $("#myModal").modal("show");
            }
        };
        vm.closeOpenModel = function () {
            $("#myModal").modal("hide");
            vm.openModelid = 0;
        };
        vm.openModel2 = function () {
            if ($(window).width() < 767) {
                $("#myModal").modal("show");
                vm.openModelid = 1;
            } else {
                $("#myModal2").modal("show");
            }
        };
        vm.closeOpenModel2 = function () {
            $("#myModal2").modal("hide");
        };
        vm.changeTab = 1;
        vm.doChange = function (changeTab) {
            vm.changeTab = changeTab;
        };
        // 获取示范线路（就是复制一份该方案的线路到自己的账号）
        vm.tipOpen = 0;
        vm.cannotOpt = false;
        vm.confirmCopy = function (id) {
            if (vm.cannotOpt) {
                return;
            }
            vm.cannotOpt = true;
            doSaveClickNum(5);
            md("方案-获取线路-" + vm.schemeId);
            $http({
                method: "GET",
                url: "/api/trip-models/copy/" + id + "/" + false,
            }).then(
                function (res) {
                    // console.log(res);
                    // if (res != null && res.data.errorCode == -1){
                    //     alert("复制失败，被复制线路中不能有新版AR");
                    //     return;
                    // }
                    // console.log(new Date().getTime())
                    // if (res != null && res.data !=null){
                    //     if (res.data.hasLocationTasks!=null && typeof(res.data.hasLocationTasks)!='undefined') {
                    //         for (var i in res.data.hasLocationTasks){
                    //             // console.log(res.data.hasLocationTasks[i]);
                    //             if (res.data.hasLocationTasks[i].hasTasks!=null && typeof(res.data.hasLocationTasks[i].hasTasks)!='undefined'){
                    //                 for (var j in res.data.hasLocationTasks[i].hasTasks){
                    //                     // console.log(res.data.hasLocationTasks[i].hasTasks[j]);
                    //                     if (res.data.hasLocationTasks[i].hasTasks[j].type!=null
                    //                         && typeof(res.data.hasLocationTasks[i].hasTasks[j].type)!='undefined'
                    //                         && res.data.hasLocationTasks[i].hasTasks[j].type == 'NEWAR'){
                    //                      //   alert("复制线路中含有新版AR，后台正在识别新版AR的扫描图片，请稍后再次编辑新版AR任务查看识别情况");
                    //                       //  return;
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                    // console.log(new Date().getTime())
                    // $('#myModal1').modal('hide');
                    vm.tipOpen = 1;
                    var num = 2;
                    vm.fun = fun;
                    function fun() {
                        if (num <= 0) {
                            vm.tipOpen = 0;
                            clearInterval(inter);
                        }
                        document.getElementById("num").innerHTML = num;
                        num = num - 1;
                    }
                    var inter = setInterval(vm.fun, 1000);
                    vm.cannotOpt = false;
                },
                function (err) {
                    console.log(err);
                    vm.cannotOpt = false;
                }
            );
        };
        // 用户在方案详情页的行为统计
        // type 1: centerClickNum(从方案中心点击进入到方案详情页); 2:shareOpenNum(方案详情页分享外链页面打开次数); 3:programDownloadNum（用户点击下载方案介绍的次数） ;4:manualDownloadNum（用户点击下载执行手册的次数）; 5:tripClickNum（用户点击复制某线路的次数）
        function doSaveClickNum(type) {
            $http({
                method: "POST",
                url: "/api/program/saveNum",
                params: {
                    id: vm.schemeId,
                    type: type,
                },
            }).then(
                function (res) {},
                function (err) {
                    console.log(err);
                }
            );
        }
        // 统计用户通过方案中心获取方案对应的线路的次数
        function md(strAction) {
            $http({
                method: "POST",
                url: "/api/mdLog/save",
                params: {
                    type: 1,
                    action: strAction,
                },
            }).then(
                function (res) {},
                function (err) {
                    console.log(err);
                }
            );
        }
        vm.closeTip = function () {
            vm.tipOpen = 0;
        };
        vm.toTrip = function () {
            $state.go("trip-model", null, { reload: true });
        };
        vm.pdfDownload = function (type) {
            if (type == 1) {
                doSaveClickNum(3);
            } else if (type == 2) {
                doSaveClickNum(4);
            }
            // 用户在手机端直接给用户展示pdf手册，下载行为只在pc端触发
            if ($(window).width() < 767) {
                var param = { id: vm.schemeId, type: type };
                $state.go(
                    "showPDF",
                    { obj: JSON.stringify(param) },
                    { reload: true }
                );
                return;
            }
            var url = "";
            var name = "";
            if (type == 1) {
                if (vm.programDetail.programPPT) {
                    url = vm.IMAGE_SERVER + vm.programDetail.programPPT;
                    name = vm.programDetail.title + "方案介绍.pptx";
                } else {
                    url = vm.IMAGE_SERVER + vm.programDetail.programUrl;
                    name = vm.programDetail.title + "方案介绍.pdf";
                }
            } else if (type == 2) {
                if (vm.programDetail.manualPPT) {
                    url = vm.IMAGE_SERVER + vm.programDetail.manualPPT;
                    name = vm.programDetail.title + "执行手册.pptx";
                } else {
                    url = vm.IMAGE_SERVER + vm.programDetail.manualUrl;
                    name = vm.programDetail.title + "执行手册.pdf";
                }
            }
            var downloadUrl =
                window.location.origin +
                "/api/programs/pdfDownload?downLoadPath=" +
                url +
                "&fileName=" +
                name;
            var form = $("<form></form>")
                .attr("action", downloadUrl)
                .attr("method", "post");
            form.appendTo("body").submit().remove();
        };
        // 手机端调用手机电话的方法
        vm.toPhone = function (phone) {
            window.location.href = "tel://" + phone;
        };
        // 退出该页面销毁该页面打开的弹窗
        $scope.$on("$destroy", function () {
            vm.closeOpenModel();
            vm.closeOpenModel2();
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");
        });
    }
})();
