(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripDeleteController',TripDeleteController);

    TripDeleteController.$inject = ['$uibModalInstance', 'entity', 'Trip','$uibModalStack'];

    function TripDeleteController($uibModalInstance, entity, Trip,$uibModalStack) {
        var vm = this;
        if(entity.id == null || !entity.canShow){
            setTimeout(function () {
                alert("无访问权限");
                clear()
            },100)
            return;
        }
        vm.trip = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $uibModalStack.dismissAll();
        }

        function confirmDelete (id) {
            Trip.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
