(function () {
    "use strict";
    angular.module("taskServiceApp").config(stateConfig);
    stateConfig.$inject = ["$stateProvider"];
    function stateConfig($stateProvider) {
        $stateProvider.state("register", {
            parent: "app",
            url: "/register",
            data: {
                authorities: [],
                pageTitle: "register.title",
            },
            params: {
                loginParam: {},
            },
            views: {
                "navbar@": {},
                "content@": {
                    templateUrl: "app/account/register/register.html",
                    controller: "RegisterController",
                    controllerAs: "vm",
                },
            },
            resolve: {},
        });
        $stateProvider.state("register-login", {
            parent: "app",
            url: "/register-login",
            data: {
                authorities: [],
                pageTitle: "完善信息",
            },
            views: {
                "navbar@": {},
                "content@": {
                    templateUrl: "app/account/register/register-login.html",
                    controller: "RegisterLoginController",
                    controllerAs: "vm",
                },
            },
            params: {
                id: "",
                login: "",
                pwd: "",
                loginParam: {},
            },
            resolve: {},
        });
        $stateProvider.state("forget-pwd", {
            parent: "account",
            url: "/forget",
            data: {
                authorities: [],
                pageTitle: "忘记密码",
            },
            params: {
                appSource: "1",
            },
            views: {
                "content@": {
                    templateUrl: "app/account/register/forget-pwd.html",
                    controller: "ForgetPwdController",
                    controllerAs: "vm",
                },
            },
            resolve: {},
        });
        $stateProvider.state("mftgforget-pwd", {
            parent: "account",
            url: "/mftgforget",
            data: {
                authorities: [],
                pageTitle: "忘记密码",
            },
            params: {
                appSource: "2",
            },
            views: {
                "content@": {
                    templateUrl: "app/account/register/forget-pwd.html",
                    controller: "ForgetPwdController",
                    controllerAs: "vm",
                },
            },
            resolve: {},
        });
    }
})();
