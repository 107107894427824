(function () {
    "use strict";
    angular.module("taskServiceApp").factory("User", User);
    User.$inject = ["$resource"];
    function User($resource) {
        var service = $resource(
            "api/users/:login",
            {},
            {
                query: { method: "GET", isArray: true, params: { size: 100 } },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        // console.log( data);
                        var authoritiesObj = [
                            { id: 1, name: "ROLE_USER", value: true },
                            // {'id':2,'name':'ROLE_ACTIVE','value':false},
                            { id: 3, name: "ROLE_INDIEDESIGN", value: false },
                            { id: 4, name: "ROLE_USERDESIGN", value: false },
                            { id: 5, name: "ROLE_OPERATION", value: false },
                            // {'id':5,'name':'ROLE_SPY','value':false},
                            // {'id':6,'name':'ROLE_DESIGNER','value':false},
                            { id: 7, name: "ROLE_RELEASE", value: false },
                            // {'id':8,'name':'ROLE_ADMIN','value':false},
                            { id: 9, name: "ROLE_SUPER", value: false },
                            // {'id':10,'name':'ROLE_COACH','value':false}
                        ];
                        for (var i = 0; i < data.authorities.length; i++) {
                            for (var j = 0; j < authoritiesObj.length; j++) {
                                if (
                                    data.authorities[i] ===
                                    authoritiesObj[j].name
                                ) {
                                    authoritiesObj[j].value = true;
                                }
                            }
                        }
                        data.authoritiesObj = authoritiesObj;
                        for (var x = 0; x < data.authorities.length; x++) {
                            if (
                                data.authorities[x] === "ROLE_SUPER" &&
                                (data.id === 3 || data.id === 66013)
                            ) {
                                document.getElementById(
                                    "user-dialog"
                                ).style.display = "none";
                                document.getElementById(
                                    "submit"
                                ).style.display = "none";
                                document.getElementById("tip").style.display =
                                    "block";
                            }
                        }
                        return data;
                    },
                },
                save: { method: "POST" },
                update: { method: "PUT" },
                delete: { method: "DELETE" },
            }
        );

        return service;
    }
})();
(function () {
    "use strict";
    angular.module("taskServiceApp").factory("UserExt", UserExt);
    UserExt.$inject = ["$http"];
    function UserExt($http) {
        return {
            getUserByID: function (userId) {
                console.debug("getUserByID:" + userId);
                return $http.get("/api/userinfo/" + userId);
            },
        };
    }
})();
