(function () {
    "use strict";

    angular.module("taskServiceApp").factory("Auth", Auth);

    Auth.$inject = [
        "$rootScope",
        "$state",
        "$sessionStorage",
        "$q",
        "$translate",
        "Principal",
        "AuthServerProvider",
        "Account",
        "Register",
        "Activate",
        "Password",
        "PasswordResetInit",
        "PasswordResetFinish",
    ];

    function Auth(
        $rootScope,
        $state,
        $sessionStorage,
        $q,
        $translate,
        Principal,
        AuthServerProvider,
        Account,
        Register,
        Activate,
        Password,
        PasswordResetInit,
        PasswordResetFinish
    ) {
        var service = {
            activateAccount: activateAccount,
            authorize: authorize,
            changePassword: changePassword,
            createAccount: createAccount,
            getPreviousState: getPreviousState,
            login: login,
            logout: logout,
            resetPasswordFinish: resetPasswordFinish,
            resetPasswordInit: resetPasswordInit,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
            updateAccount: updateAccount,
        };

        return service;

        function activateAccount(key, callback) {
            var cb = callback || angular.noop;

            return Activate.get(
                key,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)
            ).$promise;
        }
        function authorize(force) {
            var authReturn = Principal.identity(force).then(authThen);
            return authReturn;
            function authThen() {
                updateGlobalData(function () {
                    var isAuthenticated = Principal.isAuthenticated();
                    // an authenticated user can't access to login and register pages
                    if (
                        isAuthenticated &&
                        $rootScope.toState.parent === "account" &&
                        ($rootScope.toState.name === "login" ||
                            $rootScope.toState.name === "register")
                    ) {
                        $state.go("program-center");
                    }

                    // 经过查找发现，下边的代码是从nginx等服务器上获取前端存储的路由信息，这也是后台这个项目有些时候出现莫名其妙的路由乱跳的根本原因所在，这些乱跳的依据不再本地，在服务器上。备注：“JHipster”这个框架是一个完整的前后端一体的框架，但是路由记录的这个功能前后端当时开发的缺胳膊少腿，所以导致路由偶尔乱跳。
                    // recover and clear previousState after external login redirect (e.g. oauth2)
                    // 线路编辑器跳转过来不执行下边的代码
                    if (
                        $rootScope.toState.name !== "trip.new1" &&
                        isAuthenticated &&
                        !$rootScope.fromState.name &&
                        getPreviousState()
                    ) {
                        var previousState = getPreviousState();
                        resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }

                    if (
                        $rootScope.toState.data.authorities &&
                        $rootScope.toState.data.authorities.length > 0 &&
                        !Principal.hasAnyAuthority(
                            $rootScope.toState.data.authorities
                        )
                    ) {
                        if (
                            $rootScope.toState.name === "program-center-detail"
                        ) {
                            console.log("访问方案详情页");
                        } else {
                            if (isAuthenticated) {
                                // user is signed in but not authorized for desired state
                                $state.go("accessdenied", {
                                    appSource:
                                        $rootScope.toStateParams.appSource,
                                });
                            } else {
                                // user is not authenticated. stow the state they wanted before you
                                // send them to the login service, so you can return them when you're done
                                storePreviousState(
                                    $rootScope.toState.name,
                                    $rootScope.toStateParams
                                );
                                // now, send them to the signin state so they can log in
                                $state
                                    .go("accessdenied", {
                                        appSource:
                                            $rootScope.toStateParams.appSource,
                                    })
                                    .then(function () {
                                        // 这个地方其实是用户未登录跳转登录界面,
                                        if (
                                            $rootScope.toStateParams
                                                .appSource == 1
                                        ) {
                                            $state.go("login");
                                        } else if (
                                            $rootScope.toStateParams
                                                .appSource == 2 ||
                                            window.location.hostname.indexOf(
                                                "mftg"
                                            ) > -1 ||
                                            localStorage.getItem(
                                                "appSource"
                                            ) === "2"
                                        ) {
                                            $state.go("mftglogin");
                                        } else {
                                            // 上述条件不满足的时候都跳转到爱闯闯登录界面；
                                            $state.go("login");
                                        }
                                    });
                            }
                        }
                    }
                });
            }
        }

        function changePassword(newPassword, callback) {
            var cb = callback || angular.noop;

            return Password.save(
                newPassword,
                function () {
                    return cb();
                },
                function (err) {
                    return cb(err);
                }
            ).$promise;
        }

        function createAccount(account, callback) {
            var cb = callback || angular.noop;

            return Register.save(
                account,
                function () {
                    return cb(account);
                },
                function (err) {
                    this.logout();
                    return cb(err);
                }.bind(this)
            ).$promise;
        }

        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(
                    function (err) {
                        this.logout();
                        deferred.reject(err);
                        return cb(err);
                    }.bind(this)
                );

            function loginThen(data) {
                Principal.identity(true).then(function (account) {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    if (account !== null) {
                        if (account.langKey === null) {
                            account.langKey = "zh-cn";
                            $translate.use(account.langKey).then(function () {
                                $translate.refresh();
                            });
                        }
                    }
                    deferred.resolve(data);
                });
                return cb();
            }

            return deferred.promise;
        }

        function logout() {
            AuthServerProvider.logout();
            Principal.authenticate(null);
        }

        function resetPasswordFinish(keyAndPassword, callback) {
            var cb = callback || angular.noop;

            return PasswordResetFinish.save(
                keyAndPassword,
                function () {
                    return cb();
                },
                function (err) {
                    return cb(err);
                }
            ).$promise;
        }

        function resetPasswordInit(mail, callback) {
            var cb = callback || angular.noop;

            return PasswordResetInit.save(
                mail,
                function () {
                    return cb();
                },
                function (err) {
                    return cb(err);
                }
            ).$promise;
        }

        function updateAccount(account, callback) {
            var cb = callback || angular.noop;

            return Account.save(
                account,
                function () {
                    return cb(account);
                },
                function (err) {
                    return cb(err);
                }.bind(this)
            ).$promise;
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = {
                name: previousStateName,
                params: previousStateParams,
            };
            $sessionStorage.previousState = previousState;
        }
    }
})();
