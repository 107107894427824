(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("ClientVersionController", controller);
    controller.$inject = ["ClientVersionService", "$http", "$scope"];
    function controller(ClientVersionService, $http, $scope) {
        var vm = this;
        vm.clientVersions = ClientVersionService.findAll();
        vm.editData = null;
        vm.title = "";
        vm.showEdit = function (id) {
            vm.apkIcon = null;
            vm.editData = vm.clientVersions[id - 1];
            if (vm.editData.type === "ANDROID") {
                vm.title = "爱闯闯_android";
            } else if (vm.editData.type === "ANDROID_MAGIC") {
                vm.title = "魔法特工_android";
            } else if (vm.editData.type === "AR_CORE") {
                vm.title = "魔法特工_AR_CORE";
            } else {
                vm.title = "";
            }
            $("#myModal1").modal(true);
        };
        // 加载解析apk安装包的外部插件
        function loadApkInfoParser() {
            if (!window.AppInfoParser) {
                var scriptDom = document.createElement("script");
                scriptDom.src =
                    "https://image.itasktour.com/WX/plug/app-info-parser.min.js";
                document.body.appendChild(scriptDom);
            }
            if (!window.SparkMD5) {
                var scriptDom = document.createElement("script");
                scriptDom.src =
                    "https://image.itasktour.com/WX/plug/spark-md5.min.js";
                document.body.appendChild(scriptDom);
            }
        }
        loadApkInfoParser();
        function createFileMd5(file, fn) {
            var blobSlice;
            if (File.prototype.slice) {
                blobSlice = File.prototype.slice;
            } else if (File.prototype.mozSlice) {
                blobSlice = File.prototype.mozSlice;
            } else if (File.prototype.webkitSlice) {
                blobSlice = File.prototype.webkitSlice;
            }
            var chunkSize = 2097152;
            var chunks = Math.ceil(file.size / chunkSize);
            var currentChunk = 0;
            var spark = new SparkMD5.ArrayBuffer();
            var fileReader = new FileReader();
            fileReader.onload = function (e) {
                // console.log('read chunk nr', currentChunk + 1, 'of', chunks);
                spark.append(e.target.result);
                currentChunk++;
                if (currentChunk < chunks) {
                    loadNext();
                } else {
                    // console.log('finished loading====',spark.end());
                    if (fn) {
                        fn(spark.end());
                    }
                }
            };
            fileReader.onerror = function () {
                console.warn("oops, something went wrong.");
            };
            function loadNext() {
                var start = currentChunk * chunkSize,
                    end =
                        start + chunkSize >= file.size
                            ? file.size
                            : start + chunkSize;
                fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
            }
            loadNext();
        }
        vm.IMAGE_SERVER = IMAGE_SERVER; //沙雕框架不能直接在html文件中使用全局变量，如果直接使用将访问不到
        vm.parserStatus = false;
        vm.uploadStatus = false;
        vm.retryUploadStatus = false;
        vm.ossUploadFile = null; //上传到oss的安卓apk对象；
        vm.uploadPercent = 0; //上传进度
        vm.ossUploadToken = null; //后台返回的上传oss需要的参数；
        vm.apkIcon = null;
        vm.parserApk = function (file) {
            if (file) {
                if (!file.type) {
                    $message.warning({
                        text: "不能读取文件的文件格式",
                    });
                    return false;
                }
                if (file.type !== "application/vnd.android.package-archive") {
                    $message.warning({
                        text: "禁止选择安卓apk以外的文件格式",
                    });
                    return false;
                }
                vm.parserStatus = true;
                var parser = new window.AppInfoParser(file);
                parser
                    .parse()
                    .then(function (result) {
                        console.log("app info ----> ", result);
                        if (
                            (vm.editData.type === "ANDROID_MAGIC" &&
                                result.package !== "cn.com.sletech.app.mftg") ||
                            (vm.editData.type === "ANDROID" &&
                                result.package !==
                                    "cn.com.sletech.app.itasktour")
                        ) {
                            vm.parserStatus = false;
                            $message.warning({
                                text: "包名错误！",
                            });
                            $scope.$apply();
                        } else {
                            vm.editData.versionCode = result.versionCode;
                            vm.editData.versionName = result.versionName;
                            vm.editData.apkSize = Math.round(file.size / 1024);
                            vm.apkIcon = result.icon;
                            createFileMd5(file, function (md5Str) {
                                vm.parserStatus = false;
                                console.log("md5Str=", md5Str);
                                vm.editData.apkMd5 = md5Str;
                                vm.ossUploadFile = file;
                                console.log(
                                    "vm.ossUploadFile=",
                                    vm.ossUploadFile
                                );
                                vm.uploadFile();
                                $scope.$apply();
                            });
                        }
                    })
                    .catch(function (err) {
                        console.log("err ----> ", err);
                        vm.parserStatus = false;
                        $message.warning({
                            text: "安卓apk包解析失败！",
                        });
                        $scope.$apply();
                    });
            }
        };
        vm.uploadFile = function () {
            var envUrl = null;
            if (
                window.location.origin.indexOf("localhost") > -1 ||
                window.location.hostname.split(".").length === 4
            ) {
                envUrl = "/dev";
            } else {
                if (window.location.hostname.indexOf("dev") === -1) {
                    envUrl = "/prod";
                } else {
                    envUrl = "/dev";
                }
            }
            vm.uploadStatus = true;
            $http({
                method: "POST",
                url: "/api/postFileDirNameAndToken",
                timeout: 30000,
                data: {
                    path: "/apk" + envUrl,
                    fileName: vm.ossUploadFile.name,
                },
            })
                .then(function (res) {
                    // console.log('后台返回的数据',res);
                    vm.ossUploadToken = res.data.data;
                    vm.uploadOss();
                })
                .catch(function (err) {
                    vm.uploadStatus = false;
                    vm.ossUploadFile = null;
                    $message.warning({
                        text: "postFileNameAndToken接口调用失败",
                    });
                });
        };
        // 上传oss
        vm.uploadOss = function () {
            vm.retryUploadStatus = false;
            vm.uploadPercent = 0;
            var client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: "oss-cn-shanghai",
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: vm.ossUploadToken.AccessKeyID,
                accessKeySecret: vm.ossUploadToken.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: vm.ossUploadToken.SecurityToken,
                // 填写Bucket名称。
                bucket: "itasktour-muti",
            });
            // 第一个参数为上传的路径，第二个参数为上传的文件
            client
                .multipartUpload(vm.ossUploadToken.fileName, vm.ossUploadFile, {
                    // 进度条的配置项
                    progress: function (percentage) {
                        // 获取进度条的值
                        vm.uploadPercent = percentage * 100;
                        // console.log('percentage='+percentage+'----------vm.uploadPercent='+vm.uploadPercent);
                        $scope.$apply();
                    },
                    timeout: 120000,
                    // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大 可能导致很长时间进度条都看不到效果
                    // partSize: 102400 // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
                })
                .then(function (res) {
                    console.log("oss返回数据res=", res);
                    // 为了让用户看到上传进度条条的百分百的状态，这个地方延迟一秒改变状态
                    var delayId = setTimeout(function () {
                        vm.uploadPercent = 0;
                        vm.uploadStatus = false;
                        vm.ossUploadFile = null;
                        vm.editData.apkUrl =
                            "https://image.itasktour.com" + res.name;
                        $message.success({
                            text: "apk上传成功！",
                        });
                        clearTimeout(delayId);
                        $scope.$apply();
                    }, 700);
                })
                .catch(function (err) {
                    vm.uploadPercent = 0;
                    // 判断错误类型，如果是致命性错误弹出警告框提示用户，如果是超时错误为用户显示重试按钮
                    if (JSON.stringify(err).indexOf("Timeout") > -1) {
                        vm.retryUploadStatus = true;
                        $scope.$apply();
                    } else {
                        vm.uploadStatus = false;
                        vm.ossUploadFile = null;
                        $scope.$apply();
                        $message.warning({
                            text: "安卓apk上传失败！",
                        });
                    }
                });
        };

        vm.confirm = function () {
            console.log(vm.versionCode);
            if (vm.editData.versionCode < 1) {
                alert("客户端版本不能小于1");
                return;
            }
            var data = ClientVersionService.update(vm.editData);
            if (data.versionCode === vm.editData.versionCode) {
                $message.success({
                    text: "修改成功！",
                });
            } else {
                $message.success({
                    text: "修改失败！",
                });
            }
            $("#myModal1").modal("hide");
            vm.editData = null;
            location.reload();
        };
    }
})();
