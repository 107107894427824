(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TeamDetailController", TeamDetailController);

    TeamDetailController.$inject = [
        "$scope",
        "$rootScope",
        "Principal",
        "$stateParams",
        "previousState",
        "entity",
        "Team",
        "User",
        "Trip",
    ];

    function TeamDetailController(
        $scope,
        $rootScope,
        Principal,
        $stateParams,
        previousState,
        entity,
        Team,
        User,
        Trip
    ) {
        var vm = this;

        vm.team = entity;
        vm.previousState = previousState.name;

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:teamUpdate",
            function (event, result) {
                vm.team = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
