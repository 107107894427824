(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TripTodayController", TripTodayController);

    TripTodayController.$inject = [
        "$scope",
        "$state",
        "TripToday",
        "Principal",
        "TripTodaySearch",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
        "$timeout",
        "DateUtils",
    ];

    function TripTodayController(
        $scope,
        $state,
        TripToday,
        Principal,
        TripTodaySearch,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants,
        $timeout,
        DateUtils
    ) {
        var vm = this;
        vm.account = null;
        vm.isAdmin = false;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.date = "";
        // vm.searchQuery = pagingParams.search;
        if (pagingParams.search != null) {
            vm.currentSearch = new Date(pagingParams.search);
        }
        // vm.data = pagingParams.search;
        loadAll();

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                for (var i = 0; i < vm.account.authorities.length; i++) {
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN") {
                        vm.isAdmin = true;
                    }
                }
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.selectDays = selectDays;
        function selectDays(date) {
            // const data = vm.currentSearch;
            if (date == 1) {
                date = "";
                vm.date = "";
            } else if (date == 2) {
                date = "next";
                vm.date = "next";
            }
            if (typeof date != "undefined") {
                // TripToday.query({
                //     page: pagingParams.page - 1,
                //     size: vm.itemsPerPage,
                //     sort: sort(),
                //     date: date
                // }, onSuccess, onError);
                this.transition();
            }
        }

        //时间控件
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });
        vm.datePickerOpenStatus.create_date = false;
        vm.datePickerOpenStatus.update_date = false;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadAll() {
            // if (pagingParams.search) {
            //     TripTodaySearch.query({
            //         query: pagingParams.search,
            //         page: pagingParams.page - 1,
            //         size: vm.itemsPerPage,
            //         sort: null
            //     }, onSuccess, onError);
            // } else {
            //     TripToday.query({
            //         page: pagingParams.page - 1,
            //         size: vm.itemsPerPage,
            //         sort: sort(),
            //         date: vm.date
            //     }, onSuccess, onError);
            // }
            TripToday.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    date: DateUtils.convertDateTimeFromServer(
                        pagingParams.search
                    ),
                },
                onSuccess,
                onError
            );
        }

        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                result.push("id");
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers("link"));
            vm.totalItems = headers("X-Total-Count");
            vm.queryCount = vm.totalItems;
            vm.trips = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
                // date: vm.currentSearch
            });
        }

        vm.transitionByData = transitionByData;
        function transitionByData(date) {
            // vm.date = date;
            // $state.transitionTo($state.$current, {
            //     page: vm.page,
            //     sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            //     search: vm.currentSearch,
            //     date: date
            // });
            if (pagingParams.search) {
                TripTodaySearch.query(
                    {
                        page: vm.page - 1,
                        date: date,
                        search: vm.currentSearch,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
            } else {
                TripToday.query(
                    {
                        page: vm.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                        date: date,
                    },
                    onSuccess,
                    onError
                );
            }
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = "";
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = "";
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
