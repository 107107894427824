(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("EAccountController", controller);
    controller.$inject = [
        "$state",
        "EAccount",
        "EAccountSearch2",
        "$http",
        "$scope",
        "$stateParams",
    ];
    function controller(
        $state,
        EAccount,
        EAccountSearch2,
        $http,
        $scope,
        $stateParams
    ) {
        var vm = this;
        vm.itemsPerPage = 20;
        vm.page = $stateParams.page;
        vm.predicate = "id";
        vm.reverse = "desc";
        vm.sort = $stateParams.sort;
        vm.searchQuery = $stateParams.search;
        vm.mftgManager = null;
        vm.eAccount = null;
        vm.addOrChange = true; //true添加企业，false创建企业
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account) {
                    if (
                        vm.account.authorities.indexOf("ROLE_MFTG_MANAGER") > -1
                    ) {
                        vm.mftgManager = true;
                    }
                }
            });
        }
        vm.addEnterprise = function () {
            vm.eAccount = {
                name: "",
                companyRegion: "",
                contactName: "",
                contactIdCardImg: null,
                contactIdCardNo: "",
                adminAccount: "",
                adminAccount2: "",
                selectRegionType: 1,
                authRegions: "",
                rabbitActivationState: "1",
            };
            // 清空授权地址
            vm.authAreaList = [];
            // 清空暂存图片
            vm.dataUrl = null;
            document.getElementById("provincevalue1").value = "";
            document.getElementById("cityvalue1").value = "";
            vm.addOrChange = true;
            $("#myModal_add_member").modal("show");
        };
        vm.changeEnterprise = function (eAccount) {
            vm.eAccount = JSON.parse(JSON.stringify(eAccount));
            vm.eAccount.rabbitActivationState =
                vm.eAccount.rabbitActivationState + "";
            vm.eAccount.adminAccount2 = vm.eAccount.adminAccount.replace(
                /_mftg/,
                ""
            );
            // 解析地址  前端传递给后端的是英文符号，但是后端的数据有一些却是中文符号；
            var areaData = vm.eAccount.companyRegion.split(",");
            if (areaData.length < 2) {
                areaData = vm.eAccount.companyRegion.split("，");
            }
            // console.log('areaData=',areaData);
            // 把对应地区的id在省市区的数据对象中找出来
            var id1 = null,
                id2 = null;
            var provinceDom = document.getElementById("provinceid1");
            var cityDom = document.getElementById("cityid1");
            $area.every(function (item1, index1) {
                if (item1.name === areaData[0]) {
                    id1 = index1;
                    return item1.city.every(function (item2, index2) {
                        if (item2.name === areaData[1]) {
                            id2 = index2;
                            return false;
                        } else {
                            return true;
                        }
                    });
                } else {
                    return true;
                }
            });
            // console.log('id1=',id1);
            // console.log('id2=',id2);
            // 后台存放的地址数据不符合规则的情况下不展示地区选择；
            if (id1 !== null && id2 !== null) {
                insertAreaList(provinceDom, $area);
                insertAreaList(cityDom, $area[id1].city);
                var id3 = setTimeout(function () {
                    provinceDom.setAttribute("data-id", id1);
                    cityDom.setAttribute("data-id", id1);
                    cityDom.setAttribute("data-id2", id2);
                    setAreaActive(provinceDom, provinceDom.children[id1]);
                    setAreaActive(cityDom, cityDom.children[id2]);
                    clearTimeout(id3);
                }, 300);
                document.getElementById("provincevalue1").value = areaData[0];
                document.getElementById("cityvalue1").value = areaData[1];
            } else {
                document.getElementById("provincevalue1").value = "";
                document.getElementById("cityvalue1").value = "";
                $message.warning({
                    text: "该企业存放的地区数据不符合规则，请重新设置",
                });
            }
            // 读取授权地址里边的数据
            if (vm.eAccount.authRegions) {
                vm.authAreaList = JSON.parse(vm.eAccount.authRegions);
            }
            vm.addOrChange = false;
            $("#myModal_add_member").modal("show");
        };

        // 打开地区选择器    1表示大弹框的地区选择器，2表示小弹框的地区选择器
        vm.openSelect = function (params) {
            // 打开当前地区选择器的时候关闭另外一个地区选择器
            if (params === 1) {
                var maskbanner2 = document.getElementById("areamaskbanner2");
                if (maskbanner2 && maskbanner2.style.display === "block") {
                    vm.closeAreaList(2);
                }
            } else {
                var maskbanner1 = document.getElementById("areamaskbanner1");
                if (maskbanner1 && maskbanner1.style.display === "block") {
                    vm.closeAreaList(1);
                }
            }
            document.getElementById("areamaskbanner" + params).style.display =
                "block";
            // 判断是省还是市nextElementSibling previousElementSibling
            var listDom = event.target.parentNode.nextElementSibling;
            // debugger;
            if (listDom.dataset.type === "0") {
                if (listDom.style.display === "none") {
                    listDom.style.display = "block";
                    // 关闭市下拉列表
                    document.getElementById("cityid" + params).style.display =
                        "none";
                    if (listDom.children.length < 1) {
                        insertAreaList(listDom, $area);
                    }
                } else {
                    listDom.style.display = "none";
                }
            } else if (listDom.dataset.type === "1") {
                if (listDom.children.length < 1) {
                    // 用户未选择省的时候点击市的选择器，
                    $message.warning({
                        text: "选择地级市前请先选择对应的省",
                    });
                    var provinceDom = document.getElementById(
                        "provinceid" + params
                    );
                    provinceDom.style.display = "block";
                    if (provinceDom.children.length < 1) {
                        insertAreaList(provinceDom, $area);
                    }
                } else {
                    if (listDom.style.display === "none") {
                        listDom.style.display = "block";
                        // 打开市列表的时候关闭省列表
                        document.getElementById(
                            "provinceid" + params
                        ).style.display = "none";
                    } else {
                        listDom.style.display = "none";
                    }
                }
            }
            // 判断是否添加蒙层(经过上边的操作后，如果省列表和市列表都隐藏了，那么遮罩层也需要隐藏)
            if (
                document.getElementById("provinceid" + params).style.display ===
                    "none" &&
                document.getElementById("cityid" + params).style.display ===
                    "none"
            ) {
                document.getElementById(
                    "areamaskbanner" + params
                ).style.display = "none";
            }
        };

        // 用户选择地区
        vm.areaSelect = function (params) {
            document.getElementById("areamaskbanner" + params).style.display =
                "block";
            // 判断选择的是省还是市
            var listDom = event.target.parentNode;
            if (listDom.dataset.type === "0") {
                listDom.setAttribute("data-id", event.target.dataset.id);
                document.getElementById("provincevalue" + params).value =
                    $area[event.target.dataset.id].name;
                setAreaActive(listDom.children, event.target);
                // 点击省列表更新市列表的值；
                var cityDom = document.getElementById("cityid" + params);
                insertAreaList(cityDom, $area[event.target.dataset.id].city);
                // 移除市列表上中的选中的市的id
                cityDom.removeAttribute("data-id");
                cityDom.removeAttribute("data-id2");
                document.getElementById("cityvalue" + params).value = "";
                listDom.style.display = "none";
            } else if (listDom.dataset.type === "1") {
                listDom.setAttribute("data-id", event.target.dataset.id);
                listDom.setAttribute(
                    "data-id2",
                    document.getElementById("provinceid" + params).dataset.id
                );
                var cityvalue =
                    $area[
                        document.getElementById("provinceid" + params).dataset
                            .id
                    ].city[event.target.dataset.id];
                if (angular.isString(cityvalue) != "string") {
                    cityvalue = cityvalue.name;
                }
                document.getElementById("cityvalue" + params).value = cityvalue;
                setAreaActive(listDom.children, event.target);
                listDom.style.display = "none";
                if (params === 1) {
                    vm.eAccount.companyRegion =
                        document.getElementById("provincevalue" + params)
                            .value +
                        "," +
                        cityvalue;
                } else {
                    vm.authArea.authRegionsItem =
                        document.getElementById("provincevalue" + params)
                            .value +
                        "," +
                        cityvalue;
                }
            }
            // 判断是否添加蒙层
            if (
                document.getElementById("provinceid" + params).style.display ===
                    "none" &&
                document.getElementById("cityid" + params).style.display ===
                    "none"
            ) {
                document.getElementById(
                    "areamaskbanner" + params
                ).style.display = "none";
            }
        };

        function setAreaActive(domlist, targetDom) {
            Array.prototype.slice.call(domlist).forEach(function (item, index) {
                item.classList.remove("item-active");
            });
            targetDom.classList.add("item-active");
        }

        // 关闭所有地区选择列表
        vm.closeAreaList = function (params) {
            document.getElementById("provinceid" + params).style.display =
                "none";
            document.getElementById("cityid" + params).style.display = "none";
            document.getElementById("areamaskbanner" + params).style.display =
                "none";
        };

        function insertAreaList(dom, data) {
            var htmlStr = "";
            data.forEach(function (item, index) {
                // 把id设置上去，通过id我们可以从地区列表中找到具体市那个区域
                htmlStr +=
                    '<div class="list-item" data-id=' +
                    index +
                    ">" +
                    item.name +
                    "</div>";
            });
            dom.innerHTML = htmlStr;
        }

        vm.checkPhone = function () {
            vm.eAccount.adminAccount2 = vm.eAccount.adminAccount2.replace(
                /\D/g,
                ""
            );
        };

        vm.IMAGE_SERVER = IMAGE_SERVER; //沙雕框架不能直接在html文件中使用全局变量，如果直接使用将访问不到
        vm.uploadStatus = false;
        vm.dataUrl = null; //暂存用户选择的数据对象；   用户上传成功后需要手动清除
        vm.uploadPercent = 0; //上传进度
        vm.ossUploadToken = null; //后台返回的上传oss需要的参数；
        vm.ossUploadFile = null; //上传到oss的文件对象；超时重试的时候用得到
        vm.retryUpload = false;
        vm.uploadFile = function (file) {
            if (file) {
                vm.dataUrl = URL.createObjectURL(file);
                vm.uploadStatus = true;
                // 上传oss
                $http({
                    method: "POST",
                    url: "/api/postFileNameAndToken",
                    timeout: 30000,
                    data: {
                        type: "EACCOUNT",
                        fileName: file.name,
                    },
                })
                    .then(function (res) {
                        console.log("后台返回的数据", res);
                        vm.ossUploadToken = res.data.data;
                        vm.ossUploadFile = file;
                        uploadOss();
                    })
                    .catch(function (err) {
                        vm.uploadStatus = false;
                        vm.dataUrl = null;
                        $message.warning({
                            text: "postFileNameAndToken接口调用失败",
                        });
                    });
            }
        };
        // 上传oss
        function uploadOss() {
            vm.retryUpload = false;
            console.log("传递给oss的osstoken", vm.ossUploadToken);
            var client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: "oss-cn-shanghai",
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: vm.ossUploadToken.AccessKeyID,
                accessKeySecret: vm.ossUploadToken.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: vm.ossUploadToken.SecurityToken,
                // 填写Bucket名称。
                bucket: "itasktour-muti",
            });
            vm.uploadPercent = 0;
            // 第一个参数为上传的路径，第二个参数为上传的文件
            client
                .multipartUpload(vm.ossUploadToken.fileName, vm.ossUploadFile, {
                    // 进度条的配置项
                    progress: function (percentage) {
                        // 获取进度条的值
                        vm.uploadPercent = percentage * 100;
                        // console.log('percentage='+percentage+'----------vm.uploadPercent='+vm.uploadPercent);
                        $scope.$apply();
                    },
                    timeout: 60000,
                    // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大 可能导致很长时间进度条都看不到效果
                    // partSize: 102400 // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
                })
                .then(function (res) {
                    console.log("oss返回数据res=", res);
                    // 为了让用户看到上传进度条条的百分百的状态，这个地方延迟一秒改变状态
                    var delayId = setTimeout(function () {
                        vm.uploadPercent = 0;
                        vm.uploadStatus = false;
                        vm.eAccount.contactIdCardImg = "/" + res.name;
                        clearTimeout(delayId);
                        $scope.$apply();
                    }, 700);
                })
                .catch(function (err) {
                    vm.uploadPercent = 0;
                    // 判断错误类型，如果是致命性错误弹出警告框提示用户，如果是超时错误为用户显示重试按钮
                    if (JSON.stringify(err).indexOf("Timeout") > -1) {
                        vm.retryUpload = true;
                        $scope.$apply();
                    } else {
                        vm.uploadStatus = false;
                        // 上传失败的情况下，清除本都存储的用户选择的文件，使用服务器上的资源vm.trip.coverImg
                        vm.dataUrl = null;
                        $scope.$apply();
                        $message.warning({
                            text: "文件上传异常，请重新上传或更换素材！！",
                        });
                    }
                });
        }
        // 重新上传视频或者图片(仅在文件上传超时的时候触发)
        vm.retryUploadFile = function () {
            uploadOss();
        };
        vm.addOrChangeArea = true; //true表示添加授权地点 false表示编辑授权地点
        vm.areaId = null; //用户当前编辑的授权地址的id
        vm.authAreaList = []; //授权地址的转化数组
        vm.authArea = null;
        vm.addOrChangeAuthArea = function (params) {
            if (params === -1) {
                vm.addOrChangeArea = true;
                vm.authArea = {
                    name: "",
                    authRegionsItem: "",
                };
                document.getElementById("provincevalue2").value = "";
                document.getElementById("cityvalue2").value = "";
            } else {
                vm.addOrChangeArea = false;
                vm.areaId = params;
                vm.authArea = JSON.parse(
                    JSON.stringify(vm.authAreaList[vm.areaId])
                );
                // 解析地址  前端传递给后端的是英文符号，但是后端的数据有一些却是中文符号；
                var areaData = vm.authArea.authRegionsItem.split(",");
                // 把对应地区的id在省市区的数据对象中找出来
                var id1 = null,
                    id2 = null;
                var provinceDom = document.getElementById("provinceid2");
                var cityDom = document.getElementById("cityid2");
                $area.every(function (item1, index1) {
                    if (item1.name === areaData[0]) {
                        id1 = index1;
                        return item1.city.every(function (item2, index2) {
                            if (item2.name === areaData[1]) {
                                id2 = index2;
                                return false;
                            } else {
                                return true;
                            }
                        });
                    } else {
                        return true;
                    }
                });
                console.log("id1=", id1);
                console.log("id2=", id2);
                // 后台存放的地址数据不符合规则的情况下不展示地区选择；
                if (id1 !== null && id2 !== null) {
                    insertAreaList(provinceDom, $area);
                    insertAreaList(cityDom, $area[id1].city);
                    var id3 = setTimeout(function () {
                        provinceDom.setAttribute("data-id", id1);
                        cityDom.setAttribute("data-id", id1);
                        cityDom.setAttribute("data-id2", id2);
                        setAreaActive(provinceDom, provinceDom.children[id1]);
                        setAreaActive(cityDom, cityDom.children[id2]);
                        clearTimeout(id3);
                    }, 300);
                    document.getElementById("provincevalue2").value =
                        areaData[0];
                    document.getElementById("cityvalue2").value = areaData[1];
                } else {
                    document.getElementById("provincevalue2").value = "";
                    document.getElementById("cityvalue2").value = "";
                    $message.warning({
                        text: "该授权地址的地区数据不符合规则，请重新设置",
                    });
                }
            }
            document.getElementsByClassName("auth-container")[0].style.display =
                "block";
        };
        vm.cancelSelect = function () {
            document.getElementsByClassName("auth-container")[0].style.display =
                "none";
        };
        vm.deleteAuthArea = function (index) {
            vm.authAreaList.splice(index, 1);
        };
        vm.saveAuthArea = function () {
            if (vm.authArea.name.trim() === "") {
                $message.warning({
                    text: "请输入授权地点名称！",
                });
                return false;
            }
            if (vm.authArea.authRegionsItem.trim() === "") {
                $message.warning({
                    text: "请选择授权地区！",
                });
                return false;
            }
            if (vm.addOrChangeArea) {
                if (vm.authAreaList.length > 0) {
                    var isChange = vm.authAreaList.every(function (item) {
                        if (
                            item.authRegionsItem === vm.authArea.authRegionsItem
                        ) {
                            if (item.name === vm.authArea.name) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    });
                    if (!isChange) {
                        $message.warning({
                            text: "该授权地址已在授权地址列表中存在，请重新选择授权地址！",
                        });
                        return false;
                    }
                }
                vm.authAreaList.push(vm.authArea);
            } else {
                if (
                    vm.authAreaList[vm.areaId].authRegionsItem !==
                        vm.authArea.authRegionsItem ||
                    vm.authAreaList[vm.areaId].name !== vm.authArea.name
                ) {
                    var isChange2 = vm.authAreaList.every(function (item) {
                        if (
                            item.authRegionsItem === vm.authArea.authRegionsItem
                        ) {
                            if (item.name === vm.authArea.name) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    });
                    if (isChange2) {
                        vm.authAreaList[vm.areaId] = vm.authArea;
                    } else {
                        $message.warning({
                            text: "该授权地址已在授权地址列表中存在，请重新选择授权地址！",
                        });
                    }
                }
            }
            vm.authArea = null;
            vm.cancelSelect();
        };

        // 用户修改或者添加企业
        vm.save = function () {
            if (vm.eAccount.name.trim() === "") {
                $message.warning({
                    text: "请输入企业/组织全称！",
                });
                return false;
            }
            if (vm.eAccount.companyRegion.trim() === "") {
                $message.warning({
                    text: "请选择企业所在地区！",
                });
                return false;
            }
            if (vm.eAccount.contactName.trim() === "") {
                $message.warning({
                    text: "请输入法人(或运营者)姓名！",
                });
                return false;
            }
            if (vm.eAccount.contactIdCardNo.trim() === "") {
                $message.warning({
                    text: "请输入企业法人(运营者)身份证号或港澳台通行证、护照号！",
                });
                return false;
            }
            if (!vm.eAccount.contactIdCardImg) {
                $message.warning({
                    text: "请上传法人(运营者)身份证照片！",
                });
                return false;
            }
            if (!vm.eAccount.adminAccount2) {
                $message.warning({
                    text: "请输入企业管理员手机号！",
                });
                return false;
            } else {
                vm.eAccount.adminAccount = vm.eAccount.adminAccount2 + "_mftg";
            }
            if (vm.eAccount.selectRegionType == 1) {
                if (vm.authAreaList && vm.authAreaList.length > 0) {
                    // 用户手动添加了地址，转化为json字符串存储到后端
                    vm.eAccount.authRegions = JSON.stringify(vm.authAreaList);
                } else {
                    vm.eAccount.authRegions = null;
                }
            } else {
                vm.eAccount.authRegions = "";
            }
            $http({
                method: "POST",
                url: vm.addOrChange
                    ? "/api/new-e-accounts"
                    : "/api/edit-e-accounts",
                timeout: 30000,
                data: vm.eAccount,
            })
                .then(function (res) {
                    console.log("res=", res);
                    $("#myModal_add_member").modal("hide");
                    if (res.data.errorCode === 0) {
                        // 刷新列表
                        vm.transition();
                        $message.success({
                            text:
                                (vm.addOrChange ? "添加" : "修改") +
                                "企业成功！",
                        });
                    } else if (
                        res.data.errorCode === -100 ||
                        res.data.errorCode === -200
                    ) {
                        $message.error({
                            text: res.data.message,
                        });
                    }
                    vm.eAccount = null;
                })
                .catch(function (err) {
                    // $('#myModal_add_member').modal('hide');
                    $message.error({
                        text: (vm.addOrChange ? "添加" : "修改") + "企业失败！",
                    });
                });
        };

        vm.transition = function (params) {
            if (params === 1 || params === 2) {
                vm.page = 1;
            }
            if (params === 2) {
                vm.sort = vm.predicate + "," + (vm.reverse ? "desc" : "asc");
            }
            if (vm.searchQuery) {
                EAccountSearch2.query(
                    {
                        query: vm.searchQuery,
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                        sort: vm.sort,
                    },
                    onSuccess,
                    onError
                );
            } else {
                EAccount.query(
                    {
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                        sort: vm.sort,
                    },
                    onSuccess,
                    onError
                );
            }
            function onSuccess(data, headers) {
                vm.totalItems = headers("X-Total-Count");
                vm.eAccounts = data;
            }
            function onError(error) {
                $message.error({
                    text: error.data.message,
                });
            }
        };
        vm.transition();
        vm.keyDown = function (event) {
            if (event.keyCode == 13) {
                vm.transition(1);
            }
        };
    }
})();
