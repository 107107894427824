(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("WxGameTestStealthController", controller);
    controller.$inject = ["Principal", "$http"];
    function controller(Principal, $http) {
        var vm = this;
        vm.userId = null;
        vm.role = null;

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.save = save;
        function save(type) {
            var url =
                "api/stealth/save/group-data/" +
                vm.tripId +
                "/" +
                vm.teamId +
                "?type=" +
                type;
            if (vm.userId != null) {
                url = url + "&userId=" + vm.userId;
            }
            if (vm.role != null) {
                url = url + "&role=" + vm.role;
            }
            $http({
                method: "POST",
                url: url,
                data: vm.param,
            })
                .success(function () {
                    alert("操作成功");
                    getGroupData();
                })
                .error(function (err) {});
        }

        vm.getGroupData = getGroupData;
        function getGroupData() {
            $http({
                method: "GET",
                url:
                    "api/stealth/group-data/" +
                    vm.tripId +
                    "/" +
                    vm.teamId +
                    "/" +
                    vm.param.group,
            })
                .success(function (data) {
                    vm.param = data;
                })
                .error(function (err) {});
        }

        vm.clear = clear;
        function clear() {
            $http({
                method: "POST",
                url: "api/stealth/clear/" + vm.tripId + "/" + vm.teamId,
            })
                .success(function () {
                    alert("操作成功");
                    getGroupData();
                })
                .error(function (err) {});
        }

        vm.addMember = addMember;
        function addMember() {
            $http({
                method: "POST",
                url: "api/stealth/team/join/" + vm.tripId + "/" + vm.teamId,
            })
                .success(function () {
                    alert("操作成功");
                })
                .error(function (err) {});
        }
    }
})();
