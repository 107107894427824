(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('InitEAccountDialogController', controller);
    controller.$inject = ['$rootScope','$http','$cookies','$timeout', '$scope', '$state', 'entity', 'EAccount','Upload'];
    function controller ($rootScope,$http,$cookies,$timeout, $scope, $state, entity, EAccount,Upload) {
        var vm = this;
        vm.eAccount = entity;
        vm.clear = clear;
        vm.clearTip = clearTip;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.IMAGE_SERVER = IMAGE_SERVER;

        // 打开地区选择器
        vm.openSelect=function(){
            document.querySelector(".area-select-pc .areamaskbanner").style.display="block";
            // 判断是省还是市nextElementSibling previousElementSibling
            var listDom=event.target.parentNode.nextElementSibling;
            // debugger;
            if(listDom.dataset.type==="0"){
                if(listDom.style.display==="none"){
                    listDom.style.display="block";
                    // 关闭市下拉列表
                    document.getElementById('cityid').style.display="none";
                    if(listDom.children.length<1){
                        insertAreaList(listDom,$area);
                    }
                }else{
                    listDom.style.display="none";
                }
            }else if(listDom.dataset.type==="1"){
                if(listDom.children.length<1){
                    // 用户未选择省的时候点击市的选择器，
                    $message.warning({
                        text:"选择地级市前请先选择对应的省",
                    });
                    var provinceDom=document.getElementById('provinceid');
                    provinceDom.style.display="block";
                    if(provinceDom.children.length<1){
                        insertAreaList(provinceDom,$area);
                    }
                }else{
                    if(listDom.style.display==="none"){
                        listDom.style.display="block";
                        // 打开市列表的时候关闭省列表
                        document.getElementById('provinceid').style.display="none";
                    }else{
                        listDom.style.display="none";
                    }
                }
            }
            // 判断是否添加蒙层(经过上边的操作后，如果省列表和市列表都隐藏了，那么遮罩层也需要隐藏)
            if(document.getElementById('provinceid').style.display==="none"&&document.getElementById('cityid').style.display==="none"){
                document.querySelector(".area-select-pc .areamaskbanner").style.display="none";
            }
        };
        
        // 用户选择地区
        vm.areaSelect=function(){
            document.querySelector(".area-select-pc .areamaskbanner").style.display="block";
            // 判断选择的是省还是市
            var listDom=event.target.parentNode;
            if(listDom.dataset.type==="0"){
                listDom.setAttribute('data-id',event.target.dataset.id);
                document.getElementById('provincevalue').value=$area[event.target.dataset.id].name;
                setAreaActive(listDom.children,event.target);
                // 点击省列表更新市列表的值；
                var cityDom=document.getElementById('cityid');
                insertAreaList(cityDom,$area[event.target.dataset.id].city);
                // 移除市列表上中的选中的市的id
                cityDom.removeAttribute('data-id');
                cityDom.removeAttribute('data-id2');
                document.getElementById('cityvalue').value='';
                listDom.style.display="none";
            }else if(listDom.dataset.type==="1"){
                listDom.setAttribute('data-id',event.target.dataset.id);
                listDom.setAttribute('data-id2',document.getElementById('provinceid').dataset.id);
                var cityvalue=$area[document.getElementById('provinceid').dataset.id].city[event.target.dataset.id];
                if(angular.isString(cityvalue)!="string"){
                    cityvalue=cityvalue.name;
                }
                document.getElementById('cityvalue').value=cityvalue;
                setAreaActive(listDom.children,event.target);
                listDom.style.display="none";
                vm.eAccount.companyRegion=document.getElementById('provincevalue').value+","+cityvalue;
            }
            // 判断是否添加蒙层
            if(document.getElementById('provinceid').style.display==="none"&&document.getElementById('cityid').style.display==="none"){
                document.querySelector(".area-select-pc .areamaskbanner").style.display="none";
            }
        };

        function setAreaActive(domlist,targetDom){
            Array.prototype.slice.call(domlist).forEach(function(item,index){
                item.classList.remove('item-active');
            });
            targetDom.classList.add('item-active');
        }

        // 关闭所有地区选择列表
        vm.closeAreaList=function(){
            document.getElementById('provinceid').style.display="none";
            document.getElementById('cityid').style.display="none";
            document.querySelector(".area-select-pc .areamaskbanner").style.display="none";
        };

        function insertAreaList(dom,data){
            var htmlStr="";
            data.forEach(function(item,index){
                // 把id设置上去，通过id我们可以从地区列表中找到具体市那个区域
                var areaText;
                // 地区选择器部分地名可能没有子级节点数据
                angular.isString(item) === "string"?areaText=item:areaText=item.name;
                htmlStr+="<div class=\"list-item\" data-id="+index+">"+areaText+"</div>";
            });
            dom.innerHTML=htmlStr;
        }
        

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.checkNum1 = checkNum1;
        function checkNum1(input) {
            var reg=/^[0-9]*$/;
            if (!reg.test(input) && input!=null){
                alert("统一社会信用代码必须为数字");
                vm.eAccount.registerNo = null;
            }
        }
        vm.checkNum2 = checkNum2;
        function checkNum2(input) {
            var reg=/^[0-9]*$/;
            if (!reg.test(input) && input!=null){
                alert("手机号码必须为数字");
                vm.eAccount.contact = null;
            }
        }

        load();
        function load() {
            $("#nav, #foot").css("display", "none");
            $("#con").removeClass().addClass("con");
            $(".container").addClass("login-container-content");
            $(".eAccountIdent").css("min-height", $(window).height());
            window.onresize = function (event) {
                $(".eAccountIdent").css("min-height", $(window).height());
            };
            if ($(window).height()>=732) $(".footer .foot").removeClass("mobile");
        }
        function addLayout() {
            document.getElementById("nav").style.display="block";
            document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }

       
        function clear () {
            window.history.go(-1);
            addLayout();
        }

        function save () {
            if(vm.eAccount.companyRegion.indexOf(',')<0){
                alert("请选择完成的地区");
                return false;
            }
            // if (vm.eAccount.licence == null) {
            //     alert("请上传最新的营业执照");
            // } else
            if (vm.eAccount.contactIdCardImg == null) {
                alert("请上传法人/运营者/经办人身份证照片");
            } else {
                vm.isSaving = true;
                $http({
                    url:'/api/check-invate-code',
                    method:'POST',
                    params: {
                        code: vm.eAccount.invitationCode
                    }
                }).success(function (res) {
                    if (res.errorCode == -1) {
                        alert("邀请码有误");
                        vm.isSaving = false;
                        vm.code = null;
                        return;
                    } else if (res.errorCode == -2) {
                        alert("邀请码已失效");
                        vm.isSaving = false;
                        vm.code = null;
                        return;
                    } else {
                        EAccount.save(vm.eAccount, onSaveSuccess, onSaveError);
                    }
                });
            }


            // if (vm.eAccount.contact!=null){
            //     var phone = vm.eAccount.contact;
            //     if(!(/^1[34578]\d{9}$/.test(phone))){
            //         alert("手机号码有误，请重填");
            //         vm.eAccount.contact = null;
            //         vm.isSaving = false;
            //         return;
            //     }
            // }
            // if (vm.eAccount.email != null){
            //     var email = vm.eAccount.email;
            //     var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/; //邮箱验证正则表达式
            //     if (!reg.test(email)){
            //         alert("邮箱格式不正确");
            //         vm.isSaving = false;
            //         return;
            //     }
            // } else {
            //     alert("邮箱不能为空");
            //     vm.isSaving = false;
            //     return;
            // }


        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:eAccountUpdate', result);
            // $uibModalInstance.close(result);
            vm.isSaving = false;
            addLayout();
           // $state.go("trip-model",{},{reload:true});
            window.setTimeout(function () {
                $state.go("e-account-my.edit",{},{reload:true});
            },300);
        }

        function onSaveError (err) {
            vm.isSaving = false;
        }

        vm.getActivationCode = function(){
            $('#myModal').modal('show');
        };

        function clearTip() {
            $('#myModal').modal('hide');
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect (uploadFile, type){
            if (uploadFile!=null){
                uploadHelp(uploadFile);
            }
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"EACCOUNT"},
                    file: uploadFile,
                    method: 'POST'
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                    if (type == 1) {
                        vm.eAccount.licence = data.file;
                    } else if (type == 2) {
                        vm.eAccount.contactIdCardImg = data.file;
                    } else {
                        vm.eAccount.web = data.file;
                    }

                    load();
                }).error(function (data, status, headers, config) {
                    console.log('error status: ' + status);
                });
            }
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.$on("$destroy", function() {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
            $("body").removeClass('modal-open');
        });
    }
})();
