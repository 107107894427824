(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('ShowDesignController', controller);
    controller.$inject = ['$state', 'getUpDesignList',  '$http',  'AlertService', '$stateParams'];
    function controller($state, getUpDesignList,  $http,  AlertService,$stateParams) {
        var vm = this;
        document.title='设计师展示';
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.page = $stateParams.page;
        vm.sort = $stateParams.sort;
        vm.itemsPerPage=12;
        vm.totalItems=0;
        vm.designList=[];
        vm.userDesign=null;
        vm.phoneOrWechat='';
        vm.transition=function(params) {
            getUpDesignList.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.sort,
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.designList = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        vm.transition();
        
        vm.advertise = null;
        vm.getAdByType=function() {
            // 魔法特工设计师广告
            $http({
                method: 'GET',
                url: '/api/ad/getByType/' + 3
            }).then(function (res) {
                console.log(res);
                if(res.data!=null){
                    vm.advertise=res.data[0];
                }
            },function (err) {
                console.log(err);
            });
        };
        vm.getAdByType();
        vm.showPhoneOrWechat=function (params,designItem){
            vm.phoneOrWechat=params;
            vm.userDesign=designItem;
            $('#show_phone_wechat').modal('show');
        };
        vm.switchPhoneOrWechat=function(params){
            vm.phoneOrWechat=params;
        };
        vm.closePhoneWechat=function (){
            $('#show_phone_wechat').modal('hide');
        };
    }
})();
