(function () {
    "use strict";
    angular.module("taskServiceApp").config(stateConfig);
    stateConfig.$inject = ["$stateProvider"];
    function stateConfig($stateProvider) {
        $stateProvider.state("client-version", {
            parent: "admin",
            url: "/client-versions",
            data: {
                authorities: ["ROLE_ADMIN", "ROLE_SUPER"],
                pageTitle: "client-version.title",
            },
            views: {
                "content@": {
                    templateUrl: "app/admin/client-version/client-version.html",
                    controller: "ClientVersionController",
                    controllerAs: "vm",
                },
            },
            resolve: null,
        });
    }
})();
