(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TripModelDetailController", controller);

    controller.$inject = [
        "$scope",
        "$rootScope",
        "$http",
        "$stateParams",
        "entity",
        "EAccountSearchByIDAndName",
        "$state",
    ];

    function controller(
        $scope,
        $rootScope,
        $http,
        $stateParams,
        entity,
        EAccountSearchByIDAndName,
        $state
    ) {
        var vm = this;
        if (entity.id == null || !entity.canShow) {
            //直接url访问复制等弹窗这个页面也会刷新，为避免没有权限后退两次，这样做
            if (
                window.location.href.endsWith("trip-model/" + $stateParams.id)
            ) {
                alert("无访问权限");
                history.go(-1);
            }
            return;
        }
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.tripModel = entity;
        vm.showTripsButton = true;
        vm.selectedEAccounts = [];
        vm.sharedToAll = false;
        vm.disabledPlatform1 = false;

        vm.getSelectedEAccount = getSelectedEAccount;
        function getSelectedEAccount() {
            $http({
                method: "GET",
                url: "/api/trip-models/getShareEaccounts/" + vm.tripModel.id,
            }).then(
                function (res) {
                    if (res.data.errorCode == 1) {
                        vm.sharedToAll = true;
                    } else {
                        vm.sharedToAll = false;
                        if (res.data != "") {
                            vm.selectedEAccounts = res.data;
                        }
                    }
                    // console.log(res);
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        vm.tripNew = function (id, title, platform, eTime) {
            $state.go(
                "trip.new",
                {
                    id: id,
                    title: encodeURI(title),
                    platform: platform,
                    eTime: eTime,
                },
                { reload: false }
            );
        };
        vm.platform1Tip = function () {
            $message.warning({
                text: "基础版已停止支持，可在「更多」中转换为专业版",
            });
        };
        vm.shareTip = function () {
            $message.warning({
                text: "该线路处于共享状态，请复制后生成活动",
            });
        };
        vm.acfe = function () {
            var acfeUrl = "";
            var onlyid = btoa(
                vm.tripModel.id +
                    "," +
                    vm.account.appSource +
                    "," +
                    vm.account.login
            );
            if (
                window.location.origin.indexOf("localhost") > -1 ||
                window.location.hostname.split(".").length === 4
            ) {
                acfeUrl =
                    window.location.protocol +
                    "//" +
                    window.location.hostname +
                    ":3000/editor/";
            } else {
                acfeUrl = vm.account.acfeUrl;
            }
            window.open(
                acfeUrl +
                    onlyid +
                    "?at=" +
                    vm.account.password +
                    "_coach&origin=" +
                    btoa(window.location.origin),
                "acfe" + vm.tripModel.id
            );
        };
        vm.mftgManager = false;
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                    if (
                        vm.account.authorities.indexOf("ROLE_MFTG_MANAGER") > -1
                    ) {
                        vm.mftgManager = true;
                    }
                    if (
                        vm.tripModel.platform == 1 &&
                        !vm.account.inWhitelistB &&
                        vm.auth != "Super"
                    ) {
                        vm.disabledPlatform1 = true;
                    }
                }
            });
        }

        vm.showTrips = function (id) {
            if (id != null && vm.tripModel.trips == null) {
                $http({
                    method: "GET",
                    url: "/api/trip-models/getTripByTripModelId/" + id,
                }).then(
                    function (res) {
                        // console.log(res);
                        if (res != null && vm.tripModel.trips == null) {
                            vm.tripModel.trips = res.data;
                            vm.showTripsButton = false;
                        }
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }
        };
        vm.dismissFunc = function () {
            $("#share_to_eaccount").modal("hide");
            vm.eaccounts = [];
            vm.selectedEAccounts = [];
        };
        vm.filterEAccount = function (search) {
            if (search) {
                EAccountSearchByIDAndName.query(
                    {
                        query: search,
                        page: 0,
                        size: vm.itemsPerPage,
                        sort: ["id,desc"],
                    },
                    onSuccess
                );
            }
        };
        function onSuccess(data, headers) {
            console.log(data);
            //vm.eaccounts=data
            if (vm.selectedEAccounts) {
                var filterEaccount = [];
                for (var i = 0; i < data.length; i++) {
                    var flag = true;
                    for (var j = 0; j < vm.selectedEAccounts.length; j++) {
                        if (data[i].id == vm.selectedEAccounts[j].id) {
                            flag = false;
                            break;
                        }
                    }
                    if (flag) {
                        // console.log(data[i])
                        filterEaccount.push(data[i]);
                    }
                }
                vm.eaccounts = filterEaccount;
            } else {
                vm.eaccounts = data;
            }
        }

        var ids = [];
        vm.toConfirm = function toConfirm() {
            if (vm.sharedToAll) {
                $http({
                    method: "POST",
                    url:
                        "/api/trip-models/shareToAllEAccounts/" +
                        vm.tripModel.id,
                }).then(
                    function (res) {
                        if (res.data.errorCode === 0) {
                            vm.tripModel.share_to_eaccount = res.data.message;
                        }
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                    },
                    function (err) {
                        //console.log(err);
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                    }
                );
            } else {
                ids = [];
                // console.log(vm.selectedEAccounts);
                for (var i = 0; i < vm.selectedEAccounts.length; i++) {
                    ids[i] = vm.selectedEAccounts[i].id;
                }
                if (ids.length > 80) {
                    alert(
                        "共享企业超过最大限制，请复制新线路，再进行共享操作。"
                    );
                    return;
                }
                // console.log(ids);
                $http({
                    method: "POST",
                    url:
                        "/api/trip-models/setShareEaccounts/" + vm.tripModel.id,
                    data: ids,
                }).then(
                    function (res) {
                        if (res.data.errorCode === 0) {
                            vm.tripModel.share_to_eaccount = res.data.message;
                        }
                        // console.log(res);
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                    },
                    function (err) {
                        // console.log(err);
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                    }
                );
            }
        };

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:tripModelUpdate",
            function (event, result) {
                vm.tripModel = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
