(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("BaseTaskDialogController", BaseTaskDialogController);

    BaseTaskDialogController.$inject = [
        "MyEquip",
        "EquipSearch",
        "AvatarSearch",
        "$timeout",
        "$scope",
        "$sce",
        "$uibModalInstance",
        "entity",
        "BaseTask",
        "BaseTaskSearch",
        "LocationTaskSearch",
        "Upload",
        "Ahdin",
        "$http",
        "$q",
        "GdMapModalService",
        "ArModelNew",
        "DeleteEquipFromTask",
        "Principal",
    ];

    function BaseTaskDialogController(
        MyEquip,
        EquipSearch,
        AvatarSearch,
        $timeout,
        $scope,
        $sce,
        $uibModalInstance,
        entity,
        BaseTask,
        BaseTaskSearch,
        LocationTaskSearch,
        Upload,
        Ahdin,
        $http,
        $q,
        GdMapModalService,
        ArModelNew,
        DeleteEquipFromTask,
        Principal
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.AR_SERVER = AR_SERVER;
        vm.baseTask = entity;
        // console.log(vm.baseTask);
        vm.precondition_current = 0;
        vm.precondition = new Array(9);
        vm.precondition_logic = new Array(9);
        vm.itemsPerPage = 250;
        vm.location = {};
        // vm.asideAvatar = asideAvatar;
        // vm.asideAvatarAfter = asideAvatarAfter;

        vm.filterLocationTask = this.filterLocationTask;
        vm.filterAsideAvatar = this.filterAsideAvatar;
        // vm.filterArModel = this.filterArModel;
        vm.filterTask = this.filterTask;
        vm.filterEquips = this.filterEquips;
        vm.filterNewArModel = this.filterNewArModel;
        vm.show = show;
        vm.deleteEquip = deleteEquip;
        var imgLimit = 500; //上传图片限制，单位kb
        var otherLimit = 5000; //上传视频限制，单位kb
        checkCoverType(vm.baseTask.img);
        vm.other = new Array(2);
        vm.showCropped = true;
        vm.cropper = "default";
        vm.answer = 1;
        vm.platform = 0;

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else {
                        vm.arType = "old";
                    }
                }
            });
        }

        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            } else {
                return false;
            }
        }
        vm.downLoadQr = downLoadQr;
        function downLoadQr(value) {
            html2canvas($(".qrBaseTask" + value), {
                onrendered: function (canvas) {
                    var type = "image/png";
                    var imgData = canvas.toDataURL(type);
                    imgData = imgData.replace(type, "image/octet-stream");
                    if (isIE()) {
                        // console.log(isIE());
                        $("#myIEModal").modal("show");
                    } else {
                        $(".downTes" + value).attr({
                            href: imgData,
                            download: "任务:" + vm.baseTask.id + "二维码.png",
                        });
                    }
                },
            });
            // var _fixType = function(type) {
            //     type = type.toLowerCase().replace(/jpg/i, 'jpeg');
            //     var r = type.match(/png|jpeg|bmp|gif/)[0];
            //     return 'image/' + r;
            // };
        }
        vm.iknow = iknow;
        function iknow() {
            $("#myIEModal").modal("hide");
        }
        vm.addAnswer = addAnswer;
        function addAnswer() {
            if (vm.answer >= 1 && vm.answer <= 4) {
                vm.answer = vm.answer + 1;
            }
            if (vm.answer == 3) {
                $(".delete2").css("display", "none");
            } else if (vm.answer == 4) {
                $(".delete3").css("display", "none");
                $(".addAnswer").css("display", "none");
            }
        }
        vm.deleteAnswer = deleteAnswer;
        function deleteAnswer(i) {
            // console.log(i);
            $(".delete" + i).css("display", "block");
            vm.answer = i;
            vm.baseTask.answer[i].answer = "";
            vm.baseTask.answer[i].score = null;
            switch (i) {
                case 0:
                    vm.baseTask.answer1 = { answer: "", score: null };
                    break;
                case 1:
                    vm.baseTask.answer2 = { answer: "", score: null };
                    break;
                case 2:
                    vm.baseTask.answer3 = { answer: "", score: null };
                    break;
                case 3:
                    vm.baseTask.answer4 = { answer: "", score: null };
                    break;
            }
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file != null) {
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)) {
                    vm.type = "img";
                } else {
                    vm.type = "other";
                }
            }
        }

        if (vm.baseTask.latticeType == null) {
            vm.baseTask.latticeType = "2";
        }

        function deleteEquip() {
            // console.log(vm.baseTask.equip);
            if (vm.baseTask.equip != null) {
                DeleteEquipFromTask.delete(
                    { taskId: vm.baseTask.id },
                    function (res) {
                        // console.log(res);
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }
            vm.baseTask.equip = null;
            console.log("delete equip");
        }

        vm.changePre = checkIsPre;
        function checkIsPre() {
            //所属点位已经存在线索，不能设置为线索
            if (vm.baseTask.myLocationTaskId) {
                var id = vm.baseTask.myLocationTaskId;
                if (vm.baseTask.myLocationTaskId.id)
                    id = vm.baseTask.myLocationTaskId.id;
                $http({
                    method: "GET",
                    url: "api/check-has-pretask/" + id,
                })
                    .success(function (data) {
                        // console.log(data);
                        if (
                            data.errorCode == -1 &&
                            data.message != vm.baseTask.id
                        ) {
                            // alert("该基本任务所属点位已经包含一个线索,此设置将不会生效");
                            vm.baseTask.isPreTask = false;
                            $("#field_isPreTask").attr("disabled", true);
                            $(".changeColorP").css("color", "#b4b4b4");
                        }
                    })
                    .error(function (err) {
                        console.log(err);
                    });
                var locationTaskId;
                if (vm.baseTask.myLocationTaskId.id) {
                    locationTaskId = vm.baseTask.myLocationTaskId.id;
                } else {
                    locationTaskId = vm.baseTask.myLocationTaskId;
                }

                $http({
                    method: "GET",
                    url: "api/check-location-platform/" + locationTaskId,
                })
                    .success(function (data) {
                        console.log(data);
                        vm.platform = data.data;
                    })
                    .error(function (err) {
                        console.log(err);
                    });
            }
            //是其他任务的前置条件，不能设置为线索
            if (vm.baseTask.isOtherPer === true) {
                vm.baseTask.isPreTask = false;
                $("#field_isPreTask").attr("disabled", true);
                $(".changeColorP").css("color", "#b4b4b4");
                vm.baseTask.end = false;
                $("#field_isEnd").attr("disabled", true);
                $(".changeColorE").css("color", "#b4b4b4");
            }
            //设置为线索任务后，不可设为结束任务，不可跳过
            if (vm.baseTask.isPreTask) {
                vm.baseTask.end = false;
                $("#field_isEnd").attr("disabled", true);
                $(".changeColorE").css("color", "#b4b4b4");
                vm.baseTask.canIgnore = false;
                $("#field_canIgnore").attr("disabled", true);
                $(".changeColorC").css("color", "#b4b4b4");
                $("#addPre1").attr("disabled", true);
                $("#addPre2").attr("disabled", true);
                $("#dePre").attr("disabled", true);
                $(".changeColorA").css("color", "#b4b4b4");
                $(".preCondition").css("display", "none");
                vm.baseTask.preCondition = null;
            } else {
                if (!vm.baseTask.isOtherPer === true) {
                    $("#field_isEnd").attr("disabled", false);
                    $(".changeColorE").css("color", "#1d2b3c");
                }
                $("#field_canIgnore").attr("disabled", false);
                $(".changeColorC").css("color", "#1d2b3c");
                $("#addPre1").attr("disabled", false);
                $("#addPre2").attr("disabled", false);
                $("#dePre").attr("disabled", false);
                $(".changeColorA").css("color", "#1d2b3c");
                $(".preCondition").css("display", "block");
            }
            //设置为结束任务后，不可设为线索任务
            if (vm.baseTask.end) {
                vm.baseTask.isPreTask = false;
                $("#field_isPreTask").attr("disabled", true);
                $(".changeColorP").css("color", "#b4b4b4");
            } else {
                // console.log(vm.baseTask.isOtherPer);
                if (
                    vm.baseTask.isOtherPer === false ||
                    vm.baseTask.isOtherPer === undefined
                ) {
                    $("#field_isPreTask").attr("disabled", false);
                    $(".changeColorP").css("color", "#1d2b3c");
                }
            }
            //所属点位是限次点位，所以不可设为线索，结束，跳过
            if (vm.baseTask.myLocationTaskId) {
                var id = vm.baseTask.myLocationTaskId;
                if (vm.baseTask.myLocationTaskId.id)
                    id = vm.baseTask.myLocationTaskId.id;
                $http({
                    method: "GET",
                    url: "/api/location-tasks/" + id,
                })
                    .success(function (data) {})
                    .error(function (err) {
                        console.log(err);
                    });
            }
        }

        this.$onInit = function () {
            $(function () {
                checkIsPre();
                $('[data-toggle="popover"]').popover();
                $('[data-toggle="tooltip"]').tooltip();

                /*$("[name='canIgnore']").bootstrapSwitch('state');
                 $('input[name="canIgnore"]').on('switchChange.bootstrapSwitch', function (event, state) {
                 vm.baseTask.canIgnore = state;
                 });

                 $("[name='isPreTask']").bootstrapSwitch('state');
                 $('input[name="isPreTask"]').on('switchChange.bootstrapSwitch', function (event, state) {
                 vm.baseTask.isPreTask = state;
                 checkIsPre();
                 });

                 $("[name='isEnd']").bootstrapSwitch('state');
                 $('input[name="isEnd"]').on('switchChange.bootstrapSwitch', function (event, state) {
                 vm.baseTask.end = state;
                 });*/

                if (vm.baseTask.equip) {
                    MyEquip.get(vm.baseTask.equip).then(function (response) {
                        var data = response.data;
                        vm.baseTask.equip = { id: data.id, name: data.name };
                        // console.log(vm.baseTask.equip)
                    });
                }
            });
        };

        /*vm.chooseAvatar = chooseAvatar;
         function chooseAvatar() {
         getAvatars();
         }
         function getAvatars() {
         $http({
         method: 'GET',
         url: '/api/avatars/type/'+'旁白'
         }).success(function (data) {
         if (data!=null){
         vm.avatars = data;
         }
         // console.log(vm.avatars);
         }).error(function(err){
         console.log(err);
         });
         }
         function asideAvatar($index) {
         for (var i=0;i<vm.avatars.length;i++){
         if ($index==i){
         vm.baseTask.asideAvatar = vm.avatars[i].avatar;
         vm.baseTask.asideName = vm.avatars[i].name;
         }
         }
         }*/

        if (vm.baseTask.asideName != null && vm.baseTask.asideAvatar != null) {
            var a = vm.baseTask.asideAvatar;
            var b = vm.baseTask.asideName;
            vm.baseTask.asideAvatar = {};
            vm.baseTask.asideAvatar.avatar = a;
            vm.baseTask.asideAvatar.name = b;
        }
        if (
            vm.baseTask.asideNameAfter != null &&
            vm.baseTask.asideAvatarAfter != null
        ) {
            var a = vm.baseTask.asideAvatarAfter;
            var b = vm.baseTask.asideNameAfter;
            vm.baseTask.asideAvatarAfter = {};
            vm.baseTask.asideAvatarAfter.avatar = a;
            vm.baseTask.asideAvatarAfter.name = b;
        }
        vm.filterAsideAvatar = function (search) {
            if (search) {
                AvatarSearch.query(
                    {
                        query: search,
                        page: 0,
                        size: 20,
                        sort: "id",
                    },
                    onSuccess,
                    onError
                );
            }
        };
        function onSuccess(data, headers) {
            vm.avatars = data;
            // console.log(vm.avatars);
        }
        function onError(error) {
            console.log(error);
        }
        vm.removeAvatar = removeAvatar;
        function removeAvatar() {
            if (vm.baseTask.asideAvatar != null) {
                vm.baseTask.asideName = null;
                vm.baseTask.asideAvatar = null;
                // document.getElementById("field_asideAvatar").style.display='none';
            }
        }

        /*function asideAvatarAfter($index) {
         for (var i=0;i<vm.avatars.length;i++){
         if ($index==i){
         vm.baseTask.asideAvatarAfter = vm.avatars[i].avatar;
         vm.baseTask.asideNameAfter = vm.avatars[i].name;
         }
         }
         }*/
        vm.removeAvatarAfter = removeAvatarAfter;
        function removeAvatarAfter() {
            if (vm.baseTask.asideAvatarAfter != null) {
                vm.baseTask.asideNameAfter = null;
                vm.baseTask.asideAvatarAfter = null;
                // document.getElementById("field_asideAvatar").style.display='none';
            }
        }

        //设置旁白默认头像
        // if (vm.baseTask.asideAvatar == null){
        //     vm.baseTask.asideAvatar = '/PROF/Aside/a1503557908353_23413fde-01bd-454f-af46-2413cb06522f.png';
        // }

        vm.checkLength = checkLength;
        function checkLength() {
            vm.baseTask.answer1 = null;
            vm.baseTask.answer2 = null;
            vm.baseTask.answer3 = null;
            vm.baseTask.answer4 = null;
            // if (vm.baseTask.type=='AR'){
            //     if (vm.auth=='Super'){
            //         vm.arType = 'new';
            //     }else {
            //         vm.arType = 'old';
            //     }
            // }
            vm.answer = 1;
        }

        vm.removeImg = removeImg;
        vm.cover = cover;
        $timeout(function () {
            cover(vm.baseTask.latticeType);
        }, 2000);
        function cover(row) {
            CreateTable(row, row);
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + ".png";
            // console.log(img);
            onFileSelect(img, "img");
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI) {
            var binary = atob(dataURI.split(",")[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: "image/png" });
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.baseTask.img != null) {
                vm.baseTask.img = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        if (
            vm.baseTask.myLocationTaskId !== undefined &&
            vm.baseTask.myLocationTaskId !== null
        ) {
            getLocationTask(vm.baseTask.myLocationTaskId);
        }
        function getLocationTask(id) {
            $http({
                method: "GET",
                url: "/api/location-tasks/" + id,
            })
                .success(function (data) {
                    if (data != null) {
                        vm.baseTask.myLocationTaskId = {
                            id: data.id,
                            title: data.title,
                        };
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }

        var index = [];

        var initIndex = function (rowCount) {
            for (var i = 0; i < rowCount * rowCount; i++) {
                index[i] = i + 1;
            }
        };

        function CreateTable(rowCount, cellCount) {
            initIndex(rowCount);
            var styleIndex = rowCount - 1;
            $("#createtable").empty();
            var table = $("<table>");
            table.appendTo($("#createtable"));
            for (var i = 0; i < rowCount; i++) {
                var tr = $("<tr></tr>");
                tr.appendTo(table);
                for (var j = 0; j < cellCount; j++) {
                    var value = index[i * rowCount + j];
                    var td = $("<td>" + value + "</td>");
                    td.appendTo(tr);
                }
            }
            $("#createtable").append("</table>");
            var style = "table" + styleIndex;
            for (var j = 1; j < 5; j++) {
                $("#createtable").removeClass("table" + j);
            }
            $("#createtable").addClass(style);
        }

        function removeImg() {
            if (vm.baseTask.img != null) {
                vm.baseTask.img = null;
                vm.type = null;
            }
        }

        vm.filterLocationTask = function (search) {
            if (search) {
                vm.locationTasks = LocationTaskSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
                // console.log(vm.locationTasks);
            }
        };
        vm.filterEquips = function (search) {
            if (search) {
                vm.equips = EquipSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
            }
        };

        if (vm.baseTask.laititude == null && vm.baseTask.longtitude == null) {
            getDefaultLocation();
        } else {
            vm.location.laititude = vm.baseTask.laititude;
            vm.location.longtitude = vm.baseTask.longtitude;
        }

        function getDefaultLocation() {
            $http({
                method: "GET",
                url: "/api/locations/281",
            })
                .success(function (data) {
                    if (data != null) {
                        vm.location = data;
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }

        this.filterTask = function (search) {
            // if(search){
            //     vm.tasks = BaseTaskSearch.query({
            //         query: search,
            //         page :  0,
            //         size : vm.itemsPerPage,
            //         sort : ['id,desc']
            //     });
            // }
            if (vm.baseTask.myLocationTaskId) {
                if (vm.baseTask.myLocationTaskId.id) {
                    // vm.task = LocationTaskExt.get({id:vm.baseTask.myLocationTaskId.id});
                    getTask(vm.baseTask.myLocationTaskId.id);
                    // console.log(vm.tasks);
                } else {
                    // vm.task = LocationTaskExt.get({id:vm.baseTask.myLocationTaskId});
                    getTask(vm.baseTask.myLocationTaskId);
                }
                // console.log(vm.tasks);
            }
        };
        vm.tasks = [];
        function getTask(id) {
            var baseTaskId = vm.baseTask.id;
            if (vm.baseTask.id != null) {
                baseTaskId = vm.baseTask.id;
            }
            $http({
                method: "POST",
                // url: '/api/base-tasks/locationTask/'+id
                url: "/api/base-task/can-be-preconditions",
                data: { baseTaskId: baseTaskId, locationTaskId: id },
            })
                .success(function (data) {
                    if (data != null) {
                        vm.task = data;
                        /*for (var i in vm.task){
                     if (vm.task[i].id!=vm.baseTask.id){
                     vm.tasks[i] = vm.task[i];
                     }
                     }*/
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }

        parsePreCondition();

        if (entity != null && entity.id != null) {
            vm.baseTask.type = entity.type;
            vm.baseTask.challengeMode = entity.challengeMode;
            if (entity.answer != null && entity.answer.indexOf("{") != -1) {
                var answers = JSON.parse(entity.answer);
                if (
                    answers != null &&
                    answers.qaList != null &&
                    answers.qaList.length > 0
                ) {
                    vm.baseTask.answer = [];
                    for (var i = 0; i < 4; i++) {
                        var answer = answers.qaList[i];
                        if (answer != null && typeof answer !== "undefined") {
                            answer.score = parseInt(answer.score);
                            if (isNaN(answer.score)) {
                                answer.score = null;
                            }
                            vm.baseTask.answer[i] = {
                                answer: answer.answer,
                                score: answer.score,
                            };
                        } else {
                            vm.baseTask.answer[i] = { answer: "", score: null };
                        }
                    }
                    vm.baseTask.answer1 = vm.baseTask.answer[0];
                    vm.baseTask.answer2 = vm.baseTask.answer[1];
                    vm.baseTask.answer3 = vm.baseTask.answer[2];
                    vm.baseTask.answer4 = vm.baseTask.answer[3];
                }
            }

            if (
                entity.selection != null &&
                entity.selection.indexOf("{") != -1
            ) {
                var selection = JSON.parse(entity.selection);
                if (
                    selection != null &&
                    selection.selections != null &&
                    selection.selections.length > 0
                ) {
                    vm.baseTask.selection1 = selection.selections[0];
                    vm.baseTask.selection2 = selection.selections[1];
                    vm.baseTask.selection3 = selection.selections[2];
                    vm.baseTask.selection4 = selection.selections[3];
                }
                vm.baseTask.selection = [
                    vm.baseTask.selection1,
                    vm.baseTask.selection2,
                    vm.baseTask.selection3,
                    vm.baseTask.selection4,
                ];
            }
        } else {
            vm.baseTask.type = "QA";
            vm.baseTask.challengeMode = "CHALLENGE";
            vm.baseTask.answer1 = { answer: "", score: null };
            vm.baseTask.answer2 = { answer: "", score: null };
            vm.baseTask.answer3 = { answer: "", score: null };
            vm.baseTask.answer4 = { answer: "", score: null };
            vm.baseTask.answer = [
                vm.baseTask.answer1,
                vm.baseTask.answer2,
                vm.baseTask.answer3,
                vm.baseTask.answer4,
            ];

            vm.baseTask.qaSelection = "";
            vm.baseTask.selection1 = "";
            vm.baseTask.selection2 = "";
            vm.baseTask.selection3 = "";
            vm.baseTask.selection4 = "";
            vm.baseTask.selection = [
                vm.baseTask.selection1,
                vm.baseTask.selection2,
                vm.baseTask.selection3,
                vm.baseTask.selection4,
            ];
        }

        if (vm.baseTask.answer != null) {
            countAnswer();
        }
        function countAnswer() {
            var answer = 1;
            for (var i = 0; i < vm.baseTask.answer.length; i++) {
                if (vm.baseTask.answer[i].answer != "") {
                    answer = i + 1;
                }
            }
            vm.answer = answer;
        }

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        // vm.locationtasks = LocationTask.query();
        // vm.tasks =  BaseTask.query();
        vm.onFileSelect = onFileSelect;
        vm.addOrCondition = addOrCondition;
        vm.addAndCondition = addAndCondition;
        vm.removeLastCondition = removeLastCondition;
        vm.populatePrecondition = populatePrecondition;

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function parsePreCondition() {
            var conditionReg = /(\d+)/g;
            var andOrReg = /(AND|OR)/g;
            if (vm.baseTask.preCondition) {
                vm.precondition = vm.baseTask.preCondition.match(conditionReg);
                vm.precondition = _.map(vm.precondition, function (condition) {
                    return parseInt(condition);
                });
                vm.precondition_logic =
                    vm.baseTask.preCondition.match(andOrReg);
                if (vm.precondition_logic == null) {
                    vm.precondition =
                        vm.precondition == null
                            ? new Array(9)
                            : vm.precondition;
                    vm.precondition_logic = new Array(9);
                    vm.precondition_current = 0;
                } else {
                    vm.precondition_current = vm.precondition.length - 1;
                }
            } else {
                vm.precondition = new Array(9);
                vm.precondition_logic = new Array(9);
                vm.precondition_current = -1;
            }
        }

        function addOrCondition() {
            vm.precondition_logic[vm.precondition_current] = "OR";
            if (vm.precondition_current >= -1 && vm.precondition_current <= 7) {
                vm.precondition_current = vm.precondition_current + 1;
            }
            populatePrecondition();
        }

        function addAndCondition() {
            vm.precondition_logic[vm.precondition_current] = "AND";
            if (vm.precondition_current >= -1 && vm.precondition_current <= 7) {
                vm.precondition_current = vm.precondition_current + 1;
            }
            populatePrecondition();
        }

        function removeLastCondition() {
            if (vm.precondition_current >= 0 && vm.precondition_current <= 8) {
                var msg = "您确定要删除此前置条件？";
                if (confirm(msg) == true) {
                    vm.precondition[vm.precondition_current] = undefined;
                    vm.precondition_logic[vm.precondition_current] = "";
                    vm.precondition_current = vm.precondition_current - 1;
                    change();
                }
            }
            populatePrecondition();
        }

        function populatePrecondition() {
            var preCondition = "";
            if (vm.precondition != null && vm.precondition.length > 0) {
                for (var i = 0; i <= vm.precondition_current; i++) {
                    if (i == 0) {
                        preCondition =
                            vm.precondition[i] === undefined
                                ? ""
                                : vm.precondition[i];
                    } else {
                        preCondition = "( "
                            .concat(preCondition)
                            .concat(" ")
                            .concat(
                                vm.precondition_logic[i - 1] === undefined
                                    ? ""
                                    : vm.precondition_logic[i - 1]
                            )
                            .concat(" ")
                            .concat(
                                vm.precondition[i] === undefined
                                    ? ""
                                    : vm.precondition[i]
                            )
                            .concat(" )");
                    }
                }
            }

            vm.baseTask.preCondition = preCondition;
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        vm.checkNum = checkNum;
        function checkNum(input) {
            var reg = /^[0-9]*$/;
            if (!reg.test(input) && input != null) {
                alert("请输入数字答案！！");
                vm.baseTask.answer1.answer = null;
            }
        }
        function checkNum2(input) {
            var reg = /^[0-9]*$/;
            if (!reg.test(input) && input != null) {
                alert("请输入数字答案！！");
                vm.baseTask.answer2.answer = null;
            }
        }
        function checkNum3(input) {
            var reg = /^[0-9]*$/;
            if (!reg.test(input) && input != null) {
                alert("请输入数字答案！！");
                vm.baseTask.answer3.answer = null;
            }
        }
        function checkNum4(input) {
            var reg = /^[0-9]*$/;
            if (!reg.test(input) && input != null) {
                alert("请输入数字答案！！");
                vm.baseTask.answer4.answer = null;
            }
        }

        vm.showQR1 = QR1;
        function QR1() {
            var answer = vm.baseTask.answer1.answer;
            if (answer != null) {
                //console.log(answer);
                vm.qrCode1 = answer;
                document.getElementById("qrCode1").style.display = "block";
            } else {
                alert("请先填写二维码答案");
            }
        }
        vm.showQR2 = QR2;
        function QR2() {
            var answer = vm.baseTask.answer2.answer;
            if (answer != null) {
                // console.log(answer);
                vm.qrCode2 = answer;
                document.getElementById("qrCode2").style.display = "block";
            } else {
                alert("请先填写二维码答案");
            }
        }
        vm.showQR3 = QR3;
        function QR3() {
            var answer = vm.baseTask.answer3.answer;
            if (answer != null) {
                // console.log(answer);
                vm.qrCode3 = answer;
                document.getElementById("qrCode3").style.display = "block";
            } else {
                alert("请先填写二维码答案");
            }
        }
        vm.showQR4 = QR4;
        function QR4() {
            var answer = vm.baseTask.answer4.answer;
            if (answer != null) {
                // console.log(answer);
                vm.qrCode4 = answer;
                document.getElementById("qrCode4").style.display = "block";
            } else {
                alert("请先填写二维码答案");
            }
        }

        //获取答案中最高得分
        function checkScore() {
            if (vm.baseTask.answer2 == null) {
                vm.baseTask.answer2 = { answer: "", score: "" };
            }
            if (vm.baseTask.answer3 == null) {
                vm.baseTask.answer3 = { answer: "", score: "" };
            }
            if (vm.baseTask.answer4 == null) {
                vm.baseTask.answer4 = { answer: "", score: "" };
            }
            var score = [
                vm.baseTask.answer1.score,
                vm.baseTask.answer2.score,
                vm.baseTask.answer3.score,
                vm.baseTask.answer4.score,
            ];
            var max = 0;
            for (var i in score) {
                if (score[i] > max) {
                    max = score[i];
                }
            }
            return max;
        }
        //选择题勾选框设置正确答案
        if (vm.baseTask.type == "SELECTION") {
            if (
                vm.baseTask.answer1 != null &&
                vm.baseTask.answer1 != "underfine"
            ) {
                if (vm.baseTask.answer1.answer == vm.baseTask.selection1) {
                    vm.check1 = true;
                } else if (
                    vm.baseTask.answer1.answer == vm.baseTask.selection2
                ) {
                    vm.check2 = true;
                } else if (
                    vm.baseTask.answer1.answer == vm.baseTask.selection3
                ) {
                    vm.check3 = true;
                } else if (
                    vm.baseTask.answer1.answer == vm.baseTask.selection4
                ) {
                    vm.check4 = true;
                }
            } else {
                vm.baseTask.answer1 = { answer: null, score: null };
            }
            if (
                vm.baseTask.answer2 != null &&
                vm.baseTask.answer2 != "underfine"
            ) {
                if (vm.baseTask.answer2.answer == vm.baseTask.selection2) {
                    vm.check2 = true;
                } else if (
                    vm.baseTask.answer2.answer == vm.baseTask.selection1
                ) {
                    vm.check1 = true;
                } else if (
                    vm.baseTask.answer2.answer == vm.baseTask.selection3
                ) {
                    vm.check3 = true;
                } else if (
                    vm.baseTask.answer2.answer == vm.baseTask.selection4
                ) {
                    vm.check4 = true;
                }
            } else {
                vm.baseTask.answer2 = { answer: null, score: null };
            }
            if (
                vm.baseTask.answer3 != null &&
                vm.baseTask.answer3 != "underfine"
            ) {
                if (vm.baseTask.answer3.answer == vm.baseTask.selection3) {
                    vm.check3 = true;
                } else if (
                    vm.baseTask.answer3.answer == vm.baseTask.selection2
                ) {
                    vm.check2 = true;
                } else if (
                    vm.baseTask.answer3.answer == vm.baseTask.selection1
                ) {
                    vm.check1 = true;
                } else if (
                    vm.baseTask.answer3.answer == vm.baseTask.selection4
                ) {
                    vm.check4 = true;
                }
            } else {
                vm.baseTask.answer3 = { answer: null, score: null };
            }
            if (
                vm.baseTask.answer4 != null &&
                vm.baseTask.answer4 != "underfine"
            ) {
                if (vm.baseTask.answer4.answer == vm.baseTask.selection4) {
                    vm.check4 = true;
                } else if (
                    vm.baseTask.answer4.answer == vm.baseTask.selection3
                ) {
                    vm.check3 = true;
                } else if (
                    vm.baseTask.answer4.answer == vm.baseTask.selection2
                ) {
                    vm.check2 = true;
                } else if (
                    vm.baseTask.answer4.answer == vm.baseTask.selection1
                ) {
                    vm.check1 = true;
                }
            } else {
                vm.baseTask.answer4 = { answer: null, score: null };
            }
            if (vm.check2) {
                if (vm.baseTask.answer2.score == null) {
                    vm.baseTask.answer2.score = vm.baseTask.answer1.score;
                    vm.baseTask.answer1.score = 0;
                }
            }
            if (vm.check3) {
                if (vm.baseTask.answer3.score == null) {
                    vm.baseTask.answer3.score = vm.baseTask.answer1.score;
                    vm.baseTask.answer1.score = 0;
                }
            }
            if (vm.check4) {
                if (vm.baseTask.answer4.score == null) {
                    vm.baseTask.answer4.score = vm.baseTask.answer1.score;
                    vm.baseTask.answer1.score = 0;
                }
            }
        }
        //选择题勾选框设置正确答案
        function SelectionSetAnswer() {
            if (vm.check1 == true) {
                vm.baseTask.answer1.answer = vm.baseTask.selection1;
            } else {
                vm.baseTask.answer1.answer = null;
            }
            if (vm.check2 == true) {
                vm.baseTask.answer2.answer = vm.baseTask.selection2;
            } else {
                vm.baseTask.answer2.answer = null;
            }
            if (vm.check3 == true) {
                vm.baseTask.answer3.answer = vm.baseTask.selection3;
            } else {
                vm.baseTask.answer3.answer = null;
            }
            if (vm.check4 == true) {
                vm.baseTask.answer4.answer = vm.baseTask.selection4;
            } else {
                vm.baseTask.answer4.answer = null;
            }
        }
        //选择问答题（猜字题）判断答案是否在可选答案中
        function canChoose(answer) {
            var str = vm.baseTask.qaSelection;
            // console.log(str);
            for (var i in answer) {
                if (str.search(answer[i]) !== -1) {
                    str = str.replace(answer[i], "");
                    // console.log(str);
                } else {
                    alert("任务答案不在可选项内");
                    return false;
                }
            }
            return true;
        }

        //老版找茬题点击编辑显示答案
        if (vm.baseTask.type == "DIFF") {
            // var diffAnswer = angular.fromJson(vm.baseTask.answer).qaList;
            // console.log(diffAnswer);
            var diffAnswer = vm.baseTask.answer;
            if (diffAnswer != null) {
                if (
                    typeof diffAnswer[0] !== "undefined" &&
                    diffAnswer[0] != null &&
                    typeof diffAnswer[0].answer !== "undefined" &&
                    diffAnswer[0].answer != null &&
                    diffAnswer[0].answer != ""
                ) {
                    vm.baseTask.answer1 = {
                        answer: parseInt(diffAnswer[0].answer),
                        score: parseInt(diffAnswer[0].score),
                    };
                }
                if (
                    typeof diffAnswer[1] !== "undefined" &&
                    diffAnswer[1] != null &&
                    typeof diffAnswer[1].answer !== "undefined" &&
                    diffAnswer[1].answer != null &&
                    diffAnswer[1].answer != ""
                ) {
                    vm.baseTask.answer2 = {
                        answer: parseInt(diffAnswer[1].answer),
                        score: parseInt(diffAnswer[1].score),
                    };
                }
                if (
                    typeof diffAnswer[2] !== "undefined" &&
                    diffAnswer[2] != null &&
                    typeof diffAnswer[2].answer !== "undefined" &&
                    diffAnswer[2].answer != null &&
                    diffAnswer[2].answer != ""
                ) {
                    vm.baseTask.answer3 = {
                        answer: parseInt(diffAnswer[2].answer),
                        score: parseInt(diffAnswer[2].score),
                    };
                }
                if (
                    typeof diffAnswer[3] !== "undefined" &&
                    diffAnswer[3] != null &&
                    typeof diffAnswer[3].answer !== "undefined" &&
                    diffAnswer[3].answer != null &&
                    diffAnswer[3].answer != ""
                ) {
                    vm.baseTask.answer4 = {
                        answer: parseInt(diffAnswer[3].answer),
                        score: parseInt(diffAnswer[3].score),
                    };
                }
            }
        }

        //根据任务前置条件控制所属点位是否可编辑
        if (vm.baseTask.isOtherPer === true || vm.precondition[0]) {
            // $("#field_myTrip").attr("disabled", true);
            vm.isdisabled = true;
        } else {
            vm.isdisabled = false;
        }
        vm.change = change;
        function change() {
            if (vm.baseTask.isOtherPer === true || vm.precondition[0]) {
                // $("#field_myTrip").attr("disabled", true);
                vm.isdisabled = true;
            } else {
                vm.isdisabled = false;
            }
        }

        // vm.filterArModel = function (search) {
        //     if(search){
        //         vm.arModels = ArModelSearchAll.query({
        //             query: search,
        //             page :  0,
        //             size : vm.itemsPerPage,
        //             sort : ['id,desc']
        //         });
        //     }
        // };

        getAllArModel();
        function getAllArModel() {
            $http({
                method: "GET",
                url: "/api/ar-models",
            })
                .success(function (data) {
                    if (data != null) {
                        vm.arModels = data;
                        // console.log(vm.arModels);
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }
        function getArModel(id) {
            $http({
                method: "GET",
                url: "/api/ar-models/" + id,
            })
                .success(function (data) {
                    // console.log(data);
                    if (data != null) {
                        vm.arType = "old";
                        vm.baseTask.scanResult = {
                            id: data.id,
                            title: data.title,
                            androidContent: data.androidContent,
                            iosContent: data.iosContent,
                        };
                        vm.arScanResult = {
                            id: data.id,
                            title: data.title,
                            androidContent: data.androidContent,
                            iosContent: data.iosContent,
                        };
                        // console.log(vm.baseTask.scanResult);
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }

        //默认加载时判断是新AR还是旧AR，展示相关内容
        if (vm.baseTask.type == "AR" || vm.baseTask.type == "NEWAR") {
            if (vm.baseTask.newArModelId != null) {
                vm.baseTask.type = "NEWAR";
                getNewArModelFromServer(vm.baseTask.newArModelId);
            } else {
                if (vm.baseTask.scanResult != null) {
                    vm.baseTask.type = "AR";
                    getArModel(vm.baseTask.scanResult);
                }
            }
        }
        function getNewArModelFromServer(id) {
            $http({
                method: "GET",
                // url: 'http://artest.itasktour.com/api/armodel/detail?ar_model_id='+id
                url: vm.AR_SERVER + "/api/armodel/detail?ar_model_id=" + id,
            }).then(
                function (res) {
                    // console.log(res);
                    if (res.data.ar_model_id != null) {
                        vm.arType = "new";
                        // vm.newArModel = {id:vm.baseTask.scanResult,name:vm.baseTask.newArModelName,armodeltype:res.data.ar_model_type,reward:{"scan_image":res.data.scan_image}};
                        getArDetailFromServer(res.data);
                    }
                },
                function (err) {
                    console.log(err);
                    if (err.status == 404) {
                        vm.arType = "old";
                    }
                }
            );
        }
        function getArDetailFromServer(data) {
            // console.log(data);
            vm.armodeltype = data.ar_model_type; //获取AR类型
            vm.arModelName = data.name; //获取AR名称
            if (data.scan_status == "正在处理") {
                vm.scanStatus = "（" + data.scan_status + "）";
            } else {
                vm.scanStatus = "（" + data.scan_star + "）";
            }
            if (
                vm.armodeltype !== "monster" &&
                vm.armodeltype !== "bomb_lead"
            ) {
                var img = data.scan_image;
                if (img != null && img != "") {
                    if (img.indexOf(vm.IMAGE_SERVER) != -1) {
                        vm.scan_image = img.split(vm.IMAGE_SERVER)[1];
                    }
                }
            }
            if (vm.armodeltype != null) {
                $http({
                    method: "GET",
                    url:
                        vm.AR_SERVER +
                        "/api/template/armodel?type=" +
                        vm.armodeltype,
                })
                    .success(function (data) {
                        // console.log(data);
                        vm.getData = data;
                    })
                    .error(function (err) {
                        console.log(err);
                    });
            }
            //炸弹密码拆除获取次数及密码
            if (vm.armodeltype === "bomb_code") {
                vm.times = data.times;
                vm.password = data.password;
            }
            //密码箱和宝箱类型和点击打开的宝箱获取reward
            if (
                vm.armodeltype === "code_case" ||
                vm.armodeltype === "treasure_box" ||
                vm.armodeltype === "click_box"
            ) {
                vm.reward = data.reward.reward_type;
                vm.password = data.password;
            }
            //羊皮卷三种类型获取
            if (
                (vm.armodeltype === "code_case" ||
                    vm.armodeltype === "treasure_box" ||
                    vm.armodeltype === "click_box") &&
                vm.reward === "scrolls"
            ) {
                vm.reward = data.reward.reward_type;
                vm.scrolls_image = data.reward.scrolls_image;
                if (
                    data.reward.scrolls_image != null &&
                    data.reward.scrolls_image != ""
                ) {
                    if (
                        data.reward.scrolls_image.indexOf(vm.IMAGE_SERVER) != -1
                    ) {
                        vm.scrolls_image = data.reward.scrolls_image.split(
                            vm.IMAGE_SERVER
                        )[1];
                    }
                }
                vm.scrolls_style = data.reward.scrolls_style;
                vm.scrolls_desc = data.reward.scrolls_desc;
                vm.password = data.password;
            }
            //平面资源类型图片，音频，视频获取
            if (vm.armodeltype === "custom_resource") {
                var image = data.image;
                if (image != null && image != "") {
                    if (image.indexOf(vm.IMAGE_SERVER) != -1) {
                        vm.custom_rimg = image.split(vm.IMAGE_SERVER)[1];
                    }
                }
                var audio = data.audio;
                if (audio != null && audio != "") {
                    if (audio.indexOf(vm.IMAGE_SERVER) != -1) {
                        vm.custom_rmp3 = audio.split(vm.IMAGE_SERVER)[1];
                    }
                }
                var video = data.video;
                if (video != null && video != "") {
                    if (video.indexOf(vm.IMAGE_SERVER) != -1) {
                        vm.custom_rmp4 = video.split(vm.IMAGE_SERVER)[1];
                    }
                }
            }
            //炸弹-引线拆除获取
            if (vm.armodeltype === "bomb_lead") {
                // vm.lead_password = data.password;
                // console.log(data.password);
                if (data.password == 1) {
                    vm.lead_password = "红线";
                } else if (data.password == 2) {
                    vm.lead_password = "黄线";
                } else if (data.password == 3) {
                    vm.lead_password = "蓝线";
                } else if (data.password == 4) {
                    vm.lead_password = "绿线";
                }
            }
            //自定义3D模型类型 参数 模型文件，贴图文件获取
            if (vm.armodeltype === "custom_3d") {
                vm.pos_x = data.pos_x;
                vm.pos_y = data.pos_y;
                vm.pos_z = data.pos_z;
                vm.scale = data.scale;
                var obj_url = data.obj_url;
                if (obj_url != null && obj_url != "") {
                    if (obj_url.indexOf(vm.IMAGE_SERVER) != -1) {
                        vm.obj_url = obj_url.split(vm.IMAGE_SERVER)[1];
                    }
                }
                var texture_file = data.texture_file;
                if (texture_file != null && texture_file != "") {
                    if (texture_file.indexOf(vm.IMAGE_SERVER) != -1) {
                        vm.texture_file = texture_file.split(
                            vm.IMAGE_SERVER
                        )[1];
                    }
                }
                var other = data.other;
                vm.other = new Array(2);
                if (other != null && typeof other !== "undefined") {
                    for (var i = 0; i < other.length; i++) {
                        if (other[i] != null && other[i] != "") {
                            if (
                                typeof other[i] !== "undefined" &&
                                other[i].indexOf(vm.IMAGE_SERVER) != -1
                            ) {
                                vm.other[i] = other[i].split(
                                    vm.IMAGE_SERVER
                                )[1];
                            }
                        }
                    }
                }
            }
            //电话平面拨号 参数音频加http地址
            if (vm.armodeltype === "phone") {
                var audios = data.audio;
                if (audios != null && audios != "") {
                    if (audios.indexOf(vm.IMAGE_SERVER) != -1) {
                        vm.audio = audios.split(vm.IMAGE_SERVER)[1];
                    }
                }
                vm.phone = data.phone;
            }
        }

        vm.filterNewArModel = function (search) {
            if (search) {
                vm.newArModels = ArModelNew.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
            }
        };

        vm.changeARType = changeType;
        function changeType() {
            // console.log(vm.armodeltype);
            if (vm.armodeltype != null) {
                $http({
                    method: "GET",
                    url:
                        vm.AR_SERVER +
                        "/api/template/armodel?type=" +
                        vm.armodeltype,
                })
                    .success(function (data) {
                        console.log(data);
                        vm.getData = data;
                        vm.reward = {};
                    })
                    .error(function (err) {
                        console.log(err);
                    });
            }
        }

        //设置创建AR事件的参数
        function setParams() {
            var sParams = {};
            // if (vm.baseTask.type=='AR'&&vm.arType=='new'){
            if (vm.baseTask.type == "NEWAR") {
                //判断是否绑定过newArModel，绑定过-传参数arId去修改当前ar事件，否则传任务id，新建ar事件绑定当前任务
                if (vm.baseTask.newArModelId != null) {
                    sParams.ar_model_id = vm.baseTask.newArModelId;
                } else {
                    sParams.task_id = vm.baseTask.id;
                }
                sParams.ar_model_type = vm.armodeltype; //设置参数armodel类型
                sParams.name = vm.arModelName; //设置ar昵称参数
                //参数扫描图片加http地址
                if (
                    vm.armodeltype !== "monster" &&
                    vm.armodeltype !== "bomb_lead"
                ) {
                    var img = vm.scan_image;
                    if (img != null && img != "") {
                        if (img.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.scan_image = vm.IMAGE_SERVER + img;
                        }
                    }
                }
                //炸弹引线拆除，自定义3D模型设置GPS参数
                if (
                    vm.armodeltype === "bomb_lead" ||
                    vm.armodeltype === "custom_3d"
                ) {
                    sParams.gps = {};
                    sParams.gps.longitude = vm.baseTask.longtitude;
                    sParams.gps.latitude = vm.baseTask.laititude;
                }
                //炸弹密码拆除设置参数
                if (vm.armodeltype === "bomb_code") {
                    sParams.times = vm.times;
                    sParams.password = vm.password;
                }
                //密码箱和宝箱和点击打开的宝箱类型设置参数
                if (
                    vm.armodeltype === "code_case" ||
                    vm.armodeltype === "treasure_box" ||
                    vm.armodeltype === "click_box"
                ) {
                    sParams.reward = {};
                    sParams.reward.reward_type = vm.reward;
                    sParams.password = vm.password;
                }
                //羊皮卷三种类型设置参数
                if (
                    (vm.armodeltype === "code_case" ||
                        vm.armodeltype === "treasure_box" ||
                        vm.armodeltype === "click_box") &&
                    vm.reward === "scrolls"
                ) {
                    sParams.reward = { reward_type: "scrolls" };
                    sParams.reward.reward_type = vm.reward;
                    var scrolls_image = vm.scrolls_image;
                    if (scrolls_image != null && scrolls_image != "") {
                        if (scrolls_image.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.reward.scrolls_image =
                                vm.IMAGE_SERVER + scrolls_image;
                        }
                    }
                    sParams.reward.scrolls_style = vm.scrolls_style;
                    sParams.reward.scrolls_desc = vm.scrolls_desc;
                    sParams.password = vm.password;
                }
                //平面资源类型图片，音频，视频参数加http地址
                if (vm.armodeltype === "custom_resource") {
                    var image = vm.custom_rimg;
                    if (image != null && image != "") {
                        if (image.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.image = vm.IMAGE_SERVER + image;
                            sParams.audio = null;
                            sParams.video = null;
                        }
                    }
                    var audio = vm.custom_rmp3;
                    if (audio != null && audio != "") {
                        if (audio.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.audio = vm.IMAGE_SERVER + audio;
                            sParams.image = null;
                            sParams.video = null;
                        }
                    }
                    var video = vm.custom_rmp4;
                    if (video != null && video != "") {
                        if (video.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.video = vm.IMAGE_SERVER + video;
                            sParams.image = null;
                            sParams.audio = null;
                        }
                    }
                }
                //炸弹-引线拆除设置参数
                if (vm.armodeltype === "bomb_lead") {
                    // sParams.scissor_speed = reward.scissor_speed;
                    // sParams.password = vm.lead_password;
                    // console.log(vm.lead_password);
                    if (vm.lead_password == "红线") {
                        sParams.password = 1;
                    } else if (vm.lead_password == "黄线") {
                        sParams.password = 2;
                    } else if (vm.lead_password == "蓝线") {
                        sParams.password = 3;
                    } else if (vm.lead_password == "绿线") {
                        sParams.password = 4;
                    } else {
                        alert("请选择正确引线");
                        vm.isSaving = false;
                        return;
                    }
                }
                //自定义3D模型类型 参数 模型文件，贴图文件加http地址
                if (vm.armodeltype === "custom_3d") {
                    sParams.pos_x = vm.pos_x;
                    sParams.pos_y = vm.pos_y;
                    sParams.pos_z = vm.pos_z;
                    sParams.scale = vm.scale;
                    var obj_url = vm.obj_url;
                    if (obj_url != null && obj_url != "") {
                        if (obj_url.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.obj_url = vm.IMAGE_SERVER + obj_url;
                        }
                    }
                    var texture_file = vm.texture_file;
                    if (texture_file != null && texture_file != "") {
                        if (texture_file.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.texture_file =
                                vm.IMAGE_SERVER + texture_file;
                        }
                    }
                    /*var other = vm.other;
                    if (other!=null&&other!=""){
                        sParams.other = new Array(2);
                        for (var i=0;i<other.length;i++){
                            if (typeof (other[i])!='undefined'&&other[i].indexOf(vm.IMAGE_SERVER)<0){
                                sParams.other[i] = vm.IMAGE_SERVER+other[i];
                            }
                        }
                    }*/
                }
                //电话平面拨号 参数音频加http地址
                if (vm.armodeltype === "phone") {
                    audio = vm.audio;
                    if (audio != null && audio != "") {
                        if (audio.indexOf(vm.IMAGE_SERVER) < 0) {
                            sParams.audio = vm.IMAGE_SERVER + audio;
                        }
                    }
                    sParams.phone = vm.phone;
                }
                // }else if (vm.baseTask.type=='AR'&&vm.arType=='old'){
            } else if (vm.baseTask.type == "NEWAR" && vm.arType == "old") {
                sParams.task_id = vm.baseTask.id;
                sParams.ar_model_type = "custom_3d";
                sParams.name = vm.arScanResult.title;
                sParams.scan_image = vm.IMAGE_SERVER + vm.baseTask.scanImg;
                //设置GPS参数
                sParams.gps = {};
                sParams.gps.longitude = vm.baseTask.longtitude;
                sParams.gps.latitude = vm.baseTask.laititude;
                sParams.obj_url =
                    vm.IMAGE_SERVER + vm.arScanResult.androidContent;
                // sParams.obj_url = vm.IMAGE_SERVER+vm.arScanResult.iosContent;
            }
            // var a = JSON.stringify(sParams);
            // console.log(a);
            return sParams;
        }
        //向AR服务器发送修改AR事件请求
        function changeAREvent() {
            var sParams = setParams();
            // console.log(sParams);
            var deferred = $q.defer(); // 声明延后执行，表示要去监控后面的执行
            $http({
                method: "POST",
                url: vm.AR_SERVER + "/api/armodel/changeone",
                data: sParams,
            }).then(
                function (data) {
                    // console.log(data);
                    deferred.resolve(data);
                },
                function (err) {
                    console.log(err);
                    deferred.reject(err);
                    vm.isSaving = false;
                }
            );
            return deferred.promise; // 返回承诺，这里并不是最终数据，而是访问最终数据的API
        }
        //此时该任务不存在AR事件，需创建AR事件
        //创建AR事件必须绑定task_id，如果没有，则需要先保存任务，再创建事件
        function createAREvent() {
            var sParams = setParams();
            // console.log(sParams);
            $http({
                method: "POST",
                url: vm.AR_SERVER + "/api/armodel/createone",
                data: sParams,
            }).then(
                function (data) {
                    // console.log(data);
                    vm.baseTask.newArModelId = data.data.ar_model_id; //ar服务器AR事件的id存到newArModelId字段
                    if (data.data.scan_image != null) {
                        vm.baseTask.scanImg = data.data.scan_image.split(
                            vm.IMAGE_SERVER
                        )[1];
                    }
                    if (data.data.scan_status == "正在处理") {
                        alert(
                            "后台正在识别您的扫描图片，请稍后再次编辑本任务查看识别情况"
                        );
                    } else {
                        if (
                            data.data.scan_star == "无法识别" ||
                            data.data.scan_star == "很难识别" ||
                            data.data.scan_star == "勉强识别"
                        ) {
                            vm.isSaving = false;
                            alert(
                                "AR扫描图片过于简单，可能无法识别，请更换扫描图片"
                            );
                            return;
                        }
                    }
                    if (
                        data.data.ar_model_type != "bomb_lead" &&
                        vm.baseTask.scanImg == null
                    ) {
                        alert("AR任务扫描图片不能为空！！");
                        vm.isSaving = false;
                        return;
                    }
                    vm.baseTask.scanResult = null;
                    // vm.baseTask.scanResult = vm.newArModel.id;//本地模板对应id存到scanResult字段
                    if (vm.baseTask.id !== null) {
                        BaseTask.update(
                            vm.baseTask,
                            onSaveSuccess,
                            onSaveError
                        );
                    } else {
                        BaseTask.save(vm.baseTask, onSaveSuccess, onSaveError);
                    }
                },
                function (err) {
                    console.log(err);
                    alert("创建AR模型失败，请核查AR必填项是否填写！！");
                    vm.isSaving = false;
                }
            );
        }
        //新的AR模板事件创建以及保存
        function saveNewAr() {
            // console.log(vm.newArModel);
            if (vm.baseTask.newArModelId != null) {
                //此时该任务已创建AR事件，重新编辑即修改当前事件
                var uEvent = changeAREvent();
                uEvent.then(
                    function (data) {
                        console.log(data);
                        if (data != null) {
                            if (data.data.scan_image != null) {
                                vm.baseTask.scanImg =
                                    data.data.scan_image.split(
                                        vm.IMAGE_SERVER
                                    )[1];
                            }
                            // vm.baseTask.scanResult = vm.newArModel.id;//本地模板对应id存到scanResult字段
                            if (data.data.scan_status == "正在处理") {
                                alert(
                                    "后台正在识别您的扫描图片，请稍后来查看识别情况"
                                );
                            } else {
                                if (
                                    data.data.scan_star == "无法识别" ||
                                    data.data.scan_star == "很难识别" ||
                                    data.data.scan_star == "勉强识别"
                                ) {
                                    vm.isSaving = false;
                                    alert(
                                        "AR扫描图片过于简单，可能无法识别，请更换扫描图片"
                                    );
                                    return;
                                }
                            }
                            if (
                                data.data.ar_model_type != "bomb_lead" &&
                                vm.baseTask.scanImg == null
                            ) {
                                alert("AR任务扫描图片不能为空！！");
                                vm.isSaving = false;
                                return;
                            }
                            vm.baseTask.scanResult = null;
                            if (vm.baseTask.id !== null) {
                                BaseTask.update(
                                    vm.baseTask,
                                    onSaveSuccess,
                                    onSaveError
                                );
                            } else {
                                BaseTask.save(
                                    vm.baseTask,
                                    onSaveSuccess,
                                    onSaveError
                                );
                            }
                        }
                    },
                    function (err) {
                        console.log(err);
                        alert("创建AR模型失败，请核查AR必填项是否填写！！");
                        vm.isSaving = false;
                    }
                );
            } else {
                //创建AR事件必须绑定task_id，如果没有，则需要先保存任务，再创建事件
                if (vm.baseTask.id !== null) {
                    createAREvent();
                } else {
                    BaseTask.save(
                        vm.baseTask,
                        function (res) {
                            // console.log(res);
                            vm.baseTask.id = res.id;
                            vm.arType = "new";
                            createAREvent();
                        },
                        function (err) {
                            console.log(err);
                        }
                    );
                }
            }
        }

        function save() {
            if (vm.platform == 1) {
                alert("该点位对应线路属于基础版无法保存");
                return;
            }
            vm.isSaving = true;
            if (vm.baseTask.myLocationTaskId == null) {
                alert("任务所属点位不能为空，请选择所属点位");
                vm.isSaving = false;
                return;
            }
            if (vm.baseTask.type == "DIFF") {
                if (vm.baseTask.img == null) {
                    alert("任务配图不能为空，请选择任务配图");
                    vm.isSaving = false;
                    return;
                }
            }
            if (vm.baseTask.img !== null) {
                if (/\.(jpg|jpeg|png|bmp)$/.test(vm.baseTask.img)) {
                    var image = document.getElementById("file");
                    if (image != null) {
                        var height = image.height;
                        var width = image.width;
                        var bi = width / height;
                        if (vm.baseTask.type !== "DIFF") {
                            if (bi < 1.8 || bi > 2.2) {
                                alert("上传图片比例2:1，且大小不得大于500kb");
                                removeImg();
                                vm.isSaving = false;
                                return;
                            }
                        } else {
                            if (bi !== 1) {
                                alert("上传图片比例1:1，且大小不得大于500kb");
                                removeImg();
                                vm.isSaving = false;
                                return;
                            }
                        }
                    }
                }
            }
            if (
                vm.baseTask.preCondition !== undefined &&
                vm.baseTask.preCondition !== null
            ) {
                console.log("前置条件不为null");
                if (vm.baseTask.preCondition == vm.baseTask.id) {
                    console.log("前置条件和id相等");
                    window.alert("前置条件和id相等");
                    vm.isSaving = false;
                    return;
                }
                if (vm.baseTask.preCondition.length > 0) {
                    var regNumber = /\d+/;
                    if (!regNumber.test(vm.baseTask.preCondition)) {
                        alert("请输入前置条件，若不需要，请删除条件");
                        vm.isSaving = false;
                        return;
                    }
                }
            }
            if (vm.baseTask.equip) {
                if (vm.baseTask.equip.id)
                    vm.baseTask.equip = vm.baseTask.equip.id;
            }
            if (vm.baseTask.asideAvatar) {
                if (vm.baseTask.asideAvatar.avatar)
                    var a = vm.baseTask.asideAvatar.avatar;
                if (vm.baseTask.asideAvatar.name)
                    var b = vm.baseTask.asideAvatar.name;
                vm.baseTask.asideAvatar = a;
                vm.baseTask.asideName = b;
            }
            if (vm.baseTask.asideAvatarAfter) {
                if (vm.baseTask.asideAvatarAfter.avatar)
                    var a = vm.baseTask.asideAvatarAfter.avatar;
                if (vm.baseTask.asideAvatarAfter.name)
                    var b = vm.baseTask.asideAvatarAfter.name;
                vm.baseTask.asideAvatarAfter = a;
                vm.baseTask.asideNameAfter = b;
            }
            var questionLength =
                document.getElementById("field_question").value;
            if (questionLength.length > 51) {
                alert("问题仅限填51字，您已超出，请重新编写问题！");
                vm.isSaving = false;
                return;
            }
            if (
                vm.baseTask.answer1 === null ||
                typeof vm.baseTask.answer1 === "undefined"
            ) {
                vm.baseTask.answer1 = { answer: "", score: null };
            }
            if (
                vm.baseTask.answer2 === null ||
                typeof vm.baseTask.answer2 === "undefined"
            ) {
                vm.baseTask.answer2 = { answer: "", score: null };
            }
            if (
                vm.baseTask.answer3 === null ||
                typeof vm.baseTask.answer3 === "undefined"
            ) {
                vm.baseTask.answer3 = { answer: "", score: null };
            }
            if (
                vm.baseTask.answer4 === null ||
                typeof vm.baseTask.answer4 === "undefined"
            ) {
                vm.baseTask.answer4 = { answer: "", score: null };
            }
            if (vm.baseTask.type == "NUMPWD") {
                if (
                    vm.baseTask.answer1 != null &&
                    vm.baseTask.answer1 != "undefined"
                ) {
                    // if (vm.baseTask.answer1.answer!=null && vm.baseTask.answer1.answer!='undefined'){
                    checkNum(vm.baseTask.answer1.answer);
                    // }
                }
                if (
                    vm.baseTask.answer2 != null &&
                    vm.baseTask.answer2 != undefined
                ) {
                    // if (vm.baseTask.answer2.answer!=null && vm.baseTask.answer1.answer!='undefined'){
                    checkNum2(vm.baseTask.answer2.answer);
                    // }
                }
                if (
                    vm.baseTask.answer3 != null &&
                    vm.baseTask.answer3 != "undefined"
                ) {
                    // if (vm.baseTask.answer3.answer!=null && vm.baseTask.answer1.answer!='undefined'){
                    checkNum3(vm.baseTask.answer3.answer);
                    // }
                }
                if (
                    vm.baseTask.answer4 != null &&
                    vm.baseTask.answer4 != "undefined"
                ) {
                    // if (vm.baseTask.answer4.answer!=null && vm.baseTask.answer1.answer!='undefined'){
                    checkNum4(vm.baseTask.answer4.answer);
                    // }
                }
            }
            if (vm.baseTask.type == "SELECTIONQA") {
                if (vm.baseTask.qaSelection.length < 12) {
                    alert("猜字题可选答案必须为12个字符");
                    vm.isSaving = false;
                    return;
                }
            }
            if (
                vm.baseTask.type == "SELECTIONQA" ||
                vm.baseTask.type == "NUMPWD"
            ) {
                var question = vm.baseTask.qaSelection;
                // console.log(question.replace(' ','').length);
                if (
                    vm.baseTask.type == "SELECTIONQA" &&
                    question.replace(" ", "").length < 12
                ) {
                    alert("猜字题可选答案内容不够12字，请检查是否有空格");
                    vm.isSaving = false;
                    return;
                }
                if (
                    vm.baseTask.answer1 != null &&
                    vm.baseTask.answer1.answer != null
                ) {
                    if (vm.baseTask.type == "SELECTIONQA") {
                        var answer1 = vm.baseTask.answer1.answer;
                        var b1 = canChoose(answer1);
                        if (!b1) {
                            vm.isSaving = false;
                            vm.baseTask.answer1.answer = null;
                            return;
                        }
                    }
                }
                if (
                    vm.baseTask.answer2 != null &&
                    vm.baseTask.answer2.answer != null
                ) {
                    var a = "" + vm.baseTask.answer1.answer;
                    var b = "" + vm.baseTask.answer2.answer;
                    if (a.length != b.length) {
                        if (b.length != 0) {
                            alert("答案长度必须一致");
                            vm.baseTask.answer2.answer = null;
                            vm.isSaving = false;
                            return;
                        }
                    }
                    if (vm.baseTask.type == "SELECTIONQA") {
                        var answer2 = vm.baseTask.answer2.answer;
                        var b2 = canChoose(answer2);
                        if (!b2) {
                            vm.isSaving = false;
                            vm.baseTask.answer2.answer = null;
                            return;
                        }
                    }
                }
                if (
                    vm.baseTask.answer3 != null &&
                    vm.baseTask.answer3.answer != null
                ) {
                    var c = "" + vm.baseTask.answer3.answer;
                    if (b.length != c.length) {
                        if (c.length != 0) {
                            alert("答案长度必须一致");
                            // vm.baseTask.answer2.answer = null;
                            vm.baseTask.answer3.answer = null;
                            vm.isSaving = false;
                            return;
                        }
                    }
                    if (vm.baseTask.type == "SELECTIONQA") {
                        var answer3 = vm.baseTask.answer3.answer;
                        var b3 = canChoose(answer3);
                        if (!b3) {
                            vm.isSaving = false;
                            vm.baseTask.answer3.answer = null;
                            return;
                        }
                    }
                }
                if (
                    vm.baseTask.answer4 != null &&
                    vm.baseTask.answer4.answer != null
                ) {
                    var d = "" + vm.baseTask.answer4.answer;
                    if (c.length != d.length) {
                        if (d.length != 0) {
                            alert("答案长度必须一致");
                            // vm.baseTask.answer2.answer = null;
                            // vm.baseTask.answer3.answer = null;
                            vm.baseTask.answer4.answer = null;
                            vm.isSaving = false;
                            return;
                        }
                    }
                    if (vm.baseTask.type == "SELECTIONQA") {
                        var answer4 = vm.baseTask.answer4.answer;
                        var b4 = canChoose(answer4);
                        if (!b4) {
                            vm.isSaving = false;
                            vm.baseTask.answer4.answer = null;
                            return;
                        }
                    }
                }
            }
            // if (vm.baseTask.type == 'NUMPWD'){
            //     vm.baseTask.answer = '{"qaList":'+ JSON.stringify([vm.baseTask.answer1])+'}';
            //     vm.baseTask.selection = '{"selections":' + JSON.stringify([vm.baseTask.selection1, vm.baseTask.selection2, vm.baseTask.selection3, vm.baseTask.selection4]) +'}';
            // }else {
            // }
            //找茬题答案数不能大于格子数
            if (vm.baseTask.type == "DIFF") {
                var lattice = vm.baseTask.latticeType;
                // console.log(lattice);
                if (
                    vm.baseTask.answer1 != null &&
                    vm.baseTask.answer1.answer != null
                ) {
                    if (vm.baseTask.answer1.answer > lattice * lattice) {
                        alert("答案数大于找茬格子数，请核查答案一");
                        vm.baseTask.answer1.answer = null;
                        vm.isSaving = false;
                        return;
                    }
                }
                if (
                    vm.baseTask.answer2 != null &&
                    vm.baseTask.answer2.answer != null
                ) {
                    if (vm.baseTask.answer2.answer > lattice * lattice) {
                        alert("答案数大于找茬格子数，请核查答案二");
                        vm.baseTask.answer2.answer = null;
                        vm.isSaving = false;
                        return;
                    }
                }
                if (
                    vm.baseTask.answer3 != null &&
                    vm.baseTask.answer3.answer != null
                ) {
                    if (vm.baseTask.answer3.answer > lattice * lattice) {
                        alert("答案数大于找茬格子数，请核查答案三");
                        vm.baseTask.answer3.answer = null;
                        vm.isSaving = false;
                        return;
                    }
                }
                if (
                    vm.baseTask.answer4 != null &&
                    vm.baseTask.answer4.answer != null
                ) {
                    if (vm.baseTask.answer4.answer > lattice * lattice) {
                        alert("答案数大于找茬格子数，请核查答案四");
                        vm.baseTask.answer4.answer = null;
                        vm.isSaving = false;
                        return;
                    }
                }
            }
            //选择题勾选框设置正确答案
            if (vm.baseTask.type == "SELECTION") {
                // console.log(vm.check1);
                if (
                    vm.check1 != true &&
                    vm.check2 != true &&
                    vm.check3 != true &&
                    vm.check4 != true
                ) {
                    alert("请至少设置一个选项为答案");
                    vm.isSaving = false;
                    return;
                }
                if (
                    typeof vm.baseTask.selection1 === "undefined" ||
                    vm.baseTask.selection1.length == 0 ||
                    typeof vm.baseTask.selection2 === "undefined" ||
                    vm.baseTask.selection2.length == 0 ||
                    typeof vm.baseTask.selection3 === "undefined" ||
                    vm.baseTask.selection3.length == 0 ||
                    typeof vm.baseTask.selection4 === "undefined" ||
                    vm.baseTask.selection4.length == 0
                ) {
                    alert("选择题选项不能为空");
                    vm.isSaving = false;
                    return;
                }
                SelectionSetAnswer();
            }
            //判断最高得分是否大于答案得分
            if (vm.baseTask.topScore != null) {
                var max = checkScore();
                if (vm.baseTask.topScore < max) {
                    alert("最高得分数不可小于答案得分，请重新设置");
                    vm.baseTask.topScore = null;
                    // vm.baseTask.topScore = max;
                    vm.isSaving = false;
                    return;
                }
            } else {
                alert("最高得分不能为空");
                vm.isSaving = false;
                return;
            }
            // console.log(vm.baseTask.topScore);

            if (
                vm.baseTask.answer1 != null &&
                typeof vm.baseTask.answer1 !== "undefined" &&
                vm.baseTask.answer1.answer != null &&
                vm.baseTask.answer1.answer != "" &&
                (typeof vm.baseTask.answer1.score === "undefined" ||
                    vm.baseTask.answer1.score == null)
            ) {
                alert("请设置任务答案一的得分。");
                vm.isSaving = false;
                return;
            }
            if (
                vm.baseTask.answer2 != null &&
                typeof vm.baseTask.answer2 !== "undefined" &&
                vm.baseTask.answer2.answer != null &&
                vm.baseTask.answer2.answer != "" &&
                (typeof vm.baseTask.answer2.score === "undefined" ||
                    vm.baseTask.answer2.score == null)
            ) {
                alert("请设置任务答案二的得分。");
                vm.isSaving = false;
                return;
            }
            if (
                vm.baseTask.answer3 != null &&
                typeof vm.baseTask.answer3 !== "undefined" &&
                vm.baseTask.answer3.answer != null &&
                vm.baseTask.answer3.answer != "" &&
                (typeof vm.baseTask.answer3.score === "undefined" ||
                    vm.baseTask.answer3.score == null)
            ) {
                alert("请设置任务答案三的得分。");
                vm.isSaving = false;
                return;
            }
            if (
                vm.baseTask.answer4 != null &&
                typeof vm.baseTask.answer4 !== "undefined" &&
                vm.baseTask.answer4.answer != null &&
                vm.baseTask.answer4.answer != "" &&
                (typeof vm.baseTask.answer4.score === "undefined" ||
                    vm.baseTask.answer4.score == null)
            ) {
                alert("请设置任务答案四的得分。");
                vm.isSaving = false;
                return;
            }

            if (vm.baseTask.myLocationTaskId) {
                if (vm.baseTask.myLocationTaskId.id)
                    vm.baseTask.myLocationTaskId =
                        vm.baseTask.myLocationTaskId.id;
            }

            vm.baseTask.answer =
                '{"qaList":' +
                JSON.stringify([
                    vm.baseTask.answer1,
                    vm.baseTask.answer2,
                    vm.baseTask.answer3,
                    vm.baseTask.answer4,
                ]) +
                "}";
            vm.baseTask.selection =
                '{"selections":' +
                JSON.stringify([
                    vm.baseTask.selection1,
                    vm.baseTask.selection2,
                    vm.baseTask.selection3,
                    vm.baseTask.selection4,
                ]) +
                "}";

            if (vm.isLimitTimes) {
                if (
                    vm.baseTask.isPreTask ||
                    vm.baseTask.end ||
                    vm.baseTask.canIgnore
                ) {
                    alert(
                        "所属点位为限次点位，故不可作为线索、结束任务、可跳过任务"
                    );
                    vm.isSaving = false;
                    return;
                }
            }

            if (vm.baseTask.type == "AR") {
                saveOldAr();
            } else if (vm.baseTask.type == "NEWAR") {
                // console.log(vm.armodeltype);
                if (
                    typeof vm.armodeltype === "undefined" ||
                    vm.armodeltype == null
                ) {
                    alert("请输入AR类型");
                    vm.isSaving = false;
                    return;
                }
                // console.log(vm.baseTask.laititude);
                // console.log(vm.baseTask.longtitude);
                if (vm.armodeltype == "bomb_lead") {
                    if (
                        typeof vm.baseTask.laititude === "undefined" ||
                        vm.baseTask.laititude == null ||
                        vm.baseTask.laititude == "" ||
                        typeof vm.baseTask.longtitude === "undefined" ||
                        vm.baseTask.longtitude == null ||
                        vm.baseTask.longtitude == ""
                    ) {
                        alert("炸弹引线拆除必须绑定经纬度");
                        vm.isSaving = false;
                        return;
                    }
                }
                if (vm.armodeltype == "phone" && isNaN(vm.phone)) {
                    alert("电话号码必须为数字");
                    vm.isSaving = false;
                    return;
                }
                if (
                    vm.armodeltype == "code_case" ||
                    vm.armodeltype == "treasure_box" ||
                    vm.armodeltype == "bomb_code"
                ) {
                    if (isNaN(vm.password)) {
                        alert("密码必须为数字");
                        vm.isSaving = false;
                        return;
                    }
                }
                if (
                    vm.armodeltype == "code_case" ||
                    vm.armodeltype == "treasure_box" ||
                    vm.armodeltype == "click_box"
                ) {
                    var reward = JSON.stringify(vm.reward);
                    if (reward.indexOf("$$hashKey") !== -1) {
                        alert("请输入奖赏类型");
                        vm.isSaving = false;
                        return;
                    }
                }
                saveNewAr();
            } else {
                vm.baseTask.scanResult = null;
                vm.baseTask.newArModelId = null;
                if (vm.baseTask.id !== null) {
                    BaseTask.update(vm.baseTask, onSaveSuccess, onSaveError);
                } else {
                    BaseTask.save(vm.baseTask, onSaveSuccess, onSaveError);
                }
            }
            // if (vm.baseTask.type==='AR'&&vm.baseTask.scanResult!=null&&vm.baseTask.newArModelId==null){
            //     saveOldAr();
            // }else if (vm.baseTask.type==='AR'){
            //     saveNewAr();
            // }else {
            //     vm.baseTask.scanResult = null;
            //     vm.baseTask.newArModelId = null;
            //     if (vm.baseTask.id !== null) {
            //         BaseTask.update(vm.baseTask, onSaveSuccess, onSaveError);
            //     } else {
            //         BaseTask.save(vm.baseTask, onSaveSuccess, onSaveError);
            //     }
            // }
            // if (vm.baseTask.id !== null) {
            //     BaseTask.update(vm.baseTask, onSaveSuccess, onSaveError);
            // } else {
            //     BaseTask.save(vm.baseTask, onSaveSuccess, onSaveError);
            // }
        }
        function saveOldAr() {
            if (vm.baseTask.scanResult == null) {
                alert("AR任务扫描模型不能为空！！");
                vm.isSaving = false;
                return;
            }
            if (vm.baseTask.scanImg == null) {
                alert("AR任务扫描图片不能为空！！");
                vm.isSaving = false;
                return;
            }
            if (vm.baseTask.scanResult) {
                if (vm.baseTask.scanResult.id) {
                    vm.baseTask.scanResult = vm.baseTask.scanResult.id;
                }
            } else {
                vm.baseTask.scanResult = null;
            }
            vm.baseTask.newArModelId = null;
            if (vm.baseTask.id !== null) {
                BaseTask.update(vm.baseTask, onSaveSuccess, onSaveError);
            } else {
                BaseTask.save(vm.baseTask, onSaveSuccess, onSaveError);
            }
        }

        function show() {
            //[高德地图] (https://lbs.amap.com/)
            GdMapModalService.showModal(vm.location).result.then(function (
                data
            ) {
                vm.baseTask.laititude = data.point.lat;
                vm.baseTask.longtitude = data.point.lng;
                // console.log(data);
                // console.log(vm.baseTask.laititude);
                // console.log(vm.baseTask.longtitude);
            });
        }

        function onSaveSuccess(result) {
            // console.log(result);
            $scope.$emit("taskServiceApp:baseTaskUpdate", result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.videoUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        function onFileSelect(uploadFile, name) {
            // console.log(uploadFile);
            //
            // console.log(name);

            var uploadImageFile = function (compressedBlob) {
                var fileType = compressedBlob.type;
                if (fileType != null && typeof fileType !== "undefined") {
                    fileType = fileType.split("/")[1];
                }
                var imgSize = compressedBlob.size / 1024;
                //上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
                // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                if (
                    fileType == "jpg" ||
                    fileType == "jpeg" ||
                    fileType == "png" ||
                    fileType == "bmp"
                ) {
                    // console.log(imgSize);
                    if (imgSize > imgLimit) {
                        alert("图片大小超过" + imgLimit + "kb");
                        return;
                    }
                    vm.type = "img";
                } else if (fileType == "mp3" || fileType == "mp4") {
                    if (vm.baseTask.type == "DIFF") {
                        alert("找茬题只能上传图片！！");
                        return;
                    }
                    if (imgSize > otherLimit) {
                        alert(
                            "音视频格式只能为MP3/MP4,且大小不能超过" +
                                otherLimit / 1000 +
                                "M"
                        );
                        return;
                    }
                    vm.type = "other";
                } else {
                    alert("文件格式不正确，请重新上传！！");
                    return;
                }

                Upload.upload({
                    url: "/api/postfile",
                    fields: {},
                    data: { type: "TASK" },
                    file: compressedBlob,
                    method: "POST",
                })
                    .progress(function (evt) {
                        var progressPercentage = parseInt(
                            (100.0 * evt.loaded) / evt.total
                        );
                        console.log("progress: " + progressPercentage + "% ");
                    })
                    .success(function (data, status, headers, config) {
                        //update the url
                        if (name == "img") {
                            // var file = data.file;
                            // var imgSize = compressedBlob.size/1024;
                            //上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
                            // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                            //     // console.log(imgSize);
                            //     if (imgSize>imgLimit){
                            //         alert("图片大小超过"+imgLimit+"kb");
                            //         return;
                            //     }
                            //     vm.type = 'img';
                            // }else if (/\.(mp3|mp4)$/.test(file)){
                            //     if (vm.baseTask.type=='DIFF'){
                            //         alert("找茬题只能上传图片！！");
                            //         return;
                            //     }
                            //     console.log(imgSize);
                            //     if (imgSize>otherLimit){
                            //         alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                            //         return;
                            //     }
                            //     vm.type = 'other';
                            // }else {
                            //     alert("文件格式不正确，请重新上传！！");
                            //     return;
                            // }
                            vm.baseTask.img = data.file;
                        } else if (name == "scanImg") {
                            vm.baseTask.scanImg = data.file;
                        }
                    })
                    .error(function (data, status, headers, config) {
                        console.log("error status: " + status);
                    });
            };

            //TODO gif no compress
            if (uploadFile != null) {
                if (
                    name === "bgAudio" ||
                    name === "img" ||
                    name === "scanImg"
                ) {
                    uploadImageFile(uploadFile);
                } else {
                }
            }
        }

        vm.onFileSelectAR = onFileSelectAR;
        //AR上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
        function checkPic(file, imgSize) {
            var isCanCheck = true;
            // console.log(file);
            if (/\.(jpg|jpeg|png|bmp)$/.test(file)) {
                // console.log(imgSize);
                if (imgSize > imgLimit) {
                    alert("图片大小超过" + imgLimit + "kb");
                    isCanCheck = false;
                }
            } else if (/\.(mp3|mp4)$/.test(file)) {
                if (imgSize > otherLimit) {
                    alert("音视频大小超过" + otherLimit + "kb");
                    isCanCheck = false;
                }
            } else if (/\.(zip)$/.test(file)) {
                if (imgSize > otherLimit) {
                    alert("obj文件大小超过" + otherLimit + "kb");
                }
            } else {
                alert("文件格式不正确，请重新上传！！");
                isCanCheck = false;
            }
            return isCanCheck;
        }
        function onFileSelectAR(uploadFile, name) {
            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
            function uploadImageFile(compressedBlob) {
                var file = compressedBlob.name;
                var imgSize = compressedBlob.size / 1024;
                var boolean = checkPic(file, imgSize);
                if (boolean) {
                    Upload.upload({
                        url: "/api/postfile",
                        fields: {},
                        data: { type: "AR_MODEL" },
                        file: compressedBlob,
                        method: "POST",
                    })
                        .progress(function (evt) {
                            var progressPercentage = parseInt(
                                (100.0 * evt.loaded) / evt.total
                            );
                            console.log(
                                "progress: " + progressPercentage + "% "
                            );
                        })
                        .success(function (data, status, headers, config) {
                            if (name == "img") {
                                vm.scan_image = data.file;
                            }
                            if (name == "scanImg") {
                                if (/\.(png)$/.test(data.file)) {
                                    alert("图片格式不可为png，请重新上传");
                                } else {
                                    vm.baseTask.scanImg = data.file;
                                }
                            } else if (name == "scrolls_image") {
                                vm.scrolls_image = data.file;
                            } else if (name == "appear_frame") {
                                vm.appear_frame = data.file;
                            } else if (name == "hit_frame") {
                                vm.hit_frame = data.file;
                            } else if (name == "idle_frame") {
                                vm.idle_frame = data.file;
                            } else if (name == "frame_config") {
                                vm.frame_config = data.file;
                            } else if (name == "death_frame") {
                                vm.death_frame = data.file;
                            } else if (name == "custom_r") {
                                if (/\.(jpg|jpeg|png|bmp)$/.test(data.file)) {
                                    vm.custom_rimg = data.file;
                                } else if (/\.(mp3)$/.test(data.file)) {
                                    vm.custom_rmp3 = data.file;
                                } else if (/\.(mp4)$/.test(data.file)) {
                                    vm.custom_rmp4 = data.file;
                                }
                            } else if (name == "obj_url") {
                                vm.obj_url = data.file;
                            } else if (name == "other") {
                                vm.other[0] = data.file;
                            } else if (name == "other2") {
                                vm.other[1] = data.file;
                            } else if (name == "audio") {
                                if (/\.(mp3)$/.test(data.file)) {
                                    vm.audio = data.file;
                                } else {
                                    alert("请上传mp3格式的音频文件");
                                }
                            }
                        })
                        .error(function (data, status, headers, config) {
                            console.log("error status: " + status);
                        });
                }
            }
        }
        vm.removeScanImg = removeScanImg;
        function removeScanImg() {
            if (vm.scan_image != null) {
                vm.scan_image = null;
                vm.scanStatus = null;
            }
        }
        vm.removeScrolls_image = removeScrolls_image;
        function removeScrolls_image() {
            if (vm.scrolls_image != null) {
                vm.scrolls_image = null;
            }
        }
        vm.removeAppear_frame = removeAppear_frame;
        function removeAppear_frame() {
            if (vm.appear_frame != null) {
                vm.appear_frame = null;
            }
        }
        vm.removeHit_frame = removeHit_frame;
        function removeHit_frame() {
            if (vm.hit_frame != null) {
                vm.hit_frame = null;
            }
        }
        vm.removeIdle_frame = removeIdle_frame;
        function removeIdle_frame() {
            if (vm.idle_frame != null) {
                vm.idle_frame = null;
            }
        }
        vm.removeFrame_config = removeFrame_config;
        function removeFrame_config() {
            if (vm.frame_config != null) {
                vm.frame_config = null;
            }
        }
        vm.removeDeath_frame = removeDeath_frame;
        function removeDeath_frame() {
            if (vm.death_frame != null) {
                vm.death_frame = null;
            }
        }
        vm.removeCustom_r = removeCustom_r;
        function removeCustom_r() {
            if (vm.custom_rimg != null) {
                vm.custom_rimg = null;
            } else if (vm.custom_rmp3 != null) {
                vm.custom_rmp3 = null;
            } else if (vm.custom_rmp4 != null) {
                vm.custom_rmp4 = null;
            }
        }
        vm.removeObj_url = removeObj_url;
        function removeObj_url() {
            if (vm.obj_url != null) {
                vm.obj_url = null;
            }
        }
        vm.removeOther = removeOther;
        function removeOther() {
            if (vm.other != null) {
                vm.other = null;
            }
        }
        vm.removeAudio = removeAudio;
        function removeAudio() {
            if (vm.audio != null) {
                vm.audio = null;
            }
        }
    }
})();
