(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("RegisterLoginController", RegisterLoginController);

    RegisterLoginController.$inject = [
        "$scope",
        "Auth",
        "$stateParams",
        "$http",
        "LoginUrlService",
    ];

    function RegisterLoginController(
        $scope,
        Auth,
        $stateParams,
        $http,
        LoginUrlService
    ) {
        var vm = this;
        vm.goEAccountPage = false;
        vm.parray = Array(
            "北京",
            "上海",
            "天津",
            "重庆",
            "河北",
            "山西",
            "内蒙古",
            "辽宁",
            "吉林",
            "黑龙江",
            "江苏",
            "浙江",
            "安徽",
            "福建",
            "江西",
            "山东",
            "河南",
            "湖北",
            "湖南",
            "广东",
            "广西",
            "海南",
            "四川",
            "贵州",
            "云南",
            "西藏",
            "陕西",
            "甘肃",
            "宁夏",
            "青海",
            "新疆",
            "香港",
            "澳门",
            "台湾"
        );

        vm.login = login;
        vm.id = $stateParams.id;
        vm.username = $stateParams.login;
        vm.password = $stateParams.pwd;
        vm.rememberMe = null;
        vm.nickName = null;
        vm.firstName = null;
        vm.city = "请选择";
        vm.email = null;
        vm.emailError = false;
        vm.checkEmail = checkEmail;
        //填写信息登录
        vm.infoLogin = infoLogin;
        function infoLogin() {
            if (vm.emailError) {
                //格式错误直接为空
                vm.email = null;
            }
            addInfo({ login: vm.username, email: vm.email, city: vm.city }); //nickName:vm.nickName,firstName: vm.firstName,
        }
        //跳过登录，为了防止当前用只是user，为了添加独立设计师
        vm.skip = skip;
        function skip() {
            addInfo({ login: vm.username }); //nickName:vm.nickName,firstName: vm.firstName,
        }
        //将填写的信息保存，
        function addInfo(data) {
            $http({
                url: "/api/user/add-info",
                method: "POST",
                data: data,
            })
                .success(function (res) {
                    login();
                })
                .error(function (res) {
                    console.log(res);
                    vm.emailError = 2; //'该有邮箱已被注册'
                });
        }

        function checkEmail() {
            vm.emailError = null;
            var format = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (!format.test(vm.email)) {
                vm.emailError = 1; //邮箱格式不对
            }
        }

        function login() {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
            })
                .then(function (data) {
                    getAccount();
                })
                .catch(function (err) {
                    vm.authenticationError = true;
                });
        }

        $scope.$on("$destroy", function () {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
        });

        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (account) {
                vm.account = account.data;
                if (vm.account == null) {
                    return;
                }
                LoginUrlService.login($stateParams.loginParam, vm.account);
            });
        }
        vm.openWeb = openWeb;
        function openWeb() {
            // window.location.href = window.location.protocol+"//"+window.location.host;
            if (window.location.hostname.startsWith("dev")) {
                window.location.href =
                    "http://dev.itasktour.com/index.php?go=ittrweb.index.index";
            } else {
                window.location.href = "https://www.itasktour.com";
            }
        }

        $scope.$on("$destroy", function () {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
        });
    }
})();
