(function () {
    "use strict";
    angular.module("taskServiceApp").factory("TripModel", TripModel);

    TripModel.$inject = ["$resource", "DateUtils"];

    function TripModel($resource, DateUtils) {
        var resourceUrl = "api/trip-models/:id";
        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }
})();
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("TripModelSearch", TripModelSearch);
    TripModelSearch.$inject = ["$resource"];
    function TripModelSearch($resource) {
        var resourceUrl = "api/_search/trip-models/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .factory("TripModelAdminSearch", TripModelAdminSearch);

    TripModelAdminSearch.$inject = ["$resource"];

    function TripModelAdminSearch($resource) {
        var resourceUrl = "api/_search/admin/trip-models/";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
