(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("ProgramDialogController", controller);
    controller.$inject = [
        "$scope",
        "$uibModalInstance",
        "entity",
        "$http",
        "ProgramTripModelSearch",
        "ProgramInfo",
        "$state",
    ];
    function controller(
        $scope,
        $uibModalInstance,
        entity,
        $http,
        ProgramTripModelSearch,
        ProgramInfo,
        $state
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.program = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.showCropped = true;
        vm.cropper = "default";

        // vm.label= [ '','']
        vm.labelModel = [
            "团队活动",
            "分工合作",
            "团队凝聚",
            "入职训练",
            "磨练意志",
            "团队对抗",
            "悠闲享乐",
            "趣味亲子",
            "执行力",
            "领导力",
            "历史人文",
            "红色文化",
            "奖励旅游",
            "家庭日",
        ];
        //如果需要改模版还要改接口类型
        vm.programTypeModel = [
            "基础版",
            "专业版",
            "开放式寻宝",
            "极速追踪",
            "极限寻宝",
            "极限潜航",
            "极限拆弹",
            "极限谍战",
            "其他业务",
        ];

        // vm.program.programType = '专业版';
        // vm.program.tripModel = {id:111,title:'1111'};
        // vm.isCorrelation = true
        if (vm.program.id != null) {
            vm.program.labels = JSON.parse(vm.program.label);
            if (vm.program.tripModel != null) {
                vm.program.tripModel = {
                    id: vm.program.tripModel.id,
                    title: vm.program.tripModel.title,
                };
            }
            var index = vm.programTypeModel.indexOf(vm.program.programType);
            if (index != -1 && index < 4) {
                vm.isCorrelation = true;
            }
        } else {
            var styleUrl =
                '<link rel="stylesheet"  type="text/css"  href="https://www.itasktour.com/bgm/css/textarea.css">';
            vm.program.programText = styleUrl;
            vm.program.manualText = styleUrl;
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        this.$onInit = function () {
            getAccount();
        };

        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }

                    if (false && vm.auth == "IGC") {
                        // getTripByTripModel();
                    }
                }
            });
        }

        vm.changeImg = changeImg;
        function changeImg() {
            vm.program.coverImg = null;
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        function removeCoppedImg() {
            if (vm.program.coverImg != null) {
                vm.program.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        // 删除ppt
        vm.deleteppt = deleteppt;
        function deleteppt(params) {
            // 这个地方要请求后端接口把后端存放的ppt文件删除；不然后端的垃圾会越来越多；
            if (params) {
                vm.program.programPPT = null;
            } else {
                vm.program.manualPPT = null;
            }
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file != null) {
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)) {
                    vm.type = "img";
                } else {
                    vm.type = "other";
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + ".png";
            // console.log(img);
            onFileSelect(img, "coverImg");
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI) {
            var binary = atob(dataURI.split(",")[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: "image/png" });
        }

        vm.removeCoppedImg = removeCoppedImg;

        function removeCoppedImg() {
            if (vm.trip.coverImg != null) {
                vm.trip.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect(uploadFile, name) {
            var uploadImageFile = function (compressedBlob) {
                var fileType = compressedBlob.type;
                var fileName = compressedBlob.name;
                if (fileType != null && typeof fileType !== "undefined") {
                    fileType = fileType.split("/")[1];
                }
                var imgSize = compressedBlob.size / 1024;
                //上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
                // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                if (name == "coverImg") {
                    if (
                        fileType == "jpg" ||
                        fileType == "jpeg" ||
                        fileType == "png" ||
                        fileType == "bmp"
                    ) {
                        // console.log(imgSize);
                        if (imgSize > 1024) {
                            alert("图片大小超过1M");
                            return;
                        }
                        vm.type = "img";
                    }
                } else if (name == "program" || name == "manual") {
                    console.log("文件类型", fileType);
                    if (fileType != "pdf") {
                        alert("文件格式不正确，请重新上传！！");
                        return;
                    }
                    if (imgSize > 20 * 1024) {
                        alert("PDF文件大小超20M");
                        return;
                    }
                    vm.uploadStatus = true;
                } else if (name == "program2" || name == "manual2") {
                    // console.log('文件类型2',fileName);
                    // fileName.text(/.*\.pptx?/g)
                    // !( == 'vnd.openxmlformats-officedocument.presentationml.presentation'||fileType =='vnd.ms-powerpoint')
                    // console.log('=========',fileName);
                    if (!/.*\.pptx?/g.test(fileName)) {
                        alert("文件格式不正确，请重新上传！！");
                        return;
                    }
                    if (imgSize > 20 * 1024) {
                        alert("PDF文件大小超20M");
                        return;
                    }
                    vm.uploadStatus = true;
                } else if ((name = "video")) {
                    if (fileType != "mp4") {
                        alert("文件格式不正确，请重新上传！！");
                        return;
                    }
                    vm.uploadVideoStatus = true;
                } else {
                    alert("文件格式不正确，请重新上传！！");
                    return;
                }
                // 上传oss
                $http({
                    method: "POST",
                    url: "/api/postFileNameAndToken",
                    data: {
                        type: "TRIP",
                        fileName: compressedBlob.name,
                    },
                })
                    .then(function (res) {
                        console.log("后台返回的数据", res);
                        var client = new OSS({
                            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                            region: "oss-cn-shanghai",
                            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                            accessKeyId: res.data.data.AccessKeyID,
                            accessKeySecret: res.data.data.AccessKeySecret,
                            // 从STS服务获取的安全令牌（SecurityToken）。
                            stsToken: res.data.data.SecurityToken,
                            // 填写Bucket名称。
                            bucket: "itasktour-muti",
                        });
                        // 第一个参数为上传的路径，第二个参数为上传的文件
                        client
                            .put(res.data.data.fileName, compressedBlob)
                            .then(function (res) {
                                console.log("方案编辑oss返回数据res=", res);
                                // console.log('name=',name);
                                //update the url
                                if (name == "coverImg") {
                                    vm.program.coverImg = "/" + res.name;
                                    $scope.myImage =
                                        vm.IMAGE_SERVER + vm.program.coverImg;
                                } else if (name == "program") {
                                    vm.program.programUrl = "/" + res.name;
                                    vm.uploadStatus = false;
                                    alert("文件上传成功");
                                } else if (name == "program2") {
                                    vm.program.programPPT = "/" + res.name;
                                    vm.uploadStatus = false;
                                    alert("文件上传成功");
                                } else if (name == "manual") {
                                    vm.program.manualUrl = "/" + res.name;
                                    vm.uploadStatus = false;
                                    alert("文件上传成功");
                                } else if (name == "manual2") {
                                    vm.program.manualPPT = "/" + res.name;
                                    vm.uploadStatus = false;
                                    alert("文件上传成功");
                                } else if ((name = "video")) {
                                    vm.program.videoUrl = "/" + res.name;
                                    vm.uploadVideoStatus = false;
                                }
                                // angular中会出现莫名其妙的不生效情况，需要调用一下该方法；
                                $scope.$apply();
                            })
                            .catch(function (err) {
                                console.log("error status: " + err);
                                if (name == "program" || name == "manual") {
                                    vm.uploadStatus = false;
                                }
                            });
                    })
                    .catch(function (err) {
                        alert("postFileNameAndToken接口调用失败");
                    });
            };
            //TODO gif no compress
            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
        }
        vm.deleteLabel = function () {
            this.program.labels = this.program.labels.splice(
                0,
                this.program.labels.length - 1
            );
        };

        vm.addLabel = function () {
            this.program.labels.push("");
        };

        vm.save = save;
        function save() {
            if (vm.program.correlation && vm.program.tripModel == null) {
                alert("请选择示范线路！");
                return;
            }
            if (vm.program.coverImg !== null) {
                if (/\.(jpg|jpeg|png|bmp)$/.test(vm.program.coverImg)) {
                    var image = document.getElementById("file");
                    if (image != null) {
                        var height = image.height;
                        var width = image.width;
                        var bi = width / height;
                        if (bi < 1.8 || bi > 2.2) {
                            // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                            alert("上传图片比例应为2:1");
                            if (vm.program.coverImg != null) {
                                vm.program.coverImg = null;
                            }
                            vm.isSaving = false;
                            return;
                        }
                    }
                }
            } else {
                alert("上传图片不能为空");
                return;
            }

            if (vm.program.labels) {
                for (var i = 0; i < vm.program.labels.length; i++) {
                    var label = vm.program.labels[i];
                    if (label.length == 0) {
                        alert("标签不能出现空字段");
                        return;
                    }
                }
                vm.program.label = JSON.stringify(vm.program.labels);
            }
            vm.program.programText = vm.descriptionUE1.getContent();
            if (
                vm.program.programUrl == null ||
                vm.program.programUrl.length == 0
            ) {
                alert("方案介绍PDF文件不能为空");
                return;
            }
            if (
                vm.program.programText == null ||
                vm.program.programText.length == 0
            ) {
                alert("执行手册不能为空");
                return;
            }
            vm.program.manualText = vm.descriptionUE2.getContent();
            if (
                vm.program.manualUrl == null ||
                vm.program.manualUrl.length == 0
            ) {
                alert("执行手册PDF文件不能为空");
                return;
            }
            if (
                vm.program.manualText == null ||
                vm.program.manualText.length == 0
            ) {
                alert("执行手册不能为空");
                return;
            }
            if (
                vm.program.programType == null ||
                vm.program.programType.length == 0
            ) {
                alert("方案类型必选项");
                return;
            }
            if (vm.isCorrelation && vm.program.correlation) {
                if (
                    vm.program.tripModel != null &&
                    vm.program.tripModel.id != null
                ) {
                    vm.program.tripModelId = vm.program.tripModel.id;
                } else if (vm.program.tripModel != null) {
                    vm.program.tripModelId = vm.program.tripModel;
                } else {
                    alert("关联示范线路不能为空");
                    return;
                }
            } else {
                vm.program.tripModelId = null;
            }

            console.log(vm.program);
            ProgramInfo.update(vm.program, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            $state.go("program", null, { reload: "program" });
        }

        function onSaveError(result) {}

        var URL_ROOT =
            window.location.protocol +
            "//" +
            window.location.hostname +
            ":" +
            window.location.port +
            "/";
        vm.ueditorConfig = {
            serverUrl: URL_ROOT + "ueditor/controller",
            toolbars: [["paragraph", "bold", "simpleupload"]],
            paragraph: {
                p: "",
                h1: "",
            },
            elementPathEnabled: false,
            allowDivTransToP: false,
            enableContextMenu: false,
            enableAutoSave: false,
            zIndex: 11000,
            catchRemoteImageEnable: false,
            xssFilterRules: false, //input xss过滤
            inputXssFilter: false, //output xss过滤
            outputXssFilter: false,
            autoHeightEnabled: false,
            lang: "zh-cn",
            initialFrameWidth: 570,
        };

        setTimeout(function () {
            UE.delEditor("field_programText");
            vm.descriptionUE1 = UE.getEditor(
                "field_programText",
                vm.ueditorConfig
            );

            UE.delEditor("field_manualText");
            vm.descriptionUE2 = UE.getEditor(
                "field_manualText",
                vm.ueditorConfig
            );
        }, 100);

        vm.filterTripModels = this.filterTripModels;
        this.filterTripModels = function (search) {
            console.log(search);
            var type = vm.programTypeModel.indexOf(vm.program.programType);
            var platform = 0;
            switch (type) {
                case 0:
                    platform = 1;
                    break;
                case 2:
                    platform = 3;
                    break;
                case 3:
                    platform = 4;
                    break;
            }
            if (search) {
                vm.tripModels = ProgramTripModelSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                    release: "0",
                    platform: platform,
                });
            }
        };

        vm.changeProgramType = function () {
            // vm.program.myTripModel = []
            vm.program.tripModel = null;
            vm.program.tripModelId = null;
            vm.tripModels = null;
            if (
                vm.program.programType == null ||
                vm.program.programType == ""
            ) {
                vm.isCorrelation = false;
                return;
            }
            var index = vm.programTypeModel.indexOf(vm.program.programType);
            if (index != -1 && index < 4) {
                vm.isCorrelation = true;
                return;
            }
            vm.isCorrelation = false;
        };

        vm.uploadWanning = function () {
            alert("有文件正在上传请稍等");
        };

        vm.deleteVideo = function () {
            vm.program.videoUrl = null;
        };
    }
})();
