(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("MyEAccountDetailController", controller);
    controller.$inject = [
        "MyEAccountFreezeRecords",
        "Upload",
        "$cookies",
        "$state",
        "AlertService",
        "entity",
        "MyEAccount",
        "MyEAccountRecords",
        "$http",
        "$stateParams",
    ];
    function controller(
        MyEAccountFreezeRecords,
        Upload,
        $cookies,
        $state,
        AlertService,
        entity,
        MyEAccount,
        MyEAccountRecords,
        $http,
        $stateParams
    ) {
        var vm = this;
        vm.eAccount = entity;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.detailOrFreeze = true; //true展示企业账户点券消费详情  false展示企业冻结点券详情
        vm.itemsPerPage = 10;
        vm.page = $stateParams.page;
        vm.account = null;
        vm.eAccountId = null;
        vm.transition = transition;
        function transition(params) {
            if (params !== undefined) {
                vm.detailOrFreeze = params;
                vm.page = 1;
            }
            if (vm.detailOrFreeze) {
                MyEAccountRecords.query(
                    {
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                    },
                    onSuccess,
                    onError
                );
            } else {
                MyEAccountFreezeRecords.query(
                    {
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                    },
                    onSuccess,
                    onError
                );
            }
            function onSuccess(data, headers) {
                vm.totalItems = headers("X-Total-Count");
                vm.eAccountRecords = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        vm.transition();
        //如果是新加入企业会有一个提示
        var joinSuccess = $cookies.get("joinSuccess");
        if (joinSuccess) {
            $cookies.remove("joinSuccess");
            $message.success({
                text: "加入成功！",
            });
            setTimeout(function () {
                checkEaccountFreeze();
            }, 3000);
        } else {
            checkEaccountFreeze();
        }
        //检查企业是否冻结
        function checkEaccountFreeze() {
            // rabbitActivationState
            // 0未激活 1已激活 2等待管理员激活（冻结）
            // 3停运（魔法特工专属）
            // 4失效（魔法特工专属）
            if (
                vm.eAccount.rabbitActivationState != null &&
                vm.eAccount.rabbitActivationState != 1 &&
                vm.eAccount.rabbitActivationState != 3 &&
                vm.eAccount.rabbitActivationState != 4
            ) {
                $("#eaccountFreeze").modal("show");
            }
        }
        vm.freeze = function () {
            $("#eaccountFreeze").modal("hide");
            $state.go("e-account-my.freeze", {}, { reload: true });
        };

        //已申请过企业认证的企业加入别人的企业
        vm.backOurEAccountJoin = function () {
            $http({
                url:
                    "api/e-account/back-out/join?eaccountId=" +
                    $stateParams.eid,
                method: "POST",
            }).then(function (res) {
                if (res.data.errorCode === 0) {
                    onSaveSuccessJoin();
                } else {
                    alert(res.data.message);
                }
            });
        };
        // 用户存在待审核企业的情况下，点击不加入（拒绝企业申请）别人的企业的按钮跳转到线路列表页
        vm.noJoinEAccount = function () {
            $(".modal-backdrop").remove();
            $state.go("trip-model", {}, { reload: true });
        };
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var type = -1;
                    if (type != -1) {
                        $http({
                            url: "api/tip/change-status/" + type,
                            method: "POST",
                        }).then(function (res) {});
                    }
                    // 判断用户是否是被邀请加入新的企业
                    if ($stateParams.eid && $stateParams.uid) {
                        if (!vm.account.eAccountId) {
                            // 1、用户没有企业id说明用户当前未加入任何企业，且企业认证的状态不是申请中
                            // 2、用户从来没有加入企业，用户申请企业认证后，在未被处理申请之前主动撤销申请的
                            MyEAccount.addDegigner(
                                {
                                    eid: $stateParams.eid,
                                    login: vm.account.login,
                                    isTrainee: 0,
                                },
                                onSaveSuccessJoin,
                                onSaveErrorJoin
                            );
                        } else {
                            // 用户当前未成功加入任何企业，但是用户申请过企业认证并被拒绝，
                            if (vm.eAccount.status == "NOT_ACTIVE") {
                                if (vm.eAccount.notPassReason) {
                                    // 申请企业认证被拒绝后被邀请加入别人的企业
                                    vm.backOurEAccountJoin();
                                } else {
                                    // 申请企业认证的过程中被邀请加入别人的企业
                                    // 提示用户是否取消之前的企业认证申请
                                    $("#auditJoinOther").modal("show");
                                }
                            } else if (vm.eAccount.status == "ACTIVATED") {
                                // 当前用户已经有企业，且企业状态为正常状态
                                if (vm.eAccount.id == $stateParams.eid) {
                                    alert("您已加入该企业，请勿重复操作。");
                                } else {
                                    alert("加入失败！您已有所属企业。");
                                }
                                vm.transition(true);
                            }
                        }
                    }
                }
            });
        }

        function onSaveSuccessJoin() {
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000); // cookies10分钟有效期
            $cookies.put("joinSuccess", true, {
                expires: new Date(expireDate),
            });
            location.reload(); //当前页面刷新
        }
        function onSaveErrorJoin() {
            $message.warning({
                text: "加入企业失败",
            });
        }
        vm.changeEAccountAvatar = function (uploadFile) {
            if (uploadFile != null) {
                uploadHelp(uploadFile);
            }
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: "/api/postfile",
                    fields: {},
                    data: { type: "EACCOUNT" },
                    file: uploadFile,
                    method: "POST",
                })
                    .progress(function (evt) {
                        var progressPercentage = parseInt(
                            (100.0 * evt.loaded) / evt.total
                        );
                        console.log("progress: " + progressPercentage + "% ");
                    })
                    .success(function (data, status, headers, config) {
                        // console.log(data);
                        vm.eAccount.web = data.file;
                        editLogo();
                    })
                    .error(function (data, status, headers, config) {
                        console.log("error status: " + status);
                    });
            }
        };
        function editLogo() {
            if (vm.eAccount.web != null) {
                $http({
                    method: "POST",
                    url:
                        "/api/e-accounts/edit-logo/" +
                        vm.eAccount.id +
                        "?logo=" +
                        vm.eAccount.web,
                }).then(
                    function (res) {},
                    function (err) {
                        console.log(err);
                    }
                );
            }
        }
    }
})();
