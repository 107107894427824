(function () {
    "use strict";
    angular.module("taskServiceApp").directive("pageRibbon", pageRibbon);
    pageRibbon.$inject = ["ProfileService", "$rootScope", "$translate"];
    function pageRibbon(ProfileService, $rootScope, $translate) {
        var directive = {
            replace: true,
            restrict: "AE",
            template:
                '<div class="ribbon hidden"><a href="">Development</a></div>',
            link: linkFunc,
        };
        return directive;
        function linkFunc(scope, element, attrs) {
            ProfileService.getProfileInfo().then(function (response) {
                if (response.ribbonEnv) {
                    scope.ribbonEnv = response.ribbonEnv;
                    element.addClass(response.ribbonEnv);
                    element.removeClass("hidden");
                } else {
                    // 本地环境和测试服环境添加dev标识
                    if (
                        window.location.origin.indexOf("localhost") > -1 ||
                        window.location.hostname.split(".").length === 4 ||
                        window.location.origin.indexOf("dev") !== -1
                    ) {
                        scope.ribbonEnv = "dev";
                        element.addClass("dev");
                        element.removeClass("hidden");
                    }
                }
            });
        }
    }
})();
