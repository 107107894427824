(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TripModelCopyController", controller);

    controller.$inject = [
        "$uibModalInstance",
        "entity",
        "$http",
        "$state",
        "$stateParams",
        "$uibModalStack",
    ];

    function controller(
        $uibModalInstance,
        entity,
        $http,
        $state,
        $stateParams,
        $uibModalStack
    ) {
        var vm = this;
        if (entity.id == null || !entity.canShow) {
            setTimeout(function () {
                alert("无访问权限");
                clear();
            }, 100);
            return;
        }
        vm.tripModel = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;
        vm.cannotOpt = false;
        // console.log($stateParams)
        if ($stateParams.upgrade != null) {
            vm.tripModel.upgrade = $stateParams.upgrade;
        } else {
            vm.tripModel.upgrade = false;
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
            $uibModalStack.dismissAll();
        }

        function confirmCopy(tripModel) {
            vm.cannotOpt = true;
            vm.clear();
            $http({
                method: "GET",
                url:
                    "/api/trip-models/copy/" +
                    tripModel.id +
                    "/" +
                    tripModel.upgrade,
            }).then(
                function (res) {
                    // console.log(res);
                    if (res != null && res.data != null) {
                        if (
                            res.data.hasLocationTasks != null &&
                            typeof res.data.hasLocationTasks != "undefined"
                        ) {
                            for (var i in res.data.hasLocationTasks) {
                                // console.log(res.data.hasLocationTasks[i]);
                                if (
                                    res.data.hasLocationTasks[i].hasTasks !=
                                        null &&
                                    typeof res.data.hasLocationTasks[i]
                                        .hasTasks != "undefined"
                                ) {
                                    for (var j in res.data.hasLocationTasks[i]
                                        .hasTasks) {
                                        // console.log(res.data.hasLocationTasks[i].hasTasks[j]);
                                        if (
                                            res.data.hasLocationTasks[i]
                                                .hasTasks[j].type != null &&
                                            typeof res.data.hasLocationTasks[i]
                                                .hasTasks[j].type !=
                                                "undefined" &&
                                            res.data.hasLocationTasks[i]
                                                .hasTasks[j].type == "NEWAR"
                                        ) {
                                            alert(
                                                "复制线路中含有新版AR，后台正在识别新版AR的扫描图片，请稍后再次编辑新版AR任务查看识别情况"
                                            );
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    $state.go("trip-model", null, { reload: true });
                    $uibModalInstance.dismiss("cancel");
                },
                function (err) {
                    console.log(err);
                }
            );
        }
    }
})();
