(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller(
            "LocationTaskDetailController",
            LocationTaskDetailController
        );

    LocationTaskDetailController.$inject = [
        "$scope",
        "$http",
        "$rootScope",
        "Principal",
        "$stateParams",
        "previousState",
        "entity",
        "LocationTask",
        "BaseTask",
        "Location",
        "Trip",
    ];

    function LocationTaskDetailController(
        $scope,
        $http,
        $rootScope,
        Principal,
        $stateParams,
        previousState,
        entity,
        LocationTask,
        BaseTask,
        Location,
        Trip
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.locationTask = entity;
        vm.previousState = previousState.name;

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }
        // if (vm.locationTask.canEdit==false){
        //     $("#locationTaskEdit").attr("disabled", true);
        //     // $("#locationTaskDelete").attr("disabled", true);
        // }

        getTripModel();
        function getTripModel() {
            $http({
                method: "GET",
                url: "/api/trip-models/" + vm.locationTask.myTripModelId,
            }).then(
                function (res) {
                    vm.tripModel = res.data;
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:locationTaskUpdate",
            function (event, result) {
                vm.locationTask = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
