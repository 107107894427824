(function () {
    "use strict";

    angular.module("taskServiceApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("equip", {
                parent: "entity",
                url: "/equip?page&sort&search",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.equip.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/equip/equips.html",
                        controller: "EquipController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("equip");
                            $translatePartialLoader.addPart("mediaType");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("equip-detail", {
                parent: "entity",
                url: "/equip/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.equip.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/equip/equip-detail.html",
                        controller: "EquipDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("equip");
                            $translatePartialLoader.addPart("mediaType");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "Equip",
                        function ($stateParams, Equip) {
                            return Equip.get({ id: $stateParams.id }).$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "equip",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("equip-detail.edit", {
                parent: "equip-detail",
                url: "/detail/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/equip/equip-dialog.html",
                                controller: "EquipDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Equip",
                                        function (Equip) {
                                            return Equip.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("^", {}, { reload: false });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("equip.new", {
                parent: "equip",
                url: "/new",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/equip/equip-dialog.html",
                                controller: "EquipDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: function () {
                                        return {
                                            name: null,
                                            description: null,
                                            coverImg: null,
                                            type: null,
                                            content: null,
                                            curl: null,
                                            createDate: null,
                                            createBy: null,
                                            updateDate: null,
                                            updateBy: null,
                                            id: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("program-center", null, {
                                        reload: true,
                                    });
                                },
                                function () {
                                    $state.go("program-center");
                                }
                            );
                    },
                ],
            })
            .state("equip.edit", {
                parent: "equip",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/equip/equip-dialog.html",
                                controller: "EquipDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Equip",
                                        function (Equip) {
                                            return Equip.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("equip", null, {
                                        reload: "equip",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("equip.delete", {
                parent: "equip",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/equip/equip-delete-dialog.html",
                                controller: "EquipDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Equip",
                                        function (Equip) {
                                            return Equip.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("equip", null, {
                                        reload: "equip",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            });
    }
})();
