(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("WxGameTripsController", controller);

    controller.$inject = [
        "$scope",
        "$state",
        "$http",
        "weChatTripSearch",
        "BombTripSearch",
        "StealthTripSearch",
        "$sce",
        "getAvailableCouponsByEId",
        "TripGame",
        "$stateParams",
    ];

    function controller(
        $scope,
        $state,
        $http,
        weChatTripSearch,
        BombTripSearch,
        StealthTripSearch,
        $sce,
        getAvailableCouponsByEId,
        TripGame,
        $stateParams
    ) {
        console.log("$stateParams=====", $stateParams);
        var vm = this;
        vm.category = $stateParams.category; //列表活动类型 2极限潜航  3极限拆弹 4极限谍战
        vm.gameTitle = ["极限寻宝", "极限潜航", "极限拆弹", "极限谍战"][
            Number(vm.category) - 1
        ];
        vm.wxGameTrips = [];
        vm.itemsPerPage = 20;
        vm.page = $stateParams.page;
        vm.predicate = "id";
        vm.reverse = "desc";
        vm.sort = $stateParams.sort;
        vm.searchQuery = $stateParams.search;
        vm.status = $stateParams.status;
        vm.statusArray = ["所有状态", "未开始", "已结束", "进行中"];
        vm.tripId = null;
        vm.title = null;
        vm.startCode = null;
        vm.maxTeamCount = 5;
        vm.mailMaxPeople = null;
        vm.payCoupon = null;
        vm.errText = null;
        vm.paramTitleError = null;
        vm.paramCodeError = null;
        vm.isSaving = false;
        vm.time = "2098-12-30T16:00:00.000Z";
        vm.couponIds = []; //扣点的优惠券列表
        vm.differenceCoupon = null;
        vm.addTeamCount = 0;
        vm.testPaperList = []; //极限拆弹任务方案
        vm.testPaperCode = "";
        if (vm.category === "3") {
            $http({
                method: "POST",
                url: "/api/bomb/testPaperList",
            }).then(
                function (res) {
                    vm.testPaperList = res.data;
                    if (
                        vm.testPaperList != null &&
                        vm.testPaperList.length > 0
                    ) {
                        vm.testPaperCode = vm.testPaperList[0].code;
                    }
                },
                function (err) {}
            );
        }
        $scope.htmlTooltip1 = $sce.trustAsHtml(
            '<p style="text-align: left"><br>玩家在进入游戏时输入预设的口令即可开始游戏，活动无需设置日期，灵活控制，随时开启。</p>'
        );
        $scope.htmlTooltip2 = $sce.trustAsHtml(
            '<p style="text-align: left"><br>任何玩家开始游戏后，整场活动即自动置为开始状态。\n' +
                "活动在开始后5小时内有效，期间玩家可自由加入。\n" +
                "过期后活动失效，玩家无法加入或重新开局，正在进行的对战仍可继续。</p>"
        );
        $scope.htmlTooltip3 = $sce.trustAsHtml(
            '<p style="text-align: left"><br>即活动内包含的战场数。每个战场可容纳4人（2v2对战），每局对战结束后战场将重置（玩家被清出），玩家须重新选择战场加入。</p>'
        );
        $scope.htmlTooltip4 = $sce.trustAsHtml(
            '<p style="text-align: left"><br>即活动内包含的战场数。每个战场可容纳8人（4v4对战），每局对战结束后战场将重置（玩家被清出），玩家须重新选择战场加入。</p>'
        );
        $scope.htmlTooltip5 = $sce.trustAsHtml(
            '<p style="text-align: left"><br>任何玩家开始游戏后，整场活动即自动置为开始状态。\n' +
                "活动在第一组开始后的24小时内有效，期间玩家可自由加入。\n" +
                "过期后活动失效，玩家无法加入或重新开局，正在进行的对战仍可继续。</p>"
        );
        $scope.htmlTooltip6 = $sce.trustAsHtml(
            '<p style="text-align: left"><br>整场活动不限制人数和组队数，可根据活动需求自由安排组队。</p>'
        );
        vm.transition = function (params) {
            if (params === 1 || params === 2 || params === 4) {
                vm.page = 1;
            }
            if (params === 2) {
                vm.sort = vm.predicate + "," + (vm.reverse ? "desc" : "asc");
            }
            if (vm.category === "2") {
                StealthTripSearch.query(
                    {
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                        query: vm.searchQuery,
                        sort: vm.sort,
                        status: vm.status,
                    },
                    onSuccess,
                    onError
                );
            } else if (vm.category === "3") {
                BombTripSearch.query(
                    {
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                        sort: vm.sort,
                        status: vm.status,
                        query: vm.searchQuery,
                    },
                    onSuccess,
                    onError
                );
            } else if (vm.category === "4") {
                weChatTripSearch.query(
                    {
                        attribute: "SPY",
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                        query: vm.searchQuery,
                        sort: vm.sort,
                        status: vm.status,
                    },
                    onSuccess,
                    onError
                );
            }

            function onSuccess(data, headers) {
                vm.totalItems = headers("X-Total-Count");
                vm.wxGameTrips = data;
            }
            function onError(error) {
                $message.error({
                    text: error.data.message,
                });
            }
        };
        vm.transition();
        vm.keyDown = function (event) {
            if (event.keyCode == 13) {
                vm.transition(1);
            }
        };
        //方案中心详情页跳转过来打开微信游戏发布窗口
        if ($stateParams.return_scheme_id) {
            newGameTrip();
        }
        vm.newGameTrip = newGameTrip;
        function newGameTrip() {
            vm.tripId = null;
            vm.title = null;
            vm.startCode = null;
            if (vm.category === "4") {
                vm.maxTeamCount = 5;
                vm.payCoupon = vm.maxTeamCount * 4;
            } else if (vm.category === "3") {
                vm.payCoupon = 10;
                vm.mailMaxPeople = null;
            } else if (vm.category === "2") {
                vm.maxTeamCount = 5;
                vm.payCoupon = vm.maxTeamCount * 8;
            }
            vm.errText = null;
            vm.paramTitleError = null;
            vm.paramCodeError = null;
            vm.mailStartTime = null;
            vm.mailEndTime = null;
            vm.couponIds = [];
            $("#myModal1").modal("show");
        }
        vm.editTrip = function (tripId) {
            vm.tripId = tripId;
            TripGame.get({ id: tripId }, function (res) {
                if (res != null) {
                    vm.tripId = res.id;
                    vm.startCode = res.startCode;
                    vm.title = res.title;
                    if (vm.category === "2" || vm.category === "4") {
                        vm.maxTeamCount = res.maxTeamcount;
                    } else if (vm.category === "3") {
                        vm.testPaperCode = res.testPaperCode;
                        vm.mailMaxPeople = res.mailMaxPeople;
                    }
                    vm.mailStartTime = res.mailStartTime;
                    vm.mailEndTime = res.mailEndTime;
                    vm.addTeamCount = 0;
                    vm.errText = null;
                    vm.paramTitleError = null;
                    vm.paramCodeError = null;
                    vm.couponIds = [];
                    $("#myModal1").modal("show");
                }
            });
        };
        vm.closeClear = function () {
            // 方案中心的详情页跳转过来的用户不发布活动的情况下跳转回方案中心详情页；
            if ($stateParams.return_scheme_id) {
                $(".modal-backdrop").remove();
                $("#myModal2").modal("hide");
                $("#myModal1").modal("hide");
                $state.go(
                    "program-center-detail",
                    { id: $stateParams.return_scheme_id },
                    { reload: true }
                );
                return false;
            }
            vm.transition(3);
            $(".modal-backdrop").remove();
            $("#myModal2").modal("hide");
            $("#myModal1").modal("hide");
            vm.isSaving = false;
        };
        vm.tripQrCode = function (id, title, category) {
            $state.go(
                "wx-game-qr-code",
                { id: id, title: title, category: category },
                { reload: false }
            );
        };
        vm.onInputChange = function () {
            if (vm.maxTeamCount != null) {
                vm.maxTeamCount == (vm.maxTeamCount + "").replace(/\D/g, "");
                if (isNaN(vm.maxTeamCount) || Number(vm.maxTeamCount) <= 0) {
                    vm.maxTeamCount = "";
                } else {
                    vm.maxTeamCount = Number(vm.maxTeamCount);
                    if (vm.category === "4") {
                        vm.payCoupon = vm.maxTeamCount * 4;
                    } else if (vm.category === "2") {
                        vm.payCoupon = vm.maxTeamCount * 8;
                    }
                }
            }
        };
        vm.toPublish = function () {
            if (vm.title == null || vm.title === "") {
                vm.paramTitleError = "请填写活动名称";
                return false;
            }
            console.log("vm.startCode===", vm.startCode);
            if (vm.startCode == null || vm.startCode === "") {
                vm.paramCodeError = "请填写开启口令";
                return false;
            }

            if (vm.category === "2" || vm.category === "4") {
                if (vm.maxTeamCount == null || vm.maxTeamCount === "") {
                    vm.paramCountError = "请填写战场数";
                    return false;
                }
                if (vm.tripId != null && vm.addTeamCount < 0) {
                    vm.paramAddCountError = "请正确填写增加战场数";
                    return false;
                }
            }
            if (vm.mailStartTime == null || vm.mailStartTime === "") {
                $message.error({
                    text: "预计开始时间不能为空！",
                });
                return false;
            }
            if (vm.category === "3") {
                if (vm.mailEndTime == null || vm.mailEndTime === "") {
                    $message.error({
                        text: "预计结束时间不能为空！",
                    });
                    return false;
                }
                if (vm.mailMaxPeople == null || vm.mailMaxPeople == "") {
                    $message.error({
                        text: "活动预估人数不能为空！",
                    });
                    vm.isSaving = false;
                    return;
                }
            }
            vm.paramTitleError = null;
            vm.paramCodeError = null;
            if (vm.tripId != null && vm.addTeamCount == 0) {
                // 更改战场数之外的数据不用重新拉取扣点详情
                save();
            } else {
                $("#myModal1").modal("hide");
                if (vm.addTeamCount > 0) {
                    // 推测这个地方把增加战队数赋值给最大战队数的原因是这个“vm.maxTeamcount”词不达意，这个“vm.maxTeamcount”其实是在后台有多重含义，在某种情况下就是增添战队数
                    vm.maxTeamCount = vm.addTeamCount;
                }
                if (vm.category === "4") {
                    vm.payCoupon = vm.maxTeamCount * 4;
                } else if (vm.category === "2") {
                    vm.payCoupon = vm.maxTeamCount * 8;
                }
                getCoupons();
                $("#myModal2").modal("show");
            }
        };
        function getCoupons() {
            //独立设计师不请求钱
            if (vm.auth == "IGC") {
                return false;
            }
            vm.isSaving = false;
            getAvailableCouponsByEId.getCouponsByEId(vm.payCoupon).then(
                function (res) {
                    console.log(res.data);
                    if (res.data.availableCoupons != null) {
                        vm.availableCoupons = res.data.availableCoupons;
                        vm.unavailableCoupons = res.data.unavailableCoupons;
                    } else {
                        if (res.data.differenceCoupons != null) {
                            var differenceCoupons = res.data.differenceCoupons;
                            toReCharge(differenceCoupons[0].availableCoupon);
                        } else {
                            toReCharge();
                        }
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }
        function toReCharge(difference) {
            if (difference != null) {
                vm.toReCharge = true;
                vm.showPublishTitle = false;
                vm.differenceCoupon = difference;
            } else {
                vm.mContent = "发布活动所需点数不足，请先前往充值。";
            }
            vm.mText = null;
            $(".showOrHide").css("display", "none");
        }
        function transformTime(time) {
            return (
                time.getFullYear() +
                "-" +
                ("0" + (time.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + time.getDate()).slice(-2) +
                " " +
                ("0" + time.getHours()).slice(-2) +
                ":" +
                ("0" + time.getMinutes()).slice(-2) +
                ":00"
            );
        }
        function save() {
            vm.isSaving = true;
            var mailStartTime = vm.mailStartTime;
            mailStartTime = transformTime(mailStartTime);
            var mailEndTime = vm.mailStartTime;
            var mailEndTime_t = mailEndTime.getTime();
            // 微信游戏每场游戏为5个小时
            mailEndTime.setTime(mailEndTime_t + 1000 * 60 * 60 * 5);
            mailEndTime = transformTime(mailEndTime);
            var url = "";
            if (vm.category === "2" || vm.category === "4") {
                url = "/api/stealth/trip?maxTeamCount=" + vm.addTeamCount;
            } else if (vm.category === "3") {
                url =
                    "/api/bomb/trip?testPaperCode=" +
                    vm.testPaperCode +
                    "&mailMaxPeople=" +
                    vm.mailMaxPeople;
            }
            url +=
                "&startCode=" +
                vm.startCode +
                "&title=" +
                vm.title +
                "&tripId=" +
                vm.tripId +
                "&mailStartTime=" +
                mailStartTime +
                "&mailEndTime=" +
                mailEndTime;
            $http({
                method: "POST",
                url: url,
            })
                .success(function () {
                    vm.isSaving = false;
                    $("#myModal1").modal("hide");
                    $(".modal-backdrop").remove();
                    vm.transition(3);
                    vm.isSaving = false;
                    $message.success({
                        text: "活动内容修改成功！",
                    });
                })
                .error(function () {
                    vm.isSaving = false;
                    $message.error({
                        text: "活动内容修改失败！",
                    });
                });
        }
        vm.publishTrip = function () {
            vm.isSaving = true;
            if (vm.availableCoupons.length <= 0) {
                vm.isSaving = false;
                toReCharge(vm.payCoupon);
            } else {
                var mailStartTime = vm.mailStartTime;
                mailStartTime = transformTime(mailStartTime);
                var type = "";
                var mailEndTime = null;
                if (vm.category === "4" || vm.category === "2") {
                    mailEndTime = vm.mailStartTime;
                    var mailEndTime_t = mailEndTime.getTime();
                    // 微信游戏每场游戏为5个小时
                    mailEndTime.setTime(mailEndTime_t + 1000 * 60 * 60 * 5);
                    mailEndTime = transformTime(mailEndTime);
                    type =
                        (vm.category === "4" ? "SPY" : "STEALTH") +
                        "&maxTeamCount=" +
                        vm.maxTeamCount;
                } else if (vm.category === "3") {
                    mailEndTime = transformTime(vm.mailEndTime);
                    type =
                        "BOMB" +
                        "&mailMaxPeople=" +
                        vm.mailMaxPeople +
                        "&testPaperCode=" +
                        vm.testPaperCode;
                }
                var url =
                    "/api/trip-game/publish?title=" +
                    vm.title +
                    "&startCode=" +
                    vm.startCode +
                    "&type=" +
                    type +
                    "&mailStartTime=" +
                    mailStartTime +
                    "&mailEndTime=" +
                    mailEndTime +
                    "&couponIds=" +
                    vm.couponIds;
                if (vm.tripId != null) {
                    url = url + "&tripId=" + vm.tripId;
                }
                $http({
                    method: "POST",
                    url: url,
                })
                    .success(function () {
                        $(".modal-backdrop").remove();
                        $("#myModal2").modal("hide");
                        var message = "";
                        if (vm.tripId !== null) {
                            vm.transition(3);
                            message = "活动增加战队数成功！";
                        } else {
                            vm.transition(1);
                            message = "活动发布成功！";
                        }
                        vm.isSaving = false;
                        $message.success({
                            text: message,
                        });
                    })
                    .error(function (err) {
                        vm.isSaving = false;
                        if (err != null && err.errorCode == -2) {
                            selectedCouponsIsNotnough();
                        } else {
                            var message =
                                "活动" +
                                (vm.tripId !== null ? "增加战队数" : "发布") +
                                "失败！";
                            $message.success({
                                text: message,
                            });
                        }
                    });
            }
        };
        function selectedCouponsIsNotnough() {
            vm.errText = "所选的点券不足。";
            $(".errText").css("display", "block");
        }
        //将选择的点券存到couponIds数组中
        vm.chooseCoupon = function (id) {
            var length = vm.couponIds.length;
            var checked = $("#" + id)[0].checked;
            if (checked) {
                vm.couponIds[length] = id;
            } else {
                var index = vm.couponIds.indexOf(id);
                if (index > -1) {
                    vm.couponIds.splice(index, 1);
                }
            }
            $(".errText").css("display", "none");
        };
        // 优惠充值
        vm.goToRecharge = function () {
            $(".modal-backdrop").remove();
            $state.go("e-account-my-purchase", {}, { reload: true });
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.mailStartTime = false;
        vm.datePickerOpenStatus.mailEndTime = false;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
            $(".bootstrap-switch-off").css("z-index", "1");
        }

        getAccount();
        function getAccount() {
            document.getElementById("con").className = "well";
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (account) {
                if (account.data !== null) {
                    $(".container").removeClass("login-container-content");
                    $("#nav, #foot").css("display", "block");
                    var author = account.data.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.ident = function () {
            // 独立设计师（没有企业的后台用户）发布活动的时候提示用户认证企业，
            $(".modal-backdrop").remove();
            window.setTimeout(function () {
                $state.go("e-account-my.init", {}, { reload: true });
            }, 300);
        };
    }
})();
