(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('AdDialogController', controller);
    controller.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Ad', '$http'];
    function controller ($timeout, $scope, $stateParams, $uibModalInstance, entity, Ad, $http) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.ad = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.onFileSelect = onFileSelect;
        vm.showCropped = true;
        vm.cropper = 'default';
        vm.ad.appSource=$stateParams.appSource;
        console.log('$stateParams.appSource',$stateParams.appSource);
        if (vm.ad.link == null){
            vm.ad.link = 'itasktour://gototrip?tripid=';
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            console.log(vm.ad);
            vm.isSaving = true;
            if(vm.ad.type == 2){
                if (vm.ad.img !== null){
                    if (/\.(jpg|jpeg|png|bmp)$/.test(vm.ad.img)){
                        var image = document.getElementById("file");
                        if (image != null){
                            var height = image.height;
                            var width = image.width;
                            var bi = width / height;
                            if (bi<1.8 || bi>2.2) {
                                // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                                alert("上传图片比例应为2:1");
                                if (vm.ad.img != null) {
                                    vm.ad.img = null;
                                }
                                vm.isSaving = false;
                                return;
                            }
                        }
                    }
                } else {
                    alert("上传图片不能为空");
                    vm.isSaving = false;
                    return;
                }

                if(vm.ad.startTime!= null && vm.ad.endTime != null){
                    if( vm.ad.endTime.getTime() < vm.ad.startTime.getTime()){
                        console.log('时间错误');
                        vm.timeWarm = true;
                        return;
                    }
                }
            }

            if (vm.ad.id !== null) {
                Ad.update(vm.ad, onSaveSuccess, onSaveError);
            } else {
                Ad.save(vm.ad, onSaveSuccess, onSaveError);
            }
        }
        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:adUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        function onSaveError () {
            vm.isSaving = false;
        }
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onFileSelect (uploadFile,type){
            var uploadImageFile = function(compressedBlob) {
                // 上传oss
                $http({
                    method: 'POST',
                    url: '/api/postFileNameAndToken',
                    data: {
                        type:'TRIP',
                        fileName:compressedBlob.name,
                    }
                }).then(function (res) {
                    console.log('后台返回的数据',res);
                    var  client = new OSS({
                      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                        region: 'oss-cn-shanghai',
                      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                        accessKeyId: res.data.data.AccessKeyID,
                        accessKeySecret:res.data.data.AccessKeySecret,
                      // 从STS服务获取的安全令牌（SecurityToken）。
                        stsToken: res.data.data.SecurityToken,
                      // 填写Bucket名称。
                        bucket: 'itasktour-muti'
                    });
                    // 第一个参数为上传的路径，第二个参数为上传的文件
                    client.put(res.data.data.fileName,compressedBlob).then(function(res){
                        console.log('oss返回数据res=',res);
                        if(type=='coverImg'){
                            vm.ad.img ='/'+res.name;
                        }
                        if(type=='phone'){
                            vm.ad.phoneImg = '/'+res.name;
                        }
                        $scope.$apply();
                    }).catch(function(err){
                        console.log('error status: ' + err);
                    });
                }).catch(function(err){
                    alert('postFileNameAndToken接口调用失败');
                });
            };

            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
        }
        vm.changeImg = changeImg;
        function changeImg(){
            vm.ad.img = null;
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.ad.img != null) {
                vm.ad.img = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

    }
})();
