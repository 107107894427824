(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("WxGameTestSpyController", controller);
    controller.$inject = ["Principal", "$http"];
    function controller(Principal, $http) {
        var vm = this;
        vm.score = [0, 0, 0, 0];
        vm.group = 0;

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.saveScore = saveScore;
        function saveScore() {
            $http({
                method: "POST",
                url:
                    "api/spy/team/score/" +
                    vm.tripId +
                    "/" +
                    vm.teamId +
                    "?score=" +
                    vm.score,
                // data:{
                //     score: vm.score
                // }
            })
                .success(function (data) {
                    alert(data.message);
                })
                .error(function (err) {});
        }

        //双方阵营切换角色
        vm.role = role;
        function role() {
            $http({
                method: "POST",
                url:
                    "api/spy/team/role/" +
                    vm.tripId +
                    "/" +
                    vm.teamId +
                    "/" +
                    vm.group,
            })
                .success(function (data) {
                    alert(data.message);
                })
                .error(function (err) {});
        }

        vm.addMember = addMember;
        function addMember() {
            $http({
                method: "POST",
                url: "api/spy/team/join/" + vm.tripId + "/" + vm.teamId,
            })
                .success(function (data) {
                    alert(data.message);
                })
                .error(function (err) {});
        }

        vm.again = again;
        function again() {
            $http({
                method: "POST",
                url: "api/spy/team/again/" + vm.tripId + "/" + vm.teamId,
            })
                .success(function (data) {
                    alert(data.message);
                })
                .error(function (err) {});
        }
    }
})();
