(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("EAccountDetailController", EAccountDetailController);

    EAccountDetailController.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "Principal",
        "$stateParams",
        "previousState",
        "entity",
        "$timeout",
        "$http",
    ];

    function EAccountDetailController(
        $scope,
        $rootScope,
        $state,
        Principal,
        $stateParams,
        previousState,
        entity,
        $timeout,
        $http
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.eAccount = entity;
        //console.log(vm.eAccount);
        vm.previousState = previousState.name;
        $scope.value = true;
        vm.provinceId = "pid";
        vm.codeParams = [];
        vm.agentType = "";
        vm.onlyCity = false;
        // vm.directlyCity = false;
        vm.showHasTripButton = true;
        vm.newAdminAccount = null;

        // vm.testData = {10001:"济南市",10002:"青岛市",10003:"烟台市",10004:"威海市",10005:"东营市"};
        // vm.testData = [{code:10001,province:'山东省',city:"济南市"},{code:10002,province:'山东省',city:"青岛市"},{code:10003,province:'山东省',city:"烟台市"},{code:10004,province:'山东省',city:"威海市"},{code:10002,province:'山东省',city:"青岛市"},{code:10003,province:'山东省',city:"烟台市"},{code:10004,province:'山东省',city:"威海市"},{code:10002,province:'山东省',city:"青岛市"},{code:10003,province:'山东省',city:"烟台市"},{code:10004,province:'山东省',city:"威海市"},{code:10002,province:'山东省',city:"青岛市"},{code:10003,province:'山东省',city:"烟台市"},{code:10004,province:'山东省',city:"威海市"},{code:10002,province:'山东省',city:"青岛市"},{code:10003,province:'山东省',city:"烟台市"},{code:10004,province:'山东省',city:"威海市"}];
        // console.log(vm.testData);

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    }
                }
                //判断哪些账号有特权
                // console.log(vm.account.login);
                var admin_user = [
                    13601752336,
                    18016073337,
                    18917615957,
                    13601935899,
                    13818191225,
                    13916144547,
                    "admin",
                ];
                for (var i = 0; i < admin_user.length; i++) {
                    // console.log(admin_user[i]);
                    if (admin_user[i] == vm.account.login) {
                        vm.canSee = true;
                    }
                }
            });
        }

        //时间控件
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });
        vm.datePickerOpenStatus.create_date = false;
        vm.datePickerOpenStatus.update_date = false;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        this.$onInit = function () {
            if (vm.eAccount.proxyMap != null) {
                // console.log(vm.eAccount.proxyMap);
                vm.availableRegion = vm.eAccount.proxyMap.availableRegion;
                vm.unavailableRegion = vm.eAccount.proxyMap.unavailableRegion;
            }
            if (vm.eAccount.parent == true) {
                if (vm.eAccount.proxyMap != null) {
                    if (vm.eAccount.proxyMap.proxyRegionName.length > 0) {
                        vm.province =
                            vm.eAccount.proxyMap.proxyRegionName[0].name;
                    }
                }
                if (vm.eAccount.parentId === 0) {
                    vm.agentType = "province";
                    if (vm.eAccount.proxyMap != null) {
                        if (vm.eAccount.proxyMap.proxyRegionName.length > 0) {
                            vm.provinceId =
                                vm.eAccount.proxyMap.proxyRegionName[0].code;
                        }
                    }
                    if (
                        vm.province != "北京市" &&
                        vm.province != "天津市" &&
                        vm.province != "上海市" &&
                        vm.province != "重庆市"
                    ) {
                        vm.onlyCity = false;
                    } else {
                        vm.onlyCity = true;
                    }
                } else {
                    vm.agentType = "city";
                }
                vm.cities = vm.availableRegion;
                if (typeof vm.cities != "undefined") {
                    for (var i = 0; i < vm.cities.length; i++) {
                        vm.codeParams[i] = vm.cities[i].code;
                    }
                }
                $scope.value = true;
            }
        };

        vm.toConfirm = toConfirm;
        function toConfirm() {
            // console.log(vm.agentType);
            var onlyCity = [110000, 120000, 310000, 500000];
            // console.log(vm.codeParams);
            if (vm.agentType == "city") {
                for (var i in onlyCity) {
                    // console.log(onlyCity);
                    if (vm.codeParams.length > 0) {
                        for (var y in vm.codeParams) {
                            // console.log(vm.codeParams);
                            if (onlyCity[i] == vm.codeParams[y]) {
                                // console.log("true");
                                vm.directlyCity = true;
                            }
                        }
                    } else {
                        vm.directlyCity = true;
                    }
                }
            } else if (vm.agentType == "province") {
                vm.directlyCity = false;
            }
            $("#myModalLabel").modal("hide");
            // console.log(vm.haveNotOpen);
        }

        function Todate(num) {
            var date = new Date(num);
            var a = [
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
            ];
            for (var i = 0, len = a.length; i < len; i++) {
                if (a[i] < 10) {
                    a[i] = "0" + a[i];
                }
            }
            date =
                a[0] +
                "-" +
                a[1] +
                "-" +
                a[2] +
                "T" +
                a[3] +
                ":" +
                a[4] +
                ":" +
                a[5] +
                "Z";
            return date;
        }

        // console.log('params:'+vm.codeParams);
        // console.log('agentType:'+vm.agentType);
        // console.log('directlyCity:'+vm.directlyCity);
        vm.showHasTrips = showHasTrips;
        function showHasTrips(id) {
            if (
                id != null &&
                (!vm.eAccount.hasTrips ||
                    (vm.eAccount.hasTrips && vm.eAccount.hasTrips.length == 0))
            ) {
                $http({
                    method: "GET",
                    url: "/api/e-accounts/findAllTripByEAccountId/" + id,
                }).then(
                    function (res) {
                        // console.log(res);
                        vm.eAccount.hasTrips = res.data;
                        vm.showHasTripButton = false;
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            } else {
                vm.showHasTripButton = false;
            }
        }

        vm.editAdminAccount = editAdminAccount;
        function editAdminAccount() {
            if (vm.newAdminAccount != null) {
                vm.errMsg = null;
                $http({
                    method: "POST",
                    url:
                        "/api/e-accounts/edit/admin-account/" +
                        vm.eAccount.id +
                        "?phone=" +
                        vm.newAdminAccount,
                }).then(
                    function (res) {
                        if (res.data.errorCode == 0) {
                            $("#newAdmin").modal("hide");
                            window.setTimeout(function () {
                                $state.go(
                                    "e-account-detail",
                                    { id: vm.eAccount.id },
                                    { reload: true }
                                );
                            }, 300);
                        } else if (res.data.errorCode == -2) {
                            vm.errMsg = "该账号不存在，请重新输入";
                        }
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            } else {
                //未输入
                vm.errMsg = "请输入新的账号";
            }
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:eAccountUpdate",
            function (event, result) {
                vm.eAccount = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
