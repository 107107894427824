(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("EquipDialogController", EquipDialogController);

    EquipDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "Principal",
        "$uibModalInstance",
        "entity",
        "Equip",
        "EquipSearch",
        "NpcInOutEquip",
        "Upload",
        "Ahdin",
        "$http",
    ];

    function EquipDialogController(
        $timeout,
        $scope,
        $stateParams,
        Principal,
        $uibModalInstance,
        entity,
        Equip,
        EquipSearch,
        NpcInOutEquip,
        Upload,
        Ahdin,
        $http
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.equip = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.onFileSelect = onFileSelect;

        vm.equips = getEquipFragment();
        vm.itemsPerPage = 250;
        vm.account = null;

        vm.filterEquip = this.filterEquip;
        this.filterEquip = function (search) {
            if (search) {
                vm.allEquip = EquipSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
            }
        };

        vm.equip.preCondition = {};
        this.$onInit = function () {
            if (vm.equip.belongTo) {
                getEquipById(vm.equip.belongTo);
            }
            if (vm.equip.preCondition != null) {
                var precondition = vm.equip.fragments;
                if (precondition != null) {
                    for (var i = 0; i < precondition.length; i++) {
                        // vm.equip.preCondition[i] = {id:precondition[i].id,name:precondition[i].name};
                        vm.equip.preCondition = precondition;
                    }
                }
                // console.log(vm.equip.preCondition);
            }
        };

        // condition();
        // function condition() {
        //     console.log(vm.equip.preCondition);
        //     if (vm.equip.preCondition!=null){
        //         var condition = vm.equip.preCondition;
        //         condition = condition.substring(2,condition.length-2);
        //         condition = condition.split(' AND ');
        //         for (var i=0;i<condition.length;i++){
        //             getEquipById(condition[i]);
        //         }
        //         console.log(condition);
        //     }
        // }

        function getEquipById(id) {
            $http({
                method: "GET",
                url: "/api/equips/" + id,
            })
                .success(function (data) {
                    if (data != null) {
                        vm.equip.belongTo = data;
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }

        function parseReleation(str, con) {
            var str2 = str.replace(/,/g, " " + con + " ");
            return "( ".concat(str2).concat(" )");
        }

        function getConditionIdsStr(pArray) {
            var preConditionStr = "";
            for (var i = 0; i < pArray.length; i++) {
                if (pArray[i] != null) {
                    preConditionStr += pArray[i].id + ",";
                }
            }
            // console.log(preConditionStr);
            var lastIndex = preConditionStr.lastIndexOf(",");
            preConditionStr = preConditionStr.substring(0, lastIndex);
            // console.log(preConditionStr);
            return preConditionStr;
        }

        function getEquipFragment() {
            $http({
                method: "GET",
                url: "/api/equips-fragment?page=0&size=250",
            })
                .success(function (data) {
                    if (data != null) {
                        vm.equips = data;
                    }
                    // console.log(data);
                })
                .error(function (err) {
                    console.log(err);
                });
        }

        vm.npcinoutequips = NpcInOutEquip.query();
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        getAccount();
        function getAccount() {
            // Principal.identity().then(function(account) {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        function save() {
            vm.isSaving = true;

            // Principal.identity().then(function(account) {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                vm.equip.artistId = vm.account.id;
            });

            if (vm.equip.type == null) {
                alert("道具类型不能为空，请选择类型");
                vm.isSaving = false;
                return;
            }

            if (vm.equip.belongTo != null) {
                vm.equip.preCondition = null;
                if (vm.equip.belongTo.id)
                    vm.equip.belongTo = vm.equip.belongTo.id;
            }

            var preCondition = vm.equip.preCondition;
            if (preCondition != null && preCondition.length > 0) {
                var str = getConditionIdsStr(preCondition);
                if (str.indexOf(",") > 0) {
                    vm.equip.preCondition = parseReleation(str, "AND");
                } else {
                    vm.equip.preCondition = str;
                }
                console.log(vm.equip.preCondition);
            } else {
                vm.equip.preCondition = null;
            }

            var date = new Date();

            if (vm.equip.id !== null) {
                vm.equip.updateDate = date;
                vm.equip.updateBy = vm.account.firstName;
                Equip.update(vm.equip, onSaveSuccess, onSaveError);
            } else {
                vm.equip.createDate = date;
                vm.equip.createBy = vm.account.firstName;
                Equip.save(vm.equip, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("taskServiceApp:equipUpdate", result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onFileSelect(uploadFile, name) {
            var uploadImageFile = function () {
                var size = uploadFile.size / 1024;
                // console.log(imgSize);
                if (vm.equip.type == "IMAGE") {
                    if (size > 500) {
                        alert("图片大小超过500kb");
                        return;
                    }
                } else if (
                    vm.equip.type == "AUDIO" ||
                    vm.equip.type == "VIDEO" ||
                    vm.equip.type == "TEXT"
                ) {
                    if (size > 5000) {
                        alert("视频或音频大小超过5M");
                        return;
                    }
                }

                Upload.upload({
                    // url: '/api/upload',
                    // fields: {},
                    // file: uploadFile,
                    // method: 'POST'
                    url: "/api/postfile",
                    fields: {},
                    data: { type: "EQUIP" },
                    file: uploadFile,
                    method: "POST",
                })
                    .progress(function (evt) {
                        var progressPercentage = parseInt(
                            (100.0 * evt.loaded) / evt.total
                        );
                        console.log("progress: " + progressPercentage + "% ");
                    })
                    .success(function (data, status, headers, config) {
                        // console.log('status: ' + status);
                        // console.log('status: ' + data);

                        // var size = uploadFile.size/1024;
                        // // console.log(imgSize);
                        // if (vm.equip.type=='IMAGE'){
                        //     if (size>500){
                        //         alert("图片大小超过500kb");
                        //         return;
                        //     }
                        // }else if(vm.equip.type=='AUDIO'||vm.equip.type=='VIDEO'||vm.equip.type=='TEXT'){
                        //     if (size>5000){
                        //         alert("视频或音频大小超过5M");
                        //         return;
                        //     }
                        // }

                        vm.equip.content = data.file;
                    })
                    .error(function (data, status, headers, config) {
                        console.log("error status: " + status);
                    });
            };

            if (uploadFile != null) {
                uploadImageFile();
            }
        }
    }
})();
