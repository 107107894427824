(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TripPrePublishController", controller);

    controller.$inject = [
        "$stateParams",
        "$cookies",
        "$uibModalInstance",
        "$state",
        "tripUpdateStatus",
        "entity",
        "$http",
        "getAvailableCoupons",
        "PublishTrip",
        "TripPrePublish",
        "Principal",
        "PublishTripTrade",
        "EAccountSearch",
        "$interval",
        "$scope",
        "$sce",
        "$uibModalStack",
    ];

    function controller(
        $stateParams,
        $cookies,
        $uibModalInstance,
        $state,
        tripUpdateStatus,
        entity,
        $http,
        getAvailableCoupons,
        PublishTrip,
        TripPrePublish,
        Principal,
        PublishTripTrade,
        EAccountSearch,
        $interval,
        $scope,
        $sce,
        $uibModalStack
    ) {
        var vm = this;
        if (entity.id == null || !entity.canShow) {
            setTimeout(function () {
                alert("无访问权限");
                clear();
            }, 100);
            return;
        }
        vm.trip = entity;
        vm.igc = $stateParams.igc;
        vm.clear = clear;
        vm.showPublishTitle = false;
        vm.toReCharge = false;
        vm.differenceCoupon = null;
        var couponIds = [];
        vm.time = "2098-12-30T16:00:00.000Z";
        vm.availableCoupons = [];
        vm.unavailableCoupons = [];
        vm.phoneCode = "发送验证码";
        vm.sessionId = null;
        vm.code = null;
        vm.filterEAccounts = this.filterEAccounts;
        vm.publishSuccess = false;
        var date = "2019-03-21 12:00:00";
        vm.specDate = new Date(Date.parse(date.replace(/-/g, "/")));
        vm.curDate = new Date();
        // 魔法特工按队扣钱
        if (vm.trip.appSource === "2") {
            if (vm.trip.teamset == 2 || vm.trip.teamset == 3) {
                vm.realCoupon = vm.trip.maxTeamcount;
            } else {
                vm.realCoupon = vm.trip.maxPeople;
            }
        } else {
            vm.realCoupon = vm.trip.maxPeople;
        }
        $scope.htmlTooltip1 = $sce.trustAsHtml(
            '<p style="text-align: left; width: 380px"><br>发布后「活动封面」和「活动人数」等仍可修改。\n' +
                "<br><br>" +
                "「活动类型」及「开启方式」、「开始日期」、「开启口令」、「结束时间」、「只允许队长答题」发布后将被锁定不可修改。如需修改请退回发布。\n" +
                "<br><br>*由于爱闯闯将为所有活动预留服务器资源及后台支持，活动「开始日期」当日零点后将不支持退回发布。</p>"
        );
        vm.account = null;
        getAccount();
        function getAccount() {
            //Principal.identity().then(function(account) {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    for (var i = 0; i < vm.account.authorities.length; i++) {
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN") {
                            vm.isAdmin = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                    if (
                        vm.trip.platform == 1 &&
                        !vm.account.inWhitelistB &&
                        vm.auth != "Super"
                    ) {
                        alert("基础版活动已停止支持");
                        clear();
                    }
                }
            });
        }

        vm.ident = ident;
        function ident() {
            $uibModalInstance.dismiss("cancel");
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000);
            // window.setTimeout(function () {
            //     $state.go("e-account-my.init",{},{reload:true});
            // },300);
            if (vm.account == null) {
                getAccount();
                return;
            }
            if (vm.account.eAccountId != null) {
                if (vm.account.eAccountConfirmEmail !== true) {
                    window.setTimeout(function () {
                        $state.go("e-account-my.edit", {}, { reload: true });
                    }, 300);
                } else {
                    window.setTimeout(function () {
                        $state.go("e-account-my", {}, { reload: true });
                    }, 300);
                }
            } else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init", {}, { reload: true });
                }, 300);
            }
        }

        if (vm.trip != null) {
            vm.requiredCoupons = vm.trip.maxPeople;
            if (vm.requiredCoupons == null) {
                vm.requiredCoupons =
                    vm.trip.teamMaxpeople * vm.trip.maxTeamcount;
            }
        }

        TripPrePublish.check(
            { id: parseInt(vm.trip.myTripModelId) },
            onSuccess,
            onError
        );
        function onSuccess(data) {
            if (data != null) {
                vm.tripPrePublish = data;
                if (vm.tripPrePublish.code === 0) {
                    if (
                        vm.trip.status === "EDIT" ||
                        vm.trip.status === "PREPUBLISH"
                    ) {
                        if (vm.trip.fullTested !== true) {
                            vm.mContent =
                                "请确保在实地进行过完整的测试，避免影响活动执行。";
                            vm.mText = "发布活动将清空测试数据";
                        }
                        if (vm.trip.platform == 1) {
                            vm.mTitle = "基础版活动发布";
                            vm.mText = null;
                        } else if (vm.trip.platform == 4) {
                            vm.mTitle = "极速追踪活动发布";
                            vm.payCoupon = vm.realCoupon;
                            vm.mContent = "";
                            vm.mText =
                                "请确保在实地进行过完整的测试，避免影响活动执行。";
                            if (vm.auth !== "IGC") {
                                getCoupons();
                            } else if (vm.igc == 1) {
                                console.log("联合发布=====");
                                IgcPublishTrip();
                            }
                        } else {
                            vm.mTitle = "专业版活动发布";
                            if (
                                vm.trip.attribute === "OUTWARD" ||
                                vm.trip.attribute === "OUTTIMERACE"
                            ) {
                                vm.payCoupon = vm.realCoupon;
                                vm.mContent = "";
                                vm.mText =
                                    "请确保在实地进行过完整的测试，避免影响活动执行。";
                                if (vm.auth !== "IGC") {
                                    getCoupons();
                                } else if (vm.igc == 1) {
                                    console.log("联合发布=====");
                                    IgcPublishTrip();
                                }
                            }
                        }
                    }
                } else if (vm.tripPrePublish.code === -1) {
                    //修改modal内容
                    vm.mTitle = "逻辑检测";
                    if (!vm.tripPrePublish.hasSafty) {
                        vm.mTitle = "自检";
                    }
                    // $(".mShow").css("display","block");
                    // $("#conf").css("display","none");
                    if (
                        vm.tripPrePublish.locationTasks !== null &&
                        vm.tripPrePublish.locationTasks.length > 0
                    ) {
                        vm.locationTaskSize =
                            vm.tripPrePublish.locationTasks.length;
                    }
                    if (
                        vm.tripPrePublish.baseTasks !== null &&
                        vm.tripPrePublish.baseTasks.length > 0
                    ) {
                        vm.baseTaskSize = vm.tripPrePublish.baseTasks.length;
                    }

                    if (vm.tripPrePublish.tripEmpty === true) {
                        vm.tripEmptys = " 该活动绑定的线路未包含任何点位 ";
                    }
                    if (
                        vm.tripPrePublish.emptyLocationTasks !== null &&
                        vm.tripPrePublish.emptyLocationTasks.length > 0
                    ) {
                        vm.empLo =
                            " 存在 " +
                            vm.tripPrePublish.emptyLocationTasks.length +
                            " 个空点位 ";
                        vm.empLocation = vm.tripPrePublish.emptyLocationTasks;
                    }
                    if (
                        vm.tripPrePublish.b_errorPreCondition !== null &&
                        vm.tripPrePublish.b_errorPreCondition.length > 0
                    ) {
                        vm.bErrorPre = " 存在任务前置条件错误 ";
                        vm.bErrorPreCondition =
                            vm.tripPrePublish.b_errorPreCondition;
                    }
                    if (
                        vm.tripPrePublish.baseTaskCirculate !== null &&
                        vm.tripPrePublish.baseTaskCirculate.length > 0
                    ) {
                        vm.baseCir = " 存在任务出现条件死循环 ";
                        vm.baseTaskCir = vm.tripPrePublish.baseTaskCirculate;
                    }
                    if (
                        vm.tripPrePublish.clueAndEnd !== null &&
                        vm.tripPrePublish.clueAndEnd.length > 0
                    ) {
                        vm.cluAndEnd = " 存在线索与结束任务冲突 ";
                        vm.cluAndEnds = vm.tripPrePublish.clueAndEnd;
                    }
                    if (
                        vm.tripPrePublish.endInPreCondition !== null &&
                        vm.tripPrePublish.endInPreCondition.length > 0
                    ) {
                        vm.endInPre = " 存在结束任务在前置条件中 ";
                        vm.endInPreCondition =
                            vm.tripPrePublish.endInPreCondition;
                    }
                    if (
                        vm.tripPrePublish.l_errorPreCondition !== null &&
                        vm.tripPrePublish.l_errorPreCondition.length > 0
                    ) {
                        vm.errorPreLo = " 存在点位任务前置条件错误 ";
                        vm.errorPreLocation =
                            vm.tripPrePublish.l_errorPreCondition;
                    }
                    if (
                        vm.tripPrePublish.locationTaskCirculate !== null &&
                        vm.tripPrePublish.locationTaskCirculate.length > 0
                    ) {
                        vm.loCir = " 存在点位出现条件死循环 ";
                        vm.locationCir =
                            vm.tripPrePublish.locationTaskCirculate;
                    }
                    if (
                        vm.tripPrePublish.preConditionAndEnd !== null &&
                        vm.tripPrePublish.preConditionAndEnd.length > 0
                    ) {
                        vm.preConAndEnd = " 存在任务既是前置条件又是结束任务 ";
                        vm.preConditionAndEnd =
                            vm.tripPrePublish.preConditionAndEnd;
                    }
                    if (
                        vm.tripPrePublish.locationTaskDTOSHasLimitPre !==
                            null &&
                        vm.tripPrePublish.locationTaskDTOSHasLimitPre.length > 0
                    ) {
                        vm.ltHasLiPre = " 存在点位任务前置条件是限次点位 ";
                        vm.ltHasLiPreCondition =
                            vm.tripPrePublish.locationTaskDTOSHasLimitPre;
                    }
                    if (
                        vm.tripPrePublish
                            .limitLocationTaskHasClueOrHasEndOrHasCanIgnore !==
                            null &&
                        vm.tripPrePublish
                            .limitLocationTaskHasClueOrHasEndOrHasCanIgnore
                            .length > 0
                    ) {
                        vm.liLtHasCEC =
                            " 存在限次点位下任务是线索或结束或可跳过任务 ";
                        vm.liLtHasClueEndCanIgnore =
                            vm.tripPrePublish.limitLocationTaskHasClueOrHasEndOrHasCanIgnore;
                    }
                }
            }
        }
        if (vm.trip.myTripModelId == null) {
            vm.mTitle = "逻辑检测";
            vm.mContent = "该活动未绑定线路";
            vm.code = -1;
        }
        function onError(err) {
            console.log(err);
        }

        //购买弹框
        vm.openBuydiv = openBuydiv;
        vm.closeBuydiv = closeBuydiv;
        vm.openBuy = 0;
        function openBuydiv() {
            vm.openBuy = 1;
        }
        function closeBuydiv() {
            vm.openBuy = 0;
        }
        // 扣点计算
        vm.payCoupon = 0;
        function getCoupons() {
            getAvailableCoupons.getCoupons(vm.trip.id).then(
                function (res) {
                    // console.log(res);
                    vm.couponsData = res.data;
                    if (res.data.availableCoupons != null) {
                        vm.availableCoupons = res.data.availableCoupons;
                        vm.unavailableCoupons = res.data.unavailableCoupons;
                        if (vm.curDate >= vm.specDate) {
                            var data = vm.availableCoupons;
                            var resetMaxPeople = false;
                            for (var i = 0; i < data.length; i++) {
                                var coupon = data[i];
                                if (coupon.newFlag == 1) {
                                    resetMaxPeople = true;
                                    break;
                                }
                            }
                            // //如果是极限追踪有多少人扣多少钱
                            // if(vm.trip.platform == 4){
                            //     resetMaxPeople = false;
                            // }
                            // if (resetMaxPeople) {
                            //     if (vm.trip.maxPeople < 20) {
                            //         // console.log("最大人数不满20  则按20点扣");
                            //         vm.payCoupon = 20;
                            //     }
                            // }
                        }
                    } else {
                        if (
                            vm.trip.attribute != "OFFICIAL" &&
                            vm.trip.attribute != "TIMERACE" &&
                            vm.trip.attribute != "COMMON" &&
                            vm.trip.attribute != "PUBLIC"
                        ) {
                            if (res.data.differenceCoupons != null) {
                                var differenceCoupons =
                                    res.data.differenceCoupons;
                                console.log("=====2====");
                                toReCharge(
                                    differenceCoupons[0].availableCoupon
                                );
                            } else {
                                console.log("=====1=====");
                                toReCharge();
                            }
                        }
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        $().ready(function () {
            // console.log(vm.couponsData);
            // console.log(vm.availableCoupons);
            if (
                vm.couponsData != null &&
                vm.couponsData != "" &&
                vm.availableCoupons.length > 0
            ) {
                $("#" + vm.availableCoupons[0].id).prop("checked", true);
            }
        });

        //将发布页面选择的优惠点券存到couponIds数组中
        vm.chooseCoupon = chooseCoupon;
        function chooseCoupon(id) {
            var length = couponIds.length;
            var checked = $("#" + id)[0].checked;
            if (checked) {
                couponIds[length] = id;
            } else {
                var index = couponIds.indexOf(id);
                if (index > -1) {
                    couponIds.splice(index, 1);
                }
            }
            // console.log(couponIds);
            $(".errText").css("display", "none");
        }

        vm.toTrip = toTrip;
        function toTrip(id) {
            $state.go("trip-model-detail", { id: id });
            window.setTimeout(function () {
                window.location.reload();
            }, 300);
        }
        vm.toLocation = toLocation;
        function toLocation(id) {
            // clear();
            $state.go("location-task-detail", { id: id });
            window.setTimeout(function () {
                window.location.reload();
            }, 300);
        }
        vm.toBaseTask = toBaseTask;
        function toBaseTask(id) {
            // clear();
            $state.go("base-task-detail", { id: id });
            window.setTimeout(function () {
                window.location.reload();
            }, 300);
        }

        vm.confirmPrePublish = confirmPrePublish;
        function confirmPrePublish() {
            var param = null;
            if (vm.trip.status === "EDIT" || vm.trip.status === "PREPUBLISH") {
                var tripId = vm.trip.id;
                if (vm.auth != "IGC") {
                    if (
                        vm.availableCoupons.length === 0 &&
                        vm.trip.platform != 1
                    ) {
                        if (
                            vm.trip.attribute === "OUTWARD" ||
                            vm.trip.attribute === "OUTTIMERACE"
                        ) {
                            toReCharge();
                        } else {
                            param = { tripId: vm.trip.id, status: "PUBLISH" };
                            tripUpdateStatus
                                .updateStatus(param)
                                .success(function (data) {
                                    $uibModalInstance.dismiss("cancel");
                                    $message.success({
                                        text: "活动发布成功",
                                    });
                                    $state.go(
                                        "tripQrCodeNew",
                                        {
                                            id: vm.trip.id,
                                            autoQrCode: true,
                                        },
                                        { reload: false }
                                    );
                                })
                                .error(function (err) {
                                    console.log(
                                        "---tripUpdateStatus.updateStatus-----err=",
                                        err
                                    );
                                    if (err.data && err.data.message) {
                                        $message.warning({
                                            text: err.data.message,
                                        });
                                    }
                                });
                        }
                    } else {
                        PublishTrip.publishTrip(couponIds, tripId).then(
                            function (res) {
                                $uibModalInstance.dismiss("cancel");
                                $message.success({
                                    text: "活动发布成功",
                                });
                                $state.go(
                                    "tripQrCodeNew",
                                    { id: vm.trip.id, autoQrCode: true },
                                    { reload: false }
                                );
                            },
                            function (err) {
                                console.log(
                                    "---PublishTrip.publishTrip-----err=",
                                    err
                                );
                                if (err.data) {
                                    if (err.data.errorCode == -2) {
                                        selectedCouponsIsNotnough();
                                    } else {
                                        $message.warning({
                                            text: err.data.message,
                                        });
                                    }
                                }
                            }
                        );
                    }
                } else {
                    // 可交易线路
                    if (vm.sessionId == null) {
                        getValidationCode();
                    } else if (vm.code == null) {
                        setValidationCode();
                    } else {
                        PublishTripTrade.publishTripTrade(
                            tripId,
                            vm.relatedEAccountId,
                            vm.code,
                            vm.sessionId
                        ).then(
                            function (res) {
                                console.log(res);
                                if (res.data.errorCode == 0) {
                                    console.log("发布成功");
                                    vm.publishSuccess = true;
                                    //vm.clear();
                                }
                            },
                            function (err) {
                                console.log(err);
                                if (err.data != null) {
                                    if (err.data.errorCode == -2) {
                                        eAccountCouponsIsNotEnough();
                                    } else if (err.data.errorCode == -4) {
                                        invalidCode();
                                    }
                                }
                            }
                        );
                    }
                }
            }
        }
        function toReCharge(difference) {
            if (difference != null) {
                vm.toReCharge = true;
                vm.showPublishTitle = false;
                vm.differenceCoupon = difference;
            } else {
                vm.mContent = "发布活动所需点数不足，请先前往充值。";
            }

            vm.mText = null;
            $(".showOrHide").css("display", "none");
            vm.confirmPrePublish = confirm;
        }
        function selectedCouponsIsNotnough() {
            vm.errText = "所选的点券不足。";
            $(".errText").css("display", "block");
        }
        function eAccountCouponsIsNotEnough() {
            vm.errText = "该企业点券不足，请联系企业管理员";
            $(".errText").css("display", "block");
        }
        function invalidCode() {
            vm.errText = "验证码错误";
            $(".errText").css("display", "block");
        }
        function getValidationCode() {
            vm.errText = "请先获取验证码";
            $(".errText").css("display", "block");
        }
        function setValidationCode() {
            vm.errText = "请输入验证码";
            $(".errText").css("display", "block");
        }
        function confirm() {
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000);
            $cookies.put("publish", vm.trip.id, {
                expires: new Date(expireDate),
            });
            $uibModalInstance.dismiss("cancel");
            window.setTimeout(function () {
                $state.go("e-account-my-purchase", {}, { reload: true });
            }, 200);
        }

        function IgcPublishTrip() {
            console.log("===IgcPublishTrip");
            // vm.mTitle = "发布";
            vm.mContent = null;
            vm.mText = "发布活动将清空测试数据";
            if (vm.trip.eAccountId != null) {
                $http({
                    url: "api/e-accounts/" + vm.trip.eAccountId,
                    method: "GET",
                }).then(function (res) {
                    vm.relatedEAccountName = res.data.name;
                    vm.relatedEAccountId = res.data.id;
                    vm.phoneEAccount = res.data.adminAccount;
                });
            }
        }
        vm.getEAccount = getEAccount;
        function getEAccount(eName) {
            //            if(phone != null && !(/^1[3456789]\d{9}$/.test(phone))){
            console.log(eName);
            if (eName != null) {
                $http({
                    method: "GET",
                    url: "api/e-accounts/findByUser?value=" + eName,
                }).then(
                    function (res) {
                        console.log(res);
                        if (res.data != "") {
                            vm.relatedEAccountId = res.data.id;
                            vm.relatedEAccountName = res.data.name;
                            vm.phoneEAccount = res.data.adminAccount;
                        } else {
                            vm.relatedEAccountName = "nothing";
                        }
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }
        }

        this.filterEAccounts = function (search) {
            if (search) {
                vm.eAccounts = EAccountSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                    release: "0",
                });
            }
        };

        vm.setEaccountPhone = setEaccountPhone;
        function setEaccountPhone(phone) {
            console.log(phone);
            vm.phoneEAccount = phone;
        }

        //获取图片验证码
        //getImageCode();
        vm.getImageCode = getImageCode;
        function getImageCode() {
            $http({
                url: "/api/getImageCode",
                method: "POST",
            })
                .success(function (data) {
                    // console.log(data);
                    if (data != null) {
                        vm.imgUrl = data.url;
                        vm.verifyId = data.verifyId;
                        vm.imgCode = "";
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }
        //设置倒计时60s
        function setTimeOut() {
            var code = 60;
            vm.phoneCode = 60 + "s";
            vm.dis = true;
            $(".checkBtn").css("background-image", "none");
            var time = $interval(
                function () {
                    code = code - 1;
                    vm.phoneCode = code + "s";
                },
                1000,
                60
            );
            time.then(function () {
                $interval.cancel(stop);
                vm.phoneCode = "发送验证码";
                $(".checkBtn").css(
                    "background-image",
                    "linear-gradient(113deg, #35e8c0, #0c9fcb)"
                );
                vm.dis = false;
            });
        }
        //获取手机验证码
        vm.getCode = getCode;
        function getCode() {
            if (
                vm.imgCode != null &&
                vm.verifyId != null &&
                vm.relatedEAccountId != null
            ) {
                $http({
                    url:
                        "/api/trip/prePublish/sendCode/" +
                        vm.trip.id +
                        "/" +
                        vm.relatedEAccountId,
                    method: "POST",
                    data: {
                        mobile: vm.phoneEAccount,
                        verifyCode: vm.imgCode,
                        verifyId: vm.verifyId,
                        tripId: vm.trip.id,
                        eAccountId: vm.relatedEAccountId,
                    },
                })
                    .success(function (res) {
                        console.log(res);
                        if (res.code == -2) {
                            eAccountCouponsIsNotEnough();
                        } else {
                            if (
                                res.data.sessionId == null ||
                                res.data.sessionId == ""
                            ) {
                                console.log("获取失败");
                                if (res.data.code == "1012") {
                                    vm.errText = "图片验证码错误";
                                    getImageCode();
                                } else {
                                    vm.errText = "获取失败";
                                }
                            } else {
                                //设置倒计时60s
                                setTimeOut();
                                console.log("获取成功");
                                vm.errText = null;
                                vm.sessionId = res.data.sessionId;
                                //vm.paramError = null;
                            }
                        }
                    })
                    .error(function (err) {
                        console.log(err);
                    });
            } else {
                vm.paramError = "paramError";
            }
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
            window.history.go(-1);
            $uibModalStack.dismissAll();
        }
    }
})();
