(function () {
    "use strict";

    angular.module("taskServiceApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("trip", {
                parent: "entity",
                url: "/trip?page&sort&search&states&searchType",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.trip.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/trip/trips.html",
                        controller: "TripController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    searchType: {
                        value: "0",
                        squash: true,
                    },
                    search: null,
                    status: "0", //0-所有状态 1-已发布  2-未发布
                    platformFilter: "0", //0所有版本  1基础版 2专业版  3极速追踪
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("trip");
                            $translatePartialLoader.addPart("eTripType");
                            $translatePartialLoader.addPart("tripStatusType");
                            $translatePartialLoader.addPart("tripAttribute");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("trip-detail", {
                parent: "entity",
                url: "/trip-detail/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.trip.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/trip/trip-detail.html",
                        controller: "TripDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "Trip",
                        function ($stateParams, Trip) {
                            return Trip.get({ id: $stateParams.id }).$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "trip",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("trip-export-photos", {
                parent: "entity",
                url: "/trips/{id}/export-photos",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.trip.export-photos.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/trip/trip-export-photos.html",
                        controller: "TripExportPhotosController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "TripExt",
                        function ($stateParams, TripExt) {
                            return TripExt.getPhotosByTrip($stateParams.id);
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "trip",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("trip-rank", {
                parent: "entity",
                url: "/trips/{id}/rank",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.trip.rank.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/trip/trip-rank.html",
                        controller: "TripRankController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "TripExt",
                        function ($stateParams, TripExt) {
                            return TripExt.getRanksByTrip($stateParams.id);
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "trip",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("trip.new", {
                parent: "trip",
                url: "/new",
                data: {
                    authorities: ["ROLE_USER"],
                },
                params: {
                    id: "",
                    title: "",
                    platform: 0, //0-专业版 1-基础版  2-OTA，已废除 3-开放寻宝，已废除  4-急速追踪
                    createOrEditTrip: true,
                    eTime: null,
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-dialog.html",
                                controller: "TripDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "width551",
                                resolve: {
                                    entity: function () {
                                        return {
                                            myTripModelId: $stateParams.id,
                                            myTripModelTitle:
                                                $stateParams.title,
                                            teamset: 1,
                                            traceType: null,
                                            tracePlayModelType: "none",
                                            saftyTime: null,
                                            terrainDiff: null,
                                            startType:
                                                $stateParams.platform == 4
                                                    ? 3
                                                    : 1,
                                            type: null,
                                            status: "EDIT",
                                            title: null,
                                            description: null,
                                            startLocation: null,
                                            eTime: null,
                                            startTime: null,
                                            endTime: null,
                                            coverImg: null,
                                            attribute: null,
                                            area: null,
                                            maxPeople: null,
                                            createDate: null,
                                            createBy: null,
                                            updateDate: null,
                                            updateBy: null,
                                            isPause: false,
                                            timeLength: null,
                                            id: null,
                                            teamMaxpeople: null,
                                            maxTeamcount: null,
                                            mailMaxPeople: null,
                                            ibeaconType: 0,
                                            canShow: true,
                                            teamSyncModel: "1",
                                            ltExit:
                                                $stateParams.platform == 4
                                                    ? false
                                                    : true,
                                            ltSync:
                                                $stateParams.platform == 4
                                                    ? false
                                                    : true,
                                            leaderOnlyFlag:
                                                $stateParams.platform == 1
                                                    ? true
                                                    : false,
                                            isShowCharts: true,
                                            enableStart: true,
                                            cachefile: 0,
                                            syncUpload: 1,
                                            showHelp: false,
                                            helpPhone: "",
                                            maxTrackers: 8,
                                            maxFugitives: 1,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function (res) {
                                    console.log(
                                        "用户成功保存创建的活动res",
                                        res
                                    );
                                    $state.go("trip", null, { reload: "trip" });
                                },
                                function () {
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("trip.new1", {
                parent: "trip",
                url: "/new1/{id}/{title}/{platform}?appSource&token&account&eTime",
                data: {
                    authorities: ["ROLE_USER"],
                },
                // 绑定的动态路由的参数会和下边的这个对象合并成一个对象
                params: {
                    appSource: null,
                    token: null,
                    account: null,
                    eTime: null,
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-dialog.html",
                                controller: "TripDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "width551",
                                resolve: {
                                    entity: function () {
                                        return {
                                            myTripModelId: $stateParams.id,
                                            myTripModelTitle: decodeURI(
                                                $stateParams.title
                                            ),
                                            teamset: 1,
                                            startType:
                                                $stateParams.platform == 4
                                                    ? 3
                                                    : 1,
                                            traceType: null,
                                            tracePlayModelType: "none",
                                            type: null,
                                            status: "EDIT",
                                            title: null,
                                            description: null,
                                            startLocation: null,
                                            eTime: null,
                                            startTime: null,
                                            endTime: null,
                                            coverImg: null,
                                            attribute: null,
                                            area: null,
                                            maxPeople: null,
                                            createDate: null,
                                            createBy: null,
                                            updateDate: null,
                                            updateBy: null,
                                            isPause: false,
                                            timeLength: null,
                                            id: null,
                                            teamMaxpeople: null,
                                            maxTeamcount: null,
                                            mailMaxPeople: null,
                                            canShow: true,
                                            appSource: $stateParams.appSource,
                                            teamSyncModel: "1",
                                            ltExit:
                                                $stateParams.platform == 4
                                                    ? false
                                                    : true,
                                            ltSync:
                                                $stateParams.platform == 4
                                                    ? false
                                                    : true,
                                            leaderOnlyFlag:
                                                $stateParams.platform == 1
                                                    ? true
                                                    : false,
                                            isShowCharts: true,
                                            enableStart: true,
                                            cachefile: 0,
                                            syncUpload: 1,
                                            showHelp: false,
                                            helpPhone: "",
                                            maxTrackers: 8,
                                            maxFugitives: 1,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function (res) {
                                    console.log(
                                        "用户从线路编辑器成功创建活动res",
                                        res
                                    );
                                    $state.go("trip", null, { reload: "trip" });
                                },
                                function (res) {
                                    // 从线路编辑器跳转到当前生成活动页面，当线路编辑器和后台登录的token不一致的时候，不关闭浏览器窗口，并跳转到登录界面（逻辑在后续代码中）
                                    if (res !== "appSourceLogin") {
                                        window.close();
                                    }
                                }
                            );
                    },
                ],
            })
            .state("trip.edit", {
                parent: "trip",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-dialog.html",
                                controller: "TripDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "width551",
                                resolve: {
                                    entity: [
                                        "Trip",
                                        function (Trip) {
                                            return Trip.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function (res) {
                                    // 用户调用$uibModalInstance.close(result)这个方法走这个地方
                                    console.log(
                                        "用户成功保存编辑的活动res",
                                        res
                                    );
                                    $state.go("trip", null, { reload: "trip" });
                                },
                                function (res) {
                                    // 用户调用$uibModalInstance.dismiss('cancel')这个方法走这个地方
                                    console.log(
                                        "用户未保存活动编辑内容res",
                                        res
                                    );
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("trip-detail.edit", {
                parent: "trip-detail",
                url: "/detail/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-dialog.html",
                                controller: "TripDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Trip",
                                        function (Trip) {
                                            return Trip.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function (res) {
                                    console.log(
                                        "用户成功保存编辑的活动res",
                                        res
                                    );
                                    $state.go(
                                        "trip-detail",
                                        { id: $stateParams.id },
                                        { reload: true }
                                    );
                                },
                                function (res) {
                                    console.log(
                                        "用户未保存活动编辑内容res",
                                        res
                                    );
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("trip.import", {
                parent: "entity",
                url: "/trips/{id}/import",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.trip.import.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/trip/trip-import.html",
                        controller: "TripImportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("trip");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "Trip",
                        function ($stateParams, Trip) {
                            return Trip.get({ id: $stateParams.id }).$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "trip",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("trip-detail.delete", {
                parent: "trip-detail",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-delete-dialog.html",
                                controller: "TripDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Trip",
                                        function (Trip) {
                                            return Trip.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("trip", null, { reload: "trip" });
                                },
                                function () {
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("base-task-trip-id", {
                parent: "entity",
                url: "/base-tasks/locationTask/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.baseTask.home.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/base-task/base-tasks-trip.html",
                        controller: "BaseTaskController_Trip",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("baseTask");
                            $translatePartialLoader.addPart("taskType");
                            $translatePartialLoader.addPart("showMode");
                            $translatePartialLoader.addPart("challengeMode");
                            $translatePartialLoader.addPart("arResultType");
                            $translatePartialLoader.addPart("pswAnswerType");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("trip-monitor", {
                parent: "entity",
                url: "/trip-monitor?page&sort&search",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.trip.home.monitor",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/trip/trips-monitor.html",
                        controller: "TripMonitorController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("trip");
                            $translatePartialLoader.addPart("eTripType");
                            $translatePartialLoader.addPart("tripStatusType");
                            $translatePartialLoader.addPart("tripAttribute");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                },
            })
            .state("trip-today", {
                parent: "entity",
                url: "/trip-today?page&sort&search",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "今日活动",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/trip/trips-today.html",
                        controller: "TripTodayController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("trip");
                            $translatePartialLoader.addPart("eTripType");
                            $translatePartialLoader.addPart("tripStatusType");
                            $translatePartialLoader.addPart("tripAttribute");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                },
            })
            .state("tripQrCodeNew", {
                parent: "entity",
                // 经过测试发现，追加在url路径后边的参数刷新界面不会被清除掉
                url: "/trip-qr-code-new?id",
                data: {
                    authorities: ["ROLE_USER"],
                },
                params: {
                    id: "",
                    autoQrCode: false,
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-qr-code-new.html",
                                controller: "TripQrCodeNewController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "width720",
                                resolve: {
                                    entity: [
                                        "Trip",
                                        function (Trip) {
                                            return Trip.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    window.history.go(
                                        $stateParams.autoQrCode ? -2 : -1
                                    );
                                },
                                function () {
                                    window.history.go(
                                        $stateParams.autoQrCode ? -2 : -1
                                    );
                                }
                            );
                    },
                ],
            })
            .state("tripCoachQrCode", {
                parent: "entity",
                url: "/trip-coach-qrCode?id&title&attribute&appSource",
                data: {
                    authorities: ["ROLE_USER"],
                },
                params: {
                    id: "",
                    title: "",
                    attribute: "",
                    appSource: "",
                    showWeb: false,
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-coach-qr-code.html",
                                controller: "TripCoachQrCodeController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: function () {
                                        return {
                                            tripId: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    window.history.go(-1);
                                },
                                function () {
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("trip-prePublish", {
                parent: "entity",
                url: "/trip-prePublish/{tripId}/{status}/{igc}",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        // console.log($stateParams);
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/trip/trip-prePublish.html",
                                controller: "TripPrePublishController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Trip",
                                        function (Trip) {
                                            // console.log($stateParams);
                                            return Trip.get({
                                                id: $stateParams.tripId,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    window.history.go(-1);
                                },
                                function () {
                                    // window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("coach-web-hint", {
                parent: "entity",
                url: "/coach-web-hint?appSource",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "教练端",
                },
                params: {
                    appSource: "",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/trip/trip-coach-web-hint.html",
                        controller: "TripCoachWebHintController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("trip");
                            return $translate.refresh();
                        },
                    ],
                },
            });
    }
})();
