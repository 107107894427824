(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RechargeCouponsDialogController', RechargeCouponsDialogController);

    RechargeCouponsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RechargeCoupons'];

    function RechargeCouponsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RechargeCoupons) {
        var vm = this;
        vm.rechargeCoupons = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        if (vm.rechargeCoupons.type===1){
            vm.rechargeCoupons.type='1';
        }else if(vm.rechargeCoupons.type===2) {
            vm.rechargeCoupons.type='2';
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.save=function() {
            if(!vm.rechargeCoupons.appSource){
                $message.warning({
                    text:'请选择品牌类型',
                });
                return false;
            }
            if(!vm.rechargeCoupons.type){
                $message.warning({
                    text:'请选择套餐类型',
                });
                return false;
            }
            if(!vm.rechargeCoupons.maxAmount){
                $message.warning({
                    text:'请填写最大充值次数',
                });
                return false;
            }
            if(!vm.rechargeCoupons.price){
                $message.warning({
                    text:'请填写售卖价格',
                });
                return false;
            }
            if(!vm.rechargeCoupons.coupon){
                $message.warning({
                    text:'请填写充值点数',
                    shade_click:true,
                    duration:5000,
                });
                return false;
            }
            if(String(vm.rechargeCoupons.extraCoupon).length===0){
                $message.warning({
                    text:'请填写赠送点数',
                });
                return false;
            }
            if(!vm.rechargeCoupons.duration){
                $message.warning({
                    text:'请填写过期时间',
                });
                return false;
            }
            vm.isSaving = true;
            if (vm.rechargeCoupons.id !== null) {
                RechargeCoupons.update(vm.rechargeCoupons, onSaveSuccess, onSaveError);
            } else {
                RechargeCoupons.save(vm.rechargeCoupons, onSaveSuccess, onSaveError);
            }
        };

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:rechargeCouponsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
