(function () {
    "use strict";
    angular.module("taskServiceApp").config(stateConfig);
    stateConfig.$inject = ["$stateProvider"];
    function stateConfig($stateProvider) {
        $stateProvider
            .state("wx-game-type", {
                parent: "entity",
                url: "/wx-game-type",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "培训游戏类型",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/wx-game/wx-game-type.html",
                        controller: "WxGameTypeController",
                        controllerAs: "vm",
                    },
                },
            })
            .state("wx-game-trips", {
                parent: "entity",
                url: "/wx-game-trips?page&category&sort&search&status&return_scheme_id",
                data: {
                    authorities: ["ROLE_USER"],
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/wx-game/wx-game-trips.html",
                        controller: "WxGameTripsController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    category: "4",
                    status: "0",
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    search: null,
                    return_scheme_id: null,
                },
                resolve: {},
            })
            .state("wx-game-qr-code", {
                parent: "wx-game-trips",
                url: "/wx-game-qr-code/{id}?title",
                data: {
                    authorities: ["ROLE_USER"],
                },
                params: {
                    id: "",
                    title: "",
                    category: "4",
                },
                onEnter: [
                    "$uibModal",
                    function ($uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/wx-game/wx-game-qr-code.html",
                                controller: "WxGameQrCodeController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {},
                            })
                            .result.then(
                                function () {
                                    window.history.go(-1);
                                },
                                function () {
                                    window.history.go(-1);
                                }
                            );
                    },
                ],
            })
            .state("wx-game-detail", {
                parent: "entity",
                url: "/wx-game-detail/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "活动详情",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/wx-game/wx-game-detail.html",
                        controller: "WxGameDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "TripGame",
                        function ($stateParams, TripGame) {
                            return TripGame.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                },
            })
            .state("wx-game-test-spy", {
                parent: "entity",
                url: "/wx-game-test-spy",
                data: {
                    authorities: ["ROLE_USER"],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/wx-game/wx-game-test-spy.html",
                        controller: "WxGameTestSpyController",
                        controllerAs: "vm",
                    },
                },
                resolve: {},
            })
            .state("wx-game-test-stealth", {
                parent: "entity",
                url: "/wx-game-test-stealth",
                data: {
                    authorities: ["ROLE_USER"],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/wx-game/wx-game-test-stealth.html",
                        controller: "WxGameTestStealthController",
                        controllerAs: "vm",
                    },
                },
                resolve: {},
            })
            .state("wx-game-trip-rank", {
                parent: "entity",
                url: "/wx-game-trip-rank/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "活动排名",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/wx-game/wx-game-trip-rank.html",
                        controller: "WxGameTripRankController",
                        controllerAs: "vm",
                    },
                },
                resolve: {},
            });
    }
})();
