(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("TripDetailController", controller);
    controller.$inject = [
        "$filter",
        "$scope",
        "$rootScope",
        "$http",
        "$stateParams",
        "previousState",
        "entity",
        "$state",
        "Trip",
        "tripUpdateStatus",
        "tripEdit",
        "$interval",
        "EAccountSearch",
        "TripVerifyStatus",
    ];
    function controller(
        $filter,
        $scope,
        $rootScope,
        $http,
        $stateParams,
        previousState,
        entity,
        $state,
        Trip,
        tripUpdateStatus,
        tripEdit,
        $interval,
        EAccountSearch,
        TripVerifyStatus
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        if (entity.id == null || !entity.canShow) {
            vm.trip = {};
            if (
                window.location.href.endsWith("trip-detail/" + $stateParams.id)
            ) {
                alert("无访问权限");
                history.go(-1);
            }
            return;
        }
        vm.trip = entity;
        vm.previousState = previousState.name;
        vm.showTestButton = true;
        vm.showNormalButton = true;
        vm.showWebButton = true;
        vm.afterTripStartTime = false; //当前时间是在活动开始日期0点之后
        vm.mftgManager = false;
        if (vm.trip != null) {
            var date = $filter("date")(vm.trip.startTime, "yyyy-MM-dd");
            var startDay = new Date(
                (date + " " + "00:00:00").replace(/-/g, "/")
            );
            var nowTime = new Date().getTime();
            if (nowTime > startDay.getTime()) {
                vm.trip.afterTripStartTime = true;
            }
        }
        getAccount();
        //获取当前登录的账号
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    for (var i = 0; i < vm.account.authorities.length; i++) {
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN") {
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE") {
                            vm.isRelease = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if (
                        $.inArray("ROLE_SUPER", author) !== -1 ||
                        $.inArray("ROLE_MFTG_MANAGER", author) !== -1
                    ) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                    // 判断用户在魔法特工品牌下的权限
                    if (
                        vm.account.authorities.indexOf("ROLE_MFTG_MANAGER") > -1
                    ) {
                        vm.mftgManager = true;
                        vm.auth = "MftgManager";
                        vm.author = "品牌管理员";
                    }
                }
            });
        }
        vm.publishTrip = function (tripId, status, igc, endTime, platform) {
            // 判断这个活动的结束时间是否在当前时间之前
            if (
                platform != 4 &&
                endTime &&
                new Date(endTime).getTime() < new Date().getTime()
            ) {
                $message.warning({
                    text: "发布的活动结束时间不能在当前时间之前！",
                });
                return false;
            }
            if (
                vm.account.appSource == 2 &&
                vm.account.authorities.indexOf("ROLE_INDIEDESIGN") !== -1
            ) {
                $message.warning({
                    text: "你没有发布权限！",
                });
                return false;
            }
            if (gd.eAccount.rabbitActivationState == 3) {
                // 该用户所在企业已经停运，不允许发布活动；
                $message.warning({
                    text: "该企业账号状态异常，请联系管理员",
                });
            } else {
                $state.go("trip-prePublish", {
                    tripId: tripId,
                    status: status,
                    igc: igc,
                });
            }
        };
        vm.tripQrCodeNew = tripQrCodeNew;
        function tripQrCodeNew(id) {
            $state.go("tripQrCodeNew", { id: id }, { reload: false });
        }
        vm.tripCoachQrCode = function (id, title, attribute, appSource) {
            $state.go(
                "tripCoachQrCode",
                {
                    id: id,
                    title: title,
                    attribute: attribute,
                    appSource: appSource,
                },
                { reload: false }
            );
        };
        if ($stateParams.status == "PREPUBLISH") {
            vm.trip.status = "PREPUBLISH";
            vm.trip.canEdit = false;
        }

        vm.showTeams = showTeams;
        function showTeams(param) {
            // console.log(param);
            if (param.type == "test" && !vm.trip.hasTestTeams) {
                $http({
                    method: "GET",
                    url:
                        "/api/trip/getTeamsByTripId/" +
                        param.id +
                        "/" +
                        param.type,
                }).then(
                    function (res) {
                        // console.log(res);
                        vm.trip.hasTestTeams = res.data;
                        vm.showTestButton = false;
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            } else if (param.type == "normal" && !vm.trip.hasNormalTeams) {
                $http({
                    method: "GET",
                    url:
                        "/api/trip/getTeamsByTripId/" +
                        param.id +
                        "/" +
                        param.type,
                }).then(
                    function (res) {
                        // console.log(res);
                        vm.trip.hasNormalTeams = res.data;
                        vm.showNormalButton = false;
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            } else if (param.type == "web" && !vm.trip.hasWebTeams) {
                $http({
                    method: "GET",
                    url:
                        "/api/trip/getTeamsByTripId/" +
                        param.id +
                        "/" +
                        param.type,
                }).then(
                    function (res) {
                        // console.log(res);
                        vm.trip.hasWebTeams = res.data;
                        vm.showWebButton = false;
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }
        }

        getTripModel();
        function getTripModel() {
            $http({
                method: "GET",
                url: "/api/trip-models/" + vm.trip.myTripModelId,
            }).then(
                function (res) {
                    //console.log(res);
                    vm.tripModel = res.data;
                },
                function (err) {
                    console.log(err);
                }
            );
        }
        vm.acfe = function () {
            var acfeUrl = "";
            var onlyid = btoa(
                vm.tripModel.id +
                    "," +
                    vm.account.appSource +
                    "," +
                    vm.account.login
            );
            if (
                window.location.origin.indexOf("localhost") > -1 ||
                window.location.hostname.split(".").length === 4
            ) {
                acfeUrl =
                    window.location.protocol +
                    "//" +
                    window.location.hostname +
                    ":3000/editor/";
            } else {
                acfeUrl = vm.account.acfeUrl;
            }
            window.open(
                acfeUrl +
                    onlyid +
                    "?at=" +
                    vm.account.password +
                    "_coach&origin=" +
                    btoa(window.location.origin),
                "acfe" + vm.tripModel.id
            );
        };
        var unsubscribe = $rootScope.$on(
            "taskServiceApp:tripUpdate",
            function (event, result) {
                vm.trip = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);

        vm.deleteTripTip = function () {
            $("#myModal6").modal("show");
        };
        // 退回编辑和上下架
        vm.isRelease = false;
        vm.phoneCode = "发送验证码";
        vm.sessionId = "";
        vm.code = "";
        vm.tripLabels = null;
        vm.regions = null;
        vm.peopleNums = null;
        vm.labelSelect = false;
        vm.tripModel = null;
        vm.mTitle = "退回编辑";
        vm.mContent = "确定要退回发布吗？";
        vm.edit = edit;
        function edit(tripId) {
            vm.tripId = tripId;
            //不是发布管理员身份
            if ($.inArray("ROLE_RELEASE", vm.account.authorities) === -1) {
                var dataNew = new Date();
                var myJsDate = $filter("date")(
                    vm.trip.startTime,
                    "yyyy-MM-dd HH:mm:ss"
                );
                var dateStart = new Date(myJsDate);
                if (dataNew > dateStart) {
                    vm.mContent2 =
                        "活动已开始，不可退回发布，如需退回请联系发布管理员";
                    $("#myModal2").modal("show");
                    return;
                }
            }
            if (vm.trip.status !== "EDIT") {
                vm.confirm = confirms;
                vm.sendCode = sendCode;
                vm.tripStatus = vm.trip.status;
                $("#myModal1").modal("show");
            } else {
                alert("该活动已为编辑状态");
            }
        }
        function confirms() {
            //点击事件执行后将按钮置灰，请求成功后再回复按钮状态

            var param = { tripId: vm.tripId, status: "EDIT" };
            tripUpdateStatus
                .updateStatus(param)
                .success(function (data) {
                    // console.log(data);
                    // loadAll();
                    // for (var i in vm.trips){
                    //     if (vm.trips[i].id == param.tripId) {
                    //         vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                    //     }
                    // }
                    $("#myModal1").modal("hide");
                    window.history.go(0);
                })
                .error(function (err) {
                    // console.log(err);
                    if (err != null) {
                        vm.err = err;
                        vm.mTitle2 = "退回编辑";
                        vm.release = false;
                        if (
                            $.inArray(
                                "ROLE_RELEASE",
                                vm.account.authorities
                            ) !== -1
                        ) {
                            vm.release = true;
                        }
                        if (vm.release) {
                            vm.mContent2 = err.message;
                            $("#myModal1").modal("hide");
                            $("#myModal2").modal("show");
                            vm.confirmsConf = confirmsConf;
                        } else {
                            if (err.errorCode == -1) {
                                vm.mContent2 = "活动已结束，不可退回编辑";
                            } else {
                                vm.mContent2 =
                                    "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                            }
                            vm.confirmsConf = null;
                            $("#myModal1").modal("hide");
                            $("#myModal2").modal("show");
                            $("#conf2").css("display", "none");
                        }
                    }
                });
        }
        function confirmsConf() {
            console.log("--------3");
            if (vm.err.errorCode != 0) {
                var param = { tripId: vm.tripId, status: "EDIT" };
                tripEdit.tEdit(param).success(function (data) {
                    // console.log(data);
                    // loadAll();
                    // for (var i in vm.trips){
                    //     if (vm.trips[i].id == param.tripId) {
                    //         vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                    //     }
                    // }
                    $("#myModal1").modal("hide");
                    $("#myModal2").modal("hide");
                    window.history.go(0);
                });
            }
        }

        function sendCode() {
            var param = { tripId: vm.tripId };
            TripVerifyStatus.tripVerifyStatus(param)
                .success(function (data) {
                    console.log(data);
                    if (data.code == 0) {
                        $http({
                            url: "api/e-accounts/" + data.message,
                            method: "GET",
                        }).then(function (res) {
                            vm.relatedEAccountName = res.data.name;
                            vm.relatedEAccountId = res.data.id;
                            vm.phoneEAccount = res.data.adminAccount;
                        });
                        vm.updateOp = false;
                        $("#myModal1").modal("hide");
                    } else if (data.code == -1) {
                        vm.mContent2 =
                            "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                        $("#myModal1").modal("hide");
                        $("#myModal2").modal("show");
                        $("#conf2").css("display", "none");
                    } else if (data.code == -3) {
                        vm.mContent2 = "线路在已结算，不可退回编辑";
                        $("#myModal1").modal("hide");
                        $("#myModal2").modal("show");
                        $("#conf2").css("display", "none");
                    }
                })
                .error(function (err) {
                    console.log(err);
                });
        }
        $scope.$on("$destroy", function () {
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");
        });
    }
})();
