(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('design', {
            parent: 'entity',
            url: '/design?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: '设计师管理'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/design/design.html',
                    controller: 'DesignController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {}
        }).state('showdesign', {
            parent: 'entity',
            url: '/showdesign?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: '设计师管理'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/design/showDesign.html',
                    controller: 'ShowDesignController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'updateDate,desc',
                    squash: true
                }
            },
            resolve: {}
        });
        
    }

})();
