(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("TripExportPhotosController", TripExportPhotosController);

    TripExportPhotosController.$inject = [
        "$scope",
        "$rootScope",
        "$stateParams",
        "$location",
        "previousState",
        "entity",
        "$http",
        "Trip",
    ];

    function TripExportPhotosController(
        $scope,
        $rootScope,
        $stateParams,
        $location,
        previousState,
        entity,
        $http,
        Trip
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        // vm.tripPhotos = entity;
        vm.tripId = $stateParams.id;
        vm.previousState = previousState.name;
        vm.absUrl = $location.$$absUrl;
        vm.chosenPhotos = {};
        vm.tripEnd = false;

        // console.log(vm.tripRanks);
        Trip.get(
            { id: $stateParams.id },
            function (res) {
                // console.log(res);
                if (res != null) {
                    vm.trip = res;
                    if (
                        vm.trip != null &&
                        vm.trip.id != null &&
                        vm.trip.canShow &&
                        ((vm.trip.platform != 4 && vm.trip.visibleFlag) ||
                            vm.trip.platform == 4)
                    ) {
                        vm.tripPhotos = entity;
                        if (
                            vm.tripPhotos != null &&
                            typeof vm.tripPhotos != "undefined"
                        ) {
                            vm.tripHasPhotos = vm.tripPhotos.photo_list;
                            vm.tripHasVideos = vm.tripPhotos.video_list;
                        }
                    } else {
                        alert("无访问权限");
                        history.go(-1);
                    }
                }
            },
            function (err) {
                alert("无访问权限");
                history.go(-1);
            }
        );

        checkTripIsEnd();
        function checkTripIsEnd() {
            $http({
                url: "api/trip/check-end/" + vm.tripId,
                method: "GET",
            }).then(function (res) {
                console.log(res);
                vm.tripEnd = res;
            });
        }

        vm.reloadData = reloadData;
        function reloadData() {
            $http({
                url: "api/trip/photos/reload/" + vm.tripId,
                method: "GET",
            }).then(function () {
                window.location.reload();
            });
        }
    }
})();
