(function () {
    "use strict";

    angular.module("taskServiceApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("app", {
            cache: false,
            abstract: true,
            views: {
                "navbar@": {
                    templateUrl: "app/layouts/navbar/navbar.html",
                    controller: "NavbarController",
                    controllerAs: "vm",
                },
            },
            resolve: {
                authorize: [
                    "Auth",
                    function (Auth) {
                        return Auth.authorize();
                    },
                ],
                translatePartialLoader: [
                    "$translate",
                    "$translatePartialLoader",
                    "$templateCache",
                    function (
                        $translate,
                        $translatePartialLoader,
                        $templateCache
                    ) {
                        $translatePartialLoader.addPart("global");
                        // console.log($templateCache.info());
                        // $templateCache.removeAll();
                        // console.log($templateCache.info());
                        // $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
                        // $httpProvider.defaults.headers.get["Cache-Control"] = 'no-cache';
                        // $httpProvider.defaults.headers.get["Pragma"] = 'no-cache';
                    },
                ],
            },
        });
    }
})();
