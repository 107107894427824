(function () {
    "use strict";
    angular.module("taskServiceApp").controller("TripController", controller);
    controller.$inject = [
        "$state",
        "$http",
        "tripUpdateStatus",
        "TripSearchByStatus",
        "$stateParams",
    ];

    function controller(
        $state,
        $http,
        tripUpdateStatus,
        TripSearchByStatus,
        $stateParams
    ) {
        console.log("$stateParams=", $stateParams);
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.itemsPerPage = 20;
        vm.page = $stateParams.page;
        vm.status = $stateParams.status;
        vm.statusArray = ["所有状态", "已发布", "未发布"];
        vm.predicate = "id";
        vm.reverse = "desc";
        vm.sort = $stateParams.sort;
        vm.searchQuery = $stateParams.search;
        // platformFilter  0所有版本  1基础版 2专业版  3极速追踪
        vm.platformFilter = $stateParams.platformFilter;
        vm.platformArray = ["所有版本", "基础版", "专业版", "极速追踪"];
        vm.searchType = $stateParams.searchType;
        vm.disabledPlatform1 = false;
        vm.transition = function (params) {
            if (params === 1 || params === 4 || params === 2) {
                vm.page = 1;
            }
            if (params === 2) {
                vm.sort = vm.predicate + "," + (vm.reverse ? "desc" : "asc");
            }
            TripSearchByStatus.query(
                {
                    platform:
                        vm.platformFilter === "3" ? "4" : vm.platformFilter,
                    type: vm.status,
                    query: vm.searchQuery,
                    searchType: Number(vm.searchType),
                    page: Number(vm.page) - 1,
                    size: vm.itemsPerPage,
                    sort: vm.sort,
                    release: "0",
                },
                onSuccess,
                onError
            );
            function onSuccess(data, headers) {
                vm.totalItems = headers("X-Total-Count");
                vm.trips = data;
                vm.trips.forEach(function (item) {
                    if (
                        item.status === "PUBLISH" &&
                        item.endTime &&
                        new Date(item.endTime).getTime() < new Date().getTime()
                    ) {
                        item.settlemented = true;
                    }
                });
            }
            function onError(error) {
                $message.error({
                    text: error.data.message,
                });
            }
        };
        vm.transition();
        vm.keyDown = function (event) {
            if (event.keyCode == 13) {
                vm.transition(1);
            }
        };
        vm.platform1Tip = function () {
            $message.warning({
                text: "基础版活动已停止支持",
            });
        };
        vm.inputPlaceholder = function () {
            $("#searchQuery").attr("placeholder", function () {
                switch (Number(vm.searchType)) {
                    case 1:
                        return "输入企业全称或者ID";
                        break;
                    case 2:
                        return "输入设计师账号";
                        break;
                    default:
                        return "搜索活动";
                }
            });
        };
        vm.inputPlaceholder();
        vm.account = null;
        vm.isRelease = false;
        vm.mftgManager = false;
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    for (var i = 0; i < vm.account.authorities.length; i++) {
                        if (vm.account.authorities[i] === "ROLE_RELEASE") {
                            vm.isRelease = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                    console.log("vm.auth=", vm.auth);
                    // 判断用户在魔法特工品牌下的权限
                    if (
                        vm.account.authorities.indexOf("ROLE_MFTG_MANAGER") > -1
                    ) {
                        vm.mftgManager = true;
                        vm.auth = "MftgManager";
                        vm.author = "品牌管理员";
                    }
                    if ($(window).width() < 767) {
                        if (
                            !vm.account.mobileTipShowFlag &&
                            !sessionStorage.getItem("asfxf") &&
                            vm.account.appSource == 2
                        ) {
                            var id = setTimeout(function () {
                                sessionStorage.setItem("asfxf", "1211");
                                $("#myModalPh").modal("show");
                                clearTimeout(id);
                            }, 500);
                        }
                    }
                    if (!vm.account.inWhitelistB && vm.auth != "Super") {
                        vm.disabledPlatform1 = true;
                    }
                }
            });
        }
        vm.closeModalTip = function () {
            document.getElementById("myModalPh").style.display = "none";
            // document.body.remove(document.getElementById("myModalPh"));
        };
        vm.publishTrip = function (tripId, status, igc, endTime, platform) {
            // 判断这个活动的结束时间是否在当前时间之前
            if (
                platform != 4 &&
                endTime &&
                new Date(endTime).getTime() < new Date().getTime()
            ) {
                $message.warning({
                    text: "发布的活动结束时间不能在当前时间之前！",
                });
                return false;
            }
            if (
                vm.account.appSource == 2 &&
                vm.account.authorities.indexOf("ROLE_INDIEDESIGN") !== -1
            ) {
                $message.warning({
                    text: "你没有发布权限！",
                });
                return false;
            }
            if (gd.eAccount.rabbitActivationState == 3) {
                // 该用户所在企业已经停运，不允许发布活动；
                $message.warning({
                    text: "该企业账号状态异常，请联系管理员",
                });
            } else {
                $state.go("trip-prePublish", {
                    tripId: tripId,
                    status: status,
                    igc: igc,
                });
            }
        };

        vm.tripQrCodeNew = function (id) {
            $state.go("tripQrCodeNew", { id: id }, { reload: false });
        };
        vm.tripCoachQrCode = function (id, title, attribute, appSource) {
            $state.go(
                "tripCoachQrCode",
                {
                    id: id,
                    title: title,
                    attribute: attribute,
                    appSource: appSource,
                },
                { reload: false }
            );
        };

        vm.settlementTripId = null;
        vm.settlement = function (tripid) {
            vm.settlementTripId = tripid;
            $("#myModal7").modal("show");
        };
        vm.settlement2 = function () {
            $http({
                url: "/api/tripSettlement/" + vm.settlementTripId,
                method: "GET",
            }).then(
                function suc(res) {
                    console.log("超管提前结算活动", res);
                    $("#myModal7").modal("hide");
                    if (res != null && res.data != null) {
                        if (res.data == -1) {
                            $message.warning({
                                text: "该活动已结算过了！！",
                            });
                        } else if (res.data == 0) {
                            $message.success({
                                text: "结算成功！！",
                            });
                        } else {
                            $message.warning({
                                text: "该活动无企业所属！！",
                            });
                        }
                    }
                },
                function err(err) {
                    console.log(err);
                }
            );
        };

        vm.phoneLogin = false;
        vm.breakPhoneWarn = function () {
            if (vm.phoneLogin) {
                $http({
                    url: "api/tip/change-status/" + 1,
                    method: "POST",
                }).then(function (res) {
                    console.log("修改结果：" + JSON.stringify(res));
                });
            }
            vm.closeModalTip();
        };

        vm.deleteTrip = function () {
            $("#myModal6").modal("show");
        };

        vm.returnPubiish = function (tripId) {
            vm.tripId = tripId;
            for (var i = 0; i < vm.trips.length; i++) {
                if (vm.trips[i].id === tripId) {
                    if (vm.trips[i].status !== "EDIT") {
                        vm.tripStatus = vm.trips[i].status;
                        $("#myModal1").modal("show");
                    }
                }
            }
        };
        vm.disbaledConfirm = false;
        vm.confirm = function () {
            //点击事件执行后将按钮置灰，请求成功后再回复按钮状态
            vm.disbaledConfirm = true;
            var param = { tripId: vm.tripId, status: "EDIT" };
            tripUpdateStatus
                .updateStatus(param)
                .success(function (data) {
                    $("#myModal1").modal("hide");
                    vm.transition();
                    vm.disbaledConfirm = false;
                })
                .error(function (err) {
                    $("#myModal1").modal("hide");
                    $message.error({
                        text: "退回发布失败！",
                    });
                    vm.disbaledConfirm = false;
                });
        };
    }
})();
