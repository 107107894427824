(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("WxGameDetailController", controller);
    controller.$inject = ["$http", "entity"];
    function controller($http, entity) {
        if (entity.id == null || !entity.canShow) {
            alert("无访问权限");
            history.go(-1);
            return;
        }
        var vm = this;
        vm.trip = entity;
        vm.showNormalButton = true;
        vm.testPaper = null;
        vm.showTeams = function (param) {
            if (param.type == "normal" && !vm.trip.hasNormalTeams) {
                $http({
                    method: "GET",
                    url:
                        "/api/trip/getTeamsByTripId/" +
                        param.id +
                        "/" +
                        param.type,
                }).then(
                    function (res) {
                        console.log(res);
                        vm.trip.hasNormalTeams = res.data;
                        vm.showNormalButton = false;
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }
        };
        vm.back = function () {
            window.history.go(-1);
        };
        if (vm.trip.attribute === "BOMB") {
            var url = "/api/bomb/getTestPaperByTripId?tripId=" + vm.trip.id;
            $http({
                method: "POST",
                url: url,
            })
                .success(function (res) {
                    if (res.code == 0) {
                        vm.testPaper = res.data;
                    }
                })
                .error(function () {});
        }
    }
})();
