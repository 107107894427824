(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("TripModelController", controller);
    controller.$inject = [
        "$state",
        "TripModel",
        "TripModelSearch",
        "TripModelAdminSearch",
        "$http",
        "EAccountSearchByIDAndName",
        "Auth",
        "$stateParams",
    ];
    function controller(
        $state,
        TripModel,
        TripModelSearch,
        TripModelAdminSearch,
        $http,
        EAccountSearchByIDAndName,
        Auth,
        $stateParams
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.itemsPerPage = 20;
        vm.page = $stateParams.page;
        vm.predicate = "id";
        vm.reverse = "desc";
        vm.sort = $stateParams.sort;
        vm.searchQuery = $stateParams.search;
        vm.searchType = $stateParams.searchType;
        vm.selectedEAccounts = [];
        vm.sharedToAll = false;
        vm.selectTripModelId = null;
        vm.zhuanRangTripId = 0;
        vm.targetAccount = null;
        vm.zhuanRangType = "1";
        vm.zhuanRangSubmitDisabled = false;
        vm.disabledPlatform1 = false;
        vm.transition = function (params) {
            if (params === 2) {
                vm.sort = vm.predicate + "," + (vm.reverse ? "desc" : "asc");
            }
            if (params === 1 || params === 2) {
                vm.page = 1;
            }
            if (vm.searchQuery) {
                var queryData = {
                    query: vm.searchQuery,
                    searchType: Number(vm.searchType),
                    page: Number(vm.page) - 1,
                    size: vm.itemsPerPage,
                    sort: vm.sort,
                };
                if (vm.searchType == 0) {
                    TripModelSearch.query(queryData, onSuccess, onError);
                } else {
                    TripModelAdminSearch.query(queryData, onSuccess, onError);
                }
            } else {
                TripModel.query(
                    {
                        page: Number(vm.page) - 1,
                        size: vm.itemsPerPage,
                        sort: vm.sort,
                    },
                    onSuccess,
                    onError
                );
            }
            function onSuccess(data, headers) {
                vm.totalItems = headers("X-Total-Count");
                vm.tripModels = data;
            }
            function onError(error) {
                $message.error({
                    text: error.data.message,
                });
            }
        };
        vm.transition();
        vm.keyDown = function (event) {
            if (event.keyCode == 13) {
                vm.transition(1);
            }
        };
        vm.platform1Tip = function () {
            $message.warning({
                text: "基础版已停止支持，可在「更多」中转换为专业版",
            });
        };
        vm.inputPlaceholder = function () {
            $("#searchQuery").attr("placeholder", placeholderString());
            function placeholderString() {
                switch (Number(vm.searchType)) {
                    case 1:
                        return "输入企业全称或者ID";
                        break;
                    case 2:
                        return "输入设计师账号";
                        break;
                    default:
                        return "搜索线路";
                }
            }
        };
        vm.inputPlaceholder();
        vm.getSelectedEAccount = function (tripModelId) {
            $http({
                method: "GET",
                url: "/api/trip-models/getShareEaccounts/" + tripModelId,
            }).then(
                function (res) {
                    if (res.data.errorCode == 1) {
                        vm.sharedToAll = true;
                    } else {
                        vm.sharedToAll = false;
                        if (res.data != "") {
                            vm.selectedEAccounts = res.data;
                        }
                    }
                    vm.selectTripModelId = tripModelId;
                    // console.log(res);
                },
                function (err) {
                    console.log(err);
                }
            );
        };
        vm.zhuanRangTrip = function (tripId) {
            vm.zhuanRangTripId = tripId;
        };
        vm.checkTargetAccount = function () {
            vm.targetAccount = vm.targetAccount.replace(/\D/g, "");
        };
        vm.zhuanRangSubmit = function () {
            if (vm.zhuanRangSubmitDisabled) {
                return false;
            }
            vm.zhuanRangSubmitDisabled = true;
            if (vm.targetAccount === null || vm.targetAccount === "") {
                $message.warning({
                    text: "账号不能为空！",
                });
                return false;
            }
            $http({
                method: "GET",
                url:
                    "/api/trip-models/assignment/" +
                    vm.zhuanRangTripId +
                    "/" +
                    vm.targetAccount,
            }).then(
                function (res) {
                    if (res.data.code === -200 || res.data.code === -300) {
                        $message.error({
                            text: res.data.message,
                        });
                    } else if (res.data.code === 0) {
                        $message.success({
                            text: "线路转让成功！",
                        });
                        $("#zhuan_rang_xian_lu").modal("hide");
                    }
                    vm.zhuanRangSubmitDisabled = false;
                    vm.transition(1);
                },
                function (err) {
                    $message.error({
                        text: "请求服务器出错！",
                    });
                    vm.zhuanRangSubmitDisabled = false;
                }
            );
        };

        vm.dismissFunc = function () {
            $("#share_to_eaccount").modal("hide");
            vm.eaccounts = [];
            vm.selectedEAccounts = [];
        };

        vm.goTraceMoreFunc = function () {
            var local_href = window.location.href;
            var domainName = "127.0.0.1:8888";
            var protocol = "https";
            if (local_href.indexOf("v3.itasktour.com") != -1) {
                domainName = "v3.itasktour.com";
            } else if (local_href.indexOf("deve.itasktour.com") != -1) {
                domainName = "deve.itasktour.com";
            } else {
                protocol = "http";
            }
            window.open(
                protocol +
                    "://" +
                    domainName +
                    "/#/program-center-detail/14870",
                "_blank"
            );
        };

        var ids = [];
        vm.toConfirm = function toConfirm() {
            if (vm.sharedToAll) {
                $http({
                    method: "POST",
                    url:
                        "/api/trip-models/shareToAllEAccounts/" +
                        vm.selectTripModelId,
                }).then(
                    function (res) {
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                        location.reload();
                    },
                    function (err) {
                        //console.log(err);
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                    }
                );
            } else {
                ids = [];
                // console.log(vm.selectedEAccounts);
                for (var i = 0; i < vm.selectedEAccounts.length; i++) {
                    ids[i] = vm.selectedEAccounts[i].id;
                }
                if (ids.length > 80) {
                    alert(
                        "共享企业超过最大限制，请复制新线路，再进行共享操作。"
                    );
                    return;
                }
                // console.log(ids);
                $http({
                    method: "POST",
                    url:
                        "/api/trip-models/setShareEaccounts/" +
                        vm.selectTripModelId,
                    data: ids,
                }).then(
                    function (res) {
                        // console.log(res);
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                        location.reload();
                    },
                    function (err) {
                        // console.log(err);
                        $("#share_to_eaccount").modal("hide");
                        vm.eaccounts = [];
                        vm.selectedEAccounts = [];
                    }
                );
                vm.sharedToAll = false;
            }
            vm.selectedEAccounts = [];
            vm.selectTripModelId = null;
        };
        vm.filterEAccount = function (search) {
            if (search) {
                EAccountSearchByIDAndName.query(
                    {
                        query: search,
                        page: 0,
                        size: vm.itemsPerPage,
                        sort: ["id,desc"],
                    },
                    onSuccess
                );
            }
        };
        function onSuccess(data, headers) {
            console.log(data);
            //vm.eaccounts=data
            if (vm.selectedEAccounts) {
                var filterEaccount = [];
                for (var i = 0; i < data.length; i++) {
                    var flag = true;
                    for (var j = 0; j < vm.selectedEAccounts.length; j++) {
                        if (data[i].id == vm.selectedEAccounts[j].id) {
                            flag = false;
                            break;
                        }
                    }
                    if (flag) {
                        // console.log(data[i])
                        filterEaccount.push(data[i]);
                    }
                }
                vm.eaccounts = filterEaccount;
            } else {
                vm.eaccounts = data;
            }
        }
        vm.mftgManager = false;
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    for (var i = 0; i < vm.account.authorities.length; i++) {
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN") {
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_SUPER") {
                            vm.isSuper = true;
                        }
                    }
                    // 爱闯闯新用户第一次访问线路列表的时候提示提示用户
                    if (
                        vm.account.appSource === "1" &&
                        $(window).width() > 700 &&
                        !vm.account.pcTipShowFlag
                    ) {
                        $("#myModal1").modal("show");
                        $http({
                            url: "api/tip/change-status/0",
                            method: "POST",
                        }).then(function (res) {
                            console.log("修改结果：" + JSON.stringify(res));
                        });
                    }
                    // 后台魔法特工平台用户首次登录后台，展示广告，并展示更新日志
                    if (vm.account.appSource === "2") {
                        // 展示品牌定制的广告
                        getAdByType();
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                    // 判断用户在魔法特工品牌下的权限
                    if (
                        vm.account.authorities.indexOf("ROLE_MFTG_MANAGER") > -1
                    ) {
                        vm.mftgManager = true;
                    }
                    $(".container").removeClass("login-container-content");
                    $("#nav, #foot").css("display", "block");
                    // 用户从线路编辑器返回后台需要判断线路编辑器的token和后台的token是否一致，
                    if ($stateParams.appSource) {
                        if (vm.account.login !== $stateParams.account) {
                            // 线路编辑器和当前域名下的后台登录的账户不一致，退出登录，
                            logoutAppSource($stateParams.appSource);
                        }
                    }
                    if (!vm.account.inWhitelistB && vm.auth != "Super") {
                        vm.disabledPlatform1 = true;
                    }
                }
            });
        }
        function logoutAppSource(appSource) {
            Auth.logout();
            var date = new Date();
            date.setTime(date.getTime() - 1);
            document.cookie =
                "isV3LoginUser=isV3LoginUser;Path=/;domain=itasktour.com;expires=" +
                date.toGMTString();
            //退出到后台登录页
            if (appSource == 2) {
                window.location.href = window.location.origin + "/#/mftglogin";
            } else {
                window.location.href = window.location.origin + "/#/";
            }
        }
        vm.pcLgoinBtn = function () {
            if (vm.tripModels.length != 1) {
                return;
            }
            $(".pcLoginImg").css("display", "block");
            $(".pcLogin").css("z-index", "999");
            $(".modal-backdrop").css("z-index", "800");
        };
        function getAdByType() {
            // 只有从登录界面跳转到该界面才展示广告
            if ($stateParams.login) {
                $http({
                    method: "GET",
                    url: "/api/ad/getByType/" + 2,
                }).then(
                    function (res) {
                        console.log(res);
                        if (res.data != null && res.data.length > 0) {
                            vm.adModal = res.data[0];
                            $("#loginAd").modal("show");
                        } else {
                            // 页面加载版本更新日志
                            getVersion();
                        }
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            } else {
                getVersion();
            }
        }
        vm.adModalClose = function () {
            $("#loginAd").modal("hide");
            // 页面加载版本更新日志
            getVersion();
        };
        //获取 版本更新日志信息
        function getVersion() {
            $http({
                method: "GET",
                url: "/api/newest-version-descs",
            }).then(
                function (res) {
                    console.log("res=", res);
                    //判断获取到的最新的日志是否给用户展示过，是否展示的判断依据是本地是否缓存日志，缓存的日志是否与要展示的日志一样；
                    var updateLog = JSON.parse(
                        window.localStorage.getItem("updateLog")
                    );
                    if (!updateLog || updateLog.name !== res.data.name) {
                        vm.versionDesc = res.data;
                        window.localStorage.setItem(
                            "updateLog",
                            JSON.stringify(vm.versionDesc)
                        );
                        $("#myUpdateLogModal").modal("show");
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }
        //更新提示页面
        vm.toLogin = function () {
            $("#myUpdateLogModal").modal("hide");
        };
        vm.shareTip = function () {
            $message.warning({
                text: "该线路处于共享状态，请复制后生成活动",
            });
        };
        vm.tripNew = tripNew;
        function tripNew(id, title, platform, eTime) {
            $state.go(
                "trip.new",
                {
                    id: id,
                    title: encodeURI(title),
                    platform: platform,
                    eTime: eTime,
                },
                { reload: false }
            );
        }
        vm.newTripModelType = function (type) {
            $state.go("trip-model.new", { type: type }, { reload: false });
        };
        vm.clickEditImg = function (id, type) {
            if (type == 1) {
                var acfeUrl = "";
                var onlyid = btoa(
                    id + "," + vm.account.appSource + "," + vm.account.login
                );
                if (
                    window.location.origin.indexOf("localhost") > -1 ||
                    window.location.hostname.split(".").length === 4
                ) {
                    acfeUrl =
                        window.location.protocol +
                        "//" +
                        window.location.hostname +
                        ":3000/editor/";
                } else {
                    acfeUrl = vm.account.acfeUrl;
                }
                var openUrl =
                    acfeUrl +
                    onlyid +
                    "?at=" +
                    vm.account.password +
                    "_coach&origin=" +
                    btoa(window.location.origin);
                console.log("线路编辑器访问路径openUrl=", openUrl);
                window.open(openUrl, "acfe" + id);
            }
            $(".pcLoginImg").css("display", "none");
        };
    }
})();
