angular
    .module("taskServiceApp")
    .controller("TripQrCodeNewController", controller);
controller.$inject = [
    "$timeout",
    "$uibModalInstance",
    "Principal",
    "$stateParams",
    "$http",
    "$scope",
    "entity",
];
function controller(
    $timeout,
    $uibModalInstance,
    Principal,
    $stateParams,
    $http,
    $scope,
    entity
) {
    var vm = this;
    vm.trip = entity;
    console.log("vm.trip", vm.trip);
    // 测试码的状态  true正式 false测试
    vm.qrCodeStatus = false;
    vm.qrCodeUrl = "";
    vm.isSetCenter = false;
    // 分页器
    vm.page = 1; //当前多少页
    vm.itemsPerPage = 10; //每页多少条
    vm.totalItems = 0; //总共多少条
    vm.exportRecord = []; //存放用户生成二维码行为的记录
    vm.remainNums = 0; //剩余可生成的二维码数量
    vm.allNums = 0; //总共有多少个战二维码
    vm.exprotQrCodeThrottle = false; //是否可以生成二维码
    vm.qrCodeNum = null;
    getAccount();
    function getAccount() {
        Principal.identity().then(function (account) {
            vm.account = account;
            if (vm.account !== null) {
                var author = vm.account.authorities;
                if ($.inArray("ROLE_SUPER", author) !== -1) {
                    // 超管可以生成体验二维码
                    vm.auth = "Super";
                }
            }
        });
    }
    vm.transition = function () {
        console.log("vm.page", vm.page);
        console.log("vm.itemsPerPage", vm.itemsPerPage);
        $http({
            method: "GET",
            url:
                "api/teams/create-team-list/" +
                vm.trip.id +
                "/" +
                (vm.qrCodeStatus ? "0" : "1"),
            params: {
                page: vm.page - 1,
                size: vm.itemsPerPage,
            },
        })
            .success(function (res, status, headers) {
                vm.totalItems = headers("x-total-count");
                vm.exportRecord = res;
            })
            .error(function () {
                console.log("error=", error);
                alert("加载失败，错误日志打印到了控制台");
            });
    };
    //当前页面禁用鼠标右键
    banRight();
    function banRight() {
        if (window.Event) {
            document.captureEvents(Event.MOUSEUP);
        }
        function nocontextmenu() {
            event.cancelBubble = true;
            event.returnvalue = false;
            return false;
        }
        function norightclick(e) {
            if (window.Event) {
                if (e.which == 2 || e.which == 3) return false;
            } else if (event.button == 2 || event.button == 3) {
                event.cancelBubble = true;
                event.returnvalue = false;
                return false;
            }
        }
        document.oncontextmenu = nocontextmenu; // for IE5+
        document.onmousedown = norightclick; //
    }
    vm.clear = clear;
    function clear() {
        $uibModalInstance.dismiss("cancel");
    }
    vm.changeQrCode = changeQrCode;
    function changeQrCode(status) {
        if (vm.qrCodeStatus != status) {
            vm.qrCodeNum = null;
            vm.qrCodeStatus = status;
            if (vm.trip.teamset == 3) {
                vm.page = 1;
                // 获取用户当前还可以生成多少个战队二维码
                getExportDetail();
                //一队一码生成记录
                vm.transition();
            } else {
                vm.qrCodeUrl =
                    vm.account.baseUrl +
                    "mobile/official-trip-c.html?" +
                    (vm.trip.platform == 1 ? "platform=1&" : "") +
                    "tripId=" +
                    vm.trip.id +
                    "&version=2" +
                    (vm.qrCodeStatus ? "" : "&istest=1");
            }
        }
    }
    // 自动帮用户生成正式二维码
    if (!vm.trip.teamset) {
        vm.trip.teamset = 1;
    }
    if (vm.trip.teamset != 3) {
        // 输入编号组队、自动建队
        var autoCodeId = setTimeout(function () {
            changeQrCode(true);
            // angularjs中通过定时器等自己的回调函数直接或者间接的改变angularjs的状态的时候需要手动更新状态；
            $scope.$apply();
            clearTimeout(autoCodeId);
        }, 300);
    } else {
        vm.qrCodeStatus = true;
        // 获取用户当前还可以生成多少个正式二维码
        getExportDetail();
        //一队一码生成记录
        vm.transition();
    }
    vm.downloadQrCode = function () {
        if (!vm.isSetCenter) {
            var qrCodeContainer = $(".qrcode-container")[0].clientWidth;
            var qrCodeWidth = 440;
            if (qrCodeContainer < 440) {
                $(".qrcode-image").css("padding", "20px 9px");
                qrCodeWidth = 298;
            }
            $(".qrcode-image").css(
                "left",
                (qrCodeContainer - qrCodeWidth) / 2 + "px"
            );
            $(".qrcode-image>img").css("left", (qrCodeWidth - 46) / 2 + "px");
            vm.isSetCenter = true;
        }
        $(".qrcode-image").css("visibility", "visible");
        html2canvas($(".qrcode-image"), {
            onrendered: function (canvas) {
                var imgData = canvas.toDataURL("image/jpeg");
                console.log("isIE=", isIE());
                if (isIE()) {
                    $("#myIEModal").modal("show");
                } else {
                    var triggerDownload = $(".download-qrcode").attr({
                        href: imgData,
                        download:
                            "活动" +
                            vm.trip.id +
                            (vm.qrCodeStatus ? "正" : "测") +
                            "式二维码.jpeg",
                    });
                    $(".qrcode-image").css("visibility", "hidden");
                    triggerDownload[0].click();
                }
            },
        });
    };
    //判断是否为IE浏览器；
    function isIE() {
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
            return true;
        } else {
            return false;
        }
    }
    // 获取生成详情
    function getExportDetail() {
        $http({
            method: "GET",
            url:
                "api/teams/export-team-nums/" +
                vm.trip.id +
                "/" +
                (vm.qrCodeStatus ? "0" : "1"),
        }).success(function (res) {
            vm.remainNums = res.data.remainNums;
            vm.allNums = res.data.allNums;
            // 规定测试码必须一次生成五个
            if (!vm.qrCodeStatus) {
                if (vm.remainNums) {
                    vm.qrCodeNum = 5;
                } else {
                    vm.qrCodeNum = null;
                }
            }
        });
    }
    // 生成战队二维码
    vm.exportQrCode = function () {
        if (vm.exprotQrCodeThrottle) {
            return false;
        }
        if (vm.qrCodeNum > vm.remainNums) {
            $message.error({
                text: "剩余数量不足，请先上调队数",
                shade_show: true,
                shade_click: true,
                duration: 5000,
            });
            vm.qrCodeNum = null;
        } else {
            // 调用后台生成excel表格的接口
            var messageDom = $message.success({
                text: "战队二维码生成中。。。",
                shade_show: true,
                duration: 310000,
            });
            vm.exprotQrCodeThrottle = true;
            $http({
                method: "get",
                timeout: 300000,
                url:
                    "/api/teams/" +
                    (vm.qrCodeStatus
                        ? "create-empty-team-normal"
                        : "create-empty-team-test") +
                    "/" +
                    vm.trip.id +
                    "/" +
                    vm.qrCodeNum,
            }).success(function (res) {
                messageDom.removeDom();
                if (res.code == 200) {
                    $message.success({
                        text: "生成战队二维码成功，请在生成记录中点击下载",
                        shade_show: true,
                        shade_click: true,
                        duration: 5000,
                    });
                    getExportDetail();
                    vm.transition();
                    vm.qrCodeNum = null;
                } else {
                    $message.error({
                        text: res.message,
                        shade_show: true,
                        shade_click: true,
                        duration: 5000,
                    });
                }
                vm.exprotQrCodeThrottle = false;
            });
        }
    };
    // 用户下载指定的下载记录的excel表格
    vm.downlaodRecord = function (params) {
        window.location.href = "/api/teams/history-team-export/" + params;
    };
    vm.iknow = function () {
        $("#myIEModal").modal("hide");
    };
}
