(function () {
    "use strict";
    angular.module("taskServiceApp").factory("LoginUrlService", factory);
    factory.$inject = ["$state", "Principal"];
    function factory($state, Principal) {
        return {
            login: function (params, account) {
                console.log("全局方法", params);
                if (
                    account.appSource === "2" ||
                    account.login.indexOf("_mftg") > -1
                ) {
                    if (
                        account.authorities.indexOf("ROLE_MFTG_TRAINER") === -1
                    ) {
                        if (
                            params == null ||
                            Object.keys(params).length === 0
                        ) {
                            var coachJudge =
                                window.localStorage.getItem("coachJudge");
                            if (coachJudge) {
                                window.location.href =
                                    window.location.origin +
                                    window.location.pathname +
                                    coachJudge.replace(/\?.+$/g, "");
                                window.localStorage.removeItem("coachJudge");
                            } else {
                                $state.go("trip-model", { login: true });
                            }
                            return;
                        }
                    } else {
                        var idmftg = setTimeout(function () {
                            $message.warning({
                                text: "魔法特工兼职教练禁止后台",
                            });
                            clearTimeout(idmftg);
                        }, 1000);
                    }
                } else {
                    // 爱闯闯正常登录跳转到方案中心页面
                    if (params == null || Object.keys(params).length === 0) {
                        var coachJudge =
                            window.localStorage.getItem("coachJudge");
                        if (coachJudge) {
                            window.location.href =
                                window.location.origin +
                                window.location.pathname +
                                coachJudge.replace(/\?.+$/g, "");
                            window.localStorage.removeItem("coachJudge");
                        } else {
                            $state.go("program-center", { login: true });
                        }
                        return;
                    }
                    // 企业邀请加入
                    if (params.eid != null && params.uid != null) {
                        $state.go(
                            "e-account-my",
                            { eid: params.eid, uid: params.uid },
                            { reload: false }
                        );
                        return;
                    }
                }
                //用户从外链分享页面跳转到登录页面，登录后需要再跳回到对应的方案详情页
                if (params.url) {
                    window.location.href =
                        window.location.origin + "/#/" + params.url;
                    return;
                }

                // 活动监控跳转到后台登录成功后再跳转回活动监控
                var url = "";
                if (
                    window.location.origin.indexOf("localhost") > -1 ||
                    window.location.hostname.split(".").length === 4
                ) {
                    url =
                        window.location.protocol +
                        "//" +
                        window.location.hostname +
                        ":8080/cd";
                } else {
                    url =
                        "https://www.itasktour.com/" +
                        (window.location.origin.indexOf("dev") === -1
                            ? "coach"
                            : "cd");
                }
                // tripModelId这个字段供极限追踪使用
                if (params.tripId != null && params.cocahTeamId != null) {
                    // 这个地方需要传递给活动监控一个参数，告知活动监控是属于品牌定制的那个品牌
                    window.location.href =
                        url +
                        "?tripId=" +
                        params.tripId +
                        "&coachTeamId=" +
                        params.cocahTeamId +
                        (params.myTripModelId != null
                            ? "&tripModelId=" + params.myTripModelId
                            : "") +
                        "&appSource=" +
                        account.appSource +
                        "&at=" +
                        account.password +
                        "_coach";
                    return;
                }
                // 活动监控没有登录信息，且没有tripId的情况下到后台登录成功后返回活动监控
                if (params.loginType != null) {
                    window.location.href =
                        url +
                        "?appSource=" +
                        account.appSource +
                        "&at=" +
                        account.password +
                        "_coach";
                    return;
                }
                //做最后的判断保证用户正常使用  携带login这个参数的目的是在对应的页面展示一次广告
                if (account.authorities.indexOf("ROLE_MFTG_TRAINER") === -1) {
                    if (
                        account.appSource === "2" ||
                        account.login.indexOf("_mftg") > -1
                    ) {
                        $state.go("trip-model", { login: true });
                    } else {
                        $state.go("program-center", { login: true });
                    }
                }
            },
        };
    }
})();
