(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("ProgramController", ProgramController);

    ProgramController.$inject = [
        "$state",
        "ProgarmSearch",
        "Principal",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "ProgramChangeStatus",
        "$scope",
    ];

    function ProgramController(
        $state,
        ProgarmSearch,
        Principal,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        ProgramChangeStatus,
        $scope
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.modalType = -1; //0上架，1 下架 2 删除
        vm.modalProgram = null; //提示框对应的方案
        vm.copy = false;

        loadAll();

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                for (var i = 0; i < vm.account.authorities.length; i++) {
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN") {
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER") {
                        vm.isSuper = true;
                    }
                }
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        function loadAll() {
            ProgarmSearch.query(
                {
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    release: "0",
                },
                onSuccess,
                onError
            );
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.programs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            console.log("页数：" + vm.page);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = "id";
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = "id";
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.keyDown = function (event) {
            if (event.keyCode == 13) {
                vm.search(vm.searchQuery);
            }
        };

        vm.clickEditImg = function (id) {
            var acfeUrl = "";
            var onlyid = btoa(
                id + "," + vm.account.appSource + "," + vm.account.login
            );
            if (
                window.location.origin.indexOf("localhost") > -1 ||
                window.location.hostname.split(".").length === 4
            ) {
                acfeUrl =
                    window.location.protocol +
                    "//" +
                    window.location.hostname +
                    ":3000/editor/";
            } else {
                acfeUrl = vm.account.acfeUrl;
            }
            window.open(
                acfeUrl +
                    onlyid +
                    "?at=" +
                    vm.account.password +
                    "_coach&origin=" +
                    btoa(window.location.origin),
                "acfe" + id
            );
        };

        vm.close = function () {
            $("#myModal1").modal("hide");
            $("#myModal2").modal("hide");
            vm.modalType = -1; //0上架，1 下架 2 删除
            vm.modalProgram = null; //
        };

        vm.openModel = function (type, program) {
            vm.modalType = type; //0上架，1 下架 2 删除
            vm.modalProgram = program; //
            $("#myModal1").modal("show");
        };

        vm.tipSuccess = 0;
        var flag = 1;
        vm.ChangeStatus = function () {
            var message = "";
            switch (vm.modalType) {
                case 0: //上架
                    if (vm.modalProgram.status == 1) {
                        message = "该方案已经上架";
                    }
                    break;
                case 1: //下架
                    if (vm.modalProgram.status == 0) {
                        message = "该方案未上架";
                    }
                    break;
                case 2: //删除
                    //将绑定线路的绑定状态变更为
                    if (vm.modalProgram.status == 1) {
                        message = "该方案已经上架无法删除";
                    }
                    break;
            }
            if (message.length > 0) {
                vm.close();
                alert(message);
                return;
            }
            flag = vm.modalType;
            ProgramChangeStatus.change(
                {
                    id: vm.modalProgram.id,
                    status: vm.modalType,
                },
                onSuccess,
                onError
            );
            function onSuccess(data) {
                if (data.code != 0) {
                    alert(data.message);
                    return;
                }
                vm.close();
                loadAll();
                if (flag == 0) {
                    vm.tipSuccess = 1;
                    setTimeout(function () {
                        vm.tipSuccess = 0;
                    }, 300);
                }
            }
            function onError(error) {}
        };

        var location = window.location.host;
        vm.domain = window.location.protocol + "//" + location;
        vm.openCopyModel = function (id) {
            vm.joinLink = vm.domain + "/#/program-center-detail-share/" + id;
            // vm.joinLink = id
            vm.copy = false;
            $("#myModal2").modal("show");
        };

        vm.copyLink = copyLink;
        function copyLink() {
            var Url2 = document.getElementById("joinLink").value;
            var oInput = document.createElement("input");
            oInput.value = Url2;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            var tag = document.execCommand("Copy"); // 执行浏览器复制命令
            oInput.className = "oInput";
            oInput.style.display = "none";
            if (tag) {
                vm.copy = true;
            }
        }

        $scope.$on("$destroy", function () {
            vm.close();
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");
        });
    }
})();
