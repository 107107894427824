(function () {
    angular
        .module("taskServiceApp")
        .controller("TripCoachWebHintController", controller);
    controller.$inject = ["$stateParams"];
    function controller($stateParams) {
        var vm = this;
        vm.qrCodeLocal = null;
        var url = "";
        if (
            window.location.origin.indexOf("localhost") > -1 ||
            window.location.hostname.split(".").length === 4
        ) {
            url = "https://www.itasktour.com/cd";
        } else {
            url =
                "https://www.itasktour.com/" +
                (window.location.origin.indexOf("dev") === -1 ? "coach" : "cd");
        }
        vm.qrCode = url + "?appSource=" + $stateParams.appSource;
        if (
            window.location.origin.indexOf("localhost") > -1 ||
            window.location.hostname.split(".").length === 4
        ) {
            vm.qrCodeLocal =
                window.location.protocol +
                "//" +
                window.location.hostname +
                ":8080/cd/?at=" +
                gd.account.password +
                "_coach&appSource=" +
                $stateParams.appSource;
        }
    }
})();
