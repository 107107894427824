(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("EAccountMyPayController", controller);

    controller.$inject = [
        "$cookies",
        "$scope",
        "$rootScope",
        "$http",
        "$state",
        "$stateParams",
        "getOrderByOrderId",
        "$interval",
        "previousState",
        "entity",
        "UserSearch",
    ];

    function controller(
        $cookies,
        $scope,
        $rootScope,
        $http,
        $state,
        $stateParams,
        getOrderByOrderId,
        $interval,
        previousState,
        entity,
        UserSearch
    ) {
        var vm = this;
        vm.phone = null;
        vm.order = null;
        vm.eAccount = entity;
        vm.previousState = previousState.name;
        vm.itemsPerPage = 250;
        vm.toError = toError;
        vm.toSuccess = toSuccess;

        if ($stateParams.orderId != null) {
            vm.order = getOrderByOrderId.get({ orderId: $stateParams.orderId });
        }
        // console.log(vm.order);

        //每3s轮询一次,轮循45s(15次)后结束
        var stop = $interval(
            function () {
                $http({
                    method: "GET",
                    url: "/api/get-order-status",
                    params: { out_trade_no: $stateParams.orderId },
                })
                    .success(function (data) {
                        // console.log(data);
                        vm.payStatus = data;
                        if (data.errorCode === 0) {
                            _czc.push([
                                "_trackEvent",
                                "充值",
                                "支付成功",
                                "",
                                rootCoupons,
                                "",
                            ]);

                            $interval.cancel(stop);
                            // 如果是修改活动人数导致的额外扣点（企业账户余额不足）跳转到该页面充值，充值成功之后需要跳转会原来的页面；
                            //查询充值成功跳转不同页面
                            if ($cookies.get("publish") != null) {
                                $cookies.remove("publish");
                                $state.go("trip", null, { reload: "trip" });
                            } else {
                                $state.go("e-account-my-pay-success");
                            }
                        }
                    })
                    .error(function (err) {
                        console.log(err);
                        $interval.cancel(stop);
                    });
            },
            3000,
            15
        );
        stop.then(function () {
            $("#myModal1").modal("show");
        });
        //离开页面后停止轮询
        $scope.$on("$destroy", function () {
            $interval.cancel(stop);
        });

        vm.filterUser = this.filterUser;
        this.filterUser = function (search) {
            if (search) {
                vm.users = UserSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
            }
        };
        function toError() {
            $("#myModal1").modal("hide");
            window.setTimeout(function () {
                $interval.cancel(stop);
                $state.go("e-account-my-pay-error");
            }, 300);
        }
        function toSuccess() {
            $("#myModal1").modal("hide");
            $interval.cancel(stop);
            //判断是否支付成功，成功则跳到成功页面，失败则跳到失败页面
            // console.log(vm.payStatus.errorCode);
            if (vm.payStatus.errorCode === 0) {
                window.setTimeout(function () {
                    $state.go("e-account-my-pay-success");
                }, 300);
            } else if (vm.payStatus.errorCode === 1) {
                window.setTimeout(function () {
                    $state.go("e-account-my-pay-wait");
                }, 300);
            }
        }

        function onSaveSuccess(result) {
            vm.phone = null;
            $scope.$emit("taskServiceApp:eAccountUpdate", result);
        }

        function onSaveError() {}

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:eAccountUpdate",
            function (event, result) {
                vm.eAccount = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
