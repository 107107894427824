(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("LocationTaskController", LocationTaskController);

    LocationTaskController.$inject = [
        "$scope",
        "$state",
        "$http",
        "Principal",
        "LocationTask",
        "LocationTaskSearch",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
    ];

    function LocationTaskController(
        $scope,
        $state,
        $http,
        Principal,
        LocationTask,
        LocationTaskSearch,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        function loadAll() {
            if (pagingParams.search) {
                LocationTaskSearch.query(
                    {
                        query: pagingParams.search,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
            } else {
                LocationTask.query(
                    {
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
            }
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.locationTasks = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        // vm.locationTasks[i].des;
        // function getLocation(id) {
        //     $http({
        //         method: 'GET',
        //         url: '/api/locations/'+id
        //     }).success(function (data) {
        //         if (data!=null){
        //             vm.locationTask.myLocationId = {id:data.id,description:data.description};
        //         }
        //         // console.log(data);
        //     }).error(function(err){
        //         console.log(err);
        //     });
        // }

        // function getTrip(id) {
        //     $http({
        //         method: 'GET',
        //         url: '/api/trips/'+id
        //     }).success(function (data) {
        //         if (data!=null){
        //             vm.tripTitle = data.title;
        //         }
        //     }).error(function(err){
        //         console.log(err);
        //     });
        // }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = "_score";
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = "id";
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
