(function () {
    "use strict";

    angular.module("taskServiceApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("avatar", {
                parent: "entity",
                url: "/avatar?page&sort&search",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.avatar.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/avatar/avatars.html",
                        controller: "AvatarController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                },
            })
            .state("avatar-detail", {
                parent: "avatar",
                url: "/avatar/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.avatar.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/avatar/avatar-detail.html",
                        controller: "AvatarDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "Avatar",
                        function ($stateParams, Avatar) {
                            return Avatar.get({ id: $stateParams.id }).$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "avatar",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("avatar-detail.edit", {
                parent: "avatar-detail",
                url: "/detail/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/avatar/avatar-dialog.html",
                                controller: "AvatarDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Avatar",
                                        function (Avatar) {
                                            return Avatar.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("^", {}, { reload: false });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("avatar.new", {
                parent: "avatar",
                url: "/new",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/avatar/avatar-dialog.html",
                                controller: "AvatarDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: function () {
                                        return {
                                            name: null,
                                            avatar: null,
                                            createDate: null,
                                            createBy: null,
                                            updateDate: null,
                                            updateBy: null,
                                            id: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("guide", null, {
                                        reload: "guide",
                                    });
                                },
                                function () {
                                    $state.go("guide");
                                }
                            );
                    },
                ],
            })
            .state("avatar.edit", {
                parent: "avatar",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/avatar/avatar-dialog.html",
                                controller: "AvatarDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Avatar",
                                        function (Avatar) {
                                            return Avatar.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("avatar", null, {
                                        reload: "avatar",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            })
            .state("avatar.delete", {
                parent: "avatar",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/avatar/avatar-delete-dialog.html",
                                controller: "AvatarDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "Avatar",
                                        function (Avatar) {
                                            return Avatar.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("avatar", null, {
                                        reload: "avatar",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            });
    }
})();
