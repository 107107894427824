(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("TsTripQrCodeController", controller);

    controller.$inject = [
        "$timeout",
        "$uibModalInstance",
        "TripSearchOfficial",
        "Principal",
        "$stateParams",
    ];

    function controller(
        $timeout,
        $uibModalInstance,
        TripSearchOfficial,
        Principal,
        $stateParams
    ) {
        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;

        if ($stateParams.id != null && $stateParams.id != "") {
            vm.trip = [];
            vm.trip.id = $stateParams.id;
            vm.trip.title = $stateParams.title;
            vm.trip.type = $stateParams.type;
            if (vm.trip.type == "Indoor") {
                vm.trip.type = "室内版";
            } else {
                vm.trip.type = "室外版";
            }
        }

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    }
                }
            });
        }

        this.filterTrip = function (search) {
            if (search) {
                vm.trips = TripSearchOfficial.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
            }
        };

        vm.clearQrCode = clearQrCode;
        function clearQrCode() {
            document.getElementById("qrCodeTest").style.display = "none";
            document.getElementById("qrCodeNormal").style.display = "none";
        }

        banRight(); //当前页面禁用鼠标右键
        function banRight() {
            if (window.Event) document.captureEvents(Event.MOUSEUP);

            function nocontextmenu() {
                event.cancelBubble = true;
                event.returnvalue = false;
                return false;
            }

            function norightclick(e) {
                if (window.Event) {
                    if (e.which == 2 || e.which == 3) return false;
                } else if (event.button == 2 || event.button == 3) {
                    event.cancelBubble = true;
                    event.returnvalue = false;
                    return false;
                }
            }
            document.oncontextmenu = nocontextmenu; // for IE5+
            document.onmousedown = norightclick; //
        }
        var qrCodeUrl =
            window.location.origin.indexOf("dev") === -1
                ? "https://www.itasktour.com/"
                : "http://dev.itasktour.com/";
        qrCodeUrl = qrCodeUrl + "api/common/ittr/ittrts.php?tripId=";
        vm.clickNormal = showQrCodeNormal;
        function showQrCodeNormal(trip) {
            var location = window.location.host;
            // vm.qrCodeNormal = window.location.protocol+"//"+location+"/mobile/ts-trip-c.html?tripId=" + trip.id;
            vm.qrCodeNormal = qrCodeUrl + trip.id;
            document.getElementById("qrCodeTest").style.display = "none";
            document.getElementById("qrCodeNormal").style.display = "block";
        }
        vm.clickTest = showQrCodeTest;
        function showQrCodeTest(trip) {
            var location = window.location.host;
            // vm.qrCodeTest = window.location.protocol+"//"+location+"/mobile/ts-trip-c.html?tripId=" + trip.id + "&istest=1";
            vm.qrCodeTest = qrCodeUrl + trip.id + "&istest=1";
            document.getElementById("qrCodeNormal").style.display = "none";
            document.getElementById("qrCodeTest").style.display = "block";
        }

        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            } else {
                return false;
            }
        }
        vm.downLoadQr = downLoadQr;
        function downLoadQr(codeType) {
            if (codeType == "normal") {
                var down = "活动" + vm.trip.id + "正式二维码.png";
                if (down == $(".downNor")[0].download) {
                    return;
                }
                html2canvas($(".qrNormal"), {
                    onrendered: function (canvas) {
                        var type = "png";
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(
                            _fixType(type),
                            "image/octet-stream"
                        );
                        if (isIE()) {
                            $("#myIEModal").modal("show");
                        } else {
                            // $('.downNor').attr({
                            //     'href':imgData,
                            //     'download':'活动'+vm.trip.id+'正式二维码.png'
                            // });
                            var triggerDownload = $(".downNor")
                                .attr({
                                    href: imgData,
                                    download: down,
                                })
                                .unbind("click");
                            triggerDownload[0].click();
                        }
                    },
                });
            } else {
                var down = "活动" + vm.trip.id + "测试二维码.png";
                if (down == $(".downTes")[0].download) {
                    return;
                }
                html2canvas($(".qrTest"), {
                    onrendered: function (canvas) {
                        var type = "png";
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(
                            _fixType(type),
                            "image/octet-stream"
                        );
                        if (isIE()) {
                            // console.log(isIE());
                            $("#myIEModal").modal("show");
                        } else {
                            // $('.downTes').attr({
                            //     'href':imgData,
                            //     'download':'活动'+vm.trip.id+'测试二维码.png'
                            // });
                            var triggerDownload = $(".downTes")
                                .attr({
                                    href: imgData,
                                    download: down,
                                })
                                .unbind("click");
                            triggerDownload[0].click();
                        }
                    },
                });
            }

            var _fixType = function (type) {
                type = type.toLowerCase().replace(/jpg/i, "jpeg");
                var r = type.match(/png|jpeg|bmp|gif/)[0];
                return "image/" + r;
            };
        }
        vm.iknow = iknow;
        function iknow() {
            $("#myIEModal").modal("hide");
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function save() {}

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
