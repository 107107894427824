(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("AvatarDetailController", AvatarDetailController);

    AvatarDetailController.$inject = [
        "$scope",
        "$rootScope",
        "Principal",
        "$stateParams",
        "previousState",
        "entity",
        "Avatar",
    ];

    function AvatarDetailController(
        $scope,
        $rootScope,
        Principal,
        $stateParams,
        previousState,
        entity,
        Avatar
    ) {
        var vm = this;

        vm.avatar = entity;
        vm.previousState = previousState.name;
        vm.IMAGE_SERVER = IMAGE_SERVER;

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:avatarUpdate",
            function (event, result) {
                vm.avatar = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
