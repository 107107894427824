(function () {
    "use strict";
    angular.module("taskServiceApp").factory("BaseTaskSearch", BaseTaskSearch);
    BaseTaskSearch.$inject = ["$resource"];
    function BaseTaskSearch($resource) {
        var resourceUrl = "api/_search/base-tasks/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
