(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("weChatTripSearch", weChatTripSearch);
    weChatTripSearch.$inject = ["$resource"];
    function weChatTripSearch($resource) {
        var resourceUrl = "api/trip/weChat/game/";
        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("StealthTripSearch", StealthTripSearch);
    StealthTripSearch.$inject = ["$resource"];
    function StealthTripSearch($resource) {
        var resourceUrl = "api/stealth/_search/trips/";
        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
(function () {
    "use strict";
    angular.module("taskServiceApp").factory("BombTripSearch", BombTripSearch);
    BombTripSearch.$inject = ["$resource"];
    function BombTripSearch($resource) {
        var resourceUrl = "api/bomb/_search/trips/";
        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
