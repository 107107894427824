(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("TripImportController", TripImportController);
    TripImportController.$inject = [
        "$scope",
        "$rootScope",
        "$stateParams",
        "previousState",
        "entity",
        "$sce",
        "Trip",
        "LocationTask",
        // "TripType",
        "User",
        "Team",
        "Upload",
    ];

    function TripImportController(
        $scope,
        $rootScope,
        $stateParams,
        previousState,
        entity,
        $sce,
        Trip,
        LocationTask,
        // TripType,
        User,
        Team,
        Upload
    ) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.trip = entity;
        vm.onFileSelect = onFileSelect;
        vm.uploadFile = null;
        vm.status = null;
        vm.result = null;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.uploadProgress = null;
        $scope.tack_sb = 1;

        var ispass = true;

        function onFileSelect(uploadFile) {
            console.log("start check file");
            vm.result = "";
            vm.status = "";
            checkFile(uploadFile);
        }
        var wb; //读取完成的数据
        var rABS = false; //是否将文件读取为二进制字符串
        function checkFile(uploadFile) {
            ispass = true; //导入
            var check_result = "";
            var f = uploadFile;
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                if (rABS) {
                    //                wb = XLSX.read(btoa(fixdata(data)), {//手动转化
                    //                    type: 'base64'
                    //                });
                } else {
                    wb = XLSX.read(data, { type: "binary" });
                }
                //wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
                //wb.Sheets[Sheet名]获取第一个Sheet的数据
                var result = JSON.stringify(
                    XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
                );
                var jsonObj = eval(result);
                for (var i = 0; i < jsonObj.length; i++) {
                    if (jsonObj[i].length > 6 || jsonObj[i] < 3) {
                        ispass = false;
                        check_result +=
                            "请按照要求填写，不要多填:" +
                            "row:" +
                            (i + 1) +
                            "<br>";
                    }
                    if (jsonObj[i].hasOwnProperty("login_number")) {
                    } else {
                        check_result +=
                            "表格中存在登录号码为空:" +
                            "row:" +
                            (i + 1) +
                            "<br>";
                        console.log(
                            "表格中存在登录号码为空:" + "row:" + (i + 1)
                        );
                        ispass = false;
                    }
                    if (jsonObj[i].hasOwnProperty("name")) {
                    } else {
                        check_result +=
                            "表格中存在姓名为空:" + "row:" + (i + 1) + "<br>";
                        //vm.result = $sce.trustAsHtml("表格中存在姓名为空:" + "row:"+(i+1));
                        console.log("表格中存在姓名为空:" + "row:" + (i + 1));
                        ispass = false;
                    }
                    if (jsonObj[i].hasOwnProperty("team")) {
                    } else {
                        check_result +=
                            "表格中存在队伍名为空:" + "row:" + (i + 1) + "<br>";
                        //vm.result = $sce.trustAsHtml("表格中存在队伍名为空:" + "row:"+(i+1));
                        console.log("表格中存在队伍名为空:" + "row:" + (i + 1));
                        ispass = false;
                    }
                }

                vm.result = $sce.trustAsHtml(check_result);
                if (ispass) {
                    var uploadImageFile = function () {
                        Upload.upload({
                            url: "/api/upload/" + $stateParams.id + "/users",
                            fields: {},
                            file: uploadFile,
                            method: "POST",
                        })
                            .progress(function (evt) {
                                vm.uploadProgress = parseInt(
                                    (100.0 * evt.loaded) / evt.total
                                );
                                console.log(
                                    "progress: " + vm.uploadProgress + "% "
                                );
                            })
                            .success(function (data, status, headers, config) {
                                vm.uploadProgress = null;
                                vm.status = data.messages[0];
                                vm.result = $sce.trustAsHtml(data.messages[1]);
                                $message.success({
                                    text: "导入成功！",
                                });
                            })
                            .error(function (data, status, headers, config) {
                                vm.uploadProgress = null;
                                console.log("error status: " + status);
                                $message.error({
                                    text: "导入名单失败，错误码：" + status,
                                });
                            });
                    };

                    if (uploadFile != null) {
                        uploadImageFile();
                    }
                }
            };
            if (rABS) {
                //            reader.readAsArrayBuffer(f);
            } else {
                if (f != null) {
                    reader.readAsBinaryString(f);
                }
            }
        }
    }
})();
