(function () {
    "use strict";

    angular.module("taskServiceApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("user-management", {
                parent: "admin",
                url: "/user-management?page&sort",
                data: {
                    authorities: ["ROLE_SUPER", "ROLE_ADMIN"],
                    pageTitle: "userManagement.home.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/admin/user-management/user-management.html",
                        controller: "UserManagementController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("user-management");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("user-management-detail", {
                parent: "admin",
                url: "/user:{id}/:login",
                data: {
                    authorities: ["ROLE_SUPER", "ROLE_ADMIN"],
                    pageTitle: "user-management.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/admin/user-management/user-management-detail.html",
                        controller: "UserManagementDetailController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    id: null,
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("user-management");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("user-management.new", {
                parent: "user-management",
                url: "/new",
                data: {
                    authorities: ["ROLE_SUPER", "ROLE_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/admin/user-management/user-management-dialog.html",
                                controller: "UserManagementDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: function () {
                                        return {
                                            id: null,
                                            login: null,
                                            firstName: null,
                                            lastName: null,
                                            email: null,
                                            activated: true,
                                            langKey: null,
                                            createdBy: null,
                                            createdDate: null,
                                            lastModifiedBy: null,
                                            lastModifiedDate: null,
                                            resetDate: null,
                                            resetKey: null,
                                            authorities: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("user-management", null, {
                                        reload: true,
                                    });
                                },
                                function () {
                                    $state.go("user-management");
                                }
                            );
                    },
                ],
            })
            .state("user-management.edit", {
                parent: "user-management",
                url: "/{login}/edit",
                data: {
                    authorities: ["ROLE_SUPER", "ROLE_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/admin/user-management/user-management-dialog.html",
                                controller: "UserManagementDialogController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "lg",
                                resolve: {
                                    entity: [
                                        "User",
                                        function (User) {
                                            return User.get({
                                                login: $stateParams.login,
                                            });
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("user-management", null, {
                                        reload: true,
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            });
    }
})();
