(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('MyEAccountMemberController', MyEAccountMemberController);

    MyEAccountMemberController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'User','UserSearch','MyEAccount','MyEAccontUpdate','$http'];

    function MyEAccountMemberController($scope, $rootScope, $state, $stateParams, previousState, entity, User,UserSearch,MyEAccount,MyEAccontUpdate,$http) {
        var vm = this;
        vm.phone = null;
        vm.account=null;
        vm.eAccount = entity;
        console.log('vm.eAccount=',vm.eAccount);
        vm.previousState = previousState.name;
        vm.itemsPerPage = 250;
        vm.back = back;
        vm.toTrip = toTrip;
        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
            });
        }
        vm.designPhone=null;
        vm.createOrEdit=true;
        vm.identityType='1';
        vm.addDesign=function(){
            if(vm.account.appSource==='1'){
                $state.go("e-account-member.new");
            }else if(vm.account.appSource==='2'){
                vm.designPhone=null;
                vm.createOrEdit=true;
                vm.identityType='1';
                $('#myModal_add_member').modal('show');
            }
        };
        vm.checkPhone=function(){
            vm.designPhone=vm.designPhone.replace(/\D/g,'');
        };
        vm.addThrottle=true;
        vm.addDesignMember=function(){
            if(!vm.addThrottle){
                return false;
            }
            if(vm.designPhone===''){
                $message.warning({
                    text:'手机号不能为空',
                });
                return false;
            }
            if(vm.designPhone.length!==11){
                $message.warning({
                    text:'请输入正确的手机号格式',
                });
                return false;
            }
            vm.addThrottle=false;
            if(vm.createOrEdit){
                if(vm.identityType==='1'){
                    $http({
                        url: 'api/registerUserDesigner',
                        method: 'POST',
                        params:{
                            eaccountId:vm.eAccount.id,
                            phone:vm.designPhone,
                            pwd:vm.designPhone.slice(5)
                        }
                    }).then(function (res) {
                        vm.addThrottle=true;
                        vm.designPhone=null;
                        $('#myModal_add_member').modal('hide');
                        if(res.data.errorCode===0){
                            showHasUsers();
                            $message.success({
                                text:'添加成功',
                            });
                        }else if(res.data.errorCode===-100||res.data.errorCode===-200||res.data.errorCode===-300){
                            $message.error({
                                text:res.data.message
                            });
                        }
                    });
                }else if(vm.identityType==='2'){
                    $http({
                        url: 'api/registerPartTimeCoach',
                        method: 'POST',
                        params:{
                            eaccountId:vm.eAccount.id,
                            phone:vm.designPhone,
                            pwd:vm.designPhone.slice(5)
                        }
                    }).then(function (res) {
                        vm.addThrottle=true;
                        vm.designPhone=null;
                        $('#myModal_add_member').modal('hide');
                        if(res.data.errorCode===0){
                            showHasUsers();
                            $message.success({
                                text:'添加成功',
                            });
                        }else if(res.data.errorCode===-100||res.data.errorCode===-200||res.data.errorCode===-300){
                            $message.error({
                                text:res.data.message
                            });
                        }
                    });
                }
            }else{
                $http({
                    url: 'api/editSwitchMftgRole',
                    method: 'POST',
                    params:{
                        phone:vm.designPhone,
                    }
                }).then(function (res) {
                    console.log('编辑res=',res);
                    vm.addThrottle=true;
                    vm.designPhone=null;
                    $('#myModal_add_member').modal('hide');
                    if(res.data.errorCode===0){
                        showHasUsers();
                        $message.success({
                            text:'编辑成功',
                        });
                    }else if(res.data.errorCode===-100||res.data.errorCode===-200){
                        $message.error({
                            text:res.data.message
                        });
                    }
                });

            }
            
        };
        vm.filterUser = function(search) {
            if(search){
                vm.users = UserSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
            }
        };
        load();
        function load() {
            // if (vm.eAccount.confirmEmail===false){
            //     $('#myModal1').modal('show');
            // }
            addLayout();
        }
        function back() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                $state.go("e-account-my.edit");
            },300);
        }
        function toTrip() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                $state.go("trip");
            },300);
        }
        function addLayout() {
            document.getElementById("nav").style.display="block";
            document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }
        showHasUsers();
        function showHasUsers() {
            if (vm.eAccount.id != null){
                $http({
                    method: 'GET',
                    url: '/api/e-accounts/findAllUserByEAccountId/' + vm.eAccount.id
                }).then(function (res) {
                    // console.log(res);
                    vm.eAccount.hasUsers = res.data.hasUsers;
                    vm.eAccount.hasDesignUsers = res.data.hasDesignUsers;
                    vm.eAccount.hasTraineeUsers = res.data.hasTraineeUsers;
                    vm.eAccount.hasMftgTrainerUsers = res.data.hasMftgTrainerUsers;
                },function (err) {
                    console.log(err);
                });
            }
        }
        vm.addDesiger = function () {
            MyEAccount.addDegigner({eid:vm.eAccount.id,login:vm.phone},onSaveSuccess,onSaveError);
        };

        vm.deleteDesignUser=null;
        vm.deleteDesign=function(hasUser){
            vm.deleteDesignUser=hasUser;
            $('#myModal_delete_member').modal('show');
        };
        vm.deleteDesign2 = function(){
            MyEAccount.deleteDegigner({eid:vm.eAccount.id,uid:vm.deleteDesignUser.id,isTrainee:0,},function(result){
                $('#myModal_delete_member').modal('hide');
                $scope.$emit('taskServiceApp:eAccountUpdate', result);
                showHasUsers();
                $message.success({
                    text:'删除成功',
                });
            },onSaveError);
        };
        vm.editDesign=function(hasUser){
            console.log('hasUser=',hasUser);
            vm.designPhone=hasUser.login.split('_mftg')[0];
            vm.createOrEdit=false;
            if(hasUser.authorities.indexOf('ROLE_USERDESIGN')!==-1){
                vm.identityType='1';
            }else if(hasUser.authorities.indexOf('ROLE_MFTG_TRAINER')!==-1){
                vm.identityType='2';
            }else if(hasUser.authorities.indexOf('ROLE_MFTG_MANAGER')!==-1){
                $message.success({
                    text:'禁止编辑品牌管理员角色权限！',
                });
                return false;
            }else{
                $message.success({
                    text:'禁止编辑该用户！',
                });
                return false;
            }
            $('#myModal_add_member').modal('show');
        };

        vm.updateToDesign = function(hasUser){
            var index = vm.eAccount.hasTraineeUsers.indexOf(hasUser);
            var userId = vm.eAccount.hasTraineeUsers[index].id;
            //console.log(vm.eAccount.hasTraineeUsers);
            MyEAccontUpdate.updateDesign({eid:vm.eAccount.id,uid:userId,isTrainee:0},onSaveSuccess,onSaveError);
        };

        vm.updateToTrainee = function(hasUser){
            var index = vm.eAccount.hasDesignUsers.indexOf(hasUser);
            var userId = vm.eAccount.hasDesignUsers[index].id;
            MyEAccontUpdate.updateDesign({eid:vm.eAccount.id,uid:userId,isTrainee:1},onSaveSuccess,onSaveError);
        };

        function onSaveSuccess (result) {
            vm.phone = null;
            $scope.$emit('taskServiceApp:eAccountUpdate', result);
            // console.log(result);
            showHasUsers();
        }
        function onSaveError () {
        }
        var unsubscribe = $rootScope.$on('taskServiceApp:eAccountUpdate', function(event, result) {
            vm.eAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
