(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("UseHelpController", UseHelpController);

    UseHelpController.$inject = [
        "$scope",
        "$rootScope",
        "previousState",
        "Upload",
        "Ahdin",
        "Principal",
        "$http",
    ];

    function UseHelpController(
        $scope,
        $rootScope,
        previousState,
        Upload,
        Ahdin,
        Principal,
        $http
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        // vm.feedback = entity;
        vm.previousState = previousState.name;

        getAccount();
        function getAccount() {
            // Principal.identity().then(function(account) {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect(uploadFile) {
            uploadHelp(uploadFile);
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: "/api/postfile",
                    fields: {},
                    data: { type: "HELP" },
                    file: uploadFile,
                    method: "POST",
                })
                    .progress(function (evt) {
                        var progressPercentage = parseInt(
                            (100.0 * evt.loaded) / evt.total
                        );
                        console.log("progress: " + progressPercentage + "% ");
                    })
                    .success(function (data, status, headers, config) {
                        console.log(data);
                    })
                    .error(function (data, status, headers, config) {
                        console.log("error status: " + status);
                    });
            }
        }

        vm.onFileSelects = onFileSelects;
        function onFileSelects(uploadFile) {
            uploadHelp(uploadFile);
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: "/api/postfile",
                    fields: {},
                    data: { type: "OPERATION" },
                    file: uploadFile,
                    method: "POST",
                })
                    .progress(function (evt) {
                        var progressPercentage = parseInt(
                            (100.0 * evt.loaded) / evt.total
                        );
                        console.log("progress: " + progressPercentage + "% ");
                    })
                    .success(function (data, status, headers, config) {
                        console.log(data);
                    })
                    .error(function (data, status, headers, config) {
                        console.log("error status: " + status);
                    });
            }
        }

        vm.onFileSelectss = onFileSelectss;
        function onFileSelectss(uploadFile) {
            uploadHelp(uploadFile);
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: "/api/postfile",
                    fields: {},
                    data: { type: "QA" },
                    file: uploadFile,
                    method: "POST",
                })
                    .progress(function (evt) {
                        var progressPercentage = parseInt(
                            (100.0 * evt.loaded) / evt.total
                        );
                        console.log("progress: " + progressPercentage + "% ");
                    })
                    .success(function (data, status, headers, config) {
                        console.log(data);
                    })
                    .error(function (data, status, headers, config) {
                        console.log("error status: " + status);
                    });
            }
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:feedbackUpdate",
            function (event, result) {
                vm.feedback = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
