(function () {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('TripCoachQrCodeController', controller);
    controller.$inject = ['$timeout', '$uibModalInstance','TripSearchOfficial','$http','$stateParams'];
    function controller ($timeout, $uibModalInstance,TripSearchOfficial,$http,$stateParams) {
        var vm = this;
        vm.clear = clear;
        vm.coachTeamInfo = {};
        if($stateParams.id != null && $stateParams.id != ''){
            vm.trip={};
            vm.trip.id = $stateParams.id;
            vm.trip.title = $stateParams.title;
            vm.trip.attribute = $stateParams.attribute;
            vm.showWeb = $stateParams.showWeb;
            vm.tripModelsIdChange = true;
            showQrCode(vm.trip.id,$stateParams.appSource);
        }else{
            //没有活动id后退
            window.history.go(-1);
            return;
        }
        vm.qrCodeWeb=null;
        vm.qrCodeLocal=null;
        function showQrCode(id,appSource) {
            $http({
                method: 'GET',
                url: 'api/trips/'+id
            }).then(function (res) {
                console.log(res);
                if (res != null){
                    var url='';
                    if(window.location.origin.indexOf('localhost')>-1||window.location.hostname.split('.').length===4){
                        url='https://www.itasktour.com/cd';
                    }else{
                        url = 'https://www.itasktour.com/' + (window.location.origin.indexOf('dev') ===-1 ? "coach" : "cd");
                    }
                    vm.qrCodeWeb = url + '?tripId=' + id+'&appSource='+appSource;
                    // 如果是本地环境，额外提供一个url路径，复制这个路径可以直接打开本地运行的活动监控，是本地开发直接把token传递过去省去了登录环节
                    if(window.location.origin.indexOf('localhost')>-1||window.location.hostname.split('.').length===4){
                        vm.qrCodeLocal=window.location.protocol+'//'+window.location.hostname+':8080/cd/?tripId='+id+'&at='+gd.account.password + '_coach&appSource='+appSource;
                    }
                    // 获取教练组邀请码
                    coachTeamInfoFun(res.data.myCoachTeamId);
                }
            },function (err) {
                console.log(err);
            });
        }
        vm.downLoadQr =function (){
            var downloadName = '活动'+vm.trip.id +'监控二维码.png';
            if(downloadName!=$('.download-qrcode')[0].download){
                // 用户首次下载二维码图片的时候生成二维码图片
                var _fixType = function(type) {
                    type = type.toLowerCase().replace(/jpg/i, 'jpeg');
                    var r = type.match(/png|jpeg|bmp|gif/)[0];
                    return 'image/' + r;
                };
                html2canvas($('.qrcode-image'),{
                    onrendered:function(canvas){
                        var type = 'png';
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(_fixType(type),'image/octet-stream');
                        console.log('isIE=',isIE());
                        if (isIE()) {
                            $('#myIEModal').modal('show');
                        }else {
                            var triggerDownload = $('.download-qrcode').attr({
                                'href': imgData,
                                'download': downloadName
                            });
                            triggerDownload[0].click();
                        }
                    }
                });
            }else{
                $('.download-qrcode')[0].click();
            }
        };
        vm.iknow=function(){
            $('#myIEModal').modal('hide');
        };
        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            }else {
                return false;
            }
        }
        function coachTeamInfoFun (id){
            $http({
                method: 'GET',
                url: 'api/mobile/coach-teams/'+id
            }).then(function (res) {
                console.log(res);
                if (res != null){
                    vm.coachTeamInfo = res.data;
                }
            },function (err) {
                console.log(err);
            });
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        // 设置宽度
        var setPaddingId=setTimeout(function(){
            var qrCodeContainer=$('.qrcode-container')[0].clientWidth;
            if(qrCodeContainer<440){
                $('.qrcode-image').css('width',qrCodeContainer+'px');
            }
            clearTimeout(setPaddingId);
        },50);
    }
})();
