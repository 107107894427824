(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('feedback', {
            parent: 'entity',
            url: '/feedback?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.feedback.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/feedback/feedbacks.html',
                    controller: 'FeedbackController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('feedback');
                    $translatePartialLoader.addPart('feedbackType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('use-help', {
                parent: 'entity',
                url: '/use-help',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'taskServiceApp.feedback.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/feedback/use-help.html',
                        controller: 'UseHelpController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        // $translatePartialLoader.addPart('feedback');
                        // $translatePartialLoader.addPart('feedbackType');
                        return $translate.refresh();
                    }],
                    // entity: ['$stateParams', 'Feedback', function($stateParams, Feedback) {
                    //     return Feedback.get({id : $stateParams.id}).$promise;
                    // }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'feedback',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
        .state('feedback-detail', {
            parent: 'entity',
            url: '/feedback/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.feedback.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/feedback/feedback-detail.html',
                    controller: 'FeedbackDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('feedback');
                    $translatePartialLoader.addPart('feedbackType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Feedback', function($stateParams, Feedback) {
                    return Feedback.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'feedback',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('feedback.new', {
            parent: 'feedback',
            url: '/new?appSource',
            data: {
                authorities: ['ROLE_USER']
            },
            params:{
                appSource:'1',
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/feedback/feedback-dialog.html',
                    controller: 'FeedbackDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                phone: null,
                                email: null,
                                comments: null,
                                createTime: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $('.modal-backdrop').remove();
                    $state.go('trip-model', null, { reload: 'feedback' });
                }, function() {
                    $('.modal-backdrop').remove();
                    $state.go('trip-model');
                });
            }]
        })
        .state('feedback.delete', {
            parent: 'feedback',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/feedback/feedback-delete-dialog.html',
                    controller: 'FeedbackDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Feedback', function(Feedback) {
                            return Feedback.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('feedback', null, { reload: 'feedback' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
