(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('DesignController', controller);
    controller.$inject = ['$state', 'getDesignList',  'AlertService', '$stateParams', '$http','$scope'];
    function controller($state, getDesignList,  AlertService, $stateParams, $http,$scope) {
        var vm = this;
        document.title='设计师管理';
        vm.searchQuery = $stateParams.search;
        vm.page = $stateParams.page;
        vm.predicate='id';
        vm.reverse='desc';
        vm.sort = $stateParams.sort;
        vm.itemsPerPage=20;
        vm.totalItems=0;
        vm.designList=[];
        vm.userDesign=null;
        vm.createOrChange=true; 
        vm.modalTextStatus=1;      //1上架 2下架 3删除
        vm.transition=function(params) {
            if(params===1){
                vm.page=1;
            }
            if(params===2){
                vm.sort=vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc');
            }
            getDesignList.query({
                query:vm.searchQuery,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.sort,
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.designList = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        vm.transition();

        vm.createDesign=function(){
            vm.userDesign={
                name:'',
                level:'',
                province:'',
                city:'',
                intro:'',
                phone:'',
                profile:null,
                wechat:null,
            };
            // 清空暂存图片
            vm.dataUrlProfile=null;          
            vm.dataUrlWechat=null;       
            document.getElementById('provincevalue').value='';
            document.getElementById('cityvalue').value='';
            vm.createOrChange=true;
            $('#myModal_create_change').modal('show');
            initTinyMceEditor();
        };

        vm.changeDesign=function(design){
            if(design.isUp==1){
                $message.warning({
                    text:"请先下架设计师！",
                    message_top:'17%',
                });
                return false;
            }
            vm.userDesign=design;
            // 清空暂存图片
            vm.dataUrlProfile=null;          
            vm.dataUrlWechat=null; 
            console.log('编辑设计师design=',design);
            // 把对应地区的id在省市区的数据对象中找出来
            var id1=null,id2=null;
            var provinceDom=document.getElementById('provinceid');
            var cityDom=document.getElementById('cityid');
            $area.every(function(item1,index1){ 
                if(item1.name===vm.userDesign.province){
                    id1=index1;
                    return item1.city.every(function(item2,index2){
                        if(item2.name===vm.userDesign.city){
                            id2=index2;
                            return false;
                        }else{
                            return true;
                        }
                    });
                }else{
                    return true;
                }
            });
            // console.log('id1=',id1);
            // console.log('id2=',id2);
            // 后台存放的地址数据不符合规则的情况下不展示地区选择；
            if(id1!==null&&id2!==null){
                insertAreaList(provinceDom,$area);
                insertAreaList(cityDom,$area[id1].city);
                var id3=setTimeout(function(){
                    provinceDom.setAttribute('data-id',id1);
                    cityDom.setAttribute('data-id',id1);
                    cityDom.setAttribute('data-id2',id2);
                    setAreaActive(provinceDom,provinceDom.children[id1]);
                    setAreaActive(cityDom,cityDom.children[id2]);
                    clearTimeout(id3);
                },300);
                document.getElementById('provincevalue').value=vm.userDesign.province;
                document.getElementById('cityvalue').value=vm.userDesign.city;
            }else{
                document.getElementById('provincevalue').value='';
                document.getElementById('cityvalue').value='';
                $message.warning({
                    text:"该设计师存放的地区数据不符合规则，请重新设置",
                });
            }
            vm.createOrChange=false;
            $('#myModal_create_change').modal('show');
            initTinyMceEditor();
        };
        vm.upOrDownDesign=function(design){
            vm.userDesign=design;
            if(vm.userDesign.isUp==0){
                vm.modalTextStatus=1;
            }else if(vm.userDesign.isUp==1){
                vm.modalTextStatus=2;
            }
            $('#myModal_delete_design').modal('show');
        };
        vm.deleteDesign=function (design){
            if(design.isUp==1){
                $message.warning({
                    text:"请先下架设计师！",
                    message_top:'17%',
                });
                return false;
            }
            vm.userDesign=design;
            vm.modalTextStatus=3;
            $('#myModal_delete_design').modal('show');
        };
        vm.upOrDownOrDeleteDisabled=false;
        vm.upOrDownOrDelete=function(){
            if(vm.upOrDownOrDeleteDisabled){
                return false;
            }
            vm.upOrDownOrDeleteDisabled=true;
            if(vm.modalTextStatus===1||vm.modalTextStatus===2){
                $http({
                    method: 'POST',
                    url:'/api/designer/upDown',
                    timeout:30000,
                    params:{
                        id:vm.userDesign.id,
                    },
                }).then(function (res) {
                    // console.log('res=',res);
                    $('#myModal_delete_design').modal('hide');
                    $message.success({
                        text:(vm.modalTextStatus===1?"上架":"下架")+"设计师成功！",
                        message_top:'17%',
                    });
                    vm.userDesign=null;
                    vm.upOrDownOrDeleteDisabled=false;
                    vm.transition();
                }).catch(function(err){
                    $('#myModal_delete_design').modal('hide');
                    $message.error({
                        text:(vm.modalTextStatus===1?"上架":"下架")+"设计师失败！",
                        message_top:'17%',
                    });
                    vm.upOrDownOrDeleteDisabled=false;
                });
            }else if(vm.modalTextStatus===3){
                $http({
                    method: 'POST',
                    url:'/api/designer/delete',
                    timeout:30000,
                    params:{
                        id:vm.userDesign.id,
                    },
                }).then(function (res) {
                    // console.log('res=',res);
                    $('#myModal_delete_design').modal('hide');
                    $message.success({
                        text:"删除设计师成功！",
                        message_top:'17%',
                    });
                    vm.userDesign=null;
                    vm.upOrDownOrDeleteDisabled=false;
                    vm.transition();
                }).catch(function(err){
                    $('#myModal_delete_design').modal('hide');
                    $message.error({
                        text:"删除设计师失败！",
                        message_top:'17%',
                    });
                    vm.upOrDownOrDeleteDisabled=false;
                });
            }
        };

        function setAreaActive(domlist,targetDom){
            Array.prototype.slice.call(domlist).forEach(function(item,index){
                item.classList.remove('item-active');
            });
            targetDom.classList.add('item-active');
        }
        // 打开地区选择器   
        vm.openSelect=function(){
            document.getElementById("areamaskbanner").style.display="block";
            // 判断是省还是市nextElementSibling previousElementSibling
            var listDom=event.target.parentNode.nextElementSibling;
            // debugger;
            if(listDom.dataset.type==="0"){
                if(listDom.style.display==="none"){
                    listDom.style.display="block";
                    // 关闭市下拉列表
                    document.getElementById('cityid').style.display="none";
                    if(listDom.children.length<1){
                        insertAreaList(listDom,$area);
                    }
                }else{
                    listDom.style.display="none";
                }
            }else if(listDom.dataset.type==="1"){
                if(listDom.children.length<1){
                    // 用户未选择省的时候点击市的选择器，
                    $message.warning({
                        text:"选择地级市前请先选择对应的省",
                    });
                    var provinceDom=document.getElementById('provinceid');
                    provinceDom.style.display="block";
                    if(provinceDom.children.length<1){
                        insertAreaList(provinceDom,$area);
                    }
                }else{
                    if(listDom.style.display==="none"){
                        listDom.style.display="block";
                        // 打开市列表的时候关闭省列表
                        document.getElementById('provinceid').style.display="none";
                    }else{
                        listDom.style.display="none";
                    }
                }
            }
            // 判断是否添加蒙层(经过上边的操作后，如果省列表和市列表都隐藏了，那么遮罩层也需要隐藏)
            if(document.getElementById('provinceid').style.display==="none"&&document.getElementById('cityid').style.display==="none"){
                document.getElementById("areamaskbanner").style.display="none";
            }
        };
        
        // 用户选择地区
        vm.areaSelect=function(){
            document.getElementById("areamaskbanner").style.display="block";
            // 判断选择的是省还是市
            var listDom=event.target.parentNode;
            if(listDom.dataset.type==="0"){
                listDom.setAttribute('data-id',event.target.dataset.id);
                document.getElementById('provincevalue').value=$area[event.target.dataset.id].name;
                setAreaActive(listDom.children,event.target);
                // 点击省列表更新市列表的值；
                var cityDom=document.getElementById('cityid');
                insertAreaList(cityDom,$area[event.target.dataset.id].city);
                // 移除市列表上中的选中的市的id
                cityDom.removeAttribute('data-id');
                cityDom.removeAttribute('data-id2');
                document.getElementById('cityvalue').value='';
                vm.userDesign.city='';
                listDom.style.display="none";
            }else if(listDom.dataset.type==="1"){
                listDom.setAttribute('data-id',event.target.dataset.id);
                listDom.setAttribute('data-id2',document.getElementById('provinceid').dataset.id);
                var cityvalue=$area[document.getElementById('provinceid').dataset.id].city[event.target.dataset.id];
                if(angular.isString(cityvalue)!="string"){
                    cityvalue=cityvalue.name;
                }
                document.getElementById('cityvalue').value=cityvalue;
                setAreaActive(listDom.children,event.target);
                listDom.style.display="none";
                vm.userDesign.province=document.getElementById('provincevalue').value;
                vm.userDesign.city=cityvalue;
            }
            // 判断是否添加蒙层
            if(document.getElementById('provinceid').style.display==="none"&&document.getElementById('cityid').style.display==="none"){
                document.getElementById("areamaskbanner").style.display="none";
            }
        };

        // 关闭所有地区选择列表
        vm.closeAreaList=function(){
            document.getElementById('provinceid').style.display="none";
            document.getElementById('cityid').style.display="none";
            document.getElementById("areamaskbanner").style.display="none";
        };

        function insertAreaList(dom,data){
            var htmlStr="";
            data.forEach(function(item,index){
                // 把id设置上去，通过id我们可以从地区列表中找到具体市那个区域
                htmlStr+="<div class=\"list-item\" data-id="+index+">"+item.name+"</div>";
            });
            dom.innerHTML=htmlStr;
        }


        vm.checkPhone=function(){
            vm.userDesign.phone=vm.userDesign.phone.replace(/\D/g,'');
        };

        vm.IMAGE_SERVER=IMAGE_SERVER;     //沙雕框架不能直接在html文件中使用全局变量，如果直接使用将访问不到
        vm.uploadStatusProfile=false;
        vm.uploadStatusWechat=false;
        vm.dataUrlProfile=null;            //暂存用户选择的数据对象；   用户上传成功后需要手动清除
        vm.dataUrlWechat=null;            
        vm.uploadPercentProfile=0;         //上传进度
        vm.uploadPercentWechat=0;         
        vm.ossUploadTokenProfile=null;     //后台返回的上传oss需要的参数；
        vm.ossUploadTokenWechat=null;     
        vm.ossUploadFileProfile=null;      //上传到oss的文件对象；超时重试的时候用得到
        vm.ossUploadFileWechat=null;      
        vm.retryUploadProfile=false;
        vm.retryUploadWechat=false;
        vm.uploadFile=function(file,params){
            if(file){
                if(!file.type) {
                    $message.warning({
                        text:"不能读取文件的文件格式",
                    });
                    return false;
                }
                if(!/^image\/jpeg/.test(file.type) && !/^image\/png/.test(file.type)) {
                    $message.warning({
                        text:"禁止选择JPG/PNG以外的格式",
                    });
                    return false;
                }
                if(file.size>1024*1024*1){
                    $message.warning({
                        text:"上传文件大小超过了1MB",
                    });
                    return false;
                }
                if(params==='profile'){
                    vm.dataUrlProfile=URL.createObjectURL(file);
                    vm.uploadStatusProfile = true;
                }else if(params==='wechat'){
                    vm.dataUrlWechat=URL.createObjectURL(file);
                    vm.uploadStatusWechat = true;
                }
                // 上传oss
                $http({
                    method: 'POST',
                    url: '/api/postFileNameAndToken',
                    timeout:30000,
                    data: {
                        type:'DESIGNER',
                        fileName:file.name,
                    }
                }).then(function (res) {
                    // console.log('后台返回的数据',res);
                    if(params==='profile'){
                        vm.ossUploadTokenProfile=res.data.data;
                        vm.ossUploadTokenWechat = file;
                    }else if(params==='wechat'){
                        vm.ossUploadFileProfile=res.data.data;
                        vm.ossUploadFileWechat = file;
                    }
                    uploadOss(params,res.data.data,file);
                }).catch(function(err){
                    if(params==='profile'){
                        vm.dataUrlProfile=null;
                        vm.uploadStatusProfile = false;
                    }else if(params==='wechat'){
                        vm.dataUrlWechat=null;
                        vm.uploadStatusWechat = false;
                    }
                    $message.warning({
                        text:"postFileNameAndToken接口调用失败",
                    });
                });
            }
        };
        // 上传oss
        function uploadOss(params,ossToken,file){
            if(params==='profile'){
                vm.retryUploadProfile=false;
                vm.uploadPercentProfile=0;
            }else if(params==='wechat'){
                vm.retryUploadWechat=false;
                vm.uploadPercentWechat=0;
            }
            var  client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: 'oss-cn-shanghai',
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: ossToken.AccessKeyID,
                accessKeySecret:ossToken.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken:ossToken.SecurityToken,
                // 填写Bucket名称。
                bucket: 'itasktour-muti'
            });
            
            // 第一个参数为上传的路径，第二个参数为上传的文件
            client.multipartUpload(ossToken.fileName,file,{
                // 进度条的配置项
                progress: function(percentage) { 
                    // 获取进度条的值
                    if(params==='profile'){
                        vm.uploadPercentProfile = percentage * 100;
                    }else if(params==='wechat'){
                        vm.uploadPercentWechat = percentage * 100;
                    }
                    // console.log('percentage='+percentage+'----------vm.uploadPercent='+vm.uploadPercent);
                    $scope.$apply();
                },
                timeout:60000,
                // 每传输完一个分片 进度条会走一个百分比 不设置的话由于partsize过大 可能导致很长时间进度条都看不到效果
                // partSize: 102400 // 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为1 * 1024 * 1024（即1 MB）
            }).then(function(res){
                console.log('oss返回数据res=',res);
                // 为了让用户看到上传进度条条的百分百的状态，这个地方延迟一秒改变状态
                var delayId=setTimeout(function(){
                    if(params==='profile'){
                        vm.uploadPercentProfile=0;
                        vm.uploadStatusProfile = false;
                        vm.ossUploadTokenProfile=null;
                        vm.ossUploadTokenWechat = null;
                    }else if(params==='wechat'){
                        vm.uploadPercentWechat=0;
                        vm.uploadStatusWechat = false;
                        vm.ossUploadFileProfile=null;
                        vm.ossUploadFileWechat = null;
                    }
                    vm.userDesign[params] = '/'+res.name;
                    clearTimeout(delayId);
                    $scope.$apply();
                },700);
            }).catch(function(err){
                console.log('err=',err);
                if(params==='profile'){
                    vm.uploadPercentProfile = 0;
                }else if(params==='wechat'){
                    vm.uploadPercentWechat =0;
                }
                // 判断错误类型，如果是致命性错误弹出警告框提示用户，如果是超时错误为用户显示重试按钮
                if(JSON.stringify(err).indexOf('Timeout')>-1){
                    if(params==='profile'){
                        vm.retryUploadProfile=true;
                    }else if(params==='wechat'){
                        vm.retryUploadWechat=true;
                    }
                    $scope.$apply();
                }else{
                    // 上传失败的情况下，清除本都存储的用户选择的文件，使用服务器上的资源
                    if(params==='profile'){
                        vm.dataUrlProfile=null;
                        vm.uploadStatusProfile = false;
                    }else if(params==='wechat'){
                        vm.dataUrlWechat=null;
                        vm.uploadStatusWechat = false;
                    }
                    $scope.$apply();
                    $message.warning({
                        text:"文件上传异常，请重新上传或更换素材！！",
                    });
                }
            });
        }
        // 重新上传视频或者图片(仅在文件上传超时的时候触发)
        vm.retryUploadFile=function(params){
            if(params==='profile'){
                uploadOss('profile',vm.ossUploadTokenProfile,vm.ossUploadFileProfile);
            }else if(params==='wechat'){
                uploadOss('profile',vm.ossUploadTokenWechat,vm.ossUploadFileWechat);
            }
        };


        // 用户修改或者添加设计师
        vm.save=function (){
            if(vm.userDesign.level===''){
                $message.warning({
                    text:"请选择设计师等级！",
                });
                return false;
            }
            if(vm.userDesign.province.trim()===''||vm.userDesign.city.trim()===''){
                $message.warning({
                    text:"请选择完整的常住地！",
                });
                return false;
            }
            if(vm.userDesign.profile===null){
                $message.warning({
                    text:"请上传您的头像！",
                });
                return false;
            }
            if(vm.userDesign.wechat===null){
                $message.warning({
                    text:"请上传您的微信二维码图片！",
                });
                return false;
            }
            // 富文本个人简介
            var textIntro=tinyMCE.editors['message'].getContent();
            if(textIntro.length===0){
                $message.warning({
                    text:"请填写设计师个人简介！",
                });
                return false;
            }else{
                vm.userDesign.intro=textIntro;
            }
            $http({
                method: 'POST',
                url:'/api/designer/edit',
                timeout:30000,
                data: vm.userDesign,
            }).then(function (res) {
                // console.log('res=',res);
                $('#myModal_create_change').modal('hide');
                $message.success({
                    text:(vm.createOrChange?"添加":"修改")+"设计师成功！",
                    message_top:'17%',
                });
                vm.userDesign=null;
                vm.transition();
            }).catch(function(err){
                $message.error({
                    text:(vm.createOrChange?"添加":"修改")+"设计师失败！",
                });
            });
        };
        vm.showPhoneOrWechat=function (designItem){
            vm.userDesign=designItem;
            $('#show_phone_wechat').modal('show');
        };
        vm.closePhoneWechat=function (){
            vm.userDesign=null;
            $('#show_phone_wechat').modal('hide');
        };
        // 富文本编辑器的id
        vm.editorId=null;
        // 富文本编辑器相关代码
        function initTinyMceEditor(){
            if(vm.editorId!==null){
                tinyMCE.editors[vm.editorId].setContent(vm.userDesign.intro);
                console.log('vm.editorId-vm.userDesign.intro=',vm.userDesign.intro);
                return false;
            }
            var _syncScript=document.createElement('script');
            _syncScript.type="text/javascript";
            console.log('');
            _syncScript.src="https://image.itasktour.com/WX/tinymce5.6.1/tinymce.js";
            _syncScript.addEventListener('load',initTinyFn);
            document.body.appendChild(_syncScript);
            function initTinyFn(){
                console.log('执行了');
                tinymce.init({
                    selector:'#message'
                ,inline:false 
                ,menubar:false 
                ,elementpath:true
                ,language:"zh_CN"   
                ,base_url: ''
                ,auto_focus:true
                ,hidden_input:true  
                ,resize: false 
                ,draggable_modal:false
                ,statusbar: true
                ,theme:'silver'
                ,placeholder:'请输入内容'
                ,custom_undo_redo_levels:30 
                ,skin: 'oxide'  
                ,branding: false
                ,setup: function(editor) {
                    console.log("ID为: "+editor.id+" 的编辑器即将初始化.");
                }
                ,init_instance_callback:function(editor){
                    console.log('init_instance_callback-vm.userDesign.intro=',vm.userDesign.intro);
                    tinyMCE.editors[editor.id].setContent(vm.userDesign.intro);
                    vm.editorId=editor.id;
                    console.log("ID为: "+editor.id+" 的编辑器已初始化完成.");
                }
                // 字数限制插件
                // ,ax_wordlimit_num:200
                // ,ax_wordlimit_callback: function(editor,txt,num){
                //     alert('当前字数：' + txt.length + '，限制字数：' + num);
                // }
                // ,style_formats: [
                //     {title:"大标题",block:'h2',classes:'bigtitle',styles:{'color':'inherit','font-weight':'600','text-align':'center','font-size':'24px','margin':'15px 0','line-height': '28px'}},
                //     {title:"小标题",block:'h3',classes:'smalltitle',styles:{'color':'inherit','font-weight':'580','text-align':'left','font-size':'18px','margin':'9px 0','line-height': '24px'}}
                // ]
                // ,plugins:' charmap image quickbars save indent2em wordcount'
                ,plugins:'wordcount'
                // ,toolbar:['undo redo save|bold italic underline strikethrough|alignleft aligncenter alignright alignjustify|outdent indent indent2em|subscript superscript removeformat|charmap|quickimage',' styleselect|fontselect|fontsizeselect|forecolor backcolor']
                ,toolbar:['undo redo']
                // ,quickbars_image_toolbar: 'alignleft aligncenter alignright'                             
                ,quickbars_insert_toolbar: false                                                   
                ,quickbars_selection_toolbar: false                              
                ,toolbar_mode:"wrap" 
                ,height:300
                // ,fontsize_formats: "12px 14px 16px 18px"
                // ,font_formats:'Lantinghei Sc=Lantinghei Sc,Open Sans,Arial;Open Sans=Open Sans,Arial, Hiragino Sans GB;Arial=Arial,Hiragino Sans GB,Microsoft YaHei;Hiragino Sans GB=Hiragino Sans GB,Microsoft YaHei,STHeiti;Microsoft YaHei=Microsoft YaHei,STHeiti,WenQuanYi Micro Hei;STHeiti=STHeiti,WenQuanYi Micro Hei,SimSun;WenQuanYi Micro Hei=WenQuanYi Micro Hei,SimSun,sans-serif;SimSun= SimSun,sans-serif;sans-serif=sans-serif'
                // ,content_style: "p{color:#666666;font-size:14px;padding:0 0 10px 0; margin:0;line-height: 20px;overflow:hidden;}img{margin:0;padding:0;max-width: 100% !important;width: unset !important;height: unset !important;max-height: unset !important;display:block !important;}a{text-decoration:none;color:unset;}ul,dl{list-style-type: none;}li,dd,dt{list-style: none;}"
                // ,images_upload_handler:function(blobInfo,succFun,failFun){
                //     var xhr,formData;
                //     var file=blobInfo.blob();
                //     console.log(file);
                //     xhr=new XMLHttpRequest();
                //     xhr.withCredentials=false;  
                //     xhr.open('POST',location.origin+'/APP/Lib/ORG/php/controller.php?action=uploadimage',true);
                //     xhr.onload=function(){
                //         console.log(this);
                //         if (this.status != 200) {
                //             failFun('HTTP Error: ' + this.status);
                //             return;
                //         }
                //         var json = JSON.parse(this.responseText.replace(/'/g,'"'));
                //         if (!json || typeof json.url !== 'string') {
                //             failFun('Invalid JSON: ' + this.responseText);
                //             return;
                //         }
                //         succFun(json.url);
                //     };
                //     formData=new FormData();
                //     formData.append('upfile',file,file.name);
                //     xhr.send(formData);
                // }
                ,image_uploadtab:false
                ,color_cols:6
                ,custom_colors:true                       
                // ,color_map:["FFB6C1", "LightPink","DB7093", "PaleVioletRed","FF1493", "DeepPink","C71585", "MediumVioletRed","FF1493", "HotPink","800080", "Purple","BA55D3", "MediumOrchid","8A2BE2", "BlueViolet","7B68EE", "MediumSlateBlue","6495ED", "CornflowerBlue","0000FF", "Blue","00008B", "DarkBlue","1E90FF", "DodgerBlue","00BFFF", "LightSkyBlue","20B2AA", "LightSeaGreen","E0FFFF", "LightCyan","00FF7F", "SpringGreen","40E0D0", "Turquoise","008B8B", "DarkCyan","00FF00", "Lime","228B22", "ForestGreen","FAFAD2", "LightGoldenrodYellow","FFFF00", "Yellow","DAA520", "Goldenrod","FFA500", "Orange","FF8C00", "DarkOrange","FFA07A", "LightSalmon","CD5C5C", "IndianRed","FF4500", "OrangeRed","8B0000", "DarkRed","DCDCDC", "Gainsboro","A9A9A9", "DarkGray","808080", "Gray","696969", "DimGray","4c4948", "aauTheme","000000", "Black","00a391", "realink","Fe435a", "QuietlyPink","8f4b2e", "DarkBrown","121a2a", "GreenBrown"]
                ,save_enablewhendirty:true                                   
                ,save_onsavecallback: function (){console.log('保存文档成功');}
                ,save_oncancelcallback:function(){console.log('取消了保存');}
                });
            }
        }
    }
})();
