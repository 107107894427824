(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("WxGameQrCodeController", controller);
    controller.$inject = ["$uibModalInstance", "$stateParams"];
    function controller($uibModalInstance, $stateParams) {
        var vm = this;
        vm.tripId = $stateParams.id;
        vm.tripTitle = $stateParams.title;
        vm.gameTitle = ["极限寻宝", "极限潜航", "极限拆弹", "极限谍战"][
            Number($stateParams.category) - 1
        ];
        vm.qrCodeNormal = "";
        showQrCodeNormal();
        function showQrCodeNormal() {
            var qrCodeUrl = "";
            if (
                window.location.origin.indexOf("localhost") > -1 ||
                window.location.hostname.split(".").length === 4 ||
                window.location.origin.indexOf("dev") !== -1
            ) {
                qrCodeUrl = "http://dev.itasktour.com/";
            } else {
                qrCodeUrl = "https://www.itasktour.com/";
            }
            if ($stateParams.category === "4") {
                qrCodeUrl = qrCodeUrl + "api/common/ittr/spy.php?tripId=";
            } else if ($stateParams.category === "3") {
                qrCodeUrl = qrCodeUrl + "api/common/ittr/rush.php?tripId=";
            } else if ($stateParams.category === "2") {
                qrCodeUrl = qrCodeUrl + "api/common/ittr/stealth.php?tripId=";
            }
            vm.qrCodeNormal = qrCodeUrl + vm.tripId;
        }
        //当前页面禁用鼠标右键
        banRight();
        function banRight() {
            if (window.Event) document.captureEvents(Event.MOUSEUP);
            function nocontextmenu() {
                event.cancelBubble = true;
                event.returnvalue = false;
                return false;
            }
            function norightclick(e) {
                if (window.Event) {
                    if (e.which == 2 || e.which == 3) return false;
                } else if (event.button == 2 || event.button == 3) {
                    event.cancelBubble = true;
                    event.returnvalue = false;
                    return false;
                }
            }
            document.oncontextmenu = nocontextmenu; // for IE5+
            document.onmousedown = norightclick; //
        }
        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            } else {
                return false;
            }
        }
        vm.downLoadQr = function (codeType) {
            html2canvas($(".qrNormal"), {
                onrendered: function (canvas) {
                    var type = "png";
                    var imgData = canvas.toDataURL(type);
                    imgData = imgData.replace(
                        _fixType(type),
                        "image/octet-stream"
                    );
                    if (isIE()) {
                        $("#myIEModal").modal("show");
                    } else {
                        var triggerDownload = $(".downNor")
                            .attr({
                                href: imgData,
                                download: "活动" + vm.tripId + "正式二维码.png",
                            })
                            .unbind("click");
                        triggerDownload[0].click();
                    }
                },
            });

            var _fixType = function (type) {
                type = type.toLowerCase().replace(/jpg/i, "jpeg");
                var r = type.match(/png|jpeg|bmp|gif/)[0];
                return "image/" + r;
            };
        };
        vm.iknow = function () {
            $("#myIEModal").modal("hide");
        };
        vm.clear = function () {
            $uibModalInstance.dismiss("cancel");
        };
    }
})();
