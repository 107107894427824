(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("EAccountMyPurchaseController", controller);
    controller.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "MyEAccountPay",
        "MyEAccountUnionPay",
        "previousState",
        "entity",
        "GiveCoupons",
        "EAccount",
        "User",
        "UserSearch",
        "MyEAccountRecords",
        "AllCoupons",
        "$http",
        "$sce",
        "PAYACP",
    ];
    function controller(
        $scope,
        $rootScope,
        $state,
        MyEAccountPay,
        MyEAccountUnionPay,
        previousState,
        entity,
        GiveCoupons,
        EAccount,
        User,
        UserSearch,
        MyEAccountRecords,
        AllCoupons,
        $http,
        $sce,
        PAYACP
    ) {
        var vm = this;
        vm.phone = null;
        vm.eAccount = entity;
        vm.hasNewCoupon = false;
        vm.previousState = previousState.name;
        vm.itemsPerPage = 250;
        vm.back = back;
        vm.toTrip = toTrip;
        var cou = "30";
        var duration = 0;
        var extraCoupon = 0;
        var rechargeNo = 0;
        var pay = 0;
        var sPrice = 0;
        var payType = "alipay";
        var isGiveCoupons = false;
        vm.checked = false;
        vm.confirmPay = confirmPay;
        vm.preConfirmPay = preConfirmPay;
        vm.filterUser = this.filterUser;
        this.filterUser = function (search) {
            if (search) {
                vm.users = UserSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
            }
        };

        console.log(PAYACP);
        if (PAYACP) {
            vm.PAYACP = true;
        } else {
            vm.PAYACP = false;
        }

        vm.firstRabbit = false; //第一批兔子（4.3号以前）
        vm.twoRabbit = false; //第二批兔子（4.3号-5.20号）
        vm.newRabbit = false; //第三批兔子（5.20号以后）
        // console.log(vm.eAccount);
        var authenticationTime = vm.eAccount.createDate.getTime();
        // console.log(authenticationTime);
        if (authenticationTime < 1522684800000) {
            vm.firstRabbit = true; //认证时间在4.3号之前
        } else if (
            authenticationTime >= 1522684800000 &&
            authenticationTime <= 1526745600000
        ) {
            vm.twoRabbit = true; //认证时间在4.3号到5.20号之间
        } else {
            vm.newRabbit = true; //认证时间在5.20号之后
        }
        //针对第二批兔子判断时间是否在23号前
        // var now = new Date;
        // if (now.getTime()>1527004800000){
        //     vm.outTime = true;
        // }
        getAccount();
        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
            });
        }

        getAllCoupons(); //获取该企业可充值活动
        function getAllCoupons() {
            AllCoupons.allCoupons(vm.eAccount.id).then(
                function (res) {
                    // console.log(res.data);
                    if (res.data != null) {
                        vm.canRechargeCoupons = res.data;
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        load();
        function load() {
            // if (vm.eAccount.confirmEmail===false){
            //     $('#myModal1').modal('show');
            // }
            addLayout();
        }
        // getGiveCoupons();//调用接口查看是否购买过体验套餐
        function getGiveCoupons() {
            GiveCoupons.isGiveCoupons({
                saleId: "20180310-one_sale-20180320",
            }).then(
                function (res) {
                    // console.log(res);
                    //如果已经体验过了，则将栏目样式变灰，并不能选择(0:没体验过可购买)
                    if (res.data.code == 0) {
                        isGiveCoupons = false;
                    } else if (res.data.code == 1) {
                        disChoose();
                        isGiveCoupons = true;
                    } else {
                        alert("当前企业账号不存在");
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }
        //如果已经体验过了，则将栏目样式变灰，并不能选择
        function disChoose() {
            $(".dis").css("color", "#c8c8c8");
            $(".disa").css("background-color", "#c8c8c8");
            $(".disab").css("border", "solid 1px #e6e6e6");
        }
        function back() {
            $("#myModal1").modal("hide");
            window.setTimeout(function () {
                $state.go("e-account-my.edit");
            }, 300);
        }
        function toTrip() {
            $("#myModal1").modal("hide");
            window.setTimeout(function () {
                $state.go("trip");
            }, 300);
        }
        function addLayout() {
            document.getElementById("nav").style.display = "block";
            document.getElementById("foot").style.display = "block";
            document.getElementById("con").className = "well";
            $(function () {
                $(".container").removeClass("login-container-content");
                if ($(window).width() < 768)
                    $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }
        loadRecrds();
        function loadRecrds() {
            vm.records = MyEAccountRecords.query({
                page: 0,
                size: 20,
                sort: ["id,asc"],
            });
        }

        //点击选择点数(旧)
        /*$('#account_money em').each(function(i) {
            $(this).click(function(){
                // console.log($(this)[0].id);
                //判断是否是体验栏目em1，如果是则不可点击并灰掉样式，否则可点击选择
                if ($(this)[0].id=='exp'&&isGiveCoupons==true){
                    disChoose();
                }else {
                    $('#inputmoney').val("");
                    $('#account_money em').removeClass('active1');
                    $(this).addClass('active1');
                    cou = $(this).find('.coupons')[0].textContent;
                    money = $(this).find('.count')[0].textContent;
                    $('#chargeamountshow').html(money);
                }
            })
        });*/
        //点击选择点数(新)
        vm.allcoupons = 50;

        vm.chooseCoupons = chooseCoupons;
        function chooseCoupons(coupons) {
            $("#account_money em").removeClass("active1");
            $("." + coupons.id).addClass("active1");
            $("#chargeamountshow").html(coupons.price);
            cou = coupons.coupon;
            duration = coupons.duration;
            extraCoupon = coupons.extraCoupon;
            rechargeNo = coupons.id;
            sPrice = coupons.price;

            vm.allcoupons = 50;
            vm.allcoupons = cou + extraCoupon;
        }

        //点击选择支付方式
        $("#account_mode em").each(function (i) {
            $(this).click(function (event) {
                $("#account_mode em").removeClass("active2");
                $(this).addClass("active2");
                //$('.dropdown li input').attr("checked",false);
                if ($(this).attr("id") == "pay1") {
                    payType = "alipay";
                } else if ($(this).attr("id") == "pay2") {
                    payType = "wechat";
                } else if ($(this).attr("id") == "pay3") {
                    payType = "unionpay";
                }
            });
        });
        // $("#inputmoney").keyup(function() {
        //     var money = $(this).val();
        //     $('#chargeamount').val(money/100);
        //     $('#chargeamountshow').html(calculatecoupon(money/100));
        //     checkChargeAmount();
        // });
        // $('#account_money .inp_dmoney').on('focus',function(){
        //     $('#account_money em').removeClass('active');
        //     $(this).addClass('active');
        // });
        // $('#account_money .inp_dmoney').on('blur',function(){
        //     $(this).removeClass('active');
        // })

        // checkNewCoupon();
        // 推测这个地方是判断用户是否有成功的充值记录，如果没有，用户就是首次充值
        function checkNewCoupon() {
            var data = vm.eAccount.hasCoupons;
            console.log("vm.eAccount=", vm.eAccount);
            for (var i = 0; i < data.length; i++) {
                var coupon = data[i];
                if (coupon.newFlag == 1) {
                    vm.hasNewCoupon = true;
                    break;
                }
            }
        }

        function preConfirmPay() {
            if (sPrice == 0) {
                alert("请先选择您要充值的套餐");
            } else {
                $("#payOfService").modal("show");
            }
        }

        function confirmPay() {
            if (vm.checked === true) {
                if (payType === "alipay") pay = 0;
                else if (payType === "wechat") pay = 1;
                else pay = 2;
                // if (money==1){ sPrice = 1; }else{ sPrice = 0 }//money=1说明是促销体验价，传参sPrice=1
                // cou = cou.replace(/,/g,'');//去除点数中的,
                // money = money.replace(/,/g,'');//去除金额中的,
                if (sPrice == 0) {
                    alert("请先选择您要充值的套餐");
                } else {
                    if (pay < 2) {
                        MyEAccountPay.getOrder({
                            cou: cou,
                            duration: duration,
                            eId: vm.eAccount.id,
                            extraCoupon: extraCoupon,
                            rechargeNo: rechargeNo,
                            sPrice: sPrice,
                            eType: pay,
                        }).then(
                            function (res) {
                                console.log(res.data);
                                vm.order = res.data;
                                // console.log(vm.order);
                                if (vm.order.code == 0) {
                                    $(".pay").css("display", "none");
                                    $(".paying").css("display", "block");
                                    $state.go("e-account-my-pay", {
                                        orderId: vm.order.data.out_trade_no,
                                    });
                                } else if (vm.order.code == 10001) {
                                    alert("参数异常，订单提交失败");
                                    console.log("参数异常");
                                }
                            },
                            function (err) {
                                console.log(err);
                            }
                        );
                    } else {
                        // $http({
                        //     url: 'api/create/unionpay/consume',
                        //     method: 'POST',
                        //     data: {cou:cou,duration:duration,eId:vm.eAccount.id,extraCoupon:extraCoupon,rechargeNo:rechargeNo,sPrice:sPrice,eType:pay}
                        // }).then(function (res) {
                        //     // $('#content').innerHTML(res.data);
                        //     vm.payNew = $sce.trustAsHtml(res.data);
                        // },function (err) {
                        //     console.log(err);
                        // })

                        MyEAccountUnionPay.getMyOrder({
                            cou: cou,
                            duration: duration,
                            eId: vm.eAccount.id,
                            extraCoupon: extraCoupon,
                            rechargeNo: rechargeNo,
                            sPrice: sPrice,
                            eType: pay,
                        }).then(
                            function (res) {
                                vm.payNew = $sce.trustAsHtml(res.data);
                                $(".pay").css("display", "none");
                                $(".paying").css("display", "block");
                                $state.go("e-account-my-pay", {
                                    orderId: vm.order.data.out_trade_no,
                                });
                            },
                            function (err) {
                                console.log(err);
                            }
                        );
                    }
                    // else {
                    //     $.ajax({
                    //         url: "api/create/unionpay/test",//请求地址
                    //         dataType: "json",//数据格式
                    //         type: "post",//请求方式
                    //         async: false,//是否异步请求
                    //         success: function (data) {
                    //             $("#ulul").html(data);
                    //         } //在html页面id=ulul的标签里显示html内容
                    //     })
                    // }
                }
            } else {
                alert(
                    "请先勾选（我已同意" +
                        (vm.account.appSource === "1" ? "爱闯闯" : "魔法特工") +
                        "充值协议）"
                );
            }
        }

        function onSaveSuccess(result) {
            vm.phone = null;
            $scope.$emit("taskServiceApp:eAccountUpdate", result);
        }

        function onSaveError() {}

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:eAccountUpdate",
            function (event, result) {
                vm.eAccount = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
