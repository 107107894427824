(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("WxGameTripRankController", controller);
    controller.$inject = ["$stateParams", "$http", "Trip"];
    function controller($stateParams, $http, Trip) {
        var vm = this;
        vm.tripId = $stateParams.id;
        vm.tripRanks = [];
        Trip.get(
            { id: vm.tripId },
            function (res) {
                if (res != null) {
                    vm.trip = res;
                }
            },
            function (err) {
                console.log(err);
            }
        );
        rank();
        function rank() {
            $http({
                method: "POST",
                url: "/api/bomb/rank",
                params: {
                    tripId: vm.tripId,
                },
            }).then(
                function (res) {
                    vm.tripRanks = res.data.data;
                },
                function (err) {
                    console.log(err);
                }
            );
        }
    }
})();
