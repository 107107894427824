(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('AdController', controller);
    controller.$inject = ['$scope', '$state', 'Ad', 'AdSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants','$http'];
    function controller ($scope, $state, Ad, AdSearch, ParseLinks, AlertService, pagingParams, paginationConstants,$http) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                // 判断用户在魔法特工品牌下的权限
                if(vm.account.authorities.indexOf('ROLE_MFTG_MANAGER')>-1){
                    vm.mftgManager=true;
                    vm.auth = "MftgManager";
                    vm.author = "品牌管理员";
                }
            });
        }

        loadAll();

        function loadAll () {
            vm.predicate = 'id';
            vm.reverse =false;
            if (pagingParams.search) {
                AdSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Ad.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.ads = data;
                console.log('data===========',data);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();

            vm.predicate = 'id';
            vm.reverse =false;
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
