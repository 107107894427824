(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("TripModelDialogController", controller);

    controller.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$uibModalInstance",
        "entity",
        "TripModel",
        "$http",
        "$uibModalStack",
    ];

    function controller(
        $timeout,
        $scope,
        $stateParams,
        $uibModalInstance,
        entity,
        TripModel,
        $http,
        $uibModalStack
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        if (entity.id != null ? !entity.canShow : $stateParams.id != null) {
            setTimeout(function () {
                alert("无访问权限");
                clear();
            }, 100);
            return;
        }
        vm.tripModel = entity;
        vm.clear = clear;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });
        if ($stateParams.type != null) {
            vm.tripModel.platform = $stateParams.type; //0为专业版（旧版）1为基础版（小程序）
        }
        function clear() {
            $uibModalInstance.dismiss("cancel");
            $uibModalStack.dismissAll();
        }

        this.$onInit = function () {
            getAccount();
        };

        function getAccount() {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_TRAINEEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "TGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.save = function () {
            vm.isSaving = true;
            //创建线路时给某些字段赋默认值
            vm.tripModel.type = "Normal";
            var date = new Date();
            if (vm.tripModel.id !== null) {
                vm.tripModel.updateDate = date;
                vm.tripModel.updateBy = vm.account.id;
                TripModel.update(vm.tripModel, onSaveSuccess, onSaveError);
            } else {
                if (vm.tripModel.platform == 4) {
                    vm.tripModel.mapStyle = 3;
                }
                vm.tripModel.createDate = date;
                vm.tripModel.createBy = vm.account.id;
                TripModel.save(vm.tripModel, onSaveSuccess, onSaveError);
                // console.log(vm.tripModel);
            }
        };
        vm.saveAndOpen = function () {
            vm.save();
            // window.location.href = "http://acfe.itasktour.com/editor/"+vm.tripModel.id+"?at="  +vm.account.password+"_coach";
            vm.tripModel.isOpenWindow = 1;
        };

        function onSaveSuccess(result) {
            // console.log(result);
            $scope.$emit("taskServiceApp:tripModelUpdate", result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            if (vm.tripModel.isOpenWindow == 1) {
                var acfeUrl = "";
                var onlyid = btoa(
                    result.id +
                        "," +
                        vm.account.appSource +
                        "," +
                        vm.account.login
                );
                if (
                    window.location.origin.indexOf("localhost") > -1 ||
                    window.location.hostname.split(".").length === 4
                ) {
                    acfeUrl =
                        window.location.protocol +
                        "//" +
                        window.location.hostname +
                        ":3000/editor/";
                } else {
                    acfeUrl = vm.account.acfeUrl;
                }

                window.open(
                    acfeUrl +
                        onlyid +
                        "?at=" +
                        vm.account.password +
                        "_coach&origin=" +
                        btoa(window.location.origin),
                    "acfe" + result.id
                );
                vm.tripModel.isOpenWindow = 0;
            }
        }
        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
