(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("LocationDetailController", LocationDetailController);

    LocationDetailController.$inject = [
        "$scope",
        "$rootScope",
        "Principal",
        "previousState",
        "entity",
        "$http",
    ];

    function LocationDetailController(
        $scope,
        $rootScope,
        Principal,
        previousState,
        entity,
        $http
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.location = entity;
        // console.log(vm.location);
        vm.previousState = previousState.name;

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }

        var id = vm.location.id;
        getAllLocatioinTaskes();
        function getAllLocatioinTaskes() {
            $http({
                url: "/api/locationtaskes/" + id,
                method: "GET",
            }).then(
                function succ(res) {
                    //console.log(res);
                    vm.locationTaskes = res.data;
                },
                function error(res) {
                    console.log(res);
                }
            );
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:locationUpdate",
            function (event, result) {
                vm.location = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
