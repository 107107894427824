(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("LoginSelfController", controller);
    controller.$inject = [
        "$rootScope",
        "$scope",
        "$timeout",
        "Principal",
        "$state",
        "Auth",
        "$http",
        "$location",
        "LoginUrlService",
        "$stateParams",
    ];
    function controller(
        $rootScope,
        $scope,
        $timeout,
        Principal,
        $state,
        Auth,
        $http,
        $location,
        LoginUrlService,
        $stateParams
    ) {
        // $rootScope.$on(
        //     "$stateChangeSuccess",
        //     function (ev, to, toParams, from, fromParams) {
        //         // //from为前一个页面的路由信息：url,cache,views,name
        //         $rootScope.previousState = from;
        //         //$rootScope.previousState.url是同一个域名下的上一个页面的路由；
        //         rootPre = $rootScope.previousState.url;
        //     }
        // );
        var vm = this;
        // 登录界面传递的品牌定制的路由参数，用来区分是那个品牌的登录界面；
        vm.appSource = $stateParams.appSource;
        console.log("vm.appSource=" + vm.appSource);
        var titleId = setTimeout(function () {
            document.querySelector("title").innerHTML =
                vm.appSource === "2"
                    ? "魔法特工后台管理中心"
                    : "爱闯闯后台管理中心";
            clearTimeout(titleId);
        }, 1200);
        vm.account = null;
        vm.username = null;
        vm.password = null;
        vm.exit = false;
        // var coachJudge = window.localStorage.getItem("coachJudge");
        // if (coachJudge) {
        //     vm.username = coachJudge.match(/=.+$/)[0].replace(/=/, "");
        // }
        $timeout(function () {
            angular.element("#username").focus();
        });
        $scope.$on("authenticationSuccess", function () {
            getAccount();
        });
        function getAccount() {
            Principal.identity().then(function (account) {
                if (account == null) {
                    return;
                }
                // 用户登录成功需要更新本地存储的品牌定制用户的标识
                localStorage.setItem("appSource", account.appSource);
                // 通过后台返回的数据判断该用户是否为魔法特工app用户；
                if (
                    account.appSource === "2" ||
                    account.login.indexOf("_mftg") > -1
                ) {
                    // 魔法特工平台
                    // 魔法特工app用户禁止登录后台；
                    if (
                        $.inArray("ROLE_USER", account.authorities) !== -1 &&
                        account.authorities.length === 1
                    ) {
                        alert("魔法特工app普通用户无权登录后台！");
                        return false;
                    }
                } else {
                    // 爱闯闯平台
                    // 如果是普通用户（仅在爱闯闯手机注册登录游玩的玩家），跳转到完善资料页面并开放后台权限（完善资料可跳过）
                    if (
                        $.inArray("ROLE_USER", account.authorities) !== -1 &&
                        account.authorities.length === 1
                    ) {
                        $state.go(
                            "register-login",
                            {
                                login: vm.username,
                                pwd: vm.password,
                                loginParam: $location.search(),
                            },
                            { reload: false }
                        );
                        return false;
                    }
                }
                // 用户从别的端跳转到后台进行登录，需要进行判断是否需要跳回来的地方，比如（活动监控等）  这个地方把传递到登录界面所有的参数携带下边这个方法里边去了
                LoginUrlService.login($location.search(), account);
            });
        }
        vm.login = function (event) {
            event.preventDefault();
            vm.checkPwd = null;
            vm.exit = null;
            if (vm.username === null || vm.username === "") {
                vm.exit = 2; //电话格式不对
                return;
            }
            switch (vm.appSource) {
                //爱闯闯品牌
                case "1":
                    if (vm.username == "admin") {
                        loginConfirm("");
                    } else {
                        //激活
                        $http({
                            url:
                                "/api/e-accounts/check-activate?login=" +
                                vm.username +
                                "&password=" +
                                vm.password,
                            method: "GET",
                        }).success(function (res) {
                            if (res == 0) {
                                // 如果企业的成员为超管，就算是企业处于冻结状态，依然可以登录，如果不是超管，则走解冻企业逻辑
                                loginConfirm("");
                            } else if (res == -2) {
                                //当前用户不存在
                                vm.exit = 3;
                            } else if (res == -3) {
                                vm.checkPwd = "密码错误";
                            } else {
                                //本来是企业输入邀请码，这里改成登录以后填写调查信息
                                loginServer("freeze", "");
                            }
                        });
                    }
                    break;
                // 魔法特工品牌
                case "2":
                    // 检测登录用户是否存在
                    $http({
                        url:
                            "/api/e-accounts/check-activate?login=" +
                            vm.username +
                            "_mftg&password=" +
                            vm.password,
                        method: "GET",
                    }).success(function (res) {
                        if (res == 0 || res == 3) {
                            loginConfirm("_mftg");
                        } else if (res == -2) {
                            //当前用户不存在
                            vm.exit = 3;
                        } else if (res == -3) {
                            vm.checkPwd = "密码错误";
                        } else if (res == -4) {
                            vm.checkPwd = null;
                            alert("魔法特工手机用户禁止登录后台");
                        } else if (res == 4) {
                            vm.checkPwd = null;
                            alert("账户已失效!");
                        } else {
                            // 魔法特工停运和失效账户处理逻辑
                            loginServer("freeze", "_mftg");
                        }
                    });
                    break;
            }
        };

        function loginConfirm(suffix) {
            $http({
                url: "/api/registerCheckLogin",
                method: "POST",
                params: {
                    login: vm.username + suffix,
                    rabbitLeaderFlag: false,
                },
            }).success(function (res) {
                if (res.errorCode == -1) {
                    vm.exit = 3; //当前用户不存在
                } else if (res.errorCode == -2) {
                    vm.exit = 2; //邀请码错误
                } else if (res.errorCode == -3) {
                    vm.exit = 4; //邀请码已失效
                } else {
                    loginServer("login", suffix);
                }
            });
        }

        //type 登录状态，login正常登录，freeze,冻结登录
        function loginServer(type, suffix) {
            //Auth.login这个方法在"src\main\webapp\app\services\auth\auth.service.js"中
            Auth.login({
                username: vm.username + suffix,
                password: vm.password,
                rememberMe: vm.rememberMe,
            })
                .then(function (data) {
                    if (type == "freeze") {
                        //进入冻结状态
                        $state.go(
                            "e-account-my.freeze",
                            { loginParam: $location.search() },
                            { reload: false }
                        );
                        return;
                    }
                    getAccount();
                })
                .catch(function (err) {
                    vm.checkPwd = "密码错误";
                });
        }
        vm.openWeb = function () {
            var judgeUrl = "";
            if (
                window.location.origin.indexOf("localhost") > -1 ||
                window.location.hostname.split(".").length === 4 ||
                window.location.origin.indexOf("dev") !== -1
            ) {
                if (vm.appSource === "1") {
                    judgeUrl =
                        "http://dev.itasktour.com/index.php?go=ittrweb.index.index";
                } else if (vm.appSource === "2") {
                    judgeUrl = "https://www.mofategong.com";
                }
            } else {
                if (vm.appSource === "1") {
                    judgeUrl = "https://www.itasktour.com";
                } else if (vm.appSource === "2") {
                    judgeUrl = "https://www.mofategong.com";
                }
            }
            window.location.href = judgeUrl;
        };

        vm.forgetPassword = function () {
            if (vm.appSource === "1") {
                $state.go(
                    "forget-pwd",
                    { loginParam: $location.search() },
                    { reload: false }
                );
            } else if (vm.appSource === "2") {
                $state.go("mftgforget-pwd", { reload: false });
            }
        };
        $scope.$on("$destroy", function () {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
        });
    }
})();
