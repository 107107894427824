(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("BaseTaskDetailController", BaseTaskDetailController);

    BaseTaskDetailController.$inject = [
        "MyEquip",
        "$scope",
        "$rootScope",
        "Principal",
        "$stateParams",
        "previousState",
        "entity",
        "$http",
        "$sce",
    ];

    function BaseTaskDetailController(
        MyEquip,
        $scope,
        $rootScope,
        Principal,
        $stateParams,
        previousState,
        entity,
        $http,
        $sce
    ) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.AR_SERVER = AR_SERVER;
        vm.baseTask = entity;
        vm.previousState = previousState.name;
        this.$onInit = function () {
            if (vm.baseTask.equip) {
                MyEquip.get(vm.baseTask.equip).then(function (response) {
                    var data = response.data;
                    vm.baseTask.equip = { id: data.id, name: data.name };
                    // console.log(vm.baseTask.equip)
                });
            }
        };

        getAccount();
        function getAccount() {
            // Principal.identity().then(function(account) {
            $http({
                url: "api/account",
                method: "GET",
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    } else if (
                        $.inArray("ROLE_INDIEDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "IGC";
                    } else if (
                        $.inArray("ROLE_OPERATION", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "Service";
                    } else if (
                        $.inArray("ROLE_USERDESIGN", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "UGC";
                    } else if (
                        $.inArray("ROLE_USER", author) !== -1 &&
                        $.inArray("ROLE_SUPER", author) === -1
                    ) {
                        vm.auth = "User";
                    }
                }
            });
        }
        // if (vm.baseTask.canEdit==false){
        //     $("#baseTaskEdit").attr("disabled", true);
        //     // $("#baseTaskDelete").attr("disabled", true);
        // }

        if (vm.baseTask.type == "NEWAR" || vm.baseTask.type == "AR") {
            $scope.htmlTooltip = $sce.trustAsHtml(
                '<p style="text-align: left"><br>AR升级中尽情期待</p>'
            );
        }

        if (vm.baseTask.type == "NEWAR" && vm.baseTask.newArModelId != null) {
            $http({
                method: "GET",
                // url: 'http://artest.itasktour.com/api/armodel/detail?ar_model_id='+id
                // url: vm.AR_SERVER+'/api/armodel/detail?ar_model_id='+vm.baseTask.newArModelId
                url: "api/ar-model-news/" + vm.baseTask.newArModelId,
            }).then(
                function (res) {
                    console.log(res);
                    if (res != null && res.data != null) {
                        // var img = res.data.scan_image;
                        // if (img != null){
                        //     if (img.indexOf(vm.IMAGE_SERVER)!=-1){
                        //         vm.baseTask.scanImg = img.split(vm.IMAGE_SERVER)[1];
                        //     }
                        // }
                        // console.log(vm.baseTask.scanImg);
                        if (res.data.ar_model_type == "bomb_code") {
                            vm.arModelType = "炸弹-密码拆除";
                        } else if (res.data.ar_model_type == "code_case") {
                            vm.arModelType = "密码箱";
                        } else if (
                            res.data.ar_model_type == "custom_resource"
                        ) {
                            vm.arModelType = "平面资源";
                        } else if (res.data.ar_model_type == "bomb_lead") {
                            vm.arModelType = "炸弹-引线拆除";
                        } else if (res.data.ar_model_type == "treasure_box") {
                            vm.arModelType = "宝箱";
                        } else if (res.data.ar_model_type == "phone") {
                            vm.arModelType = "电话平面拨号";
                        }
                        if (res.data.ar_model_type == "bomb_code") {
                            vm.times = res.data.times;
                        }
                        if (
                            res.data.ar_model_type == "bomb_code" ||
                            res.data.ar_model_type == "code_case" ||
                            res.data.ar_model_type == "treasure_box"
                        ) {
                            if (
                                res.data.password != null &&
                                typeof res.data.password != "undefined"
                            ) {
                                vm.password = res.data.password;
                            }
                        }
                        if (res.data.ar_model_type == "bomb_lead") {
                            if (res.data.password == 1) {
                                vm.lead = "红线";
                            } else if (res.data.password == 2) {
                                vm.lead = "黄线";
                            } else if (res.data.password == 3) {
                                vm.lead = "蓝线";
                            } else if (res.data.password == 4) {
                                vm.lead = "绿线";
                            }
                        }
                        if (res.data.ar_model_type == "phone") {
                            vm.phone = res.data.phone;
                        }
                    }
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        getTripModel();
        function getTripModel() {
            $http({
                method: "GET",
                url: "/api/location-tasks/" + vm.baseTask.myLocationTaskId,
            }).then(
                function (res) {
                    vm.locationTask = res.data;
                    // console.log("locationTask:"+res.data);
                    $http({
                        method: "GET",
                        url:
                            "/api/trip-models/" + vm.locationTask.myTripModelId,
                    }).then(
                        function (res) {
                            vm.tripModel = res.data;
                        },
                        function (err) {
                            console.log(err);
                        }
                    );
                },
                function (err) {
                    console.log(err);
                }
            );
        }

        var unsubscribe = $rootScope.$on(
            "taskServiceApp:baseTaskUpdate",
            function (event, result) {
                vm.baseTask = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
