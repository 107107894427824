(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("ProgramCenterDetailShareController", controller)
        .filter("pdfFilter", function () {
            return function (text) {
                return text + ".pdf";
            };
        })
        .filter("labelFilter", function () {
            return function (text) {
                var arr = JSON.parse(text);
                return arr;
            };
        });
    controller.$inject = ["$state", "$http", "$stateParams", "$sce"];
    function controller($state, $http, $stateParams, $sce) {
        // 通过分享访问不需要登录的方案详情页，
        var vm = this;
        //schemeId(超管->综合管理->方案管理，添加的方案id)
        vm.schemeId = $stateParams.id;
        document.documentElement.scrollTop = 0;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        //vm.programDetail.tripModelId  该方案的示范线路id，超管在方案管理界面添加一个方案，可以为这个方案指定示范线路id，
        vm.programDetail = null;
        // 统计用户打开外链的行为
        doSaveClickNum(2);
        md("方案-打开分享页-" + vm.schemeId);
        // 用户是否登录 经过查找发现，本地缓存的框架的token是唯一判断用户是否登录的依据，
        vm.isLogin = Boolean(localStorage.getItem("jhi-authenticationToken"));
        // 外链界面用户已经登录的情况下直接跳转到后台的方案详情页面
        if (vm.isLogin) {
            $state.go(
                "program-center-detail",
                { id: vm.schemeId },
                { reload: true }
            );
        }
        // 方案详情外链页隐藏导航栏
        $(".container").removeClass("login-container-content");
        $("#nav, #foot").css("display", "block");
        $("body").css("background-color", "#1D2B3C");
        // 获取指定方案id的方案数据
        $http({
            method: "GET",
            url: "/api/program/getByid/" + vm.schemeId,
        }).then(function (res) {
            vm.programDetail = res.data;
            document.title = vm.programDetail.title;
            vm.programDetail.programText = $sce.trustAsHtml(
                vm.programDetail.programText
            );
            vm.programDetail.manualText = $sce.trustAsHtml(
                vm.programDetail.manualText
            );
        });
        // 正式服的微信分享逻辑
        if (window.location.origin.indexOf("v3") != -1) {
            $http({
                method: "POST",
                url: "/api/program/share",
                params: {
                    id: vm.schemeId,
                    url: window.location.href,
                },
            }).then(
                function (res) {
                    console.log(res.data);
                    wechatStart(res.data);
                },
                function (err) {
                    console.log(err);
                }
            );
        }
        function wechatStart(obj) {
            var linkURL = window.location.href;
            wx.config({
                debug: false, // true时，手机微信环境访问会alert一些errmsg信息，如果显示errmsg:ok表示正常，否认表示出了问题
                appId: obj.appId, // 必填，公众号的唯一标识
                timestamp: obj.timestamp, // 后台返回的数据中取
                nonceStr: obj.nonceStr, // 后台返回的数据中取
                signature: obj.signature, // 后台返回的数据中取
                jsApiList: [
                    "updateTimelineShareData",
                    "updateAppMessageShareData",
                ], // 必填，需要使用的JS接口列表
            });
            // 需在用户可能点击分享按钮前就先调用，说白了就是放在文章详情信息读取完后就可以执行下面的wx.ready()方法
            wx.ready(function () {
                // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                wx.updateAppMessageShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function () {
                        // 设置成功
                    },
                });
                // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
                wx.updateTimelineShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function () {
                        // 设置成功
                    },
                });
            });
        }
        // 用户在方案详情页的行为统计
        // type 1: centerClickNum(从方案中心点击进入到方案详情页); 2:shareOpenNum(方案详情页分享外链页面打开次数); 3:programDownloadNum（用户点击下载方案介绍的次数） ;4:manualDownloadNum（用户点击下载执行手册的次数）; 5:tripClickNum（用户点击复制某线路的次数）
        function doSaveClickNum(type) {
            $http({
                method: "POST",
                url: "/api/program/saveNum",
                params: {
                    id: vm.schemeId,
                    type: type,
                },
            }).then(
                function (res) {},
                function (err) {
                    console.log(err);
                }
            );
        }
        // 统计用户打开分享的方案详情外链的次数
        function md(strAction) {
            $http({
                method: "POST",
                url: "/api/mdLog/save",
                params: {
                    type: 1,
                    action: strAction,
                },
            }).then(
                function (res) {},
                function (err) {
                    console.log(err);
                }
            );
        }
        // 手机端调用手机电话的方法
        vm.toPhone = function (phone) {
            window.location.href = "tel://" + phone;
        };
        // 用户未登录下载文件先跳转到登录界面登录
        vm.pdfDownload = function () {
            login();
        };
        // 打开联系我们的弹窗的功能
        vm.openModelid = 2;
        // 在手机端的联系我们是通过弹窗展示，默认值设置为0，不让弹窗展示
        if ($(window).width() < 767) {
            vm.openModelid = 0;
        }
        vm.openModel = function (id) {
            vm.openModelid = id;
            if ($(window).width() < 767) {
                $("#myModal").modal("show");
            }
        };
        vm.closeOpenModel = function () {
            $("#myModal").modal("hide");
            vm.openModelid = 0;
        };
        vm.openModel2 = function () {
            if ($(window).width() < 767) {
                $("#myModal").modal("show");
                vm.openModelid = 1;
            } else {
                $("#myModal2").modal("show");
            }
        };
        vm.closeOpenModel2 = function () {
            $("#myModal2").modal("hide");
        };
        vm.changeTab = 1;
        vm.doChange = function (changeTab) {
            vm.changeTab = changeTab;
        };
        // 当前页面仅在用户未登录的情况下展示，用户只能点击立即登录按钮或者下载文件按钮跳转到登录界面，用户登录之后再跳转到登陆后的当前方案详情页
        vm.login = login;
        function login() {
            window.location.href =
                window.location.origin +
                "/#/?url=" +
                decodeURIComponent("program-center-detail/" + vm.schemeId);
        }
    }
})();
