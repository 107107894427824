(function () {
    "use strict";

    angular.module("taskServiceApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("e-account", {
                parent: "entity",
                url: "/e-account?page&sort&search",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/e-account/e-accounts.html",
                        controller: "EAccountController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {},
            })
            .state("e-account-my", {
                url: "/e-account/my?page",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "企业账户",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/my-e-account-detail.html",
                        controller: "MyEAccountDetailController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    eid: null, //用户要加入的企业id
                    uid: null, //用户自己的登录id
                    joinSuccess: false, //加入成功，给一个小的提示
                },
                resolve: {
                    entity: [
                        "MyEAccount",
                        function (MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                },
            })
            .state("e-account-coupons-detail", {
                url: "/e-account/coupons-detail/{eId}?page&sort&search",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-coupons-detail.html",
                        controller: "EAccountCouponsDetailController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                    eId: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            // console.log(currentStateData);
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-my-purchase", {
                url: "/e-account/my/purchase",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-my-purchase.html",
                        controller: "EAccountMyPurchaseController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-my-pay", {
                url: "/e-account/my/pay/orderId=:orderId",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-my-pay.html",
                        controller: "EAccountMyPayController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    orderId: null,
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-my-pay-success", {
                url: "/e-account/my/pay/success",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-my-pay-success.html",
                        controller: "EAccountMyPaySuccessController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-my-pay-error", {
                url: "/e-account/my/pay/error",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-my-pay-error.html",
                        controller: "EAccountMyPayErrorController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-my-pay-wait", {
                url: "/e-account/my/pay/wait",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-my-pay-wait.html",
                        controller: "EAccountMyPayWaitController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-member", {
                url: "/e-account/member",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-member.html",
                        controller: "MyEAccountMemberController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-member.new", {
                parent: "e-account-member",
                url: "/new",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/e-account/e-account-member-new.html",
                                controller: "EAccountMemberNewController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "MyEAccount",
                                        function (MyEAccount) {
                                            return MyEAccount.get().$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("e-account-member", null, {
                                        reload: "e-account-member",
                                    });
                                },
                                function () {
                                    $state.go("e-account-member");
                                }
                            );
                    },
                ],
            })
            .state("e-account-my.check", {
                url: "/e-account/my/check/{id}",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/check-e-account-detail.html",
                        controller: "CheckEAccountDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "EAccount",
                        function ($stateParams, EAccount) {
                            return EAccount.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("check-pass-e-account", {
                url: "/e-account/check/pass",
                parent: "entity",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/check-pass-e-account.html",
                        controller: "CheckPassEAccountController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("e-account-my.init", {
                url: "/e-account/my/init",
                parent: "e-account-my",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-init-dialog.html",
                        controller: "InitEAccountDialogController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            email: null,
                            description: null,
                            registerNo: null,
                            licence: null,
                            web: null,
                            coupons: null,
                            lockCoupons: null,
                            adminAccount: null,
                            status: null,
                            createDate: null,
                            createBy: null,
                            updateDate: null,
                            updateBy: null,
                            companyRegion: null,
                            id: null,
                        };
                    },
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            // 激活冻结配置
            .state("e-account-my.freeze", {
                parent: "e-account-my",
                url: "/e-account/my/freeze",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                params: {
                    loginParam: {},
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/activate-freeze.html",
                        controller: "ActivateFreezeController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                },
            })
            .state("e-account-my.edit", {
                parent: "e-account-my",
                url: "/e-account-my/edit",
                data: {
                    authorities: ["ROLE_USER"],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/my-e-account-dialog.html",
                        controller: "MyEAccountDialogController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                },
            })
            .state("e-account-detail", {
                parent: "e-account",
                url: "/e-account/{id}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "taskServiceApp.eAccount.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account/e-account-detail.html",
                        controller: "EAccountDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "EAccount",
                        function ($stateParams, EAccount) {
                            return EAccount.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            /*
            企业账户报表相关
         */
            //V2盈利明细报表
            .state("report-detail", {
                parent: "report-check",
                url: "/report/detail/{id}/{startTime}/{endTime}",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "企业账户报表明细",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account-report/report-detail.html",
                        controller: "ReportDetailController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            // $translatePartialLoader.addPart('eAccount');
                            $translatePartialLoader.addPart("eStatus");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })

            //点击 V1盈利 明细报表
            .state("report-agent-detail-v1", {
                parent: "entity",
                url: "/report/agent-detail-v1?id&agentLevel",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "V1盈利明细报表",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account-report/report-agent-detail-v1.html",
                        controller: "ReportAgentDetailV1Controller",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                    id: null,
                    agentLevel: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            // $translatePartialLoader.addPart('eAccount');
                            $translatePartialLoader.addPart("eStatus");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            //点击 通过ACC收费（V2）弹框弹出
            .state("report-agent-detail-v2", {
                parent: "entity",
                url: "/report/agent-detail-v2?id&agentLevel",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "ACC收费（V2）明细表",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/e-account-report/report-agent-detail-v2.html",
                        controller: "ReportAgentDetailV2Controller",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                    id: null,
                    agentLevel: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            // $translatePartialLoader.addPart('eAccount');
                            $translatePartialLoader.addPart("eStatus");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "MyEAccount",
                        function ($stateParams, MyEAccount) {
                            return MyEAccount.get().$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "e-account",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("exportRecharge", {
                parent: "entity",
                url: "/export-recharge",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/e-account-report/export-recharge-record.html",
                                controller: "ExportRechargeController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: function () {
                                        return {
                                            tripId: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("program-center");
                                },
                                function () {
                                    $state.go("program-center");
                                }
                            );
                    },
                ],
            })
            .state("exportConsume", {
                parent: "entity",
                url: "/export-consume",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/e-account-report/export-consume-record.html",
                                controller: "ExportConsumeController",
                                controllerAs: "vm",
                                backdrop: "static",
                                size: "md",
                                resolve: {
                                    entity: function () {
                                        return {
                                            tripId: null,
                                        };
                                    },
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("program-center");
                                },
                                function () {
                                    $state.go("program-center");
                                }
                            );
                    },
                ],
            });
    }
})();
