(function () {
    "use strict";
    angular.module("taskServiceApp").factory("Register", Register);
    Register.$inject = ["$http"];
    function Register($http) {
        return {
            getRegist: function (registerAccount) {
                var url = "/api/registerBackGroundNew";
                var data = {
                    login: registerAccount.login,
                    validationCode: registerAccount.code,
                    password: registerAccount.password,
                    sessionId: registerAccount.sessionId,
                    idCard: registerAccount.idCard,
                };
                if (registerAccount.appSource == "2") {
                    data.appSource = registerAccount.appSource;
                }

                return $http({
                    // url: '/api/registerBackGround',
                    url: url,
                    method: "POST",
                    data: data,
                });
            },
        };
    }
})();
