(function () {
    "use strict";

    angular
        .module("taskServiceApp")
        .controller("ExportTsTripController", controller);

    controller.$inject = ["$timeout", "$uibModalInstance"];

    function controller($timeout, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
    }
})();
