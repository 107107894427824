(function () {
    "use strict";
    angular.module("taskServiceApp").factory("EAccount", EAccount);

    EAccount.$inject = ["$resource", "DateUtils"];

    function EAccount($resource, DateUtils) {
        var resourceUrl = "api/e-accounts/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }
})();
//查询我的企业账号的操作记录。
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("MyEAccountRecords", MyEAccountRecords);

    MyEAccountRecords.$inject = ["$resource", "DateUtils"];

    function MyEAccountRecords($resource, DateUtils) {
        var resourceUrl = "api/e-accounts/my-e-account/records";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("MyEAccountFreezeRecords", MyEAccountFreezeRecords);

    MyEAccountFreezeRecords.$inject = ["$resource", "DateUtils"];

    function MyEAccountFreezeRecords($resource, DateUtils) {
        var resourceUrl = "api/e-accounts/my-e-account/records/freeze";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();
//根据企业账号ID查询操作记录。
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("OneEAccountRecords", OneEAccountRecords);

    OneEAccountRecords.$inject = ["$resource", "$stateParams"];

    function OneEAccountRecords($resource, $stateParams) {
        // console.log($stateParams);
        var resourceUrl = "api/e-accounts/e-account-one/records";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );
    }
})();

(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("MyEAccountConfirm", MyEAccountConfirm);

    MyEAccountConfirm.$inject = ["$resource", "DateUtils"];

    function MyEAccountConfirm($resource, DateUtils) {
        var resourceUrl = "api/e-accounts/my-e-account/confirm";

        return $resource(
            resourceUrl,
            {},
            {
                confirmEAccount: { method: "POST" },
                activeKey: { method: "POST" },
            }
        );
    }
})();

(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("MyEAccountCheck", MyEAccountCheck);
    MyEAccountCheck.$inject = ["$resource", "DateUtils"];

    function MyEAccountCheck($resource, DateUtils) {
        var resourceUrl = "api/e-accounts/my-e-account/check";

        return $resource(
            resourceUrl,
            {},
            {
                checkPass: { method: "POST" },
            }
        );
    }
})();
(function () {
    "use strict";
    angular.module("taskServiceApp").factory("MyEAccount", MyEAccount);

    MyEAccount.$inject = ["$resource", "DateUtils"];

    function MyEAccount($resource, DateUtils) {
        var resourceUrl = "api/e-accounts/my-e-account";

        return $resource(
            resourceUrl,
            {},
            {
                save: {
                    method: "POST",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
                deleteDegigner: {
                    method: "PUT",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
                addDegigner: {
                    method: "POST",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
            }
        );
    }
})();
(function () {
    "use strict";
    angular.module("taskServiceApp").factory("MyEAccountPay", MyEAccountPay);

    MyEAccountPay.$inject = ["$http"];

    function MyEAccountPay($http) {
        return {
            getOrder: function (param) {
                var data = $http({
                    method: "POST",
                    url: "/api/create/coupons-up/order/",
                    data: {
                        coupons: param.cou,
                        duration: param.duration,
                        eAccountId: param.eId,
                        extraCoupon: param.extraCoupon,
                        rechargeNo: param.rechargeNo,
                        salePrice: param.sPrice,
                        type: param.eType,
                    },
                });
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            },
        };
    }
})();
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("MyEAccountUnionPay", MyEAccountUnionPay);

    MyEAccountUnionPay.$inject = ["$http"];

    function MyEAccountUnionPay($http) {
        return {
            getMyOrder: function (param) {
                var data = $http({
                    method: "POST",
                    url: "/api/create/unionpay/consume/",
                    data: {
                        coupons: param.cou,
                        duration: param.duration,
                        eAccountId: param.eId,
                        extraCoupon: param.extraCoupon,
                        rechargeNo: param.rechargeNo,
                        salePrice: param.sPrice,
                        type: param.eType,
                    },
                });
                return data;
            },
        };
    }
})();
(function () {
    "use strict";
    angular.module("taskServiceApp").factory("GiveCoupons", GiveCoupons);

    GiveCoupons.$inject = ["$http"];

    function GiveCoupons($http) {
        return {
            isGiveCoupons: function (param) {
                var data = $http({
                    method: "POST",
                    url: "/api/e-accounts/check-sale?saleId=" + param.saleId,
                });
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            },
        };
    }
})();
(function () {
    "use strict";
    angular.module("taskServiceApp").factory("AllCoupons", AllCoupons);

    AllCoupons.$inject = ["$http"];

    function AllCoupons($http) {
        return {
            allCoupons: function (id) {
                var data = $http({
                    method: "GET",
                    url:
                        "/api/recharge-coupons/findRechargeCoupon?eAccountId=" +
                        id,
                });
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            },
        };
    }
})();
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("MyEAccontUpdate", MyEAccontUpdate);

    MyEAccontUpdate.$inject = ["$resource", "DateUtils"];

    function MyEAccontUpdate($resource, DateUtils) {
        var resourceUrl = "api/e-accounts/my-e-account/update";

        return $resource(
            resourceUrl,
            {},
            {
                updateDesign: {
                    method: "PUT",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
            }
        );
    }
})();
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("ReportRemittance", ReportRemittance);

    ReportRemittance.$inject = ["$http"];

    function ReportRemittance($http) {
        return {
            getRemittance: function (param) {
                // console.log(param);
                var data = $http({
                    method: "GET",
                    url:
                        "/api/report/findProfitToV2?startTime=" +
                        param.data.startTime +
                        "&&endTime=" +
                        param.data.endTime,
                    // data: {startTime:param.data.startTime,endTime:param.data.endTime}
                });
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            },
        };
    }
})();

//查询 V1盈利明细列表
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("ReportAgentDetialV1", ReportAgentDetialV1);

    ReportAgentDetialV1.$inject = ["$http"];

    function ReportAgentDetialV1($http) {
        return {
            getReportAgentDetialV1Data: function (param) {
                // console.log(param);
                var data = $http({
                    method: "GET",
                    url:
                        "/api/report/findAccProfitV1?eAccountId=" +
                        param.data.eAccountId +
                        "&agentLevel=" +
                        param.data.agentLevel +
                        "&startTime=" +
                        param.data.startTime +
                        "&endTime=" +
                        param.data.endTime,
                });
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            },
        };
    }
})();
//查询 通过ACC收费（V2）弹框弹出
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("ReportAgentDetialV2", ReportAgentDetialV2);

    ReportAgentDetialV2.$inject = ["$http"];

    function ReportAgentDetialV2($http) {
        return {
            getReportAgentDetialV2Data: function (param) {
                // console.log(param);
                var data = $http({
                    method: "GET",
                    url:
                        "/api/report/findAccProfitV2?eAccountId=" +
                        param.data.eAccountId +
                        "&agentLevel=" +
                        param.data.agentLevel +
                        "&startTime=" +
                        param.data.startTime +
                        "&endTime=" +
                        param.data.endTime,
                });
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            },
        };
    }
})();
// 企业充值支付订单
(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .factory("getOrderByOrderId", getOrderByOrderId);
    getOrderByOrderId.$inject = ["$resource", "DateUtils"];
    function getOrderByOrderId($resource, DateUtils) {
        var resourceUrl = "/api/coupons-detail/:orderId";
        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.createDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.createDate
                                );
                            data.updateDate =
                                DateUtils.convertDateTimeFromServer(
                                    data.updateDate
                                );
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }
})();
