(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('getDesignList', factory);
    factory.$inject = ['$resource', 'DateUtils'];
    function factory ($resource) {
        var resourceUrl =  'api/designer/findAllDesigners';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('getUpDesignList', factory);
    factory.$inject = ['$resource', 'DateUtils'];
    function factory ($resource) {
        var resourceUrl =  'api/designer/findUpDesigners';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'update': { method:'PUT' }
        });
    }
})();
